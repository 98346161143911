import React from 'react'
import NoData from '@components/NoData/NoData'
import withMyStrategyTab from './withMyStrategyTab'
import StrategyCard from '../StrategyCard'
import { MyStrategyTabProps } from './interface'

function MyStrategyTab({
  strategies,
  handleDeleteStrategy,
}: MyStrategyTabProps) {
  return (
    <div className='grid grid-col-1 gap-y-[8px] pt-[42px] px-[16px]'>
      {strategies?.length ? (
        strategies.map(strategy => (
          <StrategyCard
            key={strategy.id}
            strategy={strategy}
            handleDeleteStrategy={handleDeleteStrategy}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default withMyStrategyTab(MyStrategyTab)
