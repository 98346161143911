import React from 'react'
import { toast } from 'react-toastify'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useParams } from 'react-router-dom'
import {
  StopCoveringButtonAcceptProps,
  StopCoveringButtonProps,
} from '@features/bot/pages/BotPage/components/StopCoveringButton/interface'
import { useStopCoveringMutation } from '@client/botClient/collections/StopCoveringClientAction'

const WithStopCoveringButton = (
  Component: React.FC<StopCoveringButtonProps>
) => {
  const Hoc = ({
    refetch,
    isStopCovering,
    isDisable,
  }: StopCoveringButtonAcceptProps) => {
    const { id } = useParams()
    const [stopCoveringMutate, { isLoading: isOneOffLoading }] =
      useStopCoveringMutation()
    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to {!isStopCovering ? 'stop' : 'resume'} cover
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleOnStopCovering = () => {
      if (isDisable) return
      openModal({
        onConfirm: async () => {
          await stopCoveringMutate({
            botId: Number(id),
            isStopCovering: !isStopCovering,
          })
          toast.success(
            `${!isStopCovering ? 'Stop' : 'Resume'} cover successful`
          )
          refetch()
        },
      })
    }
    const newProps: StopCoveringButtonProps = {
      handleOnStopCovering,
      isStopCovering,
      isDisable,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithStopCoveringButton
