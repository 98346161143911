export const botQueryDocument = `
          subscription Bot($botId: Float!) {
            subscribeToBot(BotID: $botId) {
              id
              symbol
              isOneOff
              lastPrice
              nextOrderPrice
              nextTakeProfitPrice
              botControllerId
              botController {
                createdAt
                totalCycle
                cycleRound
              }
              botStrategy {
                tpRetracement
                leverage
                firstOrderRawQuote
                isInfinityCycle
                name
                side
                isCycle
                coveringQuantity
                tpRatio
                isFirstOrderDouble
                isCovering
                coverSpreads
                coverMultiples
                pullbackSpreads
                isSplitTp
                tpRatioSplit
                tpRetracementSplit
                isStopLoss
                stopLossRatio
                stopLossLimit
                cycleQuantity
                limitPrice
              }
              positionAvgPrice
              position {
                percentage
                contracts
                collateral
                unrealizedPnl
              }
              lastPrice
              profit
              status
              coverRound
              endAt
              createdAt
              errorDescription
              pullbackBoundaryPrice
              pullbackActivePrice
              tprBoundaryPrice
              tprActivePrice
              cycleNo
              startedAt
              isStopCovering
            }
          }
        `
