import React from 'react'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { CreateEasyTradeOwnerSelectPageProps } from '@features/bot/pages/CreateEasyTradeOwnerSelectPage/interface'
import NoData from '@components/NoData/NoData'
import cn from 'classnames'
import Loading from '@components/utils/Loading'
import withCreateEasyTradeOwnerSelectPage from './withCreateEasyTradeOwnerSelectPage'

const CreateEasyTradeOwnerSelectPage = ({
  easyTradeOwners,
  selectedOwner,
  handleSelectedOwner,
  handleOnNext,
  isLoading,
}: CreateEasyTradeOwnerSelectPageProps) => {
  if (isLoading) {
    return <Loading text='Loading...' />
  }
  if (easyTradeOwners && easyTradeOwners.length) {
    return (
      <div className='max-w-[700px] mx-auto '>
        <div className='pt-[22px] px-[15px]'>
          <div className='text-sm font-medium mb-[9px]'>Select template</div>
          {easyTradeOwners.map((owner, index) => (
            <div
              key={owner.id}
              className={cn(
                'border border-gray-300 rounded-[6px] px-[20px] py-[16px] cursor-pointer',
                {
                  '!border-primary': selectedOwner === owner?.id,
                }
              )}
              onClick={() => handleSelectedOwner(owner.id)}
              data-test-id={`easy-trade-owner-card-${index}`}
            >
              <div className='flex gap-x-[10px]'>
                <img
                  src={owner.logoImgPath}
                  alt={owner.name}
                  className='w-[38px] h-[38px] rounded-full'
                />
                <div className='flex flex-col gap-y-[2px]'>
                  <div className='text-sm font-bold leading-[22px]'>
                    {owner.name}
                  </div>
                  <div className='text-xs font-light'>{owner.description}</div>
                </div>
              </div>
            </div>
          ))}
          <PrimaryButton
            type='button'
            className='w-full rounded-[15px] h-[40px] mt-[25px]'
            disabled={!selectedOwner}
            onClick={handleOnNext}
            data-test-id='easy-trade-owner-btn'
          >
            Next
          </PrimaryButton>
        </div>
      </div>
    )
  }
  return <NoData />
}

const WrappedComponent = withCreateEasyTradeOwnerSelectPage(
  CreateEasyTradeOwnerSelectPage
)
export default WrappedComponent
