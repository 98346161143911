import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { classToPlain, plainToClass } from 'class-transformer'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useCreateBotMutation } from '@client/botClient/collections/CreateBotClientAction'
import { CreateBotForm } from '@models/Bot/CreateBotForm'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useLazyFutureMarketLeverageQuery } from '@client/exchangeClient/collections/FutureMarketLeverage'
import { botSettingSchema } from '@constant/form/createBotForm/validationSchema'
import { CoverSpreadsForm } from '@models/Bot/CoverSpreadsForm'
import { CoverMultiplesForm } from '@models/Bot/CoverMultiplesForm'
import { ProfitProportionForm } from '@models/Bot/ProfitProportionForm'
import { useUpsertBotStrategiesDefaultMutation } from '@client/botClient/collections/UpsertBotStrategiesDefaultClientAction'
import {
  initialCoverMultiplesValue,
  initialCoverSpreadsValue,
  initialProfitProportionValue,
  initialPullbackSpreadsValue,
  initialTpRatioSplitValue,
  initialTpRetracementSplitValue,
  initialValues,
} from '@constant/form/createBotForm/initialValues'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { useLazyBotStrategyQuery } from '@client/botClient/collections/BotStrategyClientAction'
import { MainForm } from '@models/Bot/MainForm'
import { BotStrategiesMainForm } from '@models/Bot/BotStrategiesMainForm'
import { FutureMarketLeverage } from '@models/FutureMarketLeverage/FutureMarketLeverage'
import { useLazyMinInitialMarginQuery } from '@client/exchangeClient/collections/MinInitialMargin'
import { useDebounce } from '@hooks/useDebounce'
import { useUpdateBotMutation } from '@client/botClient/collections/UpdateBotClientAction'
import { UpdateBotForm } from '@models/Bot/UpdateBotForm'
import { TpSplitForm } from '@models/Bot/TpSplitForm'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { useLazyGetTradeCopyByBotQuery } from '@client/botClient/collections/GetTradeCopyByBot'
import { TradeCopyData } from '@models/TradeCopy/TradeCopyData'
import { PullbackSpreadsForm } from '@models/Bot/PullBackSpreadsForm'
import { botModeFormToStrategyUpdateType } from '@interfaces/StrategyUpdateTypeEnum'

import { useMarketTickersQuery } from '@client/exchangeClient/collections/MargetTickersClientAction'
import { MarketTickerOption } from '@models/MarketTicker/MarketTickerOption'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { BotMarketType } from '@interfaces/BotMarketEnum'

import { BotSettingFormAcceptProps, BotSettingFormProps } from './interface'

const withBotSettingForm = (Component: React.FC<BotSettingFormProps>) => {
  function WithBotSettingForm({
    mode,
    initialForm,
    selectedBot,
    selectedPersonalId,
    initialCoverMultipleForm,
    initialCoverSpreadsForm,
    initialPullbackSpreadsForm,
    initialTpRatioSplitForm,
    initialTpRetracementSplitForm,
    bot,
    ...props
  }: BotSettingFormAcceptProps) {
    const { isFuture, botMarketType } = useBotMarketType()
    const botInitialValues = initialValues(botMarketType)
    const botRoute = isFuture ? ContractRoutesPath : SpotRoutesPath
    const navigate = useNavigate()
    const { id } = useParams()
    const [params] = useSearchParams()
    const personalId = params.get('personalId')
      ? Number(params.get('personalId'))
      : undefined
    const [maxLeverage, setMaxLeverage] = useState(125)
    const [selectedSymbol, setSelectedSymbol] = useState<string | null>(
      mode === 'bot-update-all-strategy' ||
        mode === 'bot-update-current-strategy' ||
        mode === 'bot-update-main-strategy'
        ? initialForm?.symbol || null
        : null
    )

    const [isLoadingLeverageMargin, setIsLoadingLeverageMargin] =
      useState(false)
    const [isLoadingSubmitForm, setIsLoadingSubmitForm] = useState(false)
    const [selectedStrategy, setSelectedStrategy] = useState('')
    const [mainFormInitValue, setMainFormInitValue] = useState(
      mode === 'bot'
        ? plainToClass(MainForm, botInitialValues)
        : mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
        ? initialForm!
        : plainToClass(BotStrategiesMainForm, botInitialValues)
    )

    const firstOrderInitial = isFuture ? 10 : 11
    const [minInitialMargin, setMinInitialMargin] = useState<number>(
      firstOrderInitial / (mainFormInitValue.isFirstOrderDouble ? 2 : 1)
    )

    const [coverSpreadFormValue, setCoverSpreadFormValue] =
      useState<CoverSpreadsForm>(
        mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
          ? initialCoverSpreadsForm!
          : plainToClass(CoverSpreadsForm, initialCoverSpreadsValue)
      )
    const [tpRatioSplitFormValue, setTpRatioSplitFormValue] = useState(
      mode === 'bot-update-all-strategy' ||
        mode === 'bot-update-current-strategy' ||
        mode === 'bot-update-main-strategy' ||
        mode === 'strategy-update'
        ? initialTpRatioSplitForm!
        : plainToClass(TpSplitForm, initialTpRatioSplitValue)
    )
    const [tpRetracementSplitFormValue, setTpRetracementSplitFormValue] =
      useState(
        mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
          ? initialTpRetracementSplitForm!
          : plainToClass(TpSplitForm, initialTpRetracementSplitValue)
      )

    const [coverMultiplesFormValue, setCoverMultiplesFormValue] =
      useState<CoverMultiplesForm>(
        mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
          ? initialCoverMultipleForm!
          : plainToClass(CoverMultiplesForm, initialCoverMultiplesValue)
      )

    const [pullbackSpreadsFormValue, setPullbackSpreadsFormValue] =
      useState<PullbackSpreadsForm>(
        mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
          ? initialPullbackSpreadsForm!
          : plainToClass(PullbackSpreadsForm, initialPullbackSpreadsValue)
      )

    const [profitProportionFormValue, setprofitProportionFormValue] = useState(
      plainToClass(ProfitProportionForm, initialProfitProportionValue)
    )
    const [leveragesData, setLeveragesData] = useState<FutureMarketLeverage[]>(
      []
    )
    const [tradeCopyData, setTradeCopyData] = useState<TradeCopyData>()

    const [isLimitPrice, setIsLimitPrice] = useState<boolean>(
      mode === 'bot'
        ? botInitialValues.limitPrice !== undefined
        : mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
        ? initialForm?.limitPrice !== undefined!
        : botInitialValues.limitPrice !== undefined
    )

    const [getFutureMarketLeverage] = useLazyFutureMarketLeverageQuery()

    const [queryMinInitialMargin] = useLazyMinInitialMarginQuery()

    const [queryGetTradeCopyByBot] = useLazyGetTradeCopyByBotQuery()

    const { debounce } = useDebounce()

    const getSymbols = async () => {
      try {
        if (selectedSymbol) {
          if (isFuture) setIsLoadingLeverageMargin(true)
          const data = await getFutureMarketLeverage({
            symbol: selectedSymbol!,
          }).unwrap()
          setLeveragesData(data)
          if (isFuture)
            getMinInitialMargin(
              mainFormInitValue.leverage,
              mainFormInitValue.isFirstOrderDouble
            )
        }
      } catch (e) {
        // console.log('error', e)
        setIsLoadingLeverageMargin(false)
      }
    }

    const getMinInitialMargin = async (
      leverage: number,
      isFirstOrderDouble: boolean
    ) => {
      try {
        if (selectedSymbol) {
          const data = await queryMinInitialMargin({
            symbol: selectedSymbol!,
            leverage,
          }).unwrap()
          const newMinInitialMargin =
            data.minInitialMargin / (isFirstOrderDouble ? 2 : 1)
          setMinInitialMargin(newMinInitialMargin)
          setIsLoadingLeverageMargin(false)
        }
      } catch (e) {
        // console.log('error', e)
        setIsLoadingLeverageMargin(false)
      }
    }

    const getTradeCopyByBot = async () => {
      try {
        if (
          (id && mode === 'copy-by') ||
          (mode === 'copy-by-symbol' && !!selectedBot) ||
          (mode === 'copy-by-personal' && !!selectedBot && !!selectedPersonalId)
        ) {
          const detail = await queryGetTradeCopyByBot({
            tradeCopyByBotId: Number(id || selectedBot?.id),
            tradeCopyByPersonId: personalId || selectedPersonalId,
          }).unwrap()
          setSelectedSymbol(detail.symbol)
          setTradeCopyData({
            buyDate: detail.buyDate,
            baseCurrencyData: detail.baseCurrencyData,
            symbol: detail.symbol,
          })
          setMainFormInitValue(
            plainToClass(
              MainForm,
              {
                ...detail.strategyData,
                symbol: detail.symbol,
                isCopyable: false,
              },
              {
                excludeExtraneousValues: true,
              }
            )
          )
          setCoverSpreadFormValue(
            plainToClass(CoverSpreadsForm, detail.strategyData, {
              excludeExtraneousValues: true,
            })
          )
          setPullbackSpreadsFormValue(
            plainToClass(PullbackSpreadsForm, detail.strategyData, {
              excludeExtraneousValues: true,
            })
          )
          setCoverMultiplesFormValue(
            plainToClass(CoverMultiplesForm, detail.strategyData, {
              excludeExtraneousValues: true,
            })
          )
          setTpRetracementSplitFormValue(
            plainToClass(
              TpSplitForm,
              { tpSplitField: detail.strategyData.tpRetracementSplit },
              {
                excludeExtraneousValues: true,
              }
            )
          )
          setTpRatioSplitFormValue(
            plainToClass(
              TpSplitForm,
              { tpSplitField: detail.strategyData.tpRatioSplit },
              {
                excludeExtraneousValues: true,
              }
            )
          )
          setprofitProportionFormValue(
            plainToClass(ProfitProportionForm, detail.strategyData, {
              excludeExtraneousValues: true,
            })
          )
        }
      } catch (e) {}
    }

    useEffect(() => {
      getSymbols()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSymbol])

    useEffect(() => {
      getTradeCopyByBot()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, mode, personalId, selectedBot])

    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>Are you sure to create {mode}</p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const navigateToCreateBotPage = () => {
      navigate(botRoute.createBot.absolutePath)
    }

    const onSymbolChange = value => {
      setSelectedSymbol(value)
    }

    const onLeverageChange = (
      leverage: number,
      isFirstOrderDouble: boolean
    ) => {
      if (leverage > 0)
        debounce(async () => {
          await getMinInitialMargin(leverage, isFirstOrderDouble)
        }, 400)
    }

    const handleOnIsFirstOrderDoubleChanged = (value: boolean) => {
      // Adapt Initial margin
      if (value) {
        setMinInitialMargin(prevState => prevState / 2)
      } else {
        setMinInitialMargin(prevState => prevState * 2)
      }
      // Re initial value of cover multiple form
      setCoverMultiplesFormValue(
        mode === 'bot-update-all-strategy' ||
          mode === 'bot-update-current-strategy' ||
          mode === 'bot-update-main-strategy' ||
          mode === 'strategy-update'
          ? initialCoverMultipleForm!
          : plainToClass(CoverMultiplesForm, initialCoverMultiplesValue)
      )
    }

    const { data: symbolsData } = useMarketTickersQuery({ botMarketType })

    const symbolOptions = useMemo(() => {
      return plainToClass(MarketTickerOption, symbolsData || [])
    }, [symbolsData])

    const [createBotMutate] = useCreateBotMutation()
    const [updateBotMutate] = useUpdateBotMutation()
    const [upsertStrategiesMutate] = useUpsertBotStrategiesDefaultMutation()

    const onCoverSpreadsFormSubmit = (form: CoverSpreadsForm) => {
      setCoverSpreadFormValue(form)
    }

    const onPullbackSpreadsFormSubmit = (form: PullbackSpreadsForm) => {
      setPullbackSpreadsFormValue(form)
    }

    const onCoverMultiplesFormSubmit = (form: CoverMultiplesForm) => {
      setCoverMultiplesFormValue(form)
    }

    const onTpRatioSplitFormSubmit = (form: TpSplitForm) => {
      setTpRatioSplitFormValue(form)
    }

    const onTpRetracementSplitFormSubmit = (form: TpSplitForm) => {
      setTpRetracementSplitFormValue(form)
    }

    const onProfitProportionFormSubmit = (form: ProfitProportionForm) => {
      setprofitProportionFormValue(form)
    }

    const onCoveringQuantityChange = (value: number) => {
      if (value && value > 0 && Number.isInteger(value)) {
        const coverMultiples = Array.from(
          { length: value },
          (_, i) => 2 ** (i + 1)
        )
        const initialSpreads = Array.from(
          { length: value },
          (_, i) => (i + 1) / 100
        )
        const tpRatioSpreads = Array.from(
          { length: value + 1 },
          (_, i) => (i + 1) / 100
        )
        const tpRetracementSpreads = Array.from(
          { length: value + 1 },
          _ => 0.001
        )
        const pullbackSpreads = Array.from({ length: value }, (_, __) => 0)
        const profitProportion = [...new Array(value).fill(undefined)]
        setCoverMultiplesFormValue(
          plainToClass(CoverMultiplesForm, { coverMultiples })
        )
        setPullbackSpreadsFormValue(
          plainToClass(PullbackSpreadsForm, { pullbackSpreads })
        )
        setCoverSpreadFormValue(
          plainToClass(CoverSpreadsForm, {
            coverSpreads: initialSpreads,
          })
        )
        setTpRatioSplitFormValue(
          plainToClass(TpSplitForm, {
            tpSplitField: tpRatioSpreads,
          })
        )

        setTpRetracementSplitFormValue(
          plainToClass(TpSplitForm, {
            tpSplitField: tpRetracementSpreads,
          })
        )

        setprofitProportionFormValue(
          plainToClass(ProfitProportionForm, { profitProportion })
        )
      }
    }

    const onMainFormSubmit = (form: any) => {
      if (leveragesData) {
        const firstOrderDouble = form.isFirstOrderDouble ? 2 : 1
        const currentLeverage = [...leveragesData]
          .sort((a, b) => -(b.maxLeverage - a.maxLeverage))
          ?.find(l => (form.leverage || 0) <= l.maxLeverage)
        const firstPosition = form.firstOrderRawQuote * form.leverage
        let sumPosition = firstPosition * firstOrderDouble
        coverMultiplesFormValue.coverMultiples.forEach(coverMultiple => {
          sumPosition += firstPosition * coverMultiple
        })
        if (currentLeverage && sumPosition >= currentLeverage.maxCost) {
          toast.error(
            'Exceeded the maximum allowable position when accumulate amount every covering'
          )
        } else {
          openModal({
            onConfirm: () => {
              setIsLoadingSubmitForm(true)
              const plainForm: Record<string, any> = {
                botMarketType,
                ...classToPlain(form, {
                  excludeExtraneousValues: true,
                }),
                ...classToPlain(coverSpreadFormValue, {
                  excludeExtraneousValues: true,
                }),
                ...classToPlain(coverMultiplesFormValue, {
                  excludeExtraneousValues: true,
                }),
                ...classToPlain(pullbackSpreadsFormValue, {
                  excludeExtraneousValues: true,
                }),
                ...classToPlain(profitProportionFormValue, {
                  excludeExtraneousValues: true,
                }),
                // ...(form.isSplitTp === 'split' &&
                ...{
                  tpRatioSplit: classToPlain(tpRatioSplitFormValue, {
                    excludeExtraneousValues: true,
                  }).tpSplitField,
                  tpRetracementSplit: classToPlain(
                    tpRetracementSplitFormValue,
                    {
                      excludeExtraneousValues: true,
                    }
                  ).tpSplitField,
                },
                // ),
              }
              if (mode === 'strategie') {
                upsertStrategiesMutate(plainForm as BotStrategiesDefault)
                  .unwrap()
                  .then(() => {
                    toast.success('Create strategies success')
                    navigate({
                      pathname: botRoute.dashboard.absolutePath,
                      search: `?tab=${BotStatusEnum.RUNNING}`,
                    })
                  })
                  .catch(() => {})
                  .finally(() => {
                    setIsLoadingSubmitForm(false)
                  })
              } else if (
                mode === 'bot-update-all-strategy' ||
                mode === 'bot-update-current-strategy' ||
                mode === 'bot-update-main-strategy'
              ) {
                delete plainForm.symbol
                updateBotMutate({
                  ...plainForm,
                  // Must be botControllerId
                  id: initialForm!.id,
                  strategyUpdateType: botModeFormToStrategyUpdateType(mode),
                } as UpdateBotForm)
                  .unwrap()
                  .then(() => {
                    toast.success('Update bot success')
                    navigate({
                      pathname: botRoute.dashboard.absolutePath,
                      search: `?tab=${BotStatusEnum.READY}`,
                    })
                  })
                  .catch(() => {})
                  .finally(() => {
                    setIsLoadingSubmitForm(false)
                  })
              } else if (mode === 'strategy-update') {
                delete plainForm.symbol
                upsertStrategiesMutate({
                  ...plainForm,
                  id: initialForm!.id,
                } as BotStrategiesDefault)
                  .unwrap()
                  .then(() => {
                    toast.success('Update strategy success')
                    navigate({
                      pathname: botRoute.dashboard.absolutePath,
                      search: `?tab=${BotStatusEnum.READY}`,
                    })
                  })
                  .catch(() => {})
                  .finally(() => {
                    setIsLoadingSubmitForm(false)
                  })
              } else {
                createBotMutate(plainForm as CreateBotForm)
                  .unwrap()
                  .then(() => {
                    if (form.isSaveStrategy && selectedStrategy) {
                      delete plainForm.symbol
                      upsertStrategiesMutate({
                        ...plainForm,
                        id: Number(selectedStrategy),
                      } as BotStrategiesDefault)
                    }
                    toast.success('Create bot success')
                    navigate({
                      pathname: botRoute.dashboard.absolutePath,
                      search: `?tab=${BotStatusEnum.READY}`,
                    })
                  })
                  .catch(() => {})
                  .finally(() => {
                    setIsLoadingSubmitForm(false)
                  })
              }
            },
          })
        }
      }
    }

    const [botStrategyQuery] = useLazyBotStrategyQuery()

    const handleSelectStrategy = (value: string, symbol: string) => {
      setSelectedStrategy(value)
      if (value) {
        botStrategyQuery({ botStrategyId: Number(value) })
          .unwrap()
          .then(resp => {
            setMainFormInitValue(
              plainToClass(
                MainForm,
                { ...resp, symbol, isCopyable: true },
                {
                  excludeExtraneousValues: true,
                }
              )
            )
            setCoverSpreadFormValue(
              plainToClass(CoverSpreadsForm, resp, {
                excludeExtraneousValues: true,
              })
            )
            setPullbackSpreadsFormValue(
              plainToClass(PullbackSpreadsForm, resp, {
                excludeExtraneousValues: true,
              })
            )
            setCoverMultiplesFormValue(
              plainToClass(CoverMultiplesForm, resp, {
                excludeExtraneousValues: true,
              })
            )
            setTpRetracementSplitFormValue(
              plainToClass(
                TpSplitForm,
                { tpSplitField: resp.tpRetracementSplit },
                {
                  excludeExtraneousValues: true,
                }
              )
            )
            setTpRatioSplitFormValue(
              plainToClass(
                TpSplitForm,
                { tpSplitField: resp.tpRatioSplit },
                {
                  excludeExtraneousValues: true,
                }
              )
            )
            setprofitProportionFormValue(
              plainToClass(ProfitProportionForm, resp, {
                excludeExtraneousValues: true,
              })
            )
            if (botMarketType === BotMarketType.FUTURE)
              onLeverageChange(resp.leverage, resp.isFirstOrderDouble)
          })
          .catch(() => {})
      }
    }

    const validationSchema = useMemo(() => {
      const leverages =
        leveragesData.length > 0 ? leveragesData.map(l => l.maxLeverage) : [125]
      const max = Math.max(...leverages)
      setMaxLeverage(max)
      return botSettingSchema({
        minCoveringQuantity: bot?.coverRound,
        leveragesData,
        max,
        coverSpreadFormValue,
        mode,
        minInitialMargin,
        botMarketType,
      })
    }, [
      leveragesData,
      coverSpreadFormValue,
      mode,
      minInitialMargin,
      bot,
      botMarketType,
    ])

    const handleNavigateToFAQ = () => {
      window.open('https://www.khotbot.com/copy-trade', '_blank')
    }
    const handleToggleLimitPrice = value => setIsLimitPrice(value)

    const newProps = {
      onMainFormSubmit,
      symbolOptions,
      navigateToCreateBotPage,
      validationSchema,
      maxLeverage,
      onSymbolChange,
      onCoverSpreadsFormSubmit,
      onCoverMultiplesFormSubmit,
      onProfitProportionFormSubmit,
      coverSpreadFormValue,
      coverMultiplesFormValue,
      profitProportionFormValue,
      onCoveringQuantityChange,
      mode,
      onLeverageChange,
      handleSelectStrategy,
      selectedStrategy,
      mainFormInitValue,
      minInitialMargin,
      tpRatioSplitFormValue,
      tpRetracementSplitFormValue,
      onTpRatioSplitFormSubmit,
      onTpRetracementSplitFormSubmit,
      tradeCopyData,
      isLoadingLeverageMargin,
      selectedBot,
      selectedPersonalId,
      pullbackSpreadsFormValue,
      onPullbackSpreadsFormSubmit,
      bot,
      handleNavigateToFAQ,
      handleOnIsFirstOrderDoubleChanged,
      isLoadingSubmitForm,
      handleToggleLimitPrice,
      isLimitPrice,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithBotSettingForm
}
export default withBotSettingForm
