import { useBotStrategyQuery } from '@client/botClient/collections/BotStrategyClientAction'
import Loading from '@components/utils/Loading'
import React from 'react'
import { useParams } from 'react-router-dom'
import { MyStrategyDetailPageProps } from './interface'

const withMyStrategyDetailPage = (
  Component: React.FC<MyStrategyDetailPageProps>
) => {
  function WithMyStrategyDetailPage() {
    const { id } = useParams()
    const { data: botStrategy, isLoading } = useBotStrategyQuery({
      botStrategyId: Number(id),
    })

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const newProps = { botStrategy }
    return <Component {...newProps} />
  }

  return WithMyStrategyDetailPage
}

export default withMyStrategyDetailPage
