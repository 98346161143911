import React from 'react'
import { useTranslation } from 'react-i18next'
import thFlag from '@assets/images/thFlag.png'
import enFlag from '@assets/images/enFlag.png'

const WalletPolicyModal = () => {
  const {
    t,
    i18n: { changeLanguage, language },
  } = useTranslation()

  const handleChangeLanguage = (newLang: 'en' | 'th') => {
    if (newLang !== language) {
      changeLanguage(newLang)
    }
  }

  return (
    <div className='flex flex-col text-black min-w-[304px] desktop:max-w-[600px] max-w-[304px]'>
      <div className='pt-[30px] py-[24px] px-[20px] flex flex-col space-y-[14px]'>
        <div className='flex items-center justify-between gap-x-1 font-bold text-sm'>
          {t('walletPolicy')}
          <div className=' flex justify-end gap-x-2.5'>
            <div onClick={() => handleChangeLanguage('en')}>
              <img
                src={enFlag}
                alt='EN'
                className={`cursor-pointer h-[18px] ${
                  language !== 'en' && 'opacity-40'
                }`}
              />
            </div>
            <div onClick={() => handleChangeLanguage('th')}>
              <img
                src={thFlag}
                alt='TH'
                className={`cursor-pointer h-[18px] ${
                  language !== 'th' && 'opacity-40'
                }`}
              />
            </div>
          </div>
        </div>
        <div className='flex flex-col text-xs'>
          <ul className='ml-[24px] flex flex-col space-y-[10px]'>
            <li>{t('walletPolicyBulletOne')}</li>
            <li>{t('walletPolicyBulletTwo')}</li>
            <li>{t('walletPolicyBulletThree')}</li>
            <li>{t('walletPolicyBulletFour')}</li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export default WalletPolicyModal
