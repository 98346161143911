export const authenticationPath = 'auth'
export const AuthenticationRoutesPath = {
  login: {
    path: '/login',
    absolutePath: `/${authenticationPath}/login`,
  },
  register: {
    path: '/register',
    absolutePath: `/${authenticationPath}/register`,
  },
  forgotPassword: {
    path: '/forgot-password',
    absolutePath: `/${authenticationPath}/forgot-password`,
  },
  activate: {
    path: '/activate',
    absolutePath: `/${authenticationPath}/activate`,
  },
}

export const HomeRoutesPath = {
  home: {
    path: '/portal',
  },
}

export const contractPath = 'contract'
export const spotPath = 'spot'
export const ContractRoutesPath = {
  dashboard: {
    path: '/dashboard',
    absolutePath: `/${contractPath}/dashboard`,
  },
  createContractMenu: {
    path: '/menu',
    absolutePath: `/${contractPath}/menu`,
  },
  createBot: {
    path: '/bot/create',
    absolutePath: `/${contractPath}/bot/create`,
  },
  createGridBot: {
    path: '/grid-bot/create',
    absolutePath: `/${contractPath}/grid-bot/create`,
  },
  bot: {
    path: '/bot/:id',
    absolutePath: `/${contractPath}/bot/[id]`,
  },
  createStrategy: {
    path: '/strategy/create',
    absolutePath: `/${contractPath}/strategy/create`,
  },
  updateCurrentBot: {
    path: '/bot/:id/edit/current/:botId',
    absolutePath: ({ botControllerId, botId }) =>
      `/${contractPath}/bot/${botControllerId}/edit/current/${botId}`,
  },
  updateMainBot: {
    path: '/bot/:id/edit/main',
    absolutePath: ({ id }) => `/${contractPath}/bot/${id}/edit/main`,
  },
  updateAllBot: {
    path: '/bot/:id/edit/all',
    absolutePath: ({ id }) => `/${contractPath}/bot/${id}/edit/all`,
  },
  copyByBot: {
    path: '/bot/:id/copy-by',
    absolutePath: ({ id }) => `/${contractPath}/bot/${id}/copy-by`,
  },
  copyBySymbol: {
    path: '/bot/create/copy-by-symbol',
    absolutePath: `/${contractPath}/bot/create/copy-by-symbol`,
  },
  copyByPersonal: {
    path: '/bot/create/copy-by-personal',
    absolutePath: `/${contractPath}/bot/create/copy-by-personal`,
  },
  manageStrategy: {
    path: '/strategy-management',
    absolutePath: `/${contractPath}/strategy-management`,
  },
  updateStrategy: {
    path: '/strategy/:id/edit',
    absolutePath: ({ id }) => `/${contractPath}/strategy/${id}/edit`,
  },
  createEasyTradeOwnerSelect: {
    path: '/bot/create/easy-trade',
    absolutePath: `/${contractPath}/bot/create/easy-trade`,
  },
  createEasyTradeConfig: {
    path: '/bot/create/easy-trade/:ownerId',
    absolutePath: ({ ownerId }) =>
      `/${contractPath}/bot/create/easy-trade/${ownerId}`,
  },
}

export const SpotRoutesPath = {
  dashboard: {
    path: '/dashboard',
    absolutePath: `/${spotPath}/dashboard`,
  },
  createContractMenu: {
    path: '/menu',
    absolutePath: `/${spotPath}/menu`,
  },
  createBot: {
    path: '/bot/create',
    absolutePath: `/${spotPath}/bot/create`,
  },
  createGridBot: {
    path: '/grid-bot/create',
    absolutePath: `/${spotPath}/grid-bot/create`,
  },
  bot: {
    path: '/bot/:id',
    absolutePath: `/${spotPath}/bot/[id]`,
  },
  createStrategy: {
    path: '/strategy/create',
    absolutePath: `/${spotPath}/strategy/create`,
  },
  updateCurrentBot: {
    path: '/bot/:id/edit/current/:botId',
    absolutePath: ({ botControllerId, botId }) =>
      `/${spotPath}/bot/${botControllerId}/edit/current/${botId}`,
  },
  updateMainBot: {
    path: '/bot/:id/edit/main',
    absolutePath: ({ id }) => `/${spotPath}/bot/${id}/edit/main`,
  },
  updateAllBot: {
    path: '/bot/:id/edit/all',
    absolutePath: ({ id }) => `/${spotPath}/bot/${id}/edit/all`,
  },
  copyByBot: {
    path: '/bot/:id/copy-by',
    absolutePath: ({ id }) => `/${spotPath}/bot/${id}/copy-by`,
  },
  copyBySymbol: {
    path: '/bot/create/copy-by-symbol',
    absolutePath: `/${spotPath}/bot/create/copy-by-symbol`,
  },
  copyByPersonal: {
    path: '/bot/create/copy-by-personal',
    absolutePath: `/${spotPath}/bot/create/copy-by-personal`,
  },
  manageStrategy: {
    path: '/strategy-management',
    absolutePath: `/${spotPath}/strategy-management`,
  },
  updateStrategy: {
    path: '/strategy/:id/edit',
    absolutePath: ({ id }) => `/${spotPath}/strategy/${id}/edit`,
  },
  createEasyTradeOwnerSelect: {
    path: '/bot/create/easy-trade',
    absolutePath: `/${spotPath}/bot/create/easy-trade`,
  },
  createEasyTradeConfig: {
    path: '/bot/create/easy-trade/:ownerId',
    absolutePath: ({ ownerId }) =>
      `/${spotPath}/bot/create/easy-trade/${ownerId}`,
  },
}

export const tradeCopyPath = 'trade-copy'
export const TradeCopyRoutesPath = {
  tradeCopy: {
    path: `/${tradeCopyPath}`,
    bySymbol: {
      path: '/by-symbol/:id',
      absolutePath: ({ id }) => `/${tradeCopyPath}/by-symbol/${id}`,
    },
    strategy: {
      path: '/strategy/:id',
      absolutePath: ({ id }) => `/${tradeCopyPath}/strategy/${id}`,
    },
    byPersonal: {
      path: '/by-personal/:id',
      absolutePath: ({ id }) => `/${tradeCopyPath}/by-personal/${id}`,
    },
    personal: {
      path: '/personal/:id',
      absolutePath: ({ id }) => `/${tradeCopyPath}/personal/${id}`,
    },
    myStrategy: {
      path: '/my-strategy',
      absolutePath: `/${tradeCopyPath}/my-strategy`,
      detail: {
        path: '/my-strategy/:id',
        absolutePath: ({ id }) => `/${tradeCopyPath}/my-strategy/${id}`,
      },
    },
  },
}

export const ProfitRoutesPath = {
  profit: {
    path: '/profit',
  },
}

export const TransactionLogRoutesPath = {
  transactionLog: {
    path: '/transaction',
  },
}

export const otherPath = 'other'
export const OtherRoutesPath = {
  other: {
    path: '/other',
  },
  apiManagement: {
    path: '/api-management',
    absolutePath: `/${otherPath}/api-management`,
  },
  profileSetting: {
    path: '/profile-setting',
    absolutePath: `/${otherPath}/profile-setting`,
  },
  changePassword: {
    path: '/change-password',
    absolutePath: `/${otherPath}/change-password`,
  },
  referral: {
    path: '/referral',
    absolutePath: `/${otherPath}/referral`,
  },
  ranking: {
    path: '/ranking',
    absolutePath: `/${otherPath}/ranking`,
    chart: {
      path: '/ranking/chart/:userId/:mode',
      absolutePath: ({
        userId,
        mode,
      }: {
        userId: any
        mode: 'total' | 'daily'
      }) => `/${otherPath}/ranking/chart/${userId}/${mode}`,
    },
  },
  coupon: {
    path: '/coupon',
    absolutePath: `/${otherPath}/coupon`,
  },
  calculator: {
    path: '/calculator',
    absolutePath: `/${otherPath}/calculator`,
  },
}

export const walletPath = 'wallet'
export const WalletRoutesPath = {
  walletSelect: {
    path: '/',
    absolutePath: `/${walletPath}`,
  },
  withdraw: {
    path: '/tether/withdraw',
    absolutePath: `/${walletPath}/tether/withdraw`,
  },
  deposit: {
    path: '/tether/deposit',
    absolutePath: `/${walletPath}/tether/deposit`,
  },
  transaction: {
    path: '/tether/transaction',
    absolutePath: `/${walletPath}/tether/transaction`,
  },
  transfer: {
    path: '/tether/transfer',
    absolutePath: `/${walletPath}/tether/transfer`,
  },
  withdrawRequested: {
    path: '/tether/withdraw-requested',
    absolutePath: `/${walletPath}/tether/withdraw-requested`,
  },

  khotcoinTopUp: {
    path: '/khotcoin/top-up',
    absolutePath: `/${walletPath}/khotcoin/top-up`,
  },
  khotcoinTransaction: {
    path: '/khotcoin/transaction',
    absolutePath: `/${walletPath}/khotcoin/transaction`,
  },
}

export const landingPath = '/'
export const LandingRoutesPath = {
  path: '/',
}
export const TrendingCoinRoutesPath = {
  trendingCoin: {
    path: '/trending-coin',
  },
}

export const tutorialPath = 'tutorial'
export const TutorialRoutesPath = {
  deposit: {
    path: '/deposit',
    absolutePath: `/${tutorialPath}/deposit`,
  },
  apiKey: {
    path: '/api-key',
    absolutePath: `/${tutorialPath}/api-key`,
  },
}

export const TradingViewRoutesPath = {
  tradingView: {
    path: '/trading-view',
    absolutePath: `/trading-view`,
  },
  // For rendering mobile webview
  tradingViewMobile: {
    path: '/trading-view/mobile',
    absolutePath: `/trading-view/mobile`,
  },
  tickerTape: {
    path: '/ticker-tape',
    absolutePath: `/ticker-tape`,
  },
}

export const ReCaptchaRoutesPath = {
  recaptcha: {
    path: '/recaptcha',
    absolutePath: `/recaptcha`,
  },
}

export const PolicyRoutesPath = {
  privacy: {
    path: '/privacy',
    absolutePath: `/privacy`,
  },
  termsOfService: {
    path: '/terms-of-service',
    absolutePath: `/terms-of-service`,
  },
}
