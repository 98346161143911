import { Tab } from '@components/Tabs/components/Tab'
import { TabBody } from '@components/Tabs/components/TabBody'
import { TabHeader } from '@components/Tabs/components/TabHeader'
import { Tabs } from '@components/Tabs/Tabs'
import PersonalWinRate from '@features/tradeCopy/components/PersonalWinRate'

import ProfileHeader from '@components/ProfileHeader/ProfileHeader'
import PersonalStrategy from './components/PersonalStrategy'
import ProfitHistory from './components/ProfitHistory'
import { CopyByPersonalPageProps } from './interface'
import withCopyByPersonalPage from './withCopyByPersonalPage'

function CopyByPersonalPage({
  name,
  description,
  accumulate,
  profileImg,
  handleAccmulateParams,
}: CopyByPersonalPageProps) {
  return (
    <div className='py-[10px] max-w-[700px] mx-auto'>
      <ProfileHeader
        name={name}
        description={description}
        profileImgPath={profileImg}
      />
      <Tabs initialActiveTab='personalProfile'>
        <Tab tabId='personalProfile'>
          <TabHeader>
            <span className='desktop:w-max'>Personal Profile</span>
          </TabHeader>
          <TabBody>
            <PersonalWinRate
              name={name}
              accmulate={accumulate}
              handleAccmulateParams={handleAccmulateParams}
              className='px-[16px] py-[20px]'
            />
          </TabBody>
        </Tab>
        <Tab tabId='strategy'>
          <TabHeader>
            <span className='desktop:w-max'>Strategy</span>
          </TabHeader>
          <TabBody>
            <PersonalStrategy />
          </TabBody>
        </Tab>
        <Tab tabId='profiltHistory'>
          <TabHeader>
            <span className='desktop:w-max'>Profit History</span>
          </TabHeader>
          <TabBody>
            <ProfitHistory />
          </TabBody>
        </Tab>
      </Tabs>
    </div>
  )
}

export default withCopyByPersonalPage(CopyByPersonalPage)
