import React from 'react'
import { BotCardStatusDetailProps } from '@features/bot/components/BotCard/interface'

export const BotCardRunningState = ({ bot }: BotCardStatusDetailProps) => {
  return (
    <>
      <div className='mb-[24px] flex-col items-start space-y-3'>
        <div className='px-[17px] text-sm font-medium flex flex-col gap-y-[16px] '>
          {/* Limit price show only first cycle round */}
          {bot.cycleNo === 1 && (
            <div className='flex space-x-2'>
              <span className='flex-1'>Limit price</span>
              <span>
                {bot.botStrategy.limitPrice?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                }) ?? '-'}
                {' USDT'}
              </span>
            </div>
          )}
          {/* Contractual asset */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Contractual asset</span>
            <span>
              {(
                (bot.position?.collateral ?? 0) -
                (bot.position?.unrealizedPnl ?? 0)
              )?.toLocaleString(undefined, {
                maximumFractionDigits: 4,
              }) ?? '-'}
              {' USDT'}
            </span>
          </div>

          {/* Covering round */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Current covering</span>
            <span>
              {bot?.botStrategy?.isCovering
                ? `${bot?.coverRound}/${
                    bot?.botStrategy?.coveringQuantity ?? '-'
                  }`
                : '-'}
            </span>
          </div>

          {/* Profit & Loss % */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Profit and loss</span>
            <span
              className={
                (bot.position?.percentage ?? 0) > 0
                  ? 'text-green-100'
                  : (bot.position?.percentage ?? 0) < 0
                  ? 'text-red'
                  : ''
              }
            >
              {bot.position?.percentage?.toLocaleString(undefined, {
                maximumFractionDigits: 4,
              }) ?? '-'}
              {' %'}
            </span>
          </div>

          {/* Average price */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Average price</span>
            <span>
              {bot?.positionAvgPrice?.toLocaleString(undefined, {
                maximumFractionDigits: 4,
              }) ?? '-'}
              {' USDT'}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
