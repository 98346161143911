import { useUserProfitRankQuery } from '@client/mainClient/collections/UserProfitRankClientAction'
import { ProfitType } from '@models/Profit/ProfitType'
import React from 'react'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { OtherRoutesPath } from '@config/Router'
import { GoToChartPageProps, RankingPageProps } from './interface'

const withRankingPage = (Component: React.FC<RankingPageProps>) => {
  const Hoc = () => {
    const navigate = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams({
      type: ProfitType.ALL_TIME,
    })

    const profitType =
      ProfitType[searchParams.get('type') ?? ''] ?? ProfitType.ALL_TIME

    const { data, isLoading, refetch } = useUserProfitRankQuery({ profitType })

    const handleProfitTypeChanged = (key: string) => {
      setSearchParams({ type: key })
      refetch()
    }

    const goToChartPage = ({
      userId,
      profileImgPath,
      rank,
      strategyDescription,
      userName,
    }: GoToChartPageProps) => {
      const searchData: Record<string, any> = { userName }
      if (profileImgPath) searchData.profileImgPath = profileImgPath
      if (rank) searchData.rank = rank
      if (strategyDescription)
        searchData.strategyDescription = strategyDescription
      const search = `?${createSearchParams(searchData)}`
      navigate({
        pathname: OtherRoutesPath.ranking.chart.absolutePath({
          userId,
          mode: profitType === ProfitType.DAILY ? 'daily' : 'total',
        }),
        search,
      })
    }

    const newProps = {
      profitType,
      handleProfitTypeChanged,
      usersTopThree: data?.users?.slice(0, 3),
      users: data?.users?.slice(3, data?.users?.length),
      isLoading,
      isEmpty: (data?.users ?? []).length <= 0,
      goToChartPage,
    }
    return <Component {...newProps} />
  }
  return Hoc
}

export default withRankingPage
