import React, { useState } from 'react'
import { Drawer } from '@components/Drawer'
import { DrawerContext } from '@hooks/useDrawer'
import NoData from '@components/NoData/NoData'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'
import PrimaryButton from '@components/buttons/PrimaryButton'
import SelectBotCard from './SelectBotCard'

function SelectBotDrawer({
  drawer,
  bots,
  handleSelectedCopyByBotChange,
  selectedBot,
}: {
  drawer: DrawerContext
  bots?: TradeCopyByBot[]
  handleSelectedCopyByBotChange: (bot?: TradeCopyByBot) => void
  selectedBot?: TradeCopyByBot
}) {
  return (
    <Drawer
      isDrawerOpen={drawer.isDrawerOpen}
      handleClickClose={drawer.handleClickClose}
      header='Select Symbol'
    >
      <div className='grid grid-col-1 gap-y-[8px] pt-[9px] max-h-[50vh] overflow-y-auto'>
        {bots?.length ? (
          bots?.map(bot => (
            <SelectBotCard
              key={bot.id}
              bot={bot}
              selectedBot={selectedBot}
              handleSelectedCopyByBotChange={handleSelectedCopyByBotChange}
            />
          ))
        ) : (
          <NoData />
        )}
        <PrimaryButton
          type='button'
          className='w-full mt-[20px] rounded-[18px]'
          onClick={() => drawer.handleClickClose()}
        >
          Confirm
        </PrimaryButton>
      </div>
    </Drawer>
  )
}

export default SelectBotDrawer
