import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const updateUserApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    updateUser: builder.mutation<
      { id: number },
      {
        id: number
        name: string
        email: string
        phoneNumber: string
        lineId?: string
        phoneNumberCountryCode: number
        profileImgPath?: string
      }
    >({
      query: updateUserInput => ({
        document: gql`
          mutation UpdateUser($updateUserInput: UpdateUserInput!) {
            updateUser(updateUserInput: $updateUserInput) {
              id
            }
          }
        `,
        variables: {
          updateUserInput,
        },
      }),
    }),
  }),
})

export const { useUpdateUserMutation } = updateUserApi
