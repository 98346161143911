import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import { withLogin } from '@features/authentication/components/Login/withLogin'
import InputField from '@components/form/InputField/InputField'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { LoginPropsType } from '@features/authentication/components/Login/interface'
import { withUnAuthenGuard } from '@libs/hoc/withUnAuthenGuard'
import { AuthenticationRoutesPath, HomeRoutesPath } from '@config/Router'
import { EyeIcon, EyeSlashIcon } from '@components/Icons/Icons'
import { useResponsive } from '@hooks/useResponsive'

const validationSchema = Yup.object().shape({
  username: Yup.string().required(),
  password: Yup.string().required(),
})

const Login = ({
  handleOnSubmit,
  initialValue,
  isLoading,
  handleToggleObscurePassword,
  isObscurePassword,
}: LoginPropsType) => {
  const navigate = useNavigate()
  const { isMobile, isTablet } = useResponsive()
  const isDesktop = !(isMobile || isTablet)
  return (
    <>
      <Formik
        initialValues={initialValue}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {() => (
          <Form className='flex flex-col justify-center m-auto h-4/5-screen'>
            {!isDesktop && (
              <div className='flex justify-center h-[80px] mb-[30px]'>
                {/* eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions */}
                <img
                  src='/logoHorizontal.png'
                  alt='logo-horizontal'
                  className='h-[80px] w-fit cursor-pointer'
                  onClick={() => navigate(HomeRoutesPath.home.path)}
                />
              </div>
            )}
            <div className='text-white text-4xl font-bold mb-[12px]'>
              Sign in
            </div>
            <div className='text-white text-base mb-[20px]'>
              Welcome to Khotbot
            </div>
            <InputField
              data-test-id='username-field'
              name='username'
              label='Username'
              type='text'
              className='mb-[14px]'
              required
              placeholder='Enter your username...'
            />
            <InputField
              data-test-id='password-field'
              name='password'
              label='Password'
              type='password'
              required
              isShowPassword={isObscurePassword}
              rightIcon={
                <div
                  onClick={handleToggleObscurePassword}
                  className='cursor-pointer'
                >
                  {isObscurePassword ? <EyeIcon /> : <EyeSlashIcon />}
                </div>
              }
              placeholder='Enter your password...'
            />
            <Link
              to={AuthenticationRoutesPath.forgotPassword.absolutePath}
              className='text-white underline text-sm my-[20px] text-right'
            >
              Forgotten password
            </Link>
            <PrimaryButton
              data-test-id='submit-btn'
              type='submit'
              className='w-full rounded-[6px] mb-[16px] h-[48px]'
              disabled={isLoading}
            >
              Login
            </PrimaryButton>
            <div className='py-[8px]'>
              <span>
                {`Don't have an account? `}
                <span
                  className='ml-[4px] text-primary underline cursor-pointer'
                  onClick={() =>
                    navigate(AuthenticationRoutesPath.register.absolutePath)
                  }
                >
                  Register
                </span>
              </span>
            </div>
          </Form>
        )}
      </Formik>
    </>
  )
}

export default withUnAuthenGuard(withLogin(Login))
