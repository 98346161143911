import { DateTime } from 'luxon'

export const ordinalNumbers = function (d: any) {
  const dString = String(d)
  const last = +dString.slice(-2)
  if (last > 3 && last < 21) return `${d}th`
  switch (last % 10) {
    case 1:
      return `${d}st`
    case 2:
      return `${d}nd`
    case 3:
      return `${d}rd`
    default:
      return `${d}th`
  }
}

export const diffNow = (millis: number) => {
  const diffStr = DateTime.fromMillis(Number(millis))
    .diffNow('years')
    .shiftTo('month', 'days', 'hours')
    .toObject()
  return `${diffStr.months}M : ${diffStr.days}D : ${Math.floor(
    diffStr.hours || 0
  )}H`
}

export const capitalizeFirstLetter = (str: string) => {
  return str.charAt(0).toUpperCase() + str.slice(1)
}

export const getDecimalPlaces = (value: number): number => {
  const match = String(value).match(/(?:\.(\d+))?(?:[eE]([+-]?\d+))?$/)
  if (!match) {
    return 0
  }
  // Get the number of decimal places by counting the digits after the decimal point
  const decimalPart = match[1] ? match[1].length : 0

  // Get the number of decimal places caused by scientific notation
  const scientificNotationPart = match[2] ? Number(match[2]) : 0

  return Math.max(0, decimalPart - scientificNotationPart)
}

/**
 * Converts a number to a percentage by multiply by 100
 * with keeping decimal place from origin.
 */
export const toPercentage = (value?: number): number | undefined => {
  if (value !== undefined) {
    const decimalPlace = getDecimalPlaces(value)
    const expectDecimalPlace = 10 ** decimalPlace
    return Math.round(value * 100 * expectDecimalPlace) / expectDecimalPlace
  }
  return undefined
}
