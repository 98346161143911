import * as Yup from 'yup'
import { requiredNumberMsg } from '@constant/errorMessage'

export const easyTradeSchema = ({
  minimumPerSymbol,
}: {
  minimumPerSymbol: number
}) =>
  Yup.object()
    .shape({
      easyTradeId: Yup.number().required('Please select easy trade template'),
      symbols: Yup.array().min(1, 'Please select at least 1 symbol'),
      totalInvestment: Yup.number().required(requiredNumberMsg),
    })
    .test(function (value) {
      if (value.symbols) {
        const minimumTotalInvestment = value.symbols.length * minimumPerSymbol
        if ((value.totalInvestment ?? 0) < minimumTotalInvestment) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: 'totalInvestment',
            message: `Investment must be greater than or equal to ${minimumTotalInvestment.toLocaleString(
              undefined
            )}`,
          })
        }
      }
      return true
    })
    .test(function (value) {
      if (value.symbols && !value.symbols.length) {
        return this.createError({
          path: 'symbols',
          message: `Please select at least 1 symbol`,
        })
      }
      return true
    })
