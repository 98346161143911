import cn from 'classnames'
import { PenIcon, UserCircleIcon } from '@components/Icons/Icons'
import rankOne from '@assets/icons/rankOne.png'
import rankTwo from '@assets/icons/rankTwo.png'
import rankThree from '@assets/icons/rankThree.png'

function ProfileHeader({
  name,
  description,
  profileImgPath,
  showEditIcon = false,
  className = '',
  onClickEditIcon,
  rank,
}: {
  name: string
  description: string
  profileImgPath?: string
  showEditIcon?: boolean
  className?: string
  onClickEditIcon?: () => void
  rank?: number
}) {
  return (
    <div className={cn('px-[15px] pb-[10px]', className)}>
      <div className='ml-[18px] relative'>
        <div className='mt-[8px] flex gap-x-[4px] items-center'>
          {showEditIcon && (
            <div
              className='absolute top-0 right-0 border border-primary rounded-[6px] p-[4px] cursor-pointer'
              onClick={() => {
                if (onClickEditIcon) {
                  onClickEditIcon()
                }
              }}
            >
              <PenIcon />
            </div>
          )}
          {profileImgPath ? (
            <img
              src={profileImgPath}
              alt='Profile'
              className='w-[40px] h-[40px] rounded-full mr-[5px]'
            />
          ) : (
            <UserCircleIcon className='w-[45px] h-[45px]' />
          )}
          <div className='flex space-x-1 text-sm font-bold'>
            <span>{name}</span>{' '}
            {rank === 1 ? (
              <img src={rankOne} alt='rank-1' className='h-[20px] w-[20px]' />
            ) : rank === 2 ? (
              <img src={rankTwo} alt='rank-2' className='h-[20px] w-[20px]' />
            ) : rank === 3 ? (
              <img src={rankThree} alt='rank-3' className='h-[20px] w-[20px]' />
            ) : (
              <div />
            )}
          </div>
        </div>
        <div className='ml-[49px] text-[10px] text-gray-400'>{description}</div>
      </div>
    </div>
  )
}

export default ProfileHeader
