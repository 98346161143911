import React, { useState } from 'react'
import { useWalletTransactionsQuery } from '@client/exchangeClient/collections/WalletTransactionsClientAction'
import { usePagination } from '@hooks/usePagination'
import { SuccessfulEnum } from '@interfaces/StatusEnum'

import { TransactionTypeEnum } from '@interfaces/TransactionTypeEnum'
import { useSelector } from 'react-redux'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { TransactionPageProps } from './interface'

const incomeTypes = [
  TransactionTypeEnum.DEPOSIT,
  TransactionTypeEnum.REFERRAL,
  TransactionTypeEnum.TRADE_COPY_PERSON_INCOME,
  TransactionTypeEnum.TRADE_COPY_BOT_INCOME,
]

const withTransactionPage = (Component: React.FC<TransactionPageProps>) => {
  function WithTransactionPage() {
    const { profile } = useSelector(authenticationSelector)
    const { page, perpage, pageChange, perpageChange } = usePagination()
    const [filter, setFilter] = useState<TransactionTypeEnum | undefined>()
    const { data, isLoading } = useWalletTransactionsQuery({
      limit: perpage,
      page,
      status: SuccessfulEnum.SUCCESS,
      type: filter,
    })
    // const { data: sumAmountWalletTransaction } =
    //   useSumAmountWalletTransactionQuery(undefined, {
    //     refetchOnMountOrArgChange: true,
    //   })

    const handleFilterChanged = (value: TransactionTypeEnum | undefined) => {
      setFilter(value)
    }

    const isIncomeValue = (
      transactionType: TransactionTypeEnum,
      userId: number
    ): boolean =>
      incomeTypes.includes(transactionType) ||
      (transactionType === TransactionTypeEnum.TRANSFER &&
        userId !== profile?.id)

    const newProps = {
      transactions: data,
      isLoading,
      page,
      perpage,
      filter,
      // sumAmountWalletTransaction: plainToClass(
      //   SumAmountWalletTransaction,
      //   sumAmountWalletTransaction
      // ),
      pageChange,
      perpageChange,
      handleFilterChanged,

      isIncomeValue,
    }
    return <Component {...newProps} />
  }

  return WithTransactionPage
}

export default withTransactionPage
