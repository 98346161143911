import cn from 'classnames'
import { SegmentedControlProps } from './interface'

const SegmentedControl = ({
  value,
  options,
  onChanged,
  className,
}: SegmentedControlProps) => {
  return (
    <div
      className={cn(
        `h-[48px] w-full p-2 rounded-l-full rounded-r-full flex  items-center justify-center border-1 border-gray-600`,
        className
      )}
    >
      {options.map((option, index) => {
        return (
          <div
            onClick={() => onChanged(option.value)}
            key={index}
            className={`flex items-center justify-center w-full h-full rounded-l-full rounded-r-full cursor-pointer font-bold ${
              value === option.value && 'bg-primary'
            }`}
          >
            {option.label}
          </div>
        )
      })}
    </div>
  )
}

export default SegmentedControl
