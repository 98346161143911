import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const oneOffAllApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    oneOffAll: builder.mutation<
      boolean,
      { isOneOff: boolean; botMarketType: BotMarketType }
    >({
      query: ({ isOneOff, botMarketType }) => ({
        document: gql`
          mutation OneOffAll(
            $isOneOff: Boolean!
            $botType: BotType!
            $botMarketType: BotMarketType!
          ) {
            oneOffAll(
              isOneOff: $isOneOff
              botType: $botType
              botMarketType: $botMarketType
            )
          }
        `,
        variables: {
          botType: 'Martingale',
          botMarketType,
          isOneOff,
        },
      }),
    }),
  }),
})

export const { useOneOffAllMutation } = oneOffAllApi
