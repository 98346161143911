import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { plainToClass } from 'class-transformer'
import { GetTradeCopyByBot } from '@models/TradeCopy/GetTradeCopyByBot'

export const getTradeCopyByBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTradeCopyByBot: builder.query<
      GetTradeCopyByBot,
      { tradeCopyByBotId: number; tradeCopyByPersonId?: number }
    >({
      query: getTradeCopyByBotInput => ({
        document: gql`
          query GetTradeCopyByBot(
            $getTradeCopyByBotInput: GetTradeCopyByBotDetailInput!
          ) {
            getTradeCopyByBot(getTradeCopyByBotInput: $getTradeCopyByBotInput) {
              symbol
              buyDate
              baseCurrencyData {
                imgPath
                symbol
              }
              user {
                name
              }
              strategyData {
                id
                name
                isCycle
                isInfinityCycle
                marginType
                side
                isCovering
                cycleQuantity
                isStopLoss
                isFirstOrderDouble
                firstOrderRawQuote
                firstOrderTradeQuote
                leverage
                coveringQuantity
                tpRetracement
                tpRatio
                divisionAndControlProfit
                stopLossRatio
                stopLossLimit
                coverSpreads
                coverMultiples
                isSplitTp
                tpRatioSplit
                tpRetracementSplit
                coverSpreadTrends
                profitProportion
                pullbackSpreads
              }
            }
          }
        `,
        variables: {
          getTradeCopyByBotInput,
        },
      }),
      transformResponse(res: { getTradeCopyByBot: GetTradeCopyByBot }) {
        return plainToClass(GetTradeCopyByBot, res.getTradeCopyByBot)
      },
      providesTags: (result, error, arg) => [
        { type: 'TradeCopyByBot', ...arg },
      ],
    }),
  }),
})

export const { useGetTradeCopyByBotQuery, useLazyGetTradeCopyByBotQuery } =
  getTradeCopyByBotApi
