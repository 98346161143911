import React from 'react'
import { useBotStrategiesOptionsQuery } from '@client/botClient/collections/BotStrategiesOptionClientAction'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

import { SecondStepAcceptPorps, SecondStepPorps } from './interface'

const withSecondStep = (Component: React.FC<SecondStepPorps>) => {
  function WithSecondStep({ mode, ...props }: SecondStepAcceptPorps) {
    const { botMarketType } = useBotMarketType()
    const { data: options = [] } = useBotStrategiesOptionsQuery(
      { botMarketType },
      {
        skip: [
          'copy-by',
          'copy-by-symbol',
          'copy-by-personal',
          'strategy-update',
          'bot-update-all-strategy',
          'bot-update-main-strategy',
          'bot-update-current-strategy',
        ].includes(mode),
        refetchOnMountOrArgChange: true,
      }
    )

    const newProps = {
      mode,
      botMarketType,
      options,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithSecondStep
}

export default withSecondStep
