import {
  TierFiveIcon,
  TierFourIcon,
  TierOneIcon,
  TierSixIcon,
  TierThreeIcon,
  TierTwoIcon,
} from '@components/Icons/Icons'

export const mappedTierIdToIcon = ({
  tierId,
  height,
  width,
  key,
}: {
  tierId: number
  height?: string
  width?: string
  key: string
}) => {
  switch (tierId) {
    case 1:
      return (
        <TierOneIcon
          key={`tier-one-icon-${key}`}
          height={height}
          width={width}
        />
      )
    case 2:
      return (
        <TierTwoIcon
          key={`tier-two-icon-${key}`}
          height={height}
          width={width}
        />
      )
    case 3:
      return (
        <TierThreeIcon
          key={`tier-three-icon-${key}`}
          height={height}
          width={width}
        />
      )
    case 4:
      return (
        <TierFourIcon
          key={`tier-four-icon-${key}`}
          height={height}
          width={width}
        />
      )
    case 5:
      return (
        <TierFiveIcon
          key={`tier-five-icon-${key}`}
          height={height}
          width={width}
        />
      )
    case 6:
      return (
        <TierSixIcon
          key={`tier-six-icon-${key}`}
          height={height}
          width={width}
        />
      )
    default:
      return undefined
  }
}
