import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { AuthExchangeForm } from '@models/AuthExchange/AuthExchangeForm'

export const updateAuthExchangeApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    updateAuthExchange: builder.mutation<{ id: number }, AuthExchangeForm>({
      query: newAuthExchangeInput => ({
        document: gql`
          mutation UpdateAuthExchange(
            $newAuthExchangeInput: NewAuthExchangeInput!
          ) {
            updateAuthExchange(newAuthExchangeInput: $newAuthExchangeInput) {
              id
            }
          }
        `,
        variables: {
          newAuthExchangeInput,
        },
      }),
    }),
  }),
})

export const { useUpdateAuthExchangeMutation } = updateAuthExchangeApi
