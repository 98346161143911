import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

import { Banner } from '@models/Banner/Banner'

export const bannersApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    banners: builder.query<Banner[], { isPopUp: boolean }>({
      query: ({ isPopUp }) => ({
        document: gql`
          query GetBanners($isPopUp: Boolean!) {
            getBanners(isMobile: false, isPopUp: $isPopUp) {
              id
              imgPath
              link
              createdAt
            }
          }
        `,
        variables: { isPopUp },
      }),
      transformResponse(res: { getBanners: Banner[] }) {
        const { getBanners } = res
        return getBanners
      },
      providesTags: ['Banners'],
    }),
  }),
})

export const { useBannersQuery } = bannersApi
