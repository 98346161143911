import React from 'react'
import { BotCardStatusDetailProps } from '@features/bot/components/BotCard/interface'
import { DateTime } from 'luxon'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { toLocaleStringActualDecimal } from '@libs/currencyFormat'

export const BotCardFinishState = ({ bot }: BotCardStatusDetailProps) => {
  return (
    <>
      <div className='mb-[24px] flex-col items-start space-y-3'>
        <div className='px-[17px] text-sm font-medium flex flex-col gap-y-[16px] '>
          {/* Initial margin */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Initial margin</span>
            <span>
              {toLocaleStringActualDecimal(
                bot.botStrategy?.firstOrderRawQuote *
                  (bot.botStrategy?.isFirstOrderDouble ? 2 : 1)
              ) ?? '-'}
              {' USDT'}
            </span>
          </div>

          {/* Covering round */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Current covering</span>
            <span>
              {bot?.botStrategy?.isCovering
                ? `${bot?.coverRound}/${
                    bot?.botStrategy?.coveringQuantity ?? '-'
                  }`
                : '-'}
            </span>
          </div>

          {/* Profit & Loss % */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Profit and loss</span>
            <span
              className={
                (bot.profitPercentage ?? 0) > 0
                  ? 'text-green-100'
                  : (bot.profitPercentage ?? 0) < 0
                  ? 'text-red'
                  : ''
              }
            >
              {bot?.profitPercentage?.toLocaleString(undefined, {
                maximumFractionDigits: 2,
              })}
              {' %'}
            </span>
          </div>
          {/* End At */}
          <div className='flex space-x-2'>
            <span className='flex-1'>
              {bot?.status === BotStatusEnum.ERROR
                ? 'Error time'
                : 'Finished time'}
            </span>
            <span>
              {bot.endAt
                ? DateTime.fromMillis(bot.endAt).toFormat('dd-MM-yyyy HH:mm:ss')
                : '-'}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
