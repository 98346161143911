import enFlag from '@assets/images/enFlag.png'
import thFlag from '@assets/images/thFlag.png'

import withErrorCaseModal from './withErrorCaseModal'
import { ErrorCaseModalProps } from './interface'
import AccordionErrorCase from './component/AccordionErrorCase/AccordionErrorCase'

const ErrorCaseModal = ({
  errorCaseArray,
  language,
  handleChangeLanguage,
  t,
}: ErrorCaseModalProps) => {
  return (
    <div className='max-w-[375px] sm:max-w-[100%] lg:max-w-[800px] max-h-[70vh] overflow-y-auto no-scrollbar mb-3'>
      <div className='flex'>
        <h1 className='mb-5 m-2 lg:m-5 text-xl font-semibold text-primary'>
          Explore error cases and solutions
        </h1>
        <div className='flex justify-center items-center gap-x-3 m-2 lg:m-5 '>
          <div onClick={() => handleChangeLanguage('en')}>
            <img
              src={enFlag}
              alt='EN'
              className={`cursor-pointer h-[18px] ${
                language !== 'en' && 'opacity-40'
              }`}
            />
          </div>
          <div onClick={() => handleChangeLanguage('th')}>
            <img
              src={thFlag}
              alt='TH'
              className={`cursor-pointer h-[18px] ${
                language !== 'th' && 'opacity-40'
              }`}
            />
          </div>
        </div>
      </div>

      <div className='flex flex-col'>
        {errorCaseArray.map((data, index) => (
          <AccordionErrorCase
            causeTitle={t('errorCaseModal.causeTitile')}
            solutionTitle={t('errorCaseModal.solutionTitle')}
            key={index}
            {...data}
          />
        ))}
      </div>
    </div>
  )
}

const WrappedComponent = withErrorCaseModal(ErrorCaseModal)
export default WrappedComponent
