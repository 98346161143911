import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { Announcement } from '@models/Announcement/announcement'

export const activeAnnouncementApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    activeAnnouncement: builder.query<Announcement, void>({
      query: () => ({
        document: gql`
          query ActiveAnnouncement {
            getActiveAnnouncement {
              title
              description
              createdAt
              activeAt
            }
          }
        `,
      }),
      transformResponse(res: { getActiveAnnouncement: Announcement }) {
        const { getActiveAnnouncement } = res
        return getActiveAnnouncement
      },
      providesTags: ['ActiveAnnouncement'],
    }),
  }),
})

export const { useActiveAnnouncementQuery } = activeAnnouncementApi
