import React from 'react'
import Select, { components } from 'react-select'
import { Option } from '@components/form/SelectField/interface'
import { ArrowDownIcon } from '@components/Icons/Icons'
import { SelectSimpleFieldProps } from './interface'

const SelectSimpleField = ({
  options,
  onSelectedChange,
  value,
  isMulti = false,
  disabled,
  icon,
  label,
  placeholder,
  isSearchable = false,
  isBackground = true,
  isBorder = false,
}: SelectSimpleFieldProps) => {
  return (
    <div className='inline-block w-full'>
      <div className='relative flex-wrap w-full items-stretch text-sm'>
        {label && <div className='text-sm mb-2 font-normal'>{label}</div>}
        <Select
          value={
            Array.isArray(value)
              ? value.map(id => options.find(option => option.value === id))
              : options.find(option => option.value === value) || null
          }
          options={options}
          onChange={v => {
            const val = v as Option | Option[]
            if (Array.isArray(val)) {
              onSelectedChange(val.map(option => option.value))
            } else {
              onSelectedChange(val.value)
            }
          }}
          isMulti={isMulti}
          className='basic-multi-select'
          classNamePrefix='select'
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={isSearchable}
          components={{
            DropdownIndicator: props => (
              <components.DropdownIndicator {...props}>
                {icon || <ArrowDownIcon width='14' height='14' />}
              </components.DropdownIndicator>
            ),
          }}
          styles={{
            placeholder: base => ({
              ...base,
              color: '#ffffff',
            }),
            control: (base, state) => ({
              ...base,
              borderRadius: '4px',
              overflow: 'hidden',
              backgroundColor: isBackground
                ? state.isDisabled
                  ? '#e5e7eb'
                  : '#1D2533F2'
                : 'none',
              border: isBorder ? '1' : '0',
              borderColor: '#484C4E',
              boxShadow: 'none',
              paddingTop: 9,
              paddingBottom: 9,
            }),
            input: base => ({
              ...base,
              color: '#ffffff',
            }),
            singleValue: base => ({
              ...base,
              color: '#ffffff',
            }),
            valueContainer: base => ({
              ...base,
              justifyContent: isBackground ? 'left' : 'right',
            }),
            menu: base => ({
              ...base,
              zIndex: 10,
              backgroundColor: '#1D2533F2',
            }),
            option: (base, { isSelected }) => ({
              ...base,
              color: '#ffffff',
              backgroundColor: isSelected ? '#21BB8E' : '#1D2533F2',
              ':hover': {
                backgroundColor: '#21BB8E',
              },
            }),
            indicatorSeparator: base => ({
              ...base,
              backgroundColor: 'none',
            }),
            dropdownIndicator: base => ({
              ...base,
              paddingLeft: 0,
            }),
          }}
        />
      </div>
    </div>
  )
}

export default SelectSimpleField
