import React from 'react'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { BotCardReadyState } from '@features/bot/components/BotCard/components/BotCardReadyState'
import { BotCardRunningState } from '@features/bot/components/BotCard/components/BotCardRunningState'
import { BotCardFinishState } from '@features/bot/components/BotCard/components/BotCardFinishState'
import BotSwitch from '@features/bot/components/BotSwitch/BotSwitch'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import DeleteBotButton from '@features/bot/pages/BotPage/components/DeleteBotButton/DeleteBotButton'

import { BotCardProps } from './interface'
import withBotCard from './withBotCard'

function BotCard({
  bot,
  botSymbolPath,
  refetchBot,
  isLoading,
  refetchBotList,
}: BotCardProps) {
  const navigate = useNavigate()
  const { isFuture } = useBotMarketType()
  const isShowOnlyIcon = true

  const handleNavigateToBotPage = () => {
    const route = isFuture ? ContractRoutesPath : SpotRoutesPath
    let search: string | undefined
    if (botSymbolPath) {
      search = `?${createSearchParams({
        botSymbolPath: encodeURIComponent(botSymbolPath!),
      })}`
    }
    navigate({
      pathname: route.bot.absolutePath.replace('[id]', bot.id.toString()),
      search: search ?? undefined,
    })
  }

  return (
    <div
      className='rounded-[15px] bg-gray-900 b px-[16px] pt-[16px] cursor-pointer border-1 border-white hover:border-primary'
      onClick={handleNavigateToBotPage}
    >
      {/* Bot switch */}
      <BotSwitch
        bot={bot}
        refetchBot={refetchBot}
        isLoading={isLoading}
        botSymbolPath={botSymbolPath}
      />
      {/* Divider */}
      <div className='w-full h-[1px] bg-gray-500 my-[14px]' />
      {bot.status === BotStatusEnum.READY && <BotCardReadyState bot={bot} />}
      {(bot.status === BotStatusEnum.RUNNING ||
        bot.status === BotStatusEnum.STOP) && <BotCardRunningState bot={bot} />}
      {(bot.status === BotStatusEnum.ERROR ||
        bot.status === BotStatusEnum.FINISHED) && (
        <BotCardFinishState bot={bot} />
      )}
      <div className='w-full h-[1px] bg-gray-500 my-[14px] mt-[-10px]' />
      <div className='my-[5px] flex justify-end'>
        <span
          onClick={e => {
            e.stopPropagation()
          }}
        >
          <DeleteBotButton
            onHandleAfterDelete={() => {
              refetchBotList()
            }}
            isShowOnlyIcon={isShowOnlyIcon}
            bot={bot}
          />
        </span>
      </div>
    </div>
  )
}

export default withBotCard(BotCard)
