import React, { useState } from 'react'
import { EasyTradeTemplateOption } from '@models/Bot/EasyTradeTemplateOption'
import {
  SelectEasyTradeAcceptProps,
  SelectEasyTradeProps,
} from '@features/bot/pages/CreateEasyBotPage/components/SelectEasyTrade/interface'

const withSelectEasyTrade = (Component: React.FC<SelectEasyTradeProps>) => {
  const Hoc = ({
    easyTradeTemplateOptions,
    onSelectTemplateCallback,
  }: SelectEasyTradeAcceptProps) => {
    const [selectedEasyTradeTemplate, setSelectedEasyTradeTemplate] =
      useState<EasyTradeTemplateOption>()

    const handleOnSelectTemplate = template => {
      setSelectedEasyTradeTemplate(template)
      onSelectTemplateCallback(template.id)
    }

    const newProps: SelectEasyTradeProps = {
      easyTradeTemplateOptions,
      handleOnSelectTemplate,
      selectedEasyTradeTemplate,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withSelectEasyTrade
