import React from 'react'

import TermsOfService from './TermsOfService'

const TermsOfServiceModal = props => {
  return (
    <div className='w-[500px] h-4/5-screen  overflow-y-auto mx-3 my-4'>
      <TermsOfService className='text-gray-dark px-2' />
    </div>
  )
}
export default TermsOfServiceModal
