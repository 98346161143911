import React, { useState } from 'react'
import {
  AccordionErrorCaseAcceptProps,
  AccordionErrorCaseProps,
} from './interface'

const withAccordionErrorCase = (
  Component: React.FC<AccordionErrorCaseProps>
) => {
  const Hoc = ({
    title,
    causeTitle,
    solutionTitle,
    detailList,
    causeList,
    solutionList,
  }: AccordionErrorCaseAcceptProps) => {
    const [accordionOpen, setAccordionOpen] = useState(false)

    const handleAccordionOpen = () => {
      setAccordionOpen(prev => !prev)
    }

    const newProps: AccordionErrorCaseProps = {
      accordionOpen,
      title,
      causeTitle,
      solutionTitle,
      detailList,
      causeList,
      solutionList,
      handleAccordionOpen,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
export default withAccordionErrorCase
