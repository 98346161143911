function TextTitleWithValue({
  title,
  value,
}: {
  title: string
  value: string
}) {
  return (
    <div className='text-center'>
      <div className='mb-[2px] text-xs'>{value}</div>
      <div className='text-[10px] text-gray-400'>{title}</div>
    </div>
  )
}

export default TextTitleWithValue
