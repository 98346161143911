import { ClosePositionButtonProps } from '@features/bot/pages/BotPage/components/ClosePositionButton/interface'
import OutlinedButton from '@components/buttons/OutlinedButton'
import withClosePositionButton from './withClosePositionButton'

const ClosePositionButton = ({
  handleClosePosition,
}: ClosePositionButtonProps) => {
  return (
    <OutlinedButton
      type='button'
      onClick={handleClosePosition}
      className='text-red text-sm font-bold w-full h-[48px] border-1 border-red rounded-md'
    >
      Close bot
    </OutlinedButton>
  )
}

const WrappedComponent = withClosePositionButton(ClosePositionButton)
export default WrappedComponent
