import { RankCardProps } from '../interface'
import RankProfileImage from './RankProfileImage'

function RankCard({
  name,
  profit,
  rank,
  profileImagePath,
  onClickCallback,
}: RankCardProps) {
  return (
    <div
      className='flex items-center justify-between max-w-[645px] w-full h-[55px] py-[8px] px-[20px] mobile:px-[15px] border-[1px] rounded-[10px] border-gray-300 cursor-pointer'
      onClick={onClickCallback}
    >
      <div className='flex flex-grow items-center justify-start w-0 '>
        <div className='flex-none'>
          <RankProfileImage
            rank={rank}
            profileImagePath={profileImagePath}
            rankCircleClassName='w-[16px] h-[16px]'
            rankCircleTextClassName='text-[7px]'
          />
        </div>
        <span className='whitespace-nowrap overflow-hidden truncate  px-[8px] text-xs '>
          {name}
        </span>
      </div>

      <p className='flex flex-shrink text-xs justify-end text-gray-400  '>
        {profit.toFixed(2)} USDT
      </p>
    </div>
  )
}

export default RankCard
