import TermsOfService from '@components/TermsOfService/TermsOfService'
import React from 'react'

const TermsOfServicePage = props => {
  return (
    <div className='desktop:mt-2 mb-9 px-[15px] max-w-[700px] mx-auto'>
      <TermsOfService />
    </div>
  )
}
export default TermsOfServicePage
