import { botApi } from '@client/client'
import { gql } from 'graphql-request'
import { botWsService } from '@client/botClient/ws/client'
import { BOT_WS_API_URL } from '@config/config'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const contractualAssetModelApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    contractualAssetModel: builder.query<
      { assetValue: number },
      { botMarketType: BotMarketType }
    >({
      query: ({ botMarketType }) => ({
        document: gql`
          query ContractualAssetModel($botMarketType: BotMarketType!) {
            contractualAssetModel(botMarketType: $botMarketType) {
              assetValue
            }
          }
        `,
        variables: { botMarketType },
      }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // wait for the initial query to resolve before proceeding
        await cacheDataLoaded

        // create a websocket connection when the cache subscription starts
        const ws = botWsService<{
          contractualAssetSubscription: { assetValue: number }
        }>({
          url: BOT_WS_API_URL,
          queryVariables: { botMarketType: arg.botMarketType },
          queryDocument: `subscription($botMarketType: BotMarketType!) {
                                  contractualAssetSubscription(botMarketType: $botMarketType) {
                                    assetValue
                                  }
                                }
                              `,
          onError: () => {
            // console.log('error from socket : ', e)
            // toast.error(`bot ${arg.botId} socket got error: ${e}`)
            // ws.terminate()
          },
          onNext: bot => {
            updateCachedData(() => {
              return bot.data!.contractualAssetSubscription
            })
          },
          onComplete: () => {},
        })
        await cacheEntryRemoved
        ws.terminate()
      },
      transformResponse(res: {
        contractualAssetModel: { assetValue: number }
      }) {
        const { contractualAssetModel } = res
        return contractualAssetModel
      },
      providesTags: ['ContractualAsset'],
    }),
  }),
})

export const { useContractualAssetModelQuery } = contractualAssetModelApi
