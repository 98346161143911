import React from 'react'
import cn from 'classnames'
import { InputFieldProps } from '@components/form/InputField/interface'
import Tooltip from '@components/Tooltip/Tooltip'
import { withInputField } from './withInputField'

const InputField = ({
  error,
  touched,
  icon: Icon,
  disabled,
  type,
  showErrorMessage,
  label,
  className = '',
  inputClassName = '',
  rightIcon,
  value,
  onChange,
  name,
  placeholder,
  onFormikChange,
  required = false,
  tooltip,
  step,
  showErrorWithoutTouched,
  ...rest
}: InputFieldProps) => {
  return (
    <div className={cn('inline-block w-full', className)}>
      <div className='relative flex-wrap w-full items-stretch'>
        {label && (
          <div className='flex flex-row space-x-2'>
            <div className='text-sm mb-2 font-medium text-white'>
              {label} {required && <span className='text-red-dark'>*</span>}
            </div>
            {tooltip && (
              <Tooltip
                title={typeof label === 'string' ? label : undefined}
                text={tooltip}
              />
            )}
          </div>
        )}
        <label className='relative flex items-center'>
          <input
            data-test-id={rest['data-test-id']}
            className={cn(inputClassName, {
              'border-red border': touched && error,
              'bg-gray-900 border border-gray-300 px-[18px] py-[14px] text-white rounded-[5px] outline-none placeholder-gray-400 text-sm relative w-full disabled:bg-gray-secondary disabled:bg-opacity-30 disabled:cursor-not-allowed focus:border-primary':
                !inputClassName,
            })}
            disabled={disabled}
            type={type}
            value={value}
            onChange={e => {
              onFormikChange(e)
              onChange && onChange(e.target.value)
            }}
            onWheel={(e: any) => e.target?.blur()}
            name={name}
            placeholder={placeholder}
            step={step ?? 'any'}
          />
          {rightIcon && (
            <div className={`absolute right-3 ${disabled && 'opacity-30'}`}>
              {rightIcon}
            </div>
          )}
        </label>
        {showErrorMessage && error && (touched || showErrorMessage) && (
          <div
            className='mt-[2px] text-xs text-red top-full'
            data-test-id={`input-${name}-error`}
          >
            {error}
          </div>
        )}
      </div>
    </div>
  )
}

export default withInputField(InputField)
