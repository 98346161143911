import React from 'react'
import { Field } from 'formik'
import { SelectButton } from '@components/SelectButton/SelectButton'
import { FieldPropsType } from '@interfaces/FieldPropsType'
import { SelectButtonFieldProps } from './interface'

const SelectButtonField = ({
  name,
  onChange,
  disabled,
  ...props
}: SelectButtonFieldProps) => {
  return (
    <Field name={name}>
      {({ field, meta, form }: FieldPropsType<any>) => (
        <SelectButton
          disabled={disabled}
          {...props}
          value={field.value}
          onChange={value => {
            form.setFieldValue(name, value)
            setTimeout(() => {
              form.setFieldTouched(name, true)
            })
            if (onChange) {
              onChange(value)
            }
          }}
        />
      )}
    </Field>
  )
}

export default SelectButtonField
