import { TransactionTypeEnum } from '@interfaces/TransactionTypeEnum'

export const transactionTypeOptions = [
  { label: 'All', value: undefined },
  { label: 'Activate', value: TransactionTypeEnum.ACTIVATE_ACCOUNT },
  { label: 'Deposit', value: TransactionTypeEnum.DEPOSIT },
  { label: 'Withdraw', value: TransactionTypeEnum.WITHDRAW },
  {
    label: 'Renew activate',
    value: TransactionTypeEnum.RE_NEW_ACTIVATE_ACCOUNT,
  },
  { label: 'Referral', value: TransactionTypeEnum.REFERRAL },
  { label: 'Transfer', value: TransactionTypeEnum.TRANSFER },
  { label: 'Transfer fee', value: TransactionTypeEnum.TRANSFER_FEE },
  {
    label: 'Buy TC. symbol',
    value: TransactionTypeEnum.TRADE_COPY_BOT,
  },
  {
    label: 'Sell TC. symbol',
    value: TransactionTypeEnum.TRADE_COPY_BOT_INCOME,
  },
  {
    label: 'Buy TC. person',
    value: TransactionTypeEnum.TRADE_COPY_PERSON,
  },
  {
    label: 'Sell TC. person',
    value: TransactionTypeEnum.TRADE_COPY_PERSON_INCOME,
  },
  {
    label: 'Top up Khotcoin',
    value: TransactionTypeEnum.TOP_UP_KHOT_COIN,
  },
  {
    label: 'System fee',
    value: TransactionTypeEnum.SYSTEM_FEE,
  },
]
