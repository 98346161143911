import { MinMaxType } from '@models/FutureMarketDetail/FutureMarketDetail'
import { useEffect } from 'react'
import { useFormikContext } from 'formik'
import { GridBotForm } from '@models/Bot/GridBotForm'
import { ADJUST_COEF } from '@config/config'

const GridBotCompose = ({
  initialMarginMin,
  profitPerGrid,
}: {
  initialMarginMin: number | null
  profitPerGrid: MinMaxType | null
}) => {
  const { setFieldValue, values } = useFormikContext<GridBotForm>()

  useEffect(() => {
    const gridQty =
      (ADJUST_COEF * values.initialMargin * values.leverage) /
      (values.upperPrice + values.lowerPrice)
    setFieldValue('gridQty', gridQty)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialMarginMin, values.initialMargin])

  useEffect(() => {
    setFieldValue('profitPerGridLower', profitPerGrid?.min)
    setFieldValue('profitPerGridHigher', profitPerGrid?.max)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profitPerGrid])

  return <></>
}

export default GridBotCompose
