import React from 'react'

const HelperButton = ({
  onClick,
  label,
  icon,
  ...rest
}: {
  onClick: () => void
  label: string | React.ReactNode
  icon: React.ReactNode
  'data-test-id'?: string
}) => {
  return (
    <div className='w-[60px] flex flex-col items-center justify-start'>
      <div
        className='flex flex-col items-center justify-start cursor-pointer'
        onClick={onClick}
      >
        <div
          data-test-id={rest['data-test-id']}
          className='w-[50px] h-[50px] bor border-1 border-gray-300 rounded-full flex items-center justify-center mb-2 p-2 mobile:p-3'
        >
          {icon}
        </div>
        <p className='text-center desktop:text-base text-[10px] whitespace-nowrap'>
          {label}
        </p>
      </div>
    </div>
  )
}

export default HelperButton
