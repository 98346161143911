import React from 'react'
import { ClosePositionCommandEnum } from '@constant/form/createBotForm/closePositionOptions'
import {
  ClosePositionButtonAcceptProps,
  ClosePositionButtonProps,
} from '@features/bot/pages/BotPage/components/ClosePositionButton/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { ClosePositionModalProps } from '@components/Modal/components/ClosePositionModal/interface'
import ClosePositionModal from '@components/Modal/components/ClosePositionModal'
import { useClosePositionMutation } from '@client/botClient/collections/ClosePositionClientAction'

const WithClosePositionButton = (
  Component: React.FC<ClosePositionButtonProps>
) => {
  const Hoc = ({ bot, refetch }: ClosePositionButtonAcceptProps) => {
    const [closeBotMutate, { isLoading: isClosePositionLoading }] =
      useClosePositionMutation()
    const { openModal: openClosePositionModal } = useModal({
      component: ({ onConfirm, symbol }: ClosePositionModalProps) => (
        <ClosePositionModal symbol={symbol} onConfirm={onConfirm} />
      ),
    })

    const handleClosePosition = async () => {
      await openClosePositionModal({
        symbol: bot!.symbol,
        onConfirm: async (closePositionCommand: ClosePositionCommandEnum) => {
          await closeBotMutate({
            botId: bot!.id,
            closePositionCommand,
          })
          refetch()
        },
      })
    }

    const newProps: ClosePositionButtonProps = {
      handleClosePosition,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithClosePositionButton
