import { AnnouncementIcon } from '@components/Icons/Icons'
import Loading from '@components/utils/Loading'
import React from 'react'
import { ActiveAnnouncementProps } from './interface'
import withActiveAnnouncement from './withActiveAnnouncement'

const ActiveAnnouncement = ({
  className,
  announcement,
  isLoading,
}: ActiveAnnouncementProps) => {
  if (isLoading) {
    return <Loading text='Loading...' />
  }
  if (!isLoading && announcement) {
    return (
      <div
        className={`flex items-center border-1 border-gray-300 rounded-[5px] px-4 py-3 mobile:py-2 mobile:px-3 ${className}`}
      >
        <AnnouncementIcon className='mr-2 h-[32px] w-[32px] mobile:h-[20px] mobile:w-[20px]' />
        <p className='text-base mobile:text-[10px]'>
          {announcement.description}
        </p>
      </div>
    )
  }
  return <div />
}
export default withActiveAnnouncement(ActiveAnnouncement)
