import React from 'react'
import { DateTime } from 'luxon'
import { TransactionIcon } from '@components/Icons/Icons'
import Table from '@components/Table/Table'
import withTransactionLog from './withTransactionLog'
import { TransactionLogProps } from './interface'

const TransactionLog = ({
  className,
  transactionLogs,
  isLoading,
  page,
  perpage,
  pageChange,
  perpageChange,
  isShowPagination,
}: TransactionLogProps) => {
  return (
    <>
      <div className={`${className}`}>
        <div className='flex items-center mb-[13px]'>
          <TransactionIcon />
          <div className='ml-[8px] header'>Transaction Log</div>
        </div>
        <div className='mb-[16px] '>
          <Table
            data={transactionLogs?.tradeActions}
            columns={[
              {
                Header: 'Time',
                id: 'createdAt',
                accessor: 'createdAt',
                Cell: ({ value }) => (
                  <span className='text-gray-light'>
                    {DateTime.fromMillis(value).toFormat('dd-MM-yyyy HH:mm:ss')}
                  </span>
                ),
                minWidth: 132,
              },
              {
                Header: 'Transaction',
                id: 'transaction',
                accessor: 'status',
                Cell: ({ value }) => (
                  <span
                    className={
                      value.includes('BUY') ? 'text-green' : 'text-red'
                    }
                  >
                    {value.includes('BUY') ? 'Buy' : 'Sell'}
                  </span>
                ),
                minWidth: 90,
              },
              {
                Header: 'Asset',
                id: 'symbol',
                accessor: 'symbol',
                Cell: ({ value }) => (
                  <span className='text-primary'>{value}</span>
                ),
                minWidth: 90,
              },
              {
                Header: 'Price per',
                id: 'latestOrderPrice',
                accessor: 'latestOrderPrice',
                Cell: ({ value }) => {
                  return (
                    <span>
                      {value?.toLocaleString(undefined, {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2,
                      })}
                    </span>
                  )
                },
                minWidth: 90,
              },
              {
                Header: 'Value',
                id: 'value',
                Cell: ({ row }) => {
                  return (
                    <span>{`${row.original.quote?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${row.original.quoteCurrency}`}</span>
                  )
                },
                minWidth: 90,
              },
              {
                Header: 'Quantity',
                id: 'quantity',
                Cell: ({ row }) => {
                  return (
                    <span>{`${row.original.base?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} ${row.original.baseCurrency}`}</span>
                  )
                },
                minWidth: 90,
              },
              {
                Header: 'Status',
                id: 'status',
                accessor: 'status',
                Cell: ({ value }) => (
                  <span
                    className={
                      value.includes('SUCCESS') ? 'text-green' : 'text-red'
                    }
                  >
                    {value.includes('SUCCESS') ? 'Complete' : 'Fail'}
                  </span>
                ),
                minWidth: 90,
              },
            ]}
            totalCount={transactionLogs?.pagination?.totalCount || 0}
            totalPage={transactionLogs?.pagination?.totalPage || 1}
            manualPagination
            currentPage={page}
            currentPageSize={perpage}
            handleOnPageChange={pageChange}
            handleOnPageSizeChange={perpageChange}
            isLoading={isLoading}
            isShowPagination={isShowPagination}
          />
        </div>
      </div>
    </>
  )
}

export default withTransactionLog(TransactionLog)
