import OutlinedButton from '@components/buttons/OutlinedButton'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { SuccessIcon } from '@components/Icons/Icons'
import { ModalContext } from '@components/Modal/context/ModalContext'
import React, { useContext, useState } from 'react'
import { closePositionOptions } from '@constant/form/createBotForm/closePositionOptions'
import { Form, Formik } from 'formik'
import RadioInputField from '@components/form/RadioInputField/RadioInputField'

import { ClosePositionModalForm, ClosePositionModalProps } from './interface'

const initialValues: ClosePositionModalForm = {
  closePosition: closePositionOptions[3].value.toString(),
}

function ClosePositionModal({
  onConfirm,
  onCancel,
  textCancelButton,
  textConfirmButton,
  actionButtonRTL = false,
  symbol,
  disabledConfirmButton,
  classNameCancelButton = 'px-[15px] py-[6px] text-gray-secondary border-[#DCDFE6] font-normal',
  classNameConfirmButton = 'py-[7px] px-[15px] rounded-[4px]',
}: ClosePositionModalProps) {
  const { handleToggleModal } = useContext(ModalContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  return (
    <div className='w-[304px] relative'>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => (
          <Form>
            {/* {isSubmitting && (
        <div className='absolute top-0 bottom-0 right-0 left-0 z-1 bg-white bg-opacity-80 w-full h-full flex items-center justify-center'>
          <p className='select-none'>กำลังดำเนินการ...</p>
        </div>
      )} */}
            <div className='flex flex-col items-center'>
              <SuccessIcon width='24' height='24' />
              <div className='mt-[9px] px-[40px] text-center '>
                <div className='flex flex-col items-center'>
                  <div className='text-lg font-medium'>Confirm</div>
                  <p className='mt-[7px] text-sm'>
                    Are you sure to close {symbol}
                  </p>
                </div>
              </div>
            </div>
            <div className='flex justify-center mt-[10px]'>
              <RadioInputField
                name='closePosition'
                options={closePositionOptions}
                direction='vertical'
              />
            </div>
            <div
              className={`mt-[20px] flex items-center justify-center gap-x-[10px] ${
                actionButtonRTL && 'flex-row-reverse'
              }`}
            >
              <OutlinedButton
                type='button'
                className={classNameCancelButton}
                onClick={onCancel || handleToggleModal}
              >
                <span className='text-sm text-white'>
                  {textCancelButton || 'Cancel'}
                </span>
              </OutlinedButton>
              <PrimaryButton
                type='button'
                className={classNameConfirmButton}
                onClick={() => {
                  setIsSubmitting(true)
                  try {
                    onConfirm(values.closePosition)
                  } catch (error) {
                    // TODO: notify error
                  } finally {
                    setIsSubmitting(false)
                    handleToggleModal()
                  }
                }}
                disabled={disabledConfirmButton || isSubmitting}
              >
                <span className='text-sm font-medium'>
                  {textConfirmButton || 'Confirm'}
                </span>
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default ClosePositionModal
