import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetTradeCopyByBotQuery } from '@client/botClient/collections/GetTradeCopyByBot'
import Loading from '@components/utils/Loading'
import { StrategyDetailPageProps } from './interface'

const withStrategyDetailPage = (
  Component: React.FC<StrategyDetailPageProps>
) => {
  function WithStrategyDetailPage() {
    const { id } = useParams()
    const [params] = useSearchParams()
    const personalId = Number(params.get('personalId'))
    const mode = params.get('mode') || ''

    const { data: detail, isLoading: isLoadingDetail } =
      useGetTradeCopyByBotQuery({
        tradeCopyByBotId: Number(id),
        tradeCopyByPersonId: Number(personalId),
      })

    if (isLoadingDetail) {
      return <Loading text='Loading' />
    }

    const newProps = { detail, isLoadingDetail, personalId, mode }
    return <Component {...newProps} />
  }

  return WithStrategyDetailPage
}

export default withStrategyDetailPage
