import { botApi } from '@client/client'
import { gql } from 'graphql-request'
import { EasyTradeTemplateDefault } from '@models/Bot/EasyTradeTemplateDefault'

export const easyTradeTemplateDefaultApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    easyTradeTemplateDefault: builder.query<
      EasyTradeTemplateDefault,
      { id: number }
    >({
      query: ({ id }) => ({
        operationName: 'EasyTradeTemplateDefault',
        document: gql`
          query EasyTradeTemplateDefault($id: Float!) {
            easyTradeTemplate(id: $id) {
              id
              name
              description
              symbols {
                symbol
                symbolImgPath
              }
              minimumPerSymbol
              botStrategy {
                id
                coverSpreads
                tpRatio
                coveringQuantity
                coverMultiples
                isCovering
                isSplitTp
              }
              logoImgPath
            }
          }
        `,
        variables: {
          id,
        },
      }),
      transformResponse(res: { easyTradeTemplate: EasyTradeTemplateDefault }) {
        const { easyTradeTemplate } = res
        return easyTradeTemplate
      },
      providesTags: ['EasyTradeTemplateDefault'],
    }),
  }),
})

export const { useLazyEasyTradeTemplateDefaultQuery } =
  easyTradeTemplateDefaultApi
