import React from 'react'
import Loading from '@components/utils/Loading'
import { useGetTradeCopyByPersonsQuery } from '@client/botClient/collections/GetTradeCopyByPersons'
import { TradeCopyPersonalProps } from './interface'

const withTradeCopyPersonal = (Component: React.FC<TradeCopyPersonalProps>) => {
  function WithTradeCopyPersonal() {
    const {
      data: tradeCopyByPersons,
      isLoading,
      refetch,
    } = useGetTradeCopyByPersonsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    })

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const newProps = { tradeCopyByPersons, refetch }
    return <Component {...newProps} />
  }

  return WithTradeCopyPersonal
}

export default withTradeCopyPersonal
