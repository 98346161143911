import React from 'react'
import { useOneOffMutation } from '@client/botClient/collections/OneOffClientAction'
import { toast } from 'react-toastify'
import {
  OneOffButtonAcceptProps,
  OneOffButtonProps,
} from '@features/bot/pages/BotPage/components/OneOffButton/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useParams } from 'react-router-dom'

const WithOneOffButton = (Component: React.FC<OneOffButtonProps>) => {
  const Hoc = ({ refetch, isOneOff, isDisable }: OneOffButtonAcceptProps) => {
    const { id } = useParams()
    const [oneOffMutate, { isLoading: isOneOffLoading }] = useOneOffMutation()
    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to switch one-off bot cycle
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleOnOneOff = () => {
      if (isDisable) return
      openModal({
        onConfirm: async () => {
          await oneOffMutate({ botId: Number(id), isOneOff: !isOneOff })
          toast.success('One off successful')
          refetch()
        },
      })
    }
    const newProps: OneOffButtonProps = {
      handleOnOneOff,
      isOneOff,
      isDisable,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithOneOffButton
