import { exchangeApi } from '@client/client'
import { FutureMarketLeverage } from '@models/FutureMarketLeverage/FutureMarketLeverage'
import { gql } from 'graphql-request'

export const futureMarketLeverageApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    futureMarketLeverage: builder.query<
      FutureMarketLeverage[],
      { symbol: string }
    >({
      query: ({ symbol }) => ({
        document: gql`
          query FutureMarketLeverage($symbol: String!) {
            futureMarketLeverage(symbol: $symbol) {
              maxLeverage
              maxCost
              currency
            }
          }
        `,
        variables: {
          symbol,
        },
      }),
      transformResponse(res: { futureMarketLeverage: FutureMarketLeverage[] }) {
        const { futureMarketLeverage } = res
        return futureMarketLeverage
      },
      providesTags: ['Leverage'],
    }),
  }),
})

export const {
  useFutureMarketLeverageQuery,
  useLazyFutureMarketLeverageQuery,
} = futureMarketLeverageApi
