import { createContext, useContext, useState } from 'react'
import { Modal } from '@components/Modal'

interface ModalOption {
  isHideCloseButton: boolean
  modalClassName?: string
  closeIconClassName?: string
}

type PromiseType = <T>(
  Component: JSX.Element,
  modalOptions: ModalOption
) => Promise<T>

export const ModalContext = createContext<{
  handleOpenModal: null | PromiseType
  handleToggleModal: (v?: any) => void
}>({
  handleOpenModal: null,
  handleToggleModal: () => {},
})
const initialValue: {
  body: JSX.Element
  isOpen: boolean
  isHideCloseButton: boolean
  modalClassName?: string
  closeIconClassName?: string
} = {
  body: <></>,
  isOpen: false,
  isHideCloseButton: false,
  modalClassName: undefined,
  closeIconClassName: undefined,
}

export const ModalContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [modal, setModal] = useState(initialValue)
  const [resolve, setResolve] = useState<(value?: unknown) => void>()
  const handleToggleModal = (v?: any) => {
    setModal({ ...initialValue })
    if (v && resolve) {
      resolve!(v)
    } else if (resolve) {
      resolve!()
    }
    setResolve(() => () => {})
  }

  const handleOpenModal: PromiseType = async (
    Component: JSX.Element,
    { isHideCloseButton, modalClassName, closeIconClassName }
  ) => {
    return new Promise(res => {
      setResolve(() => res)
      setModal({
        body: Component,
        isOpen: true,
        isHideCloseButton,
        modalClassName,
        closeIconClassName,
      })
    })
  }

  const handleOnClose = () => {
    setModal({ ...initialValue })
    resolve!()
    setResolve(() => () => {})
  }

  const BodyRenderer = modal.body

  return (
    <ModalContext.Provider value={{ handleOpenModal, handleToggleModal }}>
      {children}
      <Modal
        isOpen={modal.isOpen}
        onClose={handleOnClose}
        disabeldClose={modal.isHideCloseButton}
        modalClassName={modal.modalClassName}
        closeIconClassName={modal.closeIconClassName}
      >
        {BodyRenderer}
      </Modal>
    </ModalContext.Provider>
  )
}

export interface ModalHookProps<T> {
  component: (props?: any) => JSX.Element
  onSuccess?: (v: T) => void
  onClose?: () => void
  isHideCloseButton?: boolean
  modalClassName?: string
  closeIconClassName?: string
}

export function useModal<T>({
  component,
  onSuccess,
  onClose,
  isHideCloseButton = false,
  modalClassName,
  closeIconClassName,
}: ModalHookProps<T>) {
  const { handleToggleModal, handleOpenModal } = useContext(ModalContext)

  return {
    async openModal(params?: any): Promise<T | undefined> {
      const res = await handleOpenModal!<T | undefined>(component(params), {
        isHideCloseButton,
        modalClassName,
        closeIconClassName,
      })
      if (res !== undefined) {
        if (onSuccess) {
          onSuccess(res)
        }
        return res
      }
      if (onClose) {
        onClose()
      }
      return undefined
    },
    handleToggleModal,
  }
}
