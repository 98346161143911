import SearchField from '@components/form/SearchField/SearchField'
import { ArrowDownIcon, ArrowLeftIcon } from '@components/Icons/Icons'
import Loading from '@components/utils/Loading'
import NoData from '@components/NoData/NoData'
import TablePagination from '@components/Table/component/TablePagination/TablePagination'
import ReferralBanner from '@assets/images/referralBanner.png'
import TabBar from '@components/TabBar/TabBar'
import { DateRangeTypeEnum } from '@interfaces/DateRangeTypeEnum'
import { ReferralPageProps } from './interface'
import withReferralPage from './withReferralPage'
import Tier from './components/Tier/Tier'
import ReferralCard from './components/ReferralCard/ReferralCard'

const dateRangeTypeOptions = [
  { value: DateRangeTypeEnum.DAILY, label: 'Daily' },
  { value: DateRangeTypeEnum.WEEKLY, label: 'Weekly' },
  { value: DateRangeTypeEnum.MONTHLY, label: 'Monthly' },
  { value: DateRangeTypeEnum.ALL_TIME, label: 'Total' },
]

function ReferralPage({
  sortNetwork,
  isMobile,
  openSortModal,
  handleSearchTextChange,
  profile,
  upline,
  downlines,
  isLoadingProfile,
  isLoadingUpline,
  isLoadingDownlines,
  isExpanded,
  getCollapseProps,
  handleToggleExpanded,
  page,
  pageChange,
  pageCount,
  pageLimit,
  dateRangeType,
  handleOnDateRangeTypeChanged,
}: ReferralPageProps) {
  return (
    <div className='desktop:mt-2 desktop:px-[60px] px-[16px]'>
      <div className='flex flex-row mobile:flex-col desktop:space-x-[44px] mobile:space-y-6 '>
        {/* ---- Col 1 ----*/}
        <div className='desktop:w-2/5'>
          <Tier dateRangeType={dateRangeType} />
        </div>
        {/* ---- Col 2 ----*/}
        <div className='desktop:w-3/5'>
          <div className='flex flex-col'>
            {!isMobile && (
              <a
                href='https://www.khotbot.com/referral-program'
                target='_blank'
                rel='noreferrer'
              >
                <img
                  src={ReferralBanner}
                  alt='referral'
                  className='rounded-[10px] border border-gray-400 bg-gray-800 '
                />
              </a>
            )}
            <TabBar
              tabClassName='my-[10px]'
              activeTab={dateRangeType}
              tabs={dateRangeTypeOptions}
              handleTabChange={handleOnDateRangeTypeChanged}
            />
            <div className='flex items-center justify-end text-[10px] text-white text-end font-light mb-4'>
              Ref. Information update every 3 hrs.
            </div>
            <div className='flex  gap-0.5 items-center min-h-[62px] p-[8px] border border-gray-300 bg-secondary mb-[28px] rounded-[10px]'>
              <div className='text-xs flex flex-col justify-center items-center'>
                <div>Profit sharing summary</div>
                <div>(USDT)</div>
              </div>
              <div className='flex-1 text-right font-bold'>
                {(profile?.calculatedTotalReferral ?? 0).toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }
                )}
              </div>
            </div>
            <SearchField
              name='searchReferral'
              className='mb-[26px]'
              placeholder='Search by UserID , Name...'
              onChange={v => handleSearchTextChange(v)}
              // rightIcon={
              //   sortNetwork ? (
              //     <FilteredIcon
              //       onClick={() => {
              //         openSortModal({ onConfirm: () => {} })
              //       }}
              //     />
              //   ) : (
              //     <FilterIcon
              //       onClick={() => {
              //         openSortModal({ onConfirm: () => {} })
              //       }}
              //     />
              //   )
              // }
            />
            {/* Inviter */}
            {(isLoadingUpline || upline) && (
              <div className='flex flex-col mb-[23px] '>
                <span className='text-sm font-bold mb-[15px]'>Inviter</span>
                {isLoadingUpline ? (
                  <Loading text='Loading' />
                ) : (
                  upline && (
                    <ReferralCard
                      image={upline!.profileImgPath}
                      name={upline!.name}
                      order={0}
                      isUpline
                    />
                  )
                )}
              </div>
            )}
            {/* ME */}
            {(isLoadingProfile || profile) && (
              <div className='flex flex-col mb-[23px]'>
                <span className='text-sm font-bold mb-[15px]'>Me</span>
                {isLoadingProfile ? (
                  <Loading text='Loading' />
                ) : (
                  profile && (
                    <ReferralCard
                      tierName={profile.userTier.name}
                      image={profile!.profileImgPath}
                      name={profile!.name}
                      order={0 + (upline ? 1 : 0)}
                      teamProfit={profile!.calculatedTeamProfit}
                    />
                  )
                )}
              </div>
            )}
            {/* Invitee */}
            {(isLoadingDownlines || downlines) && (
              <div className='flex flex-col mb-[23px]'>
                <div className='flex flex-row items-center justify-between'>
                  <span className='text-sm font-bold mb-[15px]'>Invitee</span>
                  {isExpanded ? (
                    <ArrowDownIcon onClick={handleToggleExpanded} />
                  ) : (
                    <ArrowLeftIcon onClick={handleToggleExpanded} />
                  )}
                </div>
                {isLoadingDownlines ? (
                  <Loading text='Loading' />
                ) : downlines!.users.length ? (
                  <div {...getCollapseProps()}>
                    {downlines!.users.map((downline, index) => {
                      return (
                        <ReferralCard
                          key={downline.id}
                          className='mb-[15px]'
                          tierName={downline.userTier.name}
                          image={downline.profileImgPath}
                          name={downline.name}
                          isTeamMember={downline.isTeamMember}
                          order={
                            (page - 1) * pageLimit +
                            (index + 1) +
                            (upline ? 1 : 0)
                          }
                          profit={downline.calculatedTotalProfit ?? 0}
                          teamProfit={downline.calculatedTeamProfit ?? 0}
                          isDirectReferral={downline.isDirectReferral}
                          teamCount={downline.teamCount}
                        />
                      )
                    })}

                    <TablePagination
                      paginationProps={{
                        pageCount,
                        handleOnPageChange: pageChange,
                      }}
                      tableState={{
                        pageIndex: page - 1,
                      }}
                    />
                  </div>
                ) : (
                  <NoData />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default withReferralPage(ReferralPage)
