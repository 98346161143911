import { toast } from 'react-toastify'
import ProfileHeader from '@components/ProfileHeader/ProfileHeader'
import { Tabs } from '@components/Tabs/Tabs'
import { Tab } from '@components/Tabs/components/Tab'
import { TabHeader } from '@components/Tabs/components/TabHeader'
import { TabBody } from '@components/Tabs/components/TabBody'
import { useModal } from '@components/Modal/context/ModalContext'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useUpdateStrategyDescriptionMutation } from '@client/mainClient/collections/UpdateStrategyDescriptionClientAction'
import MyStrategyTab from './components/MyStrategyTab/MyStrategyTab'
import CopyBySymbolTab from './components/CopyBySymbolTab/CopyBySymbolTab'
import CopyByPersonalTab from './components/CopyByPersonalTab/CopyByPersonalTab'
import EditDescriptionModal, {
  EditDescriptionModalProps,
} from './components/EditDescriptionModal'

function MyStrategyPage() {
  const { data: profile, refetch: refetchProfile } = useProfileQuery({})
  const [updateStrategyDescription] = useUpdateStrategyDescriptionMutation()

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm, initDescription }: EditDescriptionModalProps) => (
      <EditDescriptionModal
        onConfirm={onConfirm}
        initDescription={initDescription}
      />
    ),
  })

  const onClickEditIcon = () => {
    openModal({
      initDescription: profile?.strategyDescription,
      onConfirm: async (value: string) => {
        await updateStrategyDescription({ strategyDescription: value }).unwrap()
        refetchProfile()
        toast.success('Update strategy description successful')
      },
    })
  }

  return (
    <div className='max-w-[700px] mx-auto py-[10px]'>
      <ProfileHeader
        name={profile?.name || ''}
        description={profile?.strategyDescription || ''}
        showEditIcon
        profileImgPath={profile?.profileImgPath || ''}
        onClickEditIcon={onClickEditIcon}
      />
      <Tabs initialActiveTab='myStrategy'>
        <Tab tabId='myStrategy'>
          <TabHeader>
            <span className='desktop:w-max'>My Strategy</span>
          </TabHeader>
          <TabBody>
            <MyStrategyTab />
          </TabBody>
        </Tab>
        <Tab tabId='copyBySymbol'>
          <TabHeader>
            <span className='desktop:w-max'>Copy by Symbol</span>
          </TabHeader>
          <TabBody>
            <CopyBySymbolTab />
          </TabBody>
        </Tab>
        <Tab tabId='copyByPersonal'>
          <TabHeader>
            <span className='desktop:w-max'>Copy by Personal</span>
          </TabHeader>
          <TabBody>
            <CopyByPersonalTab />
          </TabBody>
        </Tab>
      </Tabs>
    </div>
  )
}

export default MyStrategyPage
