import React from 'react'
import { Outlet } from 'react-router-dom'
import LoginPage from '@features/authentication/pages/LoginPage'
import { authenticationPath, AuthenticationRoutesPath } from '@config/Router'
import { AppLayout } from '@components/AppLayout/AppLayout'
import RegisterPage from './pages/RegisterPage/RegisterPage'
import ForgotPasswordPage from './pages/ForgotPasswordPage/ForgotPasswordPage'
import ActivatePage from './pages/ActivatePage/ActivatePage'
import AuthenticationLayout from './components/AuthenticationLayout/AuthenticationLayout'

export const AuthenticationRoutes = {
  path: authenticationPath,
  element: <Outlet />,
  children: [
    {
      path: AuthenticationRoutesPath.login.path,
      element: (
        <AuthenticationLayout isResponsiveBackgroundImage>
          <LoginPage />
        </AuthenticationLayout>
      ),
    },
    {
      path: AuthenticationRoutesPath.register.path,
      element: (
        <AuthenticationLayout
          className='min-h-screen'
          isResponsiveBackgroundImage
        >
          <RegisterPage />
        </AuthenticationLayout>
      ),
    },
    {
      path: AuthenticationRoutesPath.forgotPassword.path,
      element: (
        <AuthenticationLayout isResponsiveBackgroundImage>
          <ForgotPasswordPage />
        </AuthenticationLayout>
      ),
    },
    {
      path: AuthenticationRoutesPath.activate.path,
      element: (
        <AppLayout title='Activation'>
          <ActivatePage />
        </AppLayout>
      ),
    },
  ],
}
