import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { UsedCoupon } from '@models/Coupon/UsedCoupon'

export const getUsedCouponsApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getUsedCouponsApi: builder.query<
      UsedCoupon[],
      { startAt?: Date; endAt?: Date }
    >({
      query: ({ startAt, endAt }) => ({
        operationName: 'GetUsedCoupons',
        document: gql`
          query GetUsedCoupons($startAt: Timestamp, $endAt: Timestamp) {
            getUsedCoupons(
              getUsedCouponsInput: { startAt: $startAt, endAt: $endAt }
            ) {
              expiredAt
              createdAt
              coupon {
                title
                description
                type
              }
            }
          }
        `,
        variables: {
          startAt,
          endAt,
        },
      }),
      transformResponse(res: { getUsedCoupons: UsedCoupon[] }) {
        const { getUsedCoupons } = res
        return getUsedCoupons
      },
    }),
  }),
})

export const { useGetUsedCouponsApiQuery } = getUsedCouponsApi
