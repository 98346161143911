import React, { useMemo, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { useBotIdsQuery } from '@client/botClient/collections/BotIdsClientAction'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { usePagination } from '@hooks/usePagination'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { useCountBotQuery } from '@client/botClient/collections/CountBotClientAction'
import { BotSortEnum, botSortEnumToJson } from '@interfaces/BotSortEnum'

import { plainToClass } from 'class-transformer'
import { BotSortInput } from '@models/Bot/BotSortInput'
import { SortEnum } from '@interfaces/SortEnum'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

import { useIsOneOffAllQuery } from '@client/botClient/collections/IsOneOffAllClientAction'
import { useIsStopCoveringAllQuery } from '@client/botClient/collections/IsStopCoveringAllClientAction'
import { BotStatusAcceptProps, BotStatusProps } from './interface'

const defaultSort = {
  sortType: BotSortEnum.CREATED_AT,
  sortValue: SortEnum.DESCENDING,
}
const withBotStatus = (Component: React.FC<BotStatusProps>) => {
  function WithBotStatus({ refetchContractualAsset }: BotStatusAcceptProps) {
    const { botMarketType } = useBotMarketType()
    const navigate = useNavigate()
    const { isFuture } = useBotMarketType()
    const route = isFuture ? ContractRoutesPath : SpotRoutesPath
    const [sort, setSort] = useState<
      | {
          sortType: BotSortEnum
          sortValue: SortEnum
        }
      | undefined
    >(defaultSort)

    const [searchParams, setSearchParams] = useSearchParams()
    const status = searchParams.get('tab') ?? 'all'
    const paramsPage = searchParams.get('page')
      ? Number(searchParams.get('page'))
      : undefined

    const { page, perpage, pageChange, perpageChange } = usePagination(
      undefined,
      paramsPage
    )
    // eslint-disable-next-line
    const dateNow = useMemo(() => new Date(), [status])

    const { data, isLoading, refetch } = useBotIdsQuery(
      {
        page,
        botMarketType,
        limit: 10,
        sort: plainToClass(BotSortInput, botSortEnumToJson(sort)),
        status: BotStatusEnum[status],
      },
      { refetchOnMountOrArgChange: true }
    )

    const { data: botCountData, isLoading: isLoadingBotCount } =
      useCountBotQuery(
        {
          botMarketType,
          botStatus: BotStatusEnum[status],
        },
        {
          refetchOnMountOrArgChange: true,
        }
      )

    const { data: isOneOffAll } = useIsOneOffAllQuery(
      {
        botMarketType,
        dateNow,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          BotStatusEnum[status] !== BotStatusEnum.RUNNING &&
          BotStatusEnum[status] !== BotStatusEnum.STOP,
      }
    )
    const { data: isStopCoveringAll } = useIsStopCoveringAllQuery(
      {
        botMarketType,
        dateNow,
      },
      {
        refetchOnMountOrArgChange: true,
        skip:
          BotStatusEnum[status] !== BotStatusEnum.RUNNING &&
          BotStatusEnum[status] !== BotStatusEnum.STOP,
      }
    )

    const handlePageChange = (newPage: number) => {
      const cloneSearch = Object.fromEntries(searchParams.entries())
      cloneSearch.page = newPage.toString()
      setSearchParams(cloneSearch)
      pageChange(newPage)
    }

    const onActiveTabChange = (tab: string | number) => {
      const activeTab = tab === 'all' ? undefined : (tab as BotStatusEnum)
      const updatedSearchParams: Record<string, string> = { page: '1' }
      if (activeTab) {
        updatedSearchParams.tab = activeTab
      }
      setSearchParams(updatedSearchParams)
      setSort(defaultSort)
      pageChange(1)
    }

    const handleSortChange = (newSortValue?: {
      sortType: BotSortEnum
      sortValue: SortEnum
    }) => {
      setSort(newSortValue)
    }

    const newProps = {
      status: BotStatusEnum[status],
      data,
      refetch,
      isLoading: isLoading || isLoadingBotCount,
      page,
      perpage,
      botCountData,
      botSortValue: sort,
      isOneOffAll,
      isStopCoveringAll,
      onActiveTabChange,
      pageChange: handlePageChange,
      perpageChange,
      handleSortChange,
    }
    return <Component {...newProps} />
  }

  return WithBotStatus
}

export default withBotStatus
