import React from 'react'
import { useBotQuery } from '@client/botClient/collections/BotClientAction'
import Loading from '@components/utils/Loading'

import { useSearchParams } from 'react-router-dom'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { BotCardAcceptProps, BotCardProps } from './interface'

const withBotCard = (Component: React.FC<BotCardProps>) => {
  function WithBotCard({
    botId,
    botSymbolPath,
    isLoadingBotList,
    refetch: refetchBotList,
  }: BotCardAcceptProps) {
    const [searchParams] = useSearchParams()
    const status = BotStatusEnum[searchParams.get('tab') ?? '']
    const {
      data: bot,
      isLoading,
      refetch,
    } = useBotQuery(
      {
        botId: Number(botId),
        status,
      },
      { refetchOnMountOrArgChange: true }
    )

    if (isLoading || isLoadingBotList || !bot) {
      return <Loading text='Loading' />
    }

    const newProps: BotCardProps = {
      bot,
      refetchBot: refetch,
      refetchBotList,
      botSymbolPath,
      isLoading: isLoading || isLoadingBotList,
    }
    return <Component {...newProps} />
  }

  return WithBotCard
}

export default withBotCard
