import React from 'react'
import { useNavigate } from 'react-router-dom'
import { useWalletBalanceQuery } from '@client/exchangeClient/collections/WalletBalanceClientAction'
import { walletPath, WalletRoutesPath } from '@config/Router'
import { useWalletsQuery } from '@client/mainClient/collections/GetWallets'
import { WalletContainerAcceptProps, WalletContainerProps } from './interface'

const withWalletContainer = (Component: React.FC<WalletContainerProps>) => {
  function WithWalletContainer(props: WalletContainerAcceptProps) {
    const navigate = useNavigate()

    const refetchWalletBalance = () => {
      refetch()
    }

    const {
      data: balance,
      refetch,
      isFetching: isWalletBalanceFetching,
    } = useWalletsQuery(undefined, { refetchOnMountOrArgChange: true })

    const handleNavagatePage = (type: string | number) => {
      navigate(`/${walletPath}/tether/${type}`)
    }
    const handleSwitchWallet = () =>
      navigate(WalletRoutesPath.khotcoinTopUp.absolutePath)

    const newProps = {
      refetchWalletBalance,
      isWalletBalanceFetching,
      handleNavagatePage,
      handleSwitchWallet,
      walletBalance: balance?.tempWalletAmount || 0,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithWalletContainer
}

export default withWalletContainer
