import { StrategyUpdateType } from '@interfaces/StrategyUpdateTypeEnum'

export const editBotOptions = [
  {
    label: 'For this round',
    value: StrategyUpdateType.CURRENT_STRATEGY,
  },
  {
    label: 'For next round',
    value: StrategyUpdateType.MAIN_STRATEGY,
  },
  // {
  //   label: 'For this round and next round',
  //   value: StrategyUpdateType.ALL_STRATEGY,
  // },
]
