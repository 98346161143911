import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const deleteRunnerBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    deleteRunnerBot: builder.mutation<boolean, { botId: number }>({
      query: ({ botId }) => ({
        document: gql`
          mutation DeleteBot($botId: Int!) {
            deleteBot(botId: $botId)
          }
        `,
        variables: {
          botId: Number(botId),
        },
      }),
    }),
  }),
})

export const { useDeleteRunnerBotMutation } = deleteRunnerBotApi
