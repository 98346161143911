import React from 'react'
import { CreateContractMenuPageProps } from '@features/bot/pages/CreateBotMenuPage/interface'
import { useAuthExchangesQuery } from '@client/mainClient/collections/AuthExchangesClientAction'
import Loading from '@components/utils/Loading'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

const WithCreateBotMenuPage = (
  Component: React.FC<CreateContractMenuPageProps>
) => {
  const Hoc = () => {
    const { botMarketType } = useBotMarketType()
    const { data: authExchange = [], isLoading } = useAuthExchangesQuery()

    if (!isLoading) {
      const newProps: CreateContractMenuPageProps = {
        botMarketType,
        isHasAuthExchangeKey: !!(
          authExchange[0]?.apiKey && authExchange[0]?.secret
        ),
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }
  return Hoc
}
export default WithCreateBotMenuPage
