import { useParams, useSearchParams } from 'react-router-dom'
import { useGetTradeCopyByPersonBotDetailsQuery } from '@client/botClient/collections/GetTradeCopyByPersonBotDetails'
import Loading from '@components/utils/Loading'
import BuyToSeeDetail from '@features/tradeCopy/components/BuyToSeeDetail'
import PersonalBotCard from './PersonalBotCard'

function PersonalStrategy() {
  const { id } = useParams()
  const [params] = useSearchParams()
  const numberOfSymbol = Number(params.get('numberOfSymbol'))

  const {
    data: personalBotDetails,
    isLoading: isPersonalBotDetailsLoading,
    refetch,
  } = useGetTradeCopyByPersonBotDetailsQuery(Number(id))

  if (isPersonalBotDetailsLoading) {
    return <Loading text='Loading' />
  }

  return (
    <div className='p-[23px] flex flex-col gap-y-[10px]'>
      {personalBotDetails?.length ? (
        personalBotDetails?.map(bot => (
          <PersonalBotCard key={bot.id} detail={bot} personalId={id} />
        ))
      ) : (
        <div className='pt-[13px]'>
          <BuyToSeeDetail
            mode='byPersonal'
            id={Number(id)}
            refetchDetail={refetch}
            numberOfSymbol={numberOfSymbol}
          />
        </div>
      )}
    </div>
  )
}

export default PersonalStrategy
