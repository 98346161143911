import OutlinedButton from '@components/buttons/OutlinedButton'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { SuccessIcon } from '@components/Icons/Icons'
import { ModalContext } from '@components/Modal/context/ModalContext'
import React, { useContext, useState } from 'react'
import { Form, Formik } from 'formik'
import RadioInputField from '@components/form/RadioInputField/RadioInputField'
import { editBotOptions } from '@features/bot/pages/BotPage/components/EditBotButton/editBotOprions'

import { StrategyUpdateType } from '@interfaces/StrategyUpdateTypeEnum'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { useNavigate } from 'react-router-dom'

import { EditBotModalForm, EditBotModalProps } from './interface'

const initialValues: EditBotModalForm = {
  editBotMode: editBotOptions[0].value,
}

function EditBotModal({ botControllerId, botId, isFuture }: EditBotModalProps) {
  const { handleToggleModal } = useContext(ModalContext)
  const navigate = useNavigate()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const route = isFuture ? ContractRoutesPath : SpotRoutesPath

  return (
    <div className='w-[304px] relative'>
      <Formik initialValues={initialValues} onSubmit={() => {}}>
        {({ values }) => (
          <Form>
            <div className='flex flex-col items-center'>
              <SuccessIcon width='24' height='24' />
              <div className='mt-[9px] px-[40px] text-center'>
                <div className='flex flex-col items-center'>
                  <div className='text-lg font-medium'>Edit Strategy</div>
                  <p className='mt-[7px] text-sm'>
                    Please select the option for effect strategy.
                  </p>
                </div>
              </div>
            </div>
            <div className='flex justify-center mt-[10px]'>
              <RadioInputField
                name='editBotMode'
                options={editBotOptions}
                direction='vertical'
              />
            </div>
            <div
              className={`mt-[20px] flex items-center justify-center gap-x-[10px] 'flex-row-reverse'
              }`}
            >
              <OutlinedButton
                type='button'
                className='px-[15px] py-[6px]  border-[#DCDFE6] font-normal'
                onClick={() => handleToggleModal()}
              >
                <span className='text-sm'>Cancel</span>
              </OutlinedButton>
              <PrimaryButton
                type='button'
                className='py-[7px] px-[15px] rounded-[4px]'
                onClick={async () => {
                  setIsSubmitting(true)
                  try {
                    switch (values.editBotMode) {
                      case StrategyUpdateType.MAIN_STRATEGY:
                        navigate(
                          route.updateMainBot.absolutePath({
                            id: botControllerId,
                          })
                        )
                        break
                      case StrategyUpdateType.CURRENT_STRATEGY:
                        navigate(
                          route.updateCurrentBot.absolutePath({
                            botControllerId,
                            botId,
                          })
                        )
                        break
                      default:
                        return
                    }
                  } catch (error) {
                    // TODO: notify error
                  } finally {
                    setIsSubmitting(false)
                    handleToggleModal()
                  }
                }}
                disabled={isSubmitting}
              >
                <span className='text-sm font-medium'>Confirm</span>
              </PrimaryButton>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default EditBotModal
