import React from 'react'
import cn from 'classnames'
import { ProfitHistoryBot } from '@models/TradeCopy/ProfitHistoryBot'
import { TradeSideEnum } from '@interfaces/TradeSideEnum'
import { TriangleIcon } from '@components/Icons/Icons'

function ProfitHistoryCard({
  profitPercentage,
  baseCurrencyData,
  botStrategy,
  tradeActions,
  endAt,
}: ProfitHistoryBot) {
  return (
    <div className='border border-gray-300 rounded-[6px] grid grid-cols-7 p-[10px] items-center'>
      <div className='col-span-2'>
        <div className='flex gap-x-[5px] items-end pb-[3px]'>
          <img
            src={baseCurrencyData.imgPath}
            alt={baseCurrencyData.symbol}
            className='w-[28px] h-[28px]'
          />
          <div
            className={cn(
              'text-[10px] rounded-[10px] h-fit-content w-[31px] text-center',
              {
                'bg-green': botStrategy.side === TradeSideEnum.LONG,
                'bg-red': botStrategy.side === TradeSideEnum.SHORT,
              }
            )}
          >
            {botStrategy.side === TradeSideEnum.LONG ? 'Buy' : 'Sell'}
          </div>
        </div>
        <div className='text-xs font-bold'>
          {baseCurrencyData.symbol}{' '}
          <span className='text-[10px] font-normal text-gray-400'>
            {tradeActions[0]?.quoteCurrency}
          </span>
        </div>
      </div>
      <div className='col-span-2 text-[10px]'>Martingale</div>
      <div className='col-span-2 flex items-center gap-x-[7px]'>
        <TriangleIcon
          className={cn('w-[13px] h-[7px]', {
            'text-green': botStrategy.side === TradeSideEnum.LONG,
            'text-red rotate-180': botStrategy.side === TradeSideEnum.SHORT,
          })}
        />
        <div
          className={cn('text-xs', {
            'text-green': botStrategy.side === TradeSideEnum.LONG,
            'text-red': botStrategy.side === TradeSideEnum.SHORT,
          })}
        >
          {profitPercentage.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
          %
        </div>
      </div>
      <div className='text-[10px]'>
        {endAt.setLocale('en').toFormat('dd/MM/yyyy hh:mm a')}
      </div>
    </div>
  )
}

export default ProfitHistoryCard
