import { TabBarProps } from './interface'
import withTabBar from './withTabBar'

const TabBar = ({
  activeTab,
  tabs,
  handleTabChange,
  tabClassName = 'text-[14px]',
}: TabBarProps) => {
  return (
    <div className='select-none text-sm'>
      <div className='w-[90%] text-center cursor-pointer flex flex-1 mx-auto'>
        {tabs.map(({ value, label }) => (
          <div
            key={value}
            className={`flex-1 flex justify-center items-center px-3 py-2 ${tabClassName} ${
              value === activeTab
                ? 'border-b border-primary text-primary font-medium'
                : ''
            } `}
            onClick={() => handleTabChange(value)}
          >
            {label}
          </div>
        ))}
      </div>
    </div>
  )
}

/**
 * Render TabBar with single TabBody
 */
export default withTabBar(TabBar)
