export const cycleSettingTooltip =
  'In each round, the bot will buy the dips with each shares’ investment scaled by this number. For example, if you set the volume scale as 2, the bot will buy the dip with 1, 2, 4, 8, 16... shares. The bigger the volume scale is, the smaller the max safety orders you can set.'

export const orderQuantityTooltip =
  'This is the total number of safety orders the bot will place in each round. The more safety orders you set, the less amount in each buy order and the lower the price you can place an order.'

export const tPRetracementTooltip =
  'In a trailing stop future order, a trader can set a stop price that moves dynamically with the price of the futures contract. This means that as the price of the futures contract moves in favor of the trader, the stop price will also move up, maintaining a certain distance from the current price. This can help the trader to lock in profits as the price of the futures contract rises.'

export const tPRatioTooltip =
  '(Take profit ratio) In each round, when buying the dips and price rebounding to reach this ratio, the bot will order different positions to take profit.'

export const stopLossTooltip =
  'A stop-loss order is a long/short order placed to limit the losses when you fear that the prices may move against your trade.'

export const stopLossRatioTooltip =
  'For instance, if you are investing at $100 and you want to limit the loss at 5%, you can place an order in the system to close the position as soon as the loss rate comes to 5%'

export const stopLossLimitTooltip =
  'For instance, if you are investing at $100 and you want to limit the loss at 5 Unit (USDT), you can place an order in the system to close the position as soon as the loss rate comes to 5 Unit (USDT)'

export const coveringSpreadsTooltip =
  "In each round, the bot will buy the dips with each shares' investment scaled by this number. For example, if you set the volume scale as 2, the bot will buy the dip with 1, 2, 4, 8, 16... shares. The bigger the volume scale is, the smaller the max safety orders you can set."

export const coveringMultiplesTooltip =
  'In each round, after the first order is executed, the bot will buy the coins when the price drop reaches this ratio. This ratio can be filled in with a percentage number or the absolute value.'

export const fistOrderLimitTooltip =
  'Determine the amount at the first purchase. and will make the next purchase which will be calculated from first order limit.'

export const advanceTpRatioTooltip =
  '(Advance take profit ratio) As same as take profit ratio but you can only config the take profit ratio separately according to the position of that order.'

export const advanceTpRetracementTooltip =
  '(Advance take profit retracement) As same as take profit retracement but you can only config the take profit retracement separately according to the position of that order.'
export const pullBackCoveringTooltip =
  'After the price has fallen to the predetermined level in the Cover Configuration, it can be useful to wait for a rebound before deciding to buy more. This can help to confirm that the price has reached a bottom and is likely to continue its upward trend.'

export const easyTradeInvestmentTooltip =
  'Please enter the amount you want to invest. The bot will diversify your investment across different coins to spread the risk.'

export const limitPriceTooltip =
  'A limit order is an order to buy or sell at a specific price or better. Limit orders are not guaranteed to execute. Then if bot active over 2 days without matching market price, bot will be close automatically.'
