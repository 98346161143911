import { Info2Icon } from '@components/Icons/Icons'

import { useResponsive } from '@hooks/useResponsive'
import { mappedTierIdToIcon } from '@constant/tierIconMapper'
import ReferralButton from '@features/other/pages/ReferralPage/components/Tier/components/ReferralButton'
import { User } from '@models/User/User'
import { UserTier } from '@models/User/UserTier'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { AuthenticationRoutesPath } from '@config/Router'
import { useNavigate } from 'react-router-dom'

const mappedTierClassName = {
  1: 'bg-tier-1',
  2: 'bg-tier-2',
  3: 'bg-tier-3',
  4: 'bg-tier-4',
  5: 'bg-tier-5',
  6: 'bg-tier-6',
}
interface CurrentTierCardProps {
  referralCode: string
  handleInfoClick: () => void
  profile: User
  nextTier?: UserTier
}
function CurrentTierCard({
  referralCode,
  handleInfoClick,
  nextTier,
  profile,
}: CurrentTierCardProps) {
  const { isMobile } = useResponsive()
  const navigate = useNavigate()

  return (
    <div className='relative flex flex-col rounded-[10px] border border-gray-600 bg-navBar px-[15px] py-[10px]'>
      {isMobile && (
        <Info2Icon
          height='20'
          width='20'
          onClick={handleInfoClick}
          className='cursor-pointer absolute top-2 right-2'
        />
      )}

      {/* Star */}
      {profile.isActivate ? (
        <>
          <div
            className={`flex flex-wrap items-center  ${
              profile.userTier.id === 6 && 'justify-center'
            }`}
          >
            <div
              className={`text-[36px] font-bold text-transparent bg-clip-text ${
                mappedTierClassName[profile.userTier.id]
              }`}
            >
              TIER
            </div>
            <div className='flex space-x-1 ml-2'>
              {Array.from(
                {
                  length: profile.userTier.id,
                },
                (_, index) =>
                  mappedTierIdToIcon({
                    tierId: profile.userTier.id,
                    height: '32',
                    width: '32',
                    key: index.toString(),
                  })
              )}
            </div>
          </div>

          {profile.userTier.id !== 6 && (
            <div className='text-[10px]'>{profile.userTier.name}</div>
          )}

          <div className='h-full flex flex-col space-y-[2px]'>
            {/* Tier  content */}
            {profile.userTier.id === 6 ? (
              //  6 star tier content
              <div className='flex flex-col align-middle h-full font-bold text-center items-center justify-center'>
                <div className='tex-base'>Congratulations!</div>
                <div className='font-md'>You are top tier member</div>
              </div>
            ) : (
              //   1-5 star tier content
              <div className='flex flex-col space-y-[2px]'>
                <div className='text-[10px] font-bold mb-[4px]'>
                  To unlock next tier:
                </div>
                <div className='flex justify-between space-x-1 text-xs'>
                  <div className='font-light'>Direct referral</div>
                  <div className='font-bold'>
                    {profile.downLineUsers.length}/
                    {nextTier?.directReferralAmount ?? '0'}
                  </div>
                </div>
                <div className='flex justify-between space-x-1 text-xs'>
                  <div className='font-light'>Team member</div>
                  <div className='font-bold'>
                    {profile.teamMemberCount}/
                    {nextTier?.minimumTeamMembersAmount ?? '0'}
                  </div>
                </div>

                {/*  3-5 Tier content */}
                {profile.userTier.id !== 1 && profile.userTier.id !== 2 && (
                  <>
                    <div className='flex justify-between space-x-1 text-xs'>
                      <div className='font-light'>
                        {nextTier?.minimumEachTeamMemberStarLevel ?? '0'} STAR
                        user in team
                      </div>
                      <div className='font-bold'>
                        {profile.userTier.id === 1
                          ? 0
                          : profile.userTier.id === 2
                          ? profile.twoStarCount
                          : profile.userTier.id === 3
                          ? profile.threeStarCount
                          : profile.userTier.id === 4
                          ? profile.fourStarCount
                          : profile.userTier.id === 5
                          ? profile.fiveStarCount
                          : 0}
                        /{nextTier?.minimumTeamAmount ?? '0'}
                      </div>
                    </div>
                  </>
                )}
              </div>
            )}
          </div>

          <div className='my-[10px]'>
            <ReferralButton referralCode={referralCode} />
          </div>
          <div className='underline  text-[10px] text-primary text-right w-fit'>
            <a
              href='https://www.khotbot.com/referral-program'
              target='_blank'
              rel='noreferrer'
            >
              <span className='cursor-pointer py-[4px] '>
                Referral information
              </span>
            </a>
          </div>
        </>
      ) : (
        <div className='flex flex-col justify-center items-center h-full'>
          <p className='text-base font-bold mb-3'>
            Your account need activation.
          </p>
          <PrimaryButton
            type='button'
            className='rounded-[6px]'
            onClick={() =>
              navigate(AuthenticationRoutesPath.activate.absolutePath)
            }
          >
            Activate account
          </PrimaryButton>
        </div>
      )}
    </div>
  )
}

export default CurrentTierCard
