import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'
import { plainToClass } from 'class-transformer'

export const getTradeCopyByBotsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTradeCopyByBots: builder.query<TradeCopyByBot[], void>({
      query: () => ({
        document: gql`
          query GetTradeCopyByBots {
            getTradeCopyByBots {
              id
              baseCurrencyData {
                name
                symbol
                imgPath
              }
              profit
              profitPercentage
              isAlreadyBuy
              symbol
              botId
              exchange
              endAt
              user {
                name
                strategyDescription
                profileImgPath
              }
              strategyId
            }
          }
        `,
      }),
      transformResponse(res: { getTradeCopyByBots: TradeCopyByBot[] }) {
        return plainToClass(TradeCopyByBot, <any[]>res.getTradeCopyByBots)
      },
      providesTags: ['TradeCopyByBots'],
    }),
  }),
})

export const { useGetTradeCopyByBotsQuery } = getTradeCopyByBotsApi
