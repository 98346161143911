import { BotSortEnum } from '@interfaces/BotSortEnum'
import { useState } from 'react'
import { TimeIcon } from '@components/Icons/Icons'
import { SortEnum } from '@interfaces/SortEnum'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import { RadioInput } from '@components/RadioInput/RadioInput'
import { sortOptions } from '@constant/sortOptions'
import { botSortTypeOptionFromBotStatus } from '@constant/botSortTypeOptions'
import PrimaryButton from '@components/buttons/PrimaryButton'

const BotSortModal = ({
  toggleModal,
  value,
  handleOnCloseModalCallback,
  botStatus,
}: {
  toggleModal: () => void
  value?: { sortType: BotSortEnum; sortValue: SortEnum }
  handleOnCloseModalCallback: (sortValue?: {
    sortType: BotSortEnum
    sortValue: SortEnum
  }) => void
  botStatus?: BotStatusEnum
}) => {
  const [sortType, setSortType] = useState(value?.sortType)
  const [sortValue, setSortValue] = useState(value?.sortValue)
  const handleSelectSortType = (v: any) => {
    setSortType(BotSortEnum[v])
    if (BotSortEnum[v] !== sortType) setSortValue(undefined)
  }
  const handleSelectSortValue = (v: any) => {
    setSortValue(SortEnum[v.target.value])
  }

  const handleOnClear = () => {
    setSortType(undefined)
    setSortValue(undefined)
  }
  const handleOnClose = () => {
    toggleModal()

    if (sortValue && sortType) {
      handleOnCloseModalCallback({ sortType, sortValue })
    } else {
      handleOnCloseModalCallback(undefined)
    }
  }
  return (
    <div className='relative p-4 w-[270px]'>
      <TimeIcon
        width='24'
        height='24'
        className='absolute right-0 top-0 cursor-pointer z-1 text-white'
        onClick={toggleModal}
      />
      {/* Header */}
      <div className='flex justify-between mb-5 mt-4'>
        <div className='font-bold'>Sort</div>
        <div className='underline cursor-pointer' onClick={handleOnClear}>
          clear
        </div>
      </div>
      {/*  SelectButton sort type */}
      <SelectSimpleField
        options={botSortTypeOptionFromBotStatus(botStatus)}
        onSelectedChange={handleSelectSortType}
        value={sortType}
      />

      <div className='flex items-center justify-center mt-6 '>
        <RadioInput
          options={sortOptions}
          className='space-y-4'
          value={sortValue}
          onChange={handleSelectSortValue}
        />
      </div>

      <div className='flex w-full mt-6 '>
        <PrimaryButton
          type='button'
          className='w-full rounded-xl'
          onClick={handleOnClose}
        >
          Confirm
        </PrimaryButton>
      </div>
    </div>
  )
}

export default BotSortModal
