import { LogoutIcon } from '@components/Icons/Icons'
import { NavBarProps } from './interface'
import { withNavBar } from './withNavBar'

const NavBar = ({ handleLogout, profile }: NavBarProps) => {
  return (
    <div className='fixed top-0 flex items-center w-full px-[17px] py-[23px] z-10 bg-navBar'>
      <div className='flex-1 text-sm'>{profile?.name}</div>
      <div
        className='flex items-center cursor-pointer select-none'
        onClick={handleLogout}
      >
        <LogoutIcon />
        <span className='ml-[6px] font-bold'>Log out</span>
      </div>
    </div>
  )
}

export default withNavBar(NavBar)
