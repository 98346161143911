import React from 'react'
import {
  DeleteBotButtonAcceptProps,
  DeleteBotButtonProps,
} from '@features/bot/pages/BotPage/components/DeleteBotButton/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { useDeleteBotMutation } from '@client/botClient/collections/DeleteBotClientAction'
import { useDeleteRunnerBotMutation } from '@client/botClient/collections/DeleteBotRunnerClientAction'
import { useNavigate } from 'react-router-dom'
import { useClosePositionMutation } from '@client/botClient/collections/ClosePositionClientAction'
import { ClosePositionCommandEnum } from '@constant/form/createBotForm/closePositionOptions'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { BotMarketType } from '@interfaces/BotMarketEnum'

const WithDeleteBotButton = (Component: React.FC<DeleteBotButtonProps>) => {
  const Hoc = ({
    bot,
    isShowOnlyIcon = false,
    onHandleAfterDelete,
  }: DeleteBotButtonAcceptProps) => {
    const navigate = useNavigate()
    const { isFuture } = useBotMarketType()
    const route = isFuture ? ContractRoutesPath : SpotRoutesPath

    const [deleteBotMutate, { isLoading: isDeleteBotLoading }] =
      useDeleteBotMutation()
    const [deleteBotRunnerMutate] = useDeleteRunnerBotMutation()
    const [closeBotMutate, { isLoading: isClosePositionLoading }] =
      useClosePositionMutation()

    const { openModal } = useModal<
      ConfirmModalProps & { isBotRunning: boolean }
    >({
      component: ({ onConfirm, message }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>{message}</p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleDeletePosition = async () => {
      await openModal({
        isBotRunning: bot.status === BotStatusEnum.RUNNING,
        onConfirm: async () => {
          if (bot != null) {
            if (bot.status === BotStatusEnum.READY)
              await deleteBotMutate({
                botControllerId: bot!.botControllerId,
              })
            else if (
              bot.status === BotStatusEnum.ERROR ||
              bot.status === BotStatusEnum.FINISHED
            ) {
              await deleteBotRunnerMutate({
                botId: bot!.id,
              })
            } else if (
              bot.status === BotStatusEnum.RUNNING ||
              bot.status === BotStatusEnum.STOP
            ) {
              await closeBotMutate({
                botId: bot!.id,
                closePositionCommand:
                  ClosePositionCommandEnum.CLOSE_DELETE_POSITION,
              })
            }
            if (onHandleAfterDelete) {
              onHandleAfterDelete()
            }

            // Bot deleted.. go back to dashboard page
            navigate(route.dashboard.absolutePath)
          }
        },
        message: `Are you sure to delete ${
          bot.status === BotStatusEnum.RUNNING ||
          bot.status === BotStatusEnum.STOP
            ? '& close position '
            : ' '
        }${bot!.symbol} bot?`,
      })
    }

    const newProps: DeleteBotButtonProps = {
      handleDeletePosition,
      isShowOnlyIcon,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithDeleteBotButton
