import { CrownIcon } from '@components/Icons/Icons'
import Loading from '@components/utils/Loading'
import RankProfileImage from '@features/other/pages/Ranking/component/RankProfileImage'
import { toCompactNumber } from '@libs/currencyFormat'
import { HomeTopFiveUsersProps } from './interface'
import withHomeTopFiveUsers from './withHomeTopFiveUsers'

const HomeTopFiveUsers = ({
  isLoading,
  users,

  goToChartPage,
}: HomeTopFiveUsersProps) => {
  return (
    <div className='flex flex-col desktop:mb-[50px]  mb-[20px] w-full'>
      <p className='text-sm font-bold mb-5'>Top 5 daily profit</p>
      {isLoading && <Loading text='Loading...' />}
      {users && users.length > 0 ? (
        <div
          className={`flex flex-row space-x-1.5 ${
            users.length === 5 ? 'justify-evenly' : 'justify-start'
          } `}
        >
          {users!.map((user, index) => {
            return (
              <div
                className='flex flex-col items-center w-[20%] cursor-pointer'
                key={`${user.id}-${index}`}
                onClick={() =>
                  goToChartPage({
                    userId: user.id!,
                    rank: index + 1,
                    strategyDescription: user.strategyDescription,
                    profileImgPath: user.profileImgPath,
                    userName: user.name,
                  })
                }
              >
                <RankProfileImage
                  profileImageClassName={`
                  w-[80px] h-[80px] mb-[3px]
                  mobile:w-[40px] mobile:h-[40px]
                  `}
                  rank={index + 1}
                  rankPositionClassName='-top-2 -left-2'
                  profileImagePath={user.profileImgPath}
                />
                <p className='text-xs font-bold text-center w-full truncate'>
                  {user.name}
                </p>
                <p className='text-xs mobile:text-[10px] font-bold text-center'>
                  {toCompactNumber(user.dailyProfit)} USDT
                </p>
              </div>
            )
          })}
        </div>
      ) : (
        <div className='flex flex-col items-center'>
          <CrownIcon width='80' height='80' />
          <div className='text-sm text-gray-secondary'>There are no data</div>
        </div>
      )}
    </div>
  )
}
export default withHomeTopFiveUsers(HomeTopFiveUsers)
