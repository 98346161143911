import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import { SearchFieldProps } from '@components/form/SearchField/interface'

const withSearchField = (Component: React.FC<SearchFieldProps>) => {
  function Hoc(props: SearchFieldProps) {
    const newProps = {
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withSearchField }
