export abstract class DepositTutorialMessage {
  static step1 = '1. Copy wallet address.'

  static step2 =
    '2. Start the Binance application and select the “Wallet” menu.'

  static step3 = '3. Select the “Withdraw” button.'

  static step4 = '4. Select the “USDT”'

  static step5 = '5. Select the “Send via Crypto Network”'

  static step6 = '6. Pass the wallet address in the “Address”.'

  static step7 =
    '7. Select “Tron (TRC20)” in dropdown network and then select “Confirm”'

  static step8 =
    '8. Input the number of coin in the “Amount” and press “Withdrawal”'

  static step9 = '9. Validate information and press the “Confirm” button.'

  static step10 =
    '10. Waiting for the transaction success and go back to my wallet.'
}
