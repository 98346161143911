import { useDispatch } from 'react-redux'
import {
  configureStore,
  isRejectedWithValue,
  Middleware,
} from '@reduxjs/toolkit'
import storage from 'redux-persist/lib/storage'
import { persistReducer } from 'redux-persist'
import { setupListeners } from '@reduxjs/toolkit/query'
import { authenticationSlice } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { adminApi, botApi, exchangeApi, mainApi } from '@client/client'
import { toast } from 'react-toastify'
import { NetworkErrorInterface } from '@interfaces/NetworkErrorInterface'
import NetworkToastContainer from '@components/Toast/NetworkToastContainer'

const persistConfig = {
  key: authenticationSlice.name,
  storage,
}

const persistedReducer = persistReducer(
  persistConfig,
  authenticationSlice.reducer
)
const rtkQueryErrorLogger: Middleware = () => next => action => {
  if (isRejectedWithValue(action)) {
    const { errors }: { errors: NetworkErrorInterface[] } =
      action.meta.baseQueryMeta.response
    if (errors.length > 0) {
      const [error] = errors
      // 1008 (Access token expired) will handle error in baseQueryWithReAuth
      // status code 429 Too many request too annoy :(
      if (error.errorCode !== 1008 && error.status !== 429) {
        toast.error(NetworkToastContainer, {
          data: error,
          toastId: error.errorCode ?? error.status, // Prevent duplicate toast by using toastId
        })
      }
    }
  }
  return next(action)
}

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [mainApi.reducerPath]: mainApi.reducer,
    [exchangeApi.reducerPath]: exchangeApi.reducer,
    [adminApi.reducerPath]: adminApi.reducer,
    [botApi.reducerPath]: botApi.reducer,
    [authenticationSlice.name]: persistedReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat([
      rtkQueryErrorLogger,
      mainApi.middleware,
      exchangeApi.middleware,
      adminApi.middleware,
      botApi.middleware,
    ]),
})

// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)

export type AppDispatch = typeof store.dispatch
export const useAppDispatch = () => useDispatch<AppDispatch>() // Export a hook that can be reused to resolve types
export type RootState = ReturnType<typeof store.getState>
