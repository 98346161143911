import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { TrendingCoin } from '@models/TrendingCoin/TrendingCoin'

export const trendingCoinsApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    trendingCoins: builder.query<TrendingCoin[], { limit: number }>({
      query: ({ limit }) => ({
        document: gql`
          query TrendingCoins($limit: Float!) {
            treadingCoins(limit: $limit) {
              marketCap
              name
              rank
              slug
              symbol
              volume24h
              volumeChange24h
              priceChange1h
              volume1hr
              percentChange24h
              imgPath
              marketPrice
            }
          }
        `,
        variables: {
          limit,
        },
      }),
      transformResponse(res: { treadingCoins: TrendingCoin[] }) {
        const { treadingCoins } = res
        return treadingCoins
      },
      providesTags: ['TrendingCoin'],
    }),
  }),
})

export const { useLazyTrendingCoinsQuery, useTrendingCoinsQuery } =
  trendingCoinsApi
