import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'

export const payToActivateAccountApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    payToActivateAccount: builder.mutation<{ transactionHash: string }, void>({
      query: () => ({
        document: gql`
          mutation PayToActivateAccount {
            payToActivateAccount {
              transactionHash
            }
          }
        `,
      }),
      transformResponse(res: { payToActivateAccount: { transactionHash } }) {
        const { payToActivateAccount } = res
        return payToActivateAccount
      },
    }),
  }),
})

export const { usePayToActivateAccountMutation } = payToActivateAccountApi
