import React from 'react'
import { toast } from 'react-toastify'
import { useGetMyTradeCopyByBotQuery } from '@client/botClient/collections/GetMyTradeCopyByBotClientAction'
import { useDeleteTradeCopyByBotMutation } from '@client/botClient/collections/DeleteTradeCopyByBotClientAction'
import Loading from '@components/utils/Loading'
import { CopyBySymbolTabProps } from './interface'

const withCopyBySymbolTab = (Component: React.FC<CopyBySymbolTabProps>) => {
  function WithCopyBySymbolTab() {
    const { data: bots, isLoading, refetch } = useGetMyTradeCopyByBotQuery()
    const [deleteTradeCopyByBot] = useDeleteTradeCopyByBotMutation()

    const handleDeleteTradeCopyByBot = async (id: number) => {
      try {
        await deleteTradeCopyByBot({ tradeCopyByBotId: id }).unwrap()
        refetch()
        toast.success('Delete trade copy symbol success')
      } catch (_) {}
    }

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const newProps = { bots, handleDeleteTradeCopyByBot }
    return <Component {...newProps} />
  }

  return WithCopyBySymbolTab
}

export default withCopyBySymbolTab
