import React, { useEffect } from 'react'
import { useField } from 'formik'

import {
  SelectFieldAcceptProps,
  SelectFieldProps,
} from '@components/form/SelectField/interface'

const withSelectField = (Component: React.FC<SelectFieldProps>) => {
  function Hoc(props: SelectFieldAcceptProps) {
    const [{ onChange, ...field }, meta, { setValue }] = useField(props)
    const newProps = {
      ...props,
      ...meta,
      ...field,
      setValue,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withSelectField }
