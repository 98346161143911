import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const deleteBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    deleteBot: builder.mutation<boolean, { botControllerId: number }>({
      query: ({ botControllerId }) => ({
        document: gql`
          mutation DeleteBotController($id: Int!) {
            deleteBotController(id: $id)
          }
        `,
        variables: {
          id: Number(botControllerId),
        },
      }),
    }),
  }),
})

export const { useDeleteBotMutation } = deleteBotApi
