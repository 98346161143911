import React from 'react'
import { useNavigate } from 'react-router-dom'
import { walletPath, WalletRoutesPath } from '@config/Router'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import {
  KhotcoinContainerAcceptProps,
  KhotcoinContainerProps,
} from './interface'

const withKhotcoinContainer = (Component: React.FC<KhotcoinContainerProps>) => {
  function WithWalletContainer(props: KhotcoinContainerAcceptProps) {
    const navigate = useNavigate()
    const {
      data: user,
      refetch,
      isFetching: isWalletBalanceFetching,
    } = useProfileQuery({}, { refetchOnMountOrArgChange: true })

    const refetchWalletBalance = () => {
      refetch()
    }

    const handleNavagatePage = (type: string | number) => {
      navigate(`/${walletPath}/khotcoin/${type}`)
    }
    const handleSwitchWallet = () =>
      navigate(WalletRoutesPath.deposit.absolutePath)

    const newProps: KhotcoinContainerProps = {
      walletBalance: user?.khotCoinsAmount || 0,
      refetchWalletBalance,
      isWalletBalanceFetching,
      handleNavagatePage,
      handleSwitchWallet,
      ...props,
    }
    return <Component {...newProps} />
  }

  return WithWalletContainer
}

export default withKhotcoinContainer
