import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const redeemCouponApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    redeemCoupon: builder.mutation<
      { couponId: number },
      { couponCode: string }
    >({
      query: ({ couponCode }) => ({
        operationName: 'RedeemCoupon',
        document: gql`
          mutation RedeemCoupon($code: String!) {
            redeemCoupon(redeemCouponResolverInput: { couponCode: $code }) {
              couponId
            }
          }
        `,
        variables: {
          code: couponCode,
        },
      }),
    }),
  }),
})

export const { useRedeemCouponMutation } = redeemCouponApi
