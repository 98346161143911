export enum ClosePositionCommandEnum {
  CLOSE_POSITION = 'CLOSE_POSITION',
  CLOSE_DELETE_POSITION = 'CLOSE_DELETE_POSITION',
  CLOSE_POSITION_25 = 'CLOSE_POSITION_25',
  CLOSE_POSITION_50 = 'CLOSE_POSITION_50',
  CLOSE_POSITION_75 = 'CLOSE_POSITION_75',
}

export const closePositionOptions = [
  {
    label: '25% of position',
    value: ClosePositionCommandEnum.CLOSE_POSITION_25,
  },
  {
    label: '50% of position',
    value: ClosePositionCommandEnum.CLOSE_POSITION_50,
  },
  {
    label: '75% of position',
    value: ClosePositionCommandEnum.CLOSE_POSITION_75,
  },
  { label: '100% of position', value: ClosePositionCommandEnum.CLOSE_POSITION },
]
