import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const isOneOffAllApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    isOneOffAll: builder.query<
      boolean,
      {
        botMarketType: BotMarketType
        dateNow?: Date
      }
    >({
      query: ({ botMarketType }) => ({
        document: gql`
          query CheckIsOneOffAll(
            $botType: BotType!
            $botMarketType: BotMarketType!
          ) {
            checkIsOneOffAll(botType: $botType, botMarketType: $botMarketType)
          }
        `,
        variables: {
          botMarketType,
          botType: 'Martingale',
        },
      }),
      transformResponse(res: { checkIsOneOffAll: boolean }) {
        const { checkIsOneOffAll } = res
        return checkIsOneOffAll
      },
      providesTags: ['IsOneOffAll'],
    }),
  }),
})

export const { useIsOneOffAllQuery } = isOneOffAllApi
