import React from 'react'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { OptionType } from '@interfaces/OptionType'
import { SelectButton } from '@components/SelectButton/SelectButton'
import { useModal } from '@components/Modal/context/ModalContext'
import BotSortModal from '@features/bot/components/BotStatus/components/BotSortModal'
import withBotStatus from './withBotStatus'
import { BotStatusProps } from './interface'
import BotPagination from '../BotPagination/BotPagination'

const botStatusChips: OptionType[] = [
  { value: 'all', label: 'All' },
  { value: BotStatusEnum.READY, label: 'Ready' },
  { value: BotStatusEnum.RUNNING, label: 'Active' },
  { value: BotStatusEnum.STOP, label: 'Pause' },
  { value: BotStatusEnum.ERROR, label: 'Error' },
  { value: BotStatusEnum.FINISHED, label: 'Finished' },
]

function BotStatus({
  data,
  onActiveTabChange,
  refetch,
  isLoading,
  page,
  pageChange,
  status,
  botCountData,
  handleSortChange,
  botSortValue,
  isOneOffAll,
  isStopCoveringAll,
}: BotStatusProps) {
  const { openModal: openSortModal, handleToggleModal } = useModal({
    component: () => (
      <BotSortModal
        toggleModal={handleToggleModal}
        value={botSortValue}
        handleOnCloseModalCallback={handleSortChange}
        botStatus={status}
      />
    ),
    isHideCloseButton: true,
  })
  return (
    <>
      <div className='pl-[16px]'>
        <SelectButton
          options={botStatusChips}
          value={status || 'all'}
          onChange={onActiveTabChange}
        />
      </div>
      <BotPagination
        bots={data?.bots || []}
        refetch={refetch}
        isLoading={isLoading}
        page={page}
        pageChange={pageChange}
        pageCount={data?.pagination?.totalPage || 0}
        totalBotCount={data?.pagination.totalCount || 0}
        botCount={
          (data?.bots?.length || 0) +
          (data?.pagination.limit || 0) * ((data?.pagination.page || 0) - 1)
        }
        botCountLongShort={botCountData}
        handleOpenSortModal={openSortModal}
        isSorted={botSortValue !== undefined}
        status={status}
        isOneOffAll={isOneOffAll}
        isStopCoveringAll={isStopCoveringAll}
      />
    </>
  )
}

export default withBotStatus(BotStatus)
