import { BotSwitchProps } from '@features/bot/components/BotSwitch/interface'
import React from 'react'
import SymbolImage from '@components/SymbolImage/SymbolImage'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { TradeSideEnum } from '@interfaces/TradeSideEnum'
import OneOffTag from '@features/bot/components/BotCard/components/OneOffTag'
import ToggleSwitchSimpleField from '@components/form/ToggleSwitchSimpleField/ToggleSwitchSimpleField'
import Status from '@features/bot/components/Status/Status'
import CycleTag from '@features/bot/components/CycleTag/CycleTag'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import StopCoveringTag from '@features/bot/components/BotCard/components/StopCoveringTag'
import withBotSwitch from './withBotSwitch'

const BotSwitch = ({
  isBotOn,
  handleSwitchChange,
  disabledSwitch,
  botSymbolPath,
  bot,
}: BotSwitchProps) => {
  const { isSpot, isFuture } = useBotMarketType()
  return (
    <>
      <div className='flex items-start mb-[10px]'>
        {/* Col1 */}
        <SymbolImage
          className='mr-[5px] h-[32px] w-[32px] flex-shrink-0'
          path={botSymbolPath}
        />
        {/* Col2 */}
        <div className='flex flex-col space-y-2 '>
          {/* Symbol & unrealizedPnl */}
          <div className='text-sm font-bold h-[32px] flex items-center space-x-2'>
            <span>{bot?.symbol}</span>
            {(bot?.status === BotStatusEnum.RUNNING ||
              bot?.status === BotStatusEnum.STOP) && (
              <span
                className={`font-bold ${
                  (bot?.position?.unrealizedPnl ?? 0) === 0
                    ? 'text-white'
                    : (bot?.position?.unrealizedPnl ?? 0) > 0
                    ? 'text-green'
                    : 'text-red'
                }`}
              >
                {bot?.position?.unrealizedPnl?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                })}
              </span>
            )}
            {(bot.status === BotStatusEnum.ERROR ||
              bot.status === BotStatusEnum.FINISHED) && (
              <span
                className={`font-bold ${
                  (bot?.profit ?? 0) === 0
                    ? 'text-white'
                    : (bot.profit ?? 0) > 0
                    ? 'text-green'
                    : 'text-red'
                }`}
              >
                {bot?.profit?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                })}
              </span>
            )}
          </div>
          {/* Side & Leverage & OneOff */}
          <div className='flex gap-2 flex-wrap'>
            {/* Side */}
            <div
              className={`flex items-center px-2 h-[20px] text-xs border-1 ${
                isSpot
                  ? 'text-primary border-primary'
                  : bot?.botStrategy?.side === TradeSideEnum.LONG
                  ? 'text-green border-green'
                  : 'text-red border-red'
              }`}
            >
              {isSpot
                ? 'SPOT'
                : bot?.botStrategy?.side === TradeSideEnum.LONG
                ? 'Long'
                : 'Short'}
            </div>
            {/* Leverage */}
            {isFuture && (
              <div className='flex items-center px-2 h-[20px] text-xs border-1 text-primary border-primary'>
                x{bot?.botStrategy?.leverage}
              </div>
            )}

            {/* Cycle tag */}
            <CycleTag
              cycleSetting={
                bot?.botStrategy?.isInfinityCycle
                  ? 'infinity'
                  : bot?.botStrategy?.isCycle
                  ? 'cycle'
                  : 'single'
              }
            />
            {/* OneOff */}
            {bot.isOneOff && <OneOffTag />}
            {/* StopCover */}
            {bot.isStopCovering && <StopCoveringTag />}
          </div>
        </div>
        {/* Spacer */}
        <div className='flex-1' />
        {/* Col3 */}
        <div className='flex flex-col space-y-2 justify-end'>
          {/* Switch */}
          <div onClick={e => e.stopPropagation()} className='h-[32px] '>
            <ToggleSwitchSimpleField
              value={isBotOn}
              color='bg-green-switch'
              disabled={
                [BotStatusEnum.ERROR, BotStatusEnum.FINISHED].includes(
                  bot.status
                ) || disabledSwitch
              }
              onChange={e => handleSwitchChange(e.target.checked)}
            />
          </div>
          {/* Switch label */}
          <Status status={bot.status} />
        </div>
      </div>
      {bot.errorDescription && (
        <div className='flex justify-end items-center text-red text-sm text-right'>
          {bot.errorDescription}
        </div>
      )}
    </>
  )
}

const WrappedComponent = withBotSwitch(BotSwitch)
export default WrappedComponent
