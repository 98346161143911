import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { Accumulate } from '@models/TradeCopy/Accumulate'

export const getAccumulateByStrategyIdApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAccumulateByStrategyId: builder.query<
      Accumulate,
      { strategyId: number; endAtStart: number; endAtEnd: number }
    >({
      query: getAccumulateInput => ({
        document: gql`
          query GetAccumulateByStrategyId(
            $getAccumulateInput: GetAccumulateByStrategyIdInput!
          ) {
            getAccumulateByStrategyId(getAccumulateInput: $getAccumulateInput) {
              totalLoss
              totalProfit
            }
          }
        `,
        variables: {
          getAccumulateInput,
        },
      }),
      transformResponse(res: { getAccumulateByStrategyId: Accumulate }) {
        const { getAccumulateByStrategyId } = res
        return getAccumulateByStrategyId
      },
      providesTags: (result, error, arg) => [
        { type: 'AccumulateByStrategyId', ...arg },
      ],
    }),
  }),
})

export const { useGetAccumulateByStrategyIdQuery } =
  getAccumulateByStrategyIdApi
