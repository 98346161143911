import { QuestionIcon } from '@components/Icons/Icons'

import React from 'react'

export interface TooltipProps {
  title?: string
  text: string
}

const Tooltip = ({ text, title }: TooltipProps) => {
  return (
    <div className='group relative '>
      <div className='rounded bg-amber-500 text-sm text-white shadow-sm'>
        <QuestionIcon />
      </div>
      <span className='absolute z-5 desktop:w-[60vh] mobile:w-[30vh] top-7 -left-1/2  scale-0 rounded bg-gray-600 p-2 text-xs text-white group-hover:scale-100'>
        {title && <p className=' font-bold mb-[5px]'> {title}</p>}
        <p>{text}</p>
      </span>
    </div>
  )
}
export default Tooltip
