import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const verifyOTPApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    verifyOTP: builder.mutation<
      { verifiedToken: string },
      { phoneNumber: string; phoneNumberCountryCode: number; code: string }
    >({
      query: ({ phoneNumber, phoneNumberCountryCode, code }) => ({
        document: gql`
          mutation VerifyOTP(
            $phoneNumber: String!
            $code: String!
            $phoneNumberCountryCode: Float!
          ) {
            verifyOTP(
              phoneNumber: $phoneNumber
              code: $code
              phoneNumberCountryCode: $phoneNumberCountryCode
            ) {
              verifiedToken
            }
          }
        `,
        variables: {
          phoneNumber,
          phoneNumberCountryCode,
          code,
        },
      }),
      transformResponse(res: { verifyOTP: { verifiedToken: string } }) {
        const { verifyOTP } = res
        return verifyOTP
      },
    }),
  }),
})

export const { useVerifyOTPMutation } = verifyOTPApi
