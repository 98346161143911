import React from 'react'

import Loading from '@components/utils/Loading'
import StrategyDetail from '@features/tradeCopy/components/StrategyDetail'

import { StrategyDetailPageProps } from './interface'
import withStrategyDetailPage from './withStrategyDetailPage'

function StrategyDetailPage({
  detail,
  isLoadingDetail,
  personalId,
  mode,
}: StrategyDetailPageProps) {
  return (
    <div className='max-w-[700px] mx-auto px-[16px] py-[19px]'>
      <div className='flex items-center gap-x-[11px] ml-[8px] border-b border-gray-600 mb-[14px] pb-[33px]'>
        <img
          src={detail?.baseCurrencyData?.imgPath}
          alt={detail?.baseCurrencyData?.symbol}
          className='w-[38px] h-[38px] rounded-full'
        />
        <div>
          <div className='text-sm font-bold'>{detail?.symbol}</div>
          <div className='text-[10px] font-normal'>{detail?.user.name}</div>
        </div>
      </div>
      <span className='text-sm font-bold'>Detail</span>
      {isLoadingDetail ? (
        <Loading text='Loading' />
      ) : (
        <>
          {detail && (
            <StrategyDetail
              symbol={detail.symbol}
              strategy={detail.strategyData}
              isShowSymbol={false}
              className='px-[8px] py-[25px]'
              personalId={personalId}
              isShowCreateBotButton={mode !== 'detail'}
            />
          )}
        </>
      )}
    </div>
  )
}

export default withStrategyDetailPage(StrategyDetailPage)
