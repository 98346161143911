import { TotalProfitProps } from './interface'
import withTotalProfit from './withTotalProfit'

const TotalProfit = ({
  spotProfit,
  contractProfit,
  className,
}: TotalProfitProps) => {
  return (
    <table
      className={`h-full w-full table-auto p-[8px] border-[1px] border-gray-300 bg-[#3040C9AB] rounded-[10px] border-separate  ${className}`}
    >
      <thead className='mobile:text-[12px] text-[14px]'>
        <tr>
          <th className='font-bold w-[120px] mobile:w-[70px] px-[8px] py-[6px] border-b border-r  border-gray-300 '>
            Profit (USDT)
          </th>
          <th className='font-bold text-center px-[8px] py-[6px] border-b border-r border-gray-300  '>
            Today
          </th>
          <th className='font-bold text-center px-[8px] py-[6px] border-b border-r border-gray-300 '>
            Monthly
          </th>
          <th className='font-bold text-center px-[8px] py-[6px] border-b border-gray-300 '>
            Total
          </th>
        </tr>
      </thead>
      <tbody className='border-separate'>
        <tr>
          <td className='font-bold mobile:text-[12px] text-[14px] border-r border-b border-gray-300 text-center'>
            Contract
          </td>
          <td className='border-r border-b border-gray-300 p-[6px]'>
            <div
              className={`flex flex-1 justify-center gap-x-2 mobile:text-[12px] text-[18px] font-bold ${
                contractProfit.today > 0
                  ? 'text-green-100'
                  : contractProfit.today < 0
                  ? 'text-red'
                  : ''
              }`}
            >
              {`${contractProfit.today.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          </td>
          <td className='border-r border-b border-gray-300 p-[6px]'>
            <div
              className={`flex flex-1 justify-center gap-x-2 mobile:text-[12px] text-[18px] font-bold ${
                contractProfit.month > 0
                  ? 'text-green-100'
                  : contractProfit.month < 0
                  ? 'text-red'
                  : ''
              }`}
            >
              {`${contractProfit.month.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          </td>
          <td className='border-b border-gray-300 p-[6px]'>
            <div
              className={`flex flex-1 justify-center gap-x-2 mobile:text-[12px] text-[18px] font-bold ${
                contractProfit.total > 0
                  ? 'text-green-100'
                  : contractProfit.total < 0
                  ? 'text-red'
                  : ''
              }`}
            >
              {`${contractProfit.total.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          </td>
        </tr>
        <tr>
          <td className='font-bold mobile:text-[12px] text-[14px] border-r border-gray-300 text-center'>
            Spot
          </td>
          <td className='border-r border-gray-300 p-[6px]'>
            <div
              className={`flex flex-1 justify-center gap-x-2 mobile:text-[12px] text-[18px] font-bold ${
                spotProfit.today > 0
                  ? 'text-green-100'
                  : spotProfit.today < 0
                  ? 'text-red'
                  : ''
              }`}
            >
              {`${spotProfit.today.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          </td>
          <td className='border-r border-gray-300 p-[6px]'>
            <div
              className={`flex flex-1 justify-center gap-x-2 mobile:text-[12px] text-[18px] font-bold ${
                spotProfit.month > 0
                  ? 'text-green-100'
                  : spotProfit.month < 0
                  ? 'text-red'
                  : ''
              }`}
            >
              {`${spotProfit.month.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          </td>
          <td className=' border-gray-300 p-[6px]'>
            <div
              className={`flex flex-1 justify-center gap-x-2 mobile:text-[12px] text-[18px] font-bold ${
                spotProfit.total > 0
                  ? 'text-green-100'
                  : spotProfit.total < 0
                  ? 'text-red'
                  : ''
              }`}
            >
              {`${spotProfit.total.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}`}
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  )
}

export default withTotalProfit(TotalProfit)
