import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import WalletContainer from '@features/wallet/components/WalletContainer/WalletContainer'
import Otp from '@features/authentication/components/Otp/Otp'
import InputField from '@components/form/InputField/InputField'
import { SuccessIcon } from '@components/Icons/Icons'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { transactionFee } from '@constant/transactionFee'
import withWithdrawPage from './withWithdrawPage'
import { WithdrawPageProps } from './interface'

function WithdrawPage({
  withdrawStep,
  withdrawForm,
  balance,
  isBalanceFetching,
  profile,
  paidTime,
  handleWithdrawFormSubmit,
  handleVerifiedOtp,
  goToWithdrawRequestdPage,
}: WithdrawPageProps) {
  const schema = Yup.object().shape({
    amount: Yup.number()
      .min(1)
      .max(balance - 2)
      .required(),
    toAddress: Yup.string().required(),
  })

  return (
    <>
      {withdrawStep === 'form' && (
        <WalletContainer type='withdraw'>
          <Formik
            initialValues={withdrawForm}
            onSubmit={handleWithdrawFormSubmit}
            validationSchema={schema}
            enableReinitialize
          >
            {({ setFieldValue }) => (
              <Form className='mt-[16px] flex flex-col gap-y-[16px]'>
                <InputField
                  name='toAddress'
                  label='Address (wallet link)'
                  placeholder='Enter your address link'
                  required
                />
                <InputField
                  name='amount'
                  type='number'
                  label='Amount (USDT)'
                  placeholder='Enter amount'
                  rightIcon={
                    <div
                      onClick={() => setFieldValue('amount', balance)}
                      className='underline text-primary text-sm font-medium cursor-pointer'
                    >
                      Max
                    </div>
                  }
                  required
                />
                <div className='flex text-sm font-medium'>
                  <div className='flex-1'>Withdraw fee (USDT)</div>
                  <div>
                    {`${transactionFee.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} USDT`}
                  </div>
                </div>
                <div className='flex flex-col justify-end items-end mt-[60px]'>
                  <div className='text-[10px] font-bold'>
                    Network: Tron (TRC20)
                  </div>
                  <div className='text-[10px] text-gray-300'>
                    Admin will approve withdrawal in 24 hrs.
                  </div>
                </div>
                <PrimaryButton
                  type='submit'
                  className='w-full rounded-[18px] py-[8px]'
                  disabled={isBalanceFetching}
                >
                  <p className='text-lg font-bold'>Next</p>
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </WalletContainer>
      )}
      {withdrawStep === 'otp' && profile && (
        <div className='max-w-[700px] mx-auto px-[17px] pt-[14px]'>
          <Otp
            phoneNumber={profile?.phoneNumber}
            phoneNumberCountryCode={profile?.phoneNumberCountryCode}
            onSuccess={handleVerifiedOtp}
          />
        </div>
      )}
      {withdrawStep === 'success' && (
        <div className='max-w-[700px] mx-auto px-[17px] pt-[14px] flex flex-col items-center'>
          <SuccessIcon width='58' height='58' />
          <div className='text-primary font-bold mt-[20px] mb-[62px]'>
            Withdrawal requested
          </div>
          <div className='grid grid-cols-2 gap-y-[16px] text-sm w-full'>
            <div className='text-left'>Withdraw amount</div>
            <div className='text-right'>{withdrawForm.amount} USDT</div>
            <div className='text-left'>Fee</div>
            <div className='text-right'>{transactionFee} USDT</div>
            <div className='text-left'>Time</div>
            <div className='text-right'>{paidTime}</div>
            <div className='text-left'>Status</div>
            <div className='text-right text-red'>Pending</div>
          </div>
          <PrimaryButton
            type='button'
            className='w-full rounded-[18px] py-[8px] mt-[60px]'
            onClick={goToWithdrawRequestdPage}
          >
            <p className='text-lg font-bold'>View withdraw history</p>
          </PrimaryButton>
        </div>
      )}
    </>
  )
}

export default withWithdrawPage(WithdrawPage)
