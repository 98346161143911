import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const liquidateBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    liquidateBot: builder.mutation<boolean, { botId: number }>({
      query: ({ botId }) => ({
        document: gql`
          mutation LiquidateBot($botId: Int!, $botType: BotType!) {
            liquidateBot(botId: $botId, botType: $botType)
          }
        `,
        variables: {
          botId: Number(botId),
          botType: 'Martingale',
        },
      }),
    }),
  }),
})

export const { useLiquidateBotMutation } = liquidateBotApi
