import React from 'react'
import * as Yup from 'yup'
import { Drawer } from '@components/Drawer'
import { DrawerContext } from '@hooks/useDrawer'
import { FieldArray, Form, Formik } from 'formik'
import { ordinalNumbers } from '@libs/utils'
import InputField from '@components/form/InputField/InputField'

import PrimaryButton from '@components/buttons/PrimaryButton'
import { requiredNumberMsg } from '@constant/errorMessage'
import { PullbackSpreadsForm } from '@models/Bot/PullBackSpreadsForm'
import { Bot } from '@models/Bot/Bot'

const validationSchema = Yup.object().shape({
  pullbacks: Yup.array().of(Yup.number().required(requiredNumberMsg)),
})

const PullbackSpreadsDrawer = ({
  drawer,
  onSubmit,
  initialValues,
  bot,
}: {
  bot?: Bot | null
  drawer: DrawerContext
  onSubmit: (form) => void
  initialValues: PullbackSpreadsForm
}) => {
  return (
    <>
      <Drawer
        isDrawerOpen={drawer.isDrawerOpen}
        handleClickClose={drawer.handleClickClose}
        header='Pullback spreads'
      >
        {drawer.isDrawerOpen && (
          <div className='max-w-[700px] mx-auto'>
            <Formik
              initialValues={initialValues}
              onSubmit={form => {
                onSubmit(form)
                drawer.handleClickClose()
              }}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <Form>
                  <FieldArray
                    name='pullbackSpreads'
                    render={() => (
                      <>
                        {values.pullbackSpreads?.map((pullback, i) => (
                          <div key={i} className='flex items-center py-[12px]'>
                            <div className='flex-1'>{`${ordinalNumbers(
                              i + 1
                            )} Pullback spread`}</div>
                            <InputField
                              {...(bot && { disabled: i < bot.coverRound })}
                              type='number'
                              name={`pullbackSpreads.${i}.value`}
                              className='w-[160px]'
                              showErrorMessage
                              showErrorWithoutTouched
                              rightIcon={
                                <span className='text-sm font-medium'>%</span>
                              }
                            />
                          </div>
                        ))}
                      </>
                    )}
                  />
                  <PrimaryButton
                    type='submit'
                    className='w-full mt-[20px] rounded-[18px]'
                  >
                    Confirm
                  </PrimaryButton>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Drawer>
    </>
  )
}

export default PullbackSpreadsDrawer
