import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { TopupSessionModel } from '@models/Wallet/TopupSession'
import { plainToClass } from 'class-transformer'

export const topupSessionApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    topupSession: builder.query<TopupSessionModel, void>({
      query: () => ({
        document: gql`
          query {
            getTopupSession {
              expiredAt
              amount
              masterWalletAddress
            }
          }
        `,
      }),
      transformResponse(res: { getTopupSession: TopupSessionModel }) {
        const { getTopupSession } = res
        return plainToClass(TopupSessionModel, getTopupSession)
      },
      providesTags: [`TopupSession`],
    }),
  }),
})

export const { useTopupSessionQuery } = topupSessionApi
