import SymbolImage from '@components/SymbolImage/SymbolImage'
import { TradeSideEnum } from '@interfaces/TradeSideEnum'
import { Bot } from '@models/Bot/Bot'
import { DateTime } from 'luxon'

const ProfitByBotCard = ({
  bot,
  className,
}: {
  bot: Bot
  className?: string
}) => {
  return (
    <div
      className={`flex py-4 px-8 border-1 border-gray-300 rounded-[10px] ${className}`}
    >
      {/* COL 1 */}
      <SymbolImage
        path={bot.baseCurrencyData?.imgPath}
        className='w-[20px] h-[20px] mr-[5px]'
      />
      {/* COL 2 */}
      <div className='flex-1 flex-col justify-between '>
        <div className='flex'>
          <p className='text-sm font-bold'>{bot.symbol} </p>
          <div
            className={`rounded-[10px] m-auto ml-2 px-[8px] min-w-[35px] text-center text-[10px] py-0
              ${
                bot.botStrategy.side === TradeSideEnum.LONG
                  ? 'bg-green'
                  : 'bg-red'
              }`}
          >
            {bot.botStrategy.side === TradeSideEnum.LONG ? 'Buy' : 'Sell'}
          </div>
        </div>
        <p className='text-[10px]'>Bot ID: {bot.id}</p>
      </div>
      {/* COL 3 */}
      <div className='flex flex-col justify-between text-right'>
        <p className='text-xs font-bold'>
          {bot.profit.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          USDT
        </p>
        <p className='text-[10px]'>
          {bot.endAt
            ? DateTime.fromMillis(bot.endAt).toFormat('dd-MM-yyyy HH:mm:ss')
            : '-'}
        </p>
      </div>
    </div>
  )
}
export default ProfitByBotCard
