import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { TimeSeries } from '@models/Chart/TimeSeries'

export const userProfitByDayApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    userProfitByDay: builder.query<TimeSeries[], { userId: number }>({
      query: ({ userId }) => ({
        document: gql`
          query GetUserProfitByDateGraphData($userId: Float!) {
            getUserProfitByDateGraphData(userId: $userId) {
              date
              value
            }
          }
        `,
        variables: { userId },
      }),
      transformResponse(res: { getUserProfitByDateGraphData: TimeSeries[] }) {
        const { getUserProfitByDateGraphData } = res
        return getUserProfitByDateGraphData
      },
      providesTags: ['UserProfitByDay'],
    }),
  }),
})

export const { useLazyUserProfitByDayQuery } = userProfitByDayApi
