import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'

export const buyTradeCopyByPersonApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    buyTradeCopyByPerson: builder.mutation<boolean, number>({
      query: tradeCopyByPersonId => ({
        document: gql`
          mutation BuyTradeCopyByPerson($tradeCopyByPersonId: Float!) {
            buyTradeCopyByPerson(tradeCopyByPersonId: $tradeCopyByPersonId)
          }
        `,
        variables: {
          tradeCopyByPersonId,
        },
      }),
      transformResponse(res: { buyTradeCopyByPerson: boolean }) {
        const { buyTradeCopyByPerson } = res
        return buyTradeCopyByPerson
      },
    }),
  }),
})

export const { useBuyTradeCopyByPersonMutation } = buyTradeCopyByPersonApi
