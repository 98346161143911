// Create our number formatter.
import { getDecimalPlaces } from '@libs/utils'

export const bahtFormatter = new Intl.NumberFormat('th-TH')

export const currencyToNumber = currency =>
  Number(currency.replace(/[^0-9.-]+/g, ''))

export const toCompactNumber = currency => {
  // Nine Zeroes for Billions
  return Math.abs(Number(currency)) >= 1.0e9
    ? `${(Math.abs(Number(currency)) / 1.0e9).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}B`
    : // Six Zeroes for Millions
    Math.abs(Number(currency)) >= 1.0e6
    ? `${(Math.abs(Number(currency)) / 1.0e6).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}M`
    : // Three Zeroes for Thousands
    Math.abs(Number(currency)) >= 1.0e3
    ? `${(Math.abs(Number(currency)) / 1.0e3).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })}K`
    : Number(currency).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
      })
}

export const toLocaleStringActualDecimal = (value?: number) => {
  if (value === undefined) return undefined
  const decimalPlaces = getDecimalPlaces(value)
  return value.toLocaleString(undefined, {
    minimumFractionDigits: 0,
    maximumFractionDigits: decimalPlaces,
  })
}
