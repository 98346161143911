import React from 'react'
import {
  WalletSelectPageProps,
  WalletType,
} from '@features/wallet/pages/WalletSelectPage/interface'
import { useNavigate } from 'react-router-dom'
import { WalletRoutesPath } from '@config/Router'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useWalletBalanceQuery } from '@client/exchangeClient/collections/WalletBalanceClientAction'

const withWalletSelectPage = (Component: React.FC<WalletSelectPageProps>) => {
  const Hoc = () => {
    const navigate = useNavigate()
    const { data: profile } = useProfileQuery({})
    const { data: balance } = useWalletBalanceQuery()

    const handleOnSelectWalletType = (walletType: WalletType) => {
      if (walletType === 'TETHER') {
        navigate(WalletRoutesPath.deposit.absolutePath)
      } else {
        navigate(WalletRoutesPath.khotcoinTopUp.absolutePath)
      }
    }

    const newProps: WalletSelectPageProps = {
      khotcoinBalance: profile?.khotCoinsAmount ?? 0,
      tetherBalance: balance?.usdt ?? 0,
      handleOnSelectWalletType,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withWalletSelectPage
