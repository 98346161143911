import Tooltip from '@components/Tooltip/Tooltip'
import cx from 'classnames'
import { NumberFieldProps } from './interface'

export function NumberField({
  disabled,
  increase,
  decrease,
  label,
  className,
  touched,
  error,
  tooltip,
  ...props
}: NumberFieldProps) {
  return (
    <div className={cx(className)}>
      {label && (
        <div className='flex flex-row space-x-2'>
          <div className='text-sm text-write mb-[16px] font-medium'>
            {label}
          </div>
          {tooltip && <Tooltip title={label} text={tooltip} />}
        </div>
      )}
      <div
        className={cx('w-full flex rounded-[8px] overflow-hidden', {
          'border-red border-[1px]': touched && error,
        })}
      >
        <button
          className={`py-[6px] mx-auto w-[40px] bg-secondary text-white rounded-l-[8px] ${
            disabled && 'cursor-not-allowed disabled:bg-opacity-30'
          }`}
          type='button'
          onClick={decrease}
          disabled={disabled}
        >
          &#8722;
        </button>
        <input
          className={`'outline-none p-[4px] rounded-none text-[14] w-full text-center text-white text-sm bg-navBar ${
            disabled &&
            'cursor-not-allowed bg-gray-secondary disabled:bg-opacity-30'
          }`}
          type='number'
          disabled={disabled}
          {...props}
        />
        <button
          className={`py-[6px] mx-auto w-[40px] text-white rounded-r-[8px] bg-secondary ${
            disabled && 'cursor-not-allowed disabled:bg-opacity-30'
          }`}
          type='button'
          onClick={increase}
          disabled={disabled}
        >
          &#65291;
        </button>
      </div>
    </div>
  )
}
