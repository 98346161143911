import React from 'react'
import { TextAreaSimpleFieldAcceptProps } from './interface'

function TextAreaSimpleField({
  className,
  label,
  ...props
}: TextAreaSimpleFieldAcceptProps) {
  return (
    <div className='inline-block w-full'>
      <div className='relative flex w-full flex-wrap items-stretch'>
        {label && <div className='text-sm mb-2 font-normal'>{label}</div>}
        <textarea {...props} className={className} />
      </div>
    </div>
  )
}

export default TextAreaSimpleField
