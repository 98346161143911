import { useNavigate } from 'react-router-dom'
import { WalletRoutesPath } from '@config/Router'
import SymbolImage from '@components/SymbolImage/SymbolImage'
import { toCompactNumber } from '@libs/currencyFormat'
import React from 'react'
import { KhotcoinBoxProps } from '@features/home/components/KhotcoinBox/interface'
import khotcoin from '@assets/images/khotcoin.png'
import withKhotcoinBox from './withKhotcoinBox'

const KhotcoinBox = ({ khotcoinBalance, className }: KhotcoinBoxProps) => {
  const navigate = useNavigate()
  return (
    <div
      className={`flex border-[1px] border-gray-600 rounded-[10px] p-[7.5px] items-center cursor-pointer ${className}`}
      onClick={() => navigate(WalletRoutesPath.khotcoinTopUp.absolutePath)}
    >
      <SymbolImage path={khotcoin} className='h-[20px] w-[20px] mr-[5px]' />
      <span className='text-base mobile:text-[10px] whitespace-nowrap'>{`${toCompactNumber(
        khotcoinBalance
      )}`}</span>
    </div>
  )
}

const WrappedComponent = withKhotcoinBox(KhotcoinBox)
export default WrappedComponent
