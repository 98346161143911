import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { TopupSessionModel } from '@models/Wallet/TopupSession'

export const createTopupSessionApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createTopupSession: builder.mutation<TopupSessionModel, { amount: number }>(
      {
        query: input => ({
          document: gql`
            mutation CreateTopupSession(
              $input: CreateTopupSessionResolverInput!
            ) {
              createTopupSession(CreateTopupSessionInput: $input) {
                expiredAt
                amount
                masterWalletAddress
              }
            }
          `,
          variables: {
            input,
          },
        }),
      }
    ),
  }),
})

export const { useCreateTopupSessionMutation } = createTopupSessionApi
