import { useContext, useEffect } from 'react'
import { TabContext } from '@components/Tabs/Tabs'

interface TabHeaderProps {
  children: JSX.Element
  tabId?: string
}

export const TabHeader = ({ tabId, children }: TabHeaderProps) => {
  const { handleAddMenu } = useContext(TabContext)
  useEffect(() => {
    handleAddMenu({ [tabId!]: children })
  }, [tabId, children, handleAddMenu])
  return <></>
}
