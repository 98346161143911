import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const updateUserPasswordApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    updateUserPassword: builder.mutation<
      { id: number },
      { id: number; password: string; oldPassword: string }
    >({
      query: updateUserPasswordInput => ({
        document: gql`
          mutation UpdateUserPassword(
            $updateUserPasswordInput: UpdateUserPasswordInput!
          ) {
            updateUserPassword(
              updateUserPasswordInput: $updateUserPasswordInput
            ) {
              id
            }
          }
        `,
        variables: {
          updateUserPasswordInput,
        },
      }),
    }),
  }),
})

export const { useUpdateUserPasswordMutation } = updateUserPasswordApi
