import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { TradeActionEnum } from '@interfaces/TradeActionEnum'
import { TradeActions } from '@models/TradeAction/TradeActions'
import { SortEnum } from '@interfaces/SortEnum'

export const tradeActionsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    tradeActions: builder.query<
      TradeActions,
      {
        page: number
        limit: number
        status?: TradeActionEnum
        createAtStart?: number
        createAtEnd?: number
        sort?: { createdAt?: SortEnum }
      }
    >({
      query: ({ page, limit, status, createAtStart, createAtEnd, sort }) => ({
        document: gql`
          query TradeActions(
            $page: Int
            $limit: Int
            $createAtStart: Timestamp
            $createAtEnd: Timestamp
            $status: TradeAction
            $sort: TradeActionSortInput
          ) {
            userTradeActions(
              page: $page
              limit: $limit
              create_at_start: $createAtStart
              create_at_end: $createAtEnd
              status: $status
              sort: $sort
            ) {
              tradeActions {
                id
                base
                symbol
                baseCurrency
                status
                latestOrderPrice
                quote
                quoteCurrency
                createdAt
              }
              pagination {
                totalPage
                totalCount
              }
            }
          }
        `,
        variables: { page, limit, status, createAtStart, createAtEnd, sort },
      }),
      transformResponse(res: { userTradeActions: TradeActions }) {
        const { userTradeActions } = res
        return userTradeActions
      },
      providesTags: (result, error, arg) => [{ type: 'TradeActions', ...arg }],
    }),
  }),
})

export const { useTradeActionsQuery } = tradeActionsApi
