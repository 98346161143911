import { SortEnum } from '@interfaces/SortEnum'
import { Expose } from 'class-transformer'

export class BotSortInput {
  @Expose({ toClassOnly: true })
  id?: SortEnum

  @Expose({ toClassOnly: true })
  coverRound?: SortEnum

  @Expose({ toClassOnly: true })
  symbol?: SortEnum

  @Expose({ toClassOnly: true })
  errorDescription?: SortEnum

  @Expose({ toClassOnly: true })
  isRunning?: SortEnum

  @Expose({ toClassOnly: true })
  profit?: SortEnum

  @Expose({ toClassOnly: true })
  positionAvgPrice?: SortEnum

  @Expose({ toClassOnly: true })
  takeProfitPrice?: SortEnum

  @Expose({ toClassOnly: true })
  nextTakeProfitPrice?: SortEnum

  @Expose({ toClassOnly: true })
  lastPrice?: SortEnum

  @Expose({ toClassOnly: true })
  tpRetracementTopPrice?: SortEnum

  @Expose({ toClassOnly: true })
  tpRetracementBottomPrice?: SortEnum

  @Expose({ toClassOnly: true })
  feeSum?: SortEnum

  @Expose({ toClassOnly: true })
  nextOrderPrice?: SortEnum

  @Expose({ toClassOnly: true })
  liqPrice?: SortEnum

  @Expose({ toClassOnly: true })
  liqSize?: SortEnum

  @Expose({ toClassOnly: true })
  status?: SortEnum

  @Expose({ toClassOnly: true })
  createdAt?: SortEnum

  @Expose({ toClassOnly: true })
  endAt?: SortEnum

  @Expose({ toClassOnly: true })
  startedAt?: SortEnum

  @Expose({ toClassOnly: true })
  initialMargin?: SortEnum

  @Expose({ toClassOnly: true })
  botStrategyInitialMargin?: SortEnum

  @Expose({ toClassOnly: true })
  botStrategyTpRatio?: SortEnum

  @Expose({ toClassOnly: true })
  botStrategyCoveringQuantity?: SortEnum
}
