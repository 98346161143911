import React, { useEffect, useState } from 'react'
import { useVerifyOTPMutation } from '@client/mainClient/collections/VerifyOTPClientAction'
import { useSendOTPVerifyMutation } from '@client/mainClient/collections/SendOTPVerifyClientAction'
import Loading from '@components/utils/Loading'
import { OtpAcceptProps, OtpForm, OtpProps } from './interface'

const withOtp = (Component: React.FC<OtpProps>) => {
  function WithOtp({
    phoneNumberCountryCode,
    phoneNumber,
    onSuccess,
    ...props
  }: OtpAcceptProps) {
    const [verifyOtpMutate] = useVerifyOTPMutation()
    const [isInvalidToken, setIsInvalidToken] = useState(false)
    const [resendCount, setResendCount] = useState(0)
    const [sendOtpMutate, { isLoading: isSendOtpLoading }] =
      useSendOTPVerifyMutation()

    useEffect(() => {
      const interval = setInterval(() => {
        if (resendCount > 0) {
          setResendCount(prev => prev - 1000)
        }
      }, 1000)
      return () => clearInterval(interval)
    }, [resendCount])

    const handleOtpFormSubmit = (form: OtpForm) => {
      verifyOtpMutate({
        phoneNumberCountryCode,
        phoneNumber,
        code: form.otp,
      })
        .unwrap()
        .then(resp => {
          setIsInvalidToken(false)
          onSuccess(resp)
        })
        .catch(() => {
          setIsInvalidToken(true)
        })
    }

    const resendOtp = () => {
      if (resendCount === 0) {
        sendOtpMutate({
          phoneNumber,
          phoneNumberCountryCode,
        })
          .unwrap()
          .then(() => {
            setResendCount(60000)
          })
          .catch(() => {})
      }
    }

    if (!isSendOtpLoading) {
      const newProps = {
        handleOtpFormSubmit,
        phoneNumberCountryCode,
        phoneNumber,
        isInvalidToken,
        resendOtp,
        resendCount,
        ...props,
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }

  return WithOtp
}

export default withOtp
