import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const uploadFileApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    upload: builder.mutation<{ publicUrl: string }, { file: File }>({
      query: ({ file }) => ({
        document: gql`
          mutation Upload($file: Upload!) {
            upload(file: $file) {
              publicUrl
            }
          }
        `,
        variables: {
          file,
        },
      }),
      transformResponse(res: { upload: { publicUrl: string } }) {
        const { upload } = res
        return upload
      },
    }),
  }),
})

export const { useUploadMutation } = uploadFileApi
