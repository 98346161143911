import { ResetIcon } from '@components/Icons/Icons'
import SymbolImage from '@components/SymbolImage/SymbolImage'
import tether from '@assets/images/tether.png'
import { WalletBalanceProps } from './interface'

function WalletBalance({
  balance,
  isFetching,
  refetchBalance,
  className = '',
  title = 'Wallet balance',
  walletLogoPath = tether,
}: WalletBalanceProps) {
  return (
    <div
      className={`flex items-center space-x-3 p-[10px] border border-gray-600 bg-navBar rounded-[5px] ${className}`}
    >
      <SymbolImage path={walletLogoPath} className='h-[40px] w-[40px]' />
      <div className='flex flex-col'>
        <div className='text-white mb-[5px] text-xs'>{title}</div>
        <div className='flex '>
          <span className='text-[10px] text-white'>{`${balance.toLocaleString(
            undefined,
            {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            }
          )} USDT`}</span>
        </div>
      </div>
      <div className='flex-1' />

      <ResetIcon
        height='25'
        width='25'
        className={`flex-shrink-0 ${
          isFetching ? 'animate-spin cursor-not-allowed' : 'cursor-pointer'
        }`}
        onClick={refetchBalance}
      />
    </div>
  )
}

export default WalletBalance
