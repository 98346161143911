import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import DatePickerSimpleField from '@components/form/DatePickerSimpleField/DatePickerSimpleField'
import InputField from '@components/form/InputField/InputField'
import Loading from '@components/utils/Loading'
import { DateTime } from 'luxon'
import PrimaryButton from '@components/buttons/PrimaryButton'
import withRedeemCouponPage from './withRedeemCouponPage'
import { RedeemCouPageProps } from './interface'

const redeemCouponPageFormSchema = Yup.object().shape({
  couponCode: Yup.string().required(),
})

function RedeemCouponPage({
  handleRedeemCoupon,
  isLoading,
  startDate,
  endDate,
  handleOnSelectDates,
  usedCoupons,
  isUsedCouponsLoading,
}: RedeemCouPageProps) {
  return (
    <div className='desktop:mt-2 mb-9 px-[15px] max-w-[700px] mx-auto'>
      <Formik
        initialValues={{ couponCode: '' }}
        onSubmit={handleRedeemCoupon}
        validationSchema={redeemCouponPageFormSchema}
      >
        {() => (
          <Form>
            <div className='flex flex-col gap-y-[8px] mb-[24px]'>
              <InputField
                data-test-id='coupon-code-field'
                name='couponCode'
                label='Coupon Code'
                placeholder='Enter Coupon Code'
                required
              />
            </div>
            <PrimaryButton
              data-test-id='submit-btn'
              type='submit'
              className='w-full rounded-[18px] py-[8px] mt-[40px]'
              disabled={isLoading}
            >
              <p className='text-lg font-bold'>Redeem</p>
            </PrimaryButton>
          </Form>
        )}
      </Formik>
      <div className='my-10'>
        <div className='my-5 font-bold '>History</div>
        <DatePickerSimpleField
          data-test-id='history-filter-date-range-field'
          name='dateRange'
          value={startDate?.toJSDate()}
          startDate={startDate?.toJSDate()}
          endDate={endDate?.toJSDate()}
          handleOnSelectDateRange={handleOnSelectDates}
          placeholder='Select date range'
          selectsRange
        />
      </div>
      {isUsedCouponsLoading ? (
        <Loading text='Loading' />
      ) : usedCoupons ? (
        usedCoupons.map(usedCoupon => (
          <div
            data-test-id='used-coupon-card'
            className='rounded-lg p-3 border-1 border-gray-300 mt-5'
          >
            <div className='flex justify-between font-bold text-lg'>
              <div className='text-white'>{usedCoupon.coupon.title}</div>
              <div className='text-gray-400'>{usedCoupon.coupon.type}</div>
            </div>
            <div className='flex justify-between mt-3'>
              <div className='text-white'>
                Activated date:{' '}
                {DateTime.fromMillis(usedCoupon.createdAt).toFormat(
                  'dd/MM/yyyy'
                )}
              </div>
              <div className='text-gray-400'>
                Expired date:{' '}
                {DateTime.fromMillis(usedCoupon.expiredAt).toFormat(
                  'dd/MM/yyyy'
                )}
              </div>
            </div>
          </div>
        ))
      ) : (
        'No history found'
      )}
    </div>
  )
}

export default withRedeemCouponPage(RedeemCouponPage)
