import React from 'react'
import { ButtonInterfaceProps } from './interface'

function OutlinedButton({
  type,
  className,
  children,
  ...props
}: ButtonInterfaceProps) {
  return (
    <button
      type={type}
      className={`font-bold text-sm rounded-[4px] border-[1px] border-gray-secondary disabled:opacity-40 ${className}`}
      {...props}
    >
      <div className='select-none'>{children}</div>
    </button>
  )
}

export default OutlinedButton
