import { PlusCircleIcon } from '@components/Icons/Icons'
import withAddCoverButton from '@features/bot/pages/BotPage/components/AddCoverButton/withAddCoverButton'
import { ManualCoveringButtonProps } from '@features/bot/pages/BotPage/components/AddCoverButton/interface'
import cn from 'classnames'

const AddCoverButton = ({
  handleManualCovering,
  isDisable,
}: ManualCoveringButtonProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center h-[84px] w-[80px] border-1 py-[10px] px-[20px] rounded-[10px] cursor-pointer border-gray-300',
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer'
      )}
      onClick={handleManualCovering}
    >
      <PlusCircleIcon
        height='40'
        width='40'
        className={cn(isDisable ? 'text-gray-300' : 'text-white')}
      />
      <span className={cn('text-[10px]', isDisable && 'text-gray-300')}>
        Cover
      </span>
    </div>
  )
}

export default withAddCoverButton(AddCoverButton)
