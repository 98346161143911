import { PenStripIcon } from '@components/Icons/Icons'
import { useModal } from '@components/Modal/context/ModalContext'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import EditBotModal from '@features/bot/pages/BotPage/components/EditBotButton/EditBotModal/EditBotModal'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import cn from 'classnames'

const EditBotButton = ({
  botControllerId,
  botId,
  botStatus,
  isDisable,
}: {
  botControllerId: number
  botId: number
  botStatus: BotStatusEnum
  isDisable: boolean
}) => {
  const { isFuture } = useBotMarketType()
  const { openModal: openEditBotModalModal } = useModal({
    component: () => (
      <EditBotModal
        botControllerId={botControllerId}
        botId={botId}
        isFuture={isFuture}
      />
    ),
  })

  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center h-[84px] w-[80px] border-1 py-[10px] px-[20px] rounded-[10px] cursor-pointer border-gray-300',
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer'
      )}
      onClick={() => {
        if (isDisable) return
        openEditBotModalModal()
      }}
    >
      <PenStripIcon
        height='40'
        width='40'
        className={cn(isDisable ? 'text-gray-300' : 'text-white')}
      />
      <span className={cn('text-[10px]', isDisable && 'text-gray-300')}>
        Edit
      </span>
    </div>
  )
}

export default EditBotButton
