import cn from 'classnames'
import { ArrowUpIcon } from '@components/Icons/Icons'
import { GoToTopButtonProps } from './interface'

export function GoToTopButton({
  isShowButton,
  className,
  handleScrollUp,
}: GoToTopButtonProps) {
  return (
    <div
      onClick={handleScrollUp}
      className={cn(
        'fixed z-10 bottom-[5%] right-[20px] w-[56px] h-[56px] flex items-center justify-center bg-primary rounded-full cursor-pointer',
        { hidden: !isShowButton },
        className
      )}
    >
      <ArrowUpIcon />
    </div>
  )
}
