import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { plainToClass } from 'class-transformer'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'

export const getTradeCopyByPersonBotDetailsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTradeCopyByPersonBotDetails: builder.query<TradeCopyByBot[], number>({
      query: tradeCopyByPersonId => ({
        document: gql`
          query GetTradeCopyByPersonBotDetails($tradeCopyByPersonId: Float!) {
            getTradeCopyByPersonBotDetails(
              tradeCopyByPersonId: $tradeCopyByPersonId
            ) {
              id
              symbol
              endAt
              profit
              baseCurrencyData {
                imgPath
              }
              buyDate
            }
          }
        `,
        variables: {
          tradeCopyByPersonId,
        },
      }),
      transformResponse(res: {
        getTradeCopyByPersonBotDetails: TradeCopyByBot[]
      }) {
        return plainToClass(
          TradeCopyByBot,
          <any[]>res.getTradeCopyByPersonBotDetails
        )
      },
      providesTags: (result, error, arg) => [
        { type: 'TradeCopyByPersonBotDetails', arg },
      ],
    }),
  }),
})

export const { useGetTradeCopyByPersonBotDetailsQuery } =
  getTradeCopyByPersonBotDetailsApi
