import ReCAPTCHA from 'react-google-recaptcha'
import { useEffect } from 'react'
import { RECAPTCHA_SITE_KEY } from '@config/config'

const ReCaptchaPage = () => {
  useEffect(() => {
    document.body.classList.add('bg-white')
    document.body.parentElement?.classList.add('bg-white')

    return () => {
      document.body.classList.add('bg-[#1F1F1F]')
      document.body.parentElement?.classList.add('bg-[#1F1F1F]')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleOnReCaptchaChange = (value: string) => {
    window.parent.postMessage(value, '*')
  }

  return (
    <div>
      <ReCAPTCHA
        sitekey={RECAPTCHA_SITE_KEY}
        onChange={(value: string) => handleOnReCaptchaChange(value)}
        size='normal'
      />
    </div>
  )
}

export default ReCaptchaPage
