import { NetworkErrorInterface } from '@interfaces/NetworkErrorInterface'
import { ToastContentProps } from 'react-toastify/dist/types'

export interface NetworkToastContainerProps extends ToastContentProps {
  data: NetworkErrorInterface
}
const NetworkToastContainer = ({ data }: NetworkToastContainerProps) => {
  return (
    <div>
      <p className='font-bold'>
        {data.errorCode
          ? `Error code: ${data.errorCode}`
          : `Status code: ${data.status}`}
      </p>
      <p>{data.message}</p>
    </div>
  )
}

export default NetworkToastContainer
