import * as Yup from 'yup'
import { FutureMarketLeverage } from '@models/FutureMarketLeverage/FutureMarketLeverage'
import { requiredMsg, requiredNumberMsg } from '@constant/errorMessage'
import { CoverSpreadsForm } from '@models/Bot/CoverSpreadsForm'
import { BotFormMode } from '@features/bot/pages/CreateBotPage/interface'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const botSettingSchema = ({
  leveragesData,
  max,
  coverSpreadFormValue,
  mode,
  minInitialMargin,
  minCoveringQuantity,
  botMarketType,
}: {
  minCoveringQuantity?: number
  leveragesData?: FutureMarketLeverage[]
  max: number
  coverSpreadFormValue: CoverSpreadsForm
  mode: BotFormMode
  minInitialMargin: number
  botMarketType: BotMarketType
}) =>
  Yup.object()
    .shape({
      firstOrderRawQuote: Yup.number()
        .required(requiredNumberMsg)
        .test({
          name: 'min-initial-margin',
          test: (value, ctx) => {
            const isFutureStrategy =
              botMarketType === BotMarketType.FUTURE &&
              (mode === 'strategie' || mode === 'strategy-update')
            if (!isFutureStrategy && value && value < minInitialMargin) {
              return ctx.createError({
                message: `First order limit must be greater than or equal to ${minInitialMargin}`,
              })
            }
            return true
          },
        }),

      symbol:
        mode === 'bot'
          ? Yup.string().required(requiredMsg)
          : Yup.mixed().nullable(),
      isCovering: Yup.mixed().nullable(),
      name:
        mode === 'strategie' ? Yup.string().required() : Yup.mixed().nullable(),
      side: Yup.string().required(),
      leverage: Yup.number().min(1).max(max).required(),
      isCycle: Yup.string().required(),
      isSplitTp: Yup.string().required(),
      cycleQuantity: Yup.number().when('isCycle', {
        is: 'cycle',
        then: schema => schema.min(2).required(requiredNumberMsg),
      }),
      isFirstOrderDouble: Yup.boolean().required(),
      coveringQuantity: Yup.number()
        .min(minCoveringQuantity || 1)
        .required(requiredNumberMsg)
        .test({
          name: 'no-decimal',
          test: (value, ctx) => {
            const regex = /^\d+$/
            if (!regex.test(String(value))) {
              return ctx.createError({
                message: 'Order quantity must be digits only',
              })
            }
            return true
          },
        }),
      tpRetracement: Yup.number().when('isSplitTp', {
        is: v => v !== 'split',
        then: schema =>
          schema
            .min(0)
            .required(requiredNumberMsg)
            .typeError(requiredNumberMsg),
      }),
      tpRatio: Yup.number().when('isSplitTp', {
        is: v => {
          return v !== 'split'
        },
        then: schema =>
          schema
            .min(0.01)
            .required(requiredNumberMsg)
            .typeError(requiredNumberMsg),
      }),
      isStopLoss: Yup.boolean().required(),
      stopLossRatio: Yup.number()
        .nullable()
        .when('isStopLoss', {
          is: true,
          then: schema =>
            schema.when('stopLossLimit', {
              is: stopLossLimit => !stopLossLimit,
              then: stlSchema => stlSchema.min(1).required(requiredNumberMsg),
            }),
        }),
      stopLossLimit: Yup.number()
        .nullable()
        .when('isStopLoss', {
          is: true,
          then: schema =>
            schema.when('stopLossRatio', {
              is: stopLossRatio => !stopLossRatio,
              then: stlSchema => stlSchema.min(1).required(requiredNumberMsg),
            }),
        }),
      divisionAndControlProfit: Yup.number().required(requiredNumberMsg),
    })
    .test(function (value) {
      if (value.leverage && value.firstOrderRawQuote && leveragesData?.length) {
        const firstOrderDouble = value.isFirstOrderDouble ? 2 : 1
        const currentLeverage = [...leveragesData]
          .sort((a, b) => -(b.maxLeverage - a.maxLeverage))
          ?.find(l => (value.leverage || 0) <= l.maxLeverage)
        if (
          currentLeverage &&
          value.firstOrderRawQuote * firstOrderDouble * value.leverage >=
            currentLeverage.maxCost
        ) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: 'firstOrderRawQuote',
            message: `First order must be less than ${currentLeverage.maxCost}`,
          })
        }
      }
      return true
    })
    // .test(function (value) {
    //   if (value.leverage && value.firstOrderRawQuote) {
    //     if (value.leverage * value.firstOrderRawQuote < 11) {
    //       // eslint-disable-next-line react/no-this-in-sfc
    //       return this.createError({
    //         path: 'firstOrderRawQuote',
    //         message: 'First order x Leverage must be more than 10',
    //       })
    //     }
    //   }
    //   return true
    // })
    .test(function (value) {
      if (value.coveringQuantity && value.stopLossRatio && value.isCovering) {
        if (
          value.stopLossRatio <=
          coverSpreadFormValue.coverSpreads[value.coveringQuantity - 1]?.value
        ) {
          // eslint-disable-next-line react/no-this-in-sfc
          return this.createError({
            path: 'stopLossRatio',
            message: 'Stop loss ratio must be more than covering spreads',
          })
        }
      }
      return true
    })
