import { UserTier } from '@models/User/UserTier'
import React from 'react'
import TierStep from './TierStep'

interface TierStepsProps {
  currentTierId: number
  allTier: UserTier[]
}

function TierSteps({ currentTierId, allTier }: TierStepsProps) {
  return (
    <div className='mobile:max-h-[80vh] mobile:overflow-y-auto'>
      <div className='flex flex-row justify-between items-end desktop:my-[16px] mobile:pb-[18px] mobile:pt-[25px]'>
        <span className='text-xl font-bold'>Tier</span>
      </div>

      <div className='flex flex-col space-y-2'>
        {allTier.map(tier => {
          const isActiveTier = currentTierId >= tier.id
          return (
            <TierStep
              key={tier.name}
              userTier={tier}
              isActiveTier={isActiveTier}
            />
          )
        })}
      </div>
    </div>
  )
}

export default TierSteps
