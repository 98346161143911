import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { useBotsQuery } from '@client/botClient/collections/BotsClientAction'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { SortEnum } from '@interfaces/SortEnum'
import { ProfitByBotAcceptProps, ProfitByBotProps } from './interface'

const withProfitByBot = (Component: React.FC<ProfitByBotProps>) => {
  function Hoc({
    limit,
    sortDate,
    page,
    pageChange,
    botMarketType,
    ...props
  }: ProfitByBotAcceptProps) {
    const endAtFilter = useMemo(() => {
      const dt = DateTime.now()

      return {
        endAtStart: dt.startOf(sortDate).toMillis(),
        endAtEnd: dt.endOf(sortDate).toMillis(),
      }
    }, [sortDate])

    const { data: bots, isLoading } = useBotsQuery(
      {
        page,
        limit,
        status: BotStatusEnum.FINISHED,
        sort: { endAt: SortEnum.DESCENDING },
        botMarketType,
        ...endAtFilter,
      },
      { refetchOnMountOrArgChange: true }
    )

    const newProps = {
      page,
      pageChange,
      isLoading,
      bots,
      sortDate,
      ...props,
    }
    return <Component {...newProps} />
  }

  return Hoc
}

export default withProfitByBot
