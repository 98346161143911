import React from 'react'
import NoData from '@components/NoData/NoData'
import { CopyByPersonalTabProps } from './interface'
import withCopyByPersonalTab from './withCopyByPersonalTab'
import MyCopyByPersonalCard from '../MyCopyByPersonalCard'

function CopyByPersonalTab({
  personals,
  handleDeleteTradeCopyByPerson,
}: CopyByPersonalTabProps) {
  return (
    <div className='grid grid-col-1 gap-y-[8px] pt-[21px] px-[16px]'>
      {personals?.length ? (
        personals.map(personal => (
          <MyCopyByPersonalCard
            key={personal.id}
            personal={personal}
            handleDeleteTradeCopyByPerson={handleDeleteTradeCopyByPerson}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default withCopyByPersonalTab(CopyByPersonalTab)
