import { DateTime } from 'luxon'
import WalletContainer from '@features/wallet/components/WalletContainer/WalletContainer'
import Table from '@components/Table/Table'
import { TransactionTypeEnum } from '@interfaces/TransactionTypeEnum'
import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import { transactionTypeOptions } from '@constant/transactionTypeOptions'
import withTransactionPage from './withTransactionPage'
import { TransactionPageProps } from './interface'

const transactionType = {
  [TransactionTypeEnum.ACTIVATE_ACCOUNT]: 'Activate',
  [TransactionTypeEnum.RE_NEW_ACTIVATE_ACCOUNT]: 'Renew activate',
  [TransactionTypeEnum.WITHDRAW]: 'Withdraw',
  [TransactionTypeEnum.DEPOSIT]: 'Deposit',
  [TransactionTypeEnum.TRANSFER]: 'Transfer',
  [TransactionTypeEnum.TRANSFER_FEE]: 'Transfer fee',
  [TransactionTypeEnum.REFERRAL]: 'Referral',
  [TransactionTypeEnum.TRADE_COPY_BOT]: 'TC. symbol',
  [TransactionTypeEnum.TRADE_COPY_BOT_INCOME]: 'TC. symbol',
  [TransactionTypeEnum.TRADE_COPY_PERSON]: 'TC. person',
  [TransactionTypeEnum.TRADE_COPY_PERSON_INCOME]: 'TC. person',
  [TransactionTypeEnum.TOP_UP_KHOT_COIN]: 'Top up Khotcoin',
  [TransactionTypeEnum.SYSTEM_FEE]: 'System fee',
}

function TransactionPage({
  isLoading,
  transactions,
  page,
  perpage,
  filter,
  // sumAmountWalletTransaction,
  perpageChange,
  pageChange,
  handleFilterChanged,
  isIncomeValue,
}: TransactionPageProps) {
  return (
    <WalletContainer type='transaction'>
      <div className='flex justify-between items-center mt-[16px]'>
        {/* <div className='text-sm font-bold'> */}
        {/*  Total:{' '} */}
        {/*  <span */}
        {/*    className={ */}
        {/*      (sumAmountWalletTransaction?.getFieldByType(filter) || 0) >= 0 */}
        {/*        ? 'text-green' */}
        {/*        : 'text-red' */}
        {/*    } */}
        {/*  > */}
        {/*    {( */}
        {/*      sumAmountWalletTransaction?.getFieldByType(filter) || 0 */}
        {/*    ).toLocaleString(undefined, { */}
        {/*      minimumFractionDigits: 0, */}
        {/*      maximumFractionDigits: 2, */}
        {/*    })} */}
        {/*  </span> */}
        {/*  {' USDT'} */}
        {/* </div> */}
        <div className='flex-1' />
        <div className='w-[150px]'>
          <SelectSimpleField
            isSearchable
            onSelectedChange={value => handleFilterChanged(value)}
            value={filter}
            options={transactionTypeOptions}
            placeholder='Sort'
          />
        </div>
      </div>
      <div className='mt-[24px]'>
        <Table
          data={transactions?.walletTransactions}
          columns={[
            {
              Header: 'Date & Time',
              id: 'createdAt',
              accessor: 'createdAt',
              Cell: ({ value, row: { original } }) => (
                <span
                  className={`${
                    isIncomeValue(original.type, original.userId)
                      ? 'text-green-100'
                      : 'text-red'
                  }`}
                >
                  {value
                    ? DateTime.fromMillis(value).toFormat('dd-MM-yyyy HH:mm:ss')
                    : '-'}
                </span>
              ),
              minWidth: 130,
            },
            {
              Header: 'Transaction',
              id: 'type',
              accessor: 'type',
              Cell: ({ value, row: { original } }) => (
                <span
                  className={
                    isIncomeValue(value, original.userId)
                      ? 'text-green-100'
                      : 'text-red'
                  }
                >
                  {transactionType[value]}
                </span>
              ),
              minWidth: 90,
            },
            {
              Header: 'Detail',
              id: 'detail',
              accessor: 'transactionDetail',
              Cell: ({ value, row: { original } }) => {
                return (
                  <span
                    className={
                      isIncomeValue(original.type, original.userId)
                        ? 'text-green-100'
                        : 'text-red'
                    }
                  >
                    {isIncomeValue(original.type, original.userId) &&
                      `From: ${value?.from ?? '-'}`}
                    {!isIncomeValue(original.type, original.userId) &&
                      `To: ${value?.to ?? '-'}`}
                  </span>
                )
              },
              minWidth: 90,
            },
            {
              Header: 'Amount (USDT)',
              id: 'amount',
              accessor: 'amount',
              Cell: ({ value, row: { original } }) => {
                return (
                  <span
                    className={
                      isIncomeValue(original.type, original.userId)
                        ? 'text-green-100'
                        : 'text-red'
                    }
                  >
                    {isIncomeValue(original.type, original.userId) ? '' : '-'}
                    {value?.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })}
                  </span>
                )
              },
              minWidth: 90,
            },
          ]}
          totalCount={transactions?.pagination?.totalCount || 0}
          totalPage={transactions?.pagination?.totalPage || 1}
          manualPagination
          currentPage={page}
          currentPageSize={perpage}
          handleOnPageChange={pageChange}
          handleOnPageSizeChange={perpageChange}
          isLoading={isLoading}
          isShowPagination
        />
      </div>
    </WalletContainer>
  )
}

export default withTransactionPage(TransactionPage)
