import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import ProfileImage from '@components/ProfileImage/ProfileImage'
import InputField from '@components/form/InputField/InputField'
import PhoneNumberInputField from '@components/form/PhoneNumberInputField/PhoneNumberInputField'
import PrimaryButton from '@components/buttons/PrimaryButton'
import withSettingProfilePage from './withSettingProfilePage'
import { SettingProfilePageProps } from './interface'

const validationSchema = Yup.object()
  .shape({
    id: Yup.number().required(),
    name: Yup.string().required(),
    email: Yup.string().required(),
    phoneNumber: Yup.string().required().min(11).max(13),
    countryCode: Yup.number().required(),
  })
  .test(function (value) {
    return value.phoneNumber &&
      value.countryCode &&
      value.phoneNumber
        .slice(value.countryCode.toString().length)
        .match(/^[1-9][0-9]*$/)
      ? false
      : this.createError({
          path: 'phoneNumber',
          message: 'phoneNumber first digit must not be 0',
        })
  })
  .test(function (value) {
    return value.existPhoneNumber && value.userId !== value.id
      ? this.createError({
          path: 'phoneNumber',
          message: 'phoneNumber is already exist',
        })
      : false
  })

function SettingProfilePage({
  profile,
  initialValues,
  handleUpdateUser,
  isLoading,
  handleSelectImage,
  isDisabledEditPhoneNo,
}: SettingProfilePageProps) {
  return (
    <div className='py-[24px] px-[15px] max-w-[700px] mx-auto'>
      <div className='flex flex-col items-center gap-y-[11px] mb-[44px]'>
        <ProfileImage
          path={profile.profileImgPath ?? ''}
          className='w-[75px] h-[75px]'
          avatarClassName='text-gray-secondary'
          isPicker
          onSelectImageCallback={handleSelectImage}
        />
        <span>{profile.name}</span>
        <div className='flex flex-col text-sm gap-y-[4px] items-center'>
          <span className='text-sm'>
            Username: <span className='font-bold'>{profile.username}</span>
          </span>
          <span className='text-sm'>
            Account ID: <span className='font-bold'>{profile.id}</span>
          </span>
        </div>
      </div>
      <Formik
        initialValues={initialValues}
        onSubmit={handleUpdateUser}
        validationSchema={validationSchema}
      >
        {() => (
          <Form>
            <div className='flex flex-col gap-y-[16px]'>
              <InputField
                name='name'
                label='Name'
                type='text'
                placeholder='Enter name'
                required
              />
              <InputField
                name='email'
                label='Email'
                type='text'
                placeholder='Enter email'
                required
              />
              <PhoneNumberInputField disabled={isDisabledEditPhoneNo} />
              <InputField
                name='lineId'
                label='Line ID'
                type='text'
                placeholder='Line ID (Optional)'
              />
            </div>
            <PrimaryButton
              type='submit'
              className='w-full mt-[40px] rounded-[18px]'
              disabled={isLoading}
            >
              Save
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default withSettingProfilePage(SettingProfilePage)
