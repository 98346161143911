import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import Loading from '@components/utils/Loading'
import { useUpdateUserMutation } from '@client/mainClient/collections/UpdateUserClientAction'
import { UpdateUserForm } from '@models/User/UpdateUserForm'
import { OtherRoutesPath } from '@config/Router'
import { useUploadMutation } from '@client/mainClient/collections/UploadFileClientAction'
import { SettingProfilePageProps } from './interface'

const withSettingProfilePage = (
  Component: React.FC<SettingProfilePageProps>
) => {
  function WithSettingProfilePage() {
    const { data: profile, refetch } = useProfileQuery({})
    const [profileImage, setProfileImage] = useState<File>()
    const [updateUserMutate, { isLoading }] = useUpdateUserMutation()
    const [uploadImageMutate, { isLoading: isLoadingImageMutate }] =
      useUploadMutation()
    const navigate = useNavigate()

    const handleSelectImage = async (file?: File) => {
      setProfileImage(file)
    }

    const isDisabledEditPhoneNo = true

    const handleUpdateUser = async (form: UpdateUserForm) => {
      if (profile) {
        try {
          // Image profile
          let publicUrlImage: string | undefined
          if (profileImage) {
            const { publicUrl } = await uploadImageMutate({
              file: profileImage,
            }).unwrap()
            publicUrlImage = publicUrl
          }
          // User data
          await updateUserMutate({
            id: form.id,
            name: form.name,
            email: form.email,
            lineId: form.lineId ? form.lineId : undefined,
            phoneNumberCountryCode: form.countryCode,
            phoneNumber: form.phoneNumber.slice(
              form.countryCode.toString().length
            ),
            profileImgPath: publicUrlImage,
          }).unwrap()
          toast.success('Update profile success')
          refetch()
          navigate(OtherRoutesPath.other.path)
        } catch (_) {}
      }
    }

    if (profile) {
      const newProps = {
        profile,
        initialValues: {
          id: Number(profile.id),
          name: profile.name,
          email: profile.email,
          lineId: profile.lineId,
          phoneNumber: profile.phoneNumberCountryCode + profile.phoneNumber,
          countryCode: profile.phoneNumberCountryCode,
        },
        isLoading: isLoading || isLoadingImageMutate,
        handleUpdateUser,
        handleSelectImage,
        isDisabledEditPhoneNo,
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }

  return WithSettingProfilePage
}

export default withSettingProfilePage
