import React, { useMemo } from 'react'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'

function Status({
  status,
  className,
}: {
  status: BotStatusEnum
  className?: string
}) {
  const displayStatus = useMemo(() => {
    switch (status) {
      case BotStatusEnum.READY:
        return { color: 'bg-white', label: 'Ready' }
      case BotStatusEnum.RUNNING:
        return { color: 'bg-green', label: 'Active' }
      case BotStatusEnum.STOP:
        return { color: 'bg-orange', label: 'Pause' }
      case BotStatusEnum.ERROR:
        return { color: 'bg-red', label: 'Error' }
      case BotStatusEnum.FINISHED:
        return { color: 'bg-primary', label: 'Finish' }
      default:
        return {}
    }
  }, [status])

  return (
    <div className={`flex items-center mt-3 ${className}`}>
      <div
        className={`w-[12px] h-[12px] rounded-full ${displayStatus.color}`}
      />
      <div className='ml-[7px] text-sm font-medium'>{displayStatus.label}</div>
    </div>
  )
}

export default Status
