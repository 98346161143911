import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotControllerDetail } from '@models/Bot/BotControllerDetail'

export const getBotControllerApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getBotController: builder.query<BotControllerDetail, { id: number }>({
      query: botControllerDetailInput => ({
        document: gql`
          query GetBotController($botControllerId: Int!) {
            botController(botControllerId: $botControllerId) {
              id
              totalCycle
              cycleRound
              botStrategyId
              createdAt
              bots {
                symbol
              }
              botStrategy {
                id
                name
                isCycle
                isInfinityCycle
                marginType
                side
                isCovering
                cycleQuantity
                isStopLoss
                isFirstOrderDouble
                firstOrderRawQuote
                firstOrderTradeQuote
                leverage
                coveringQuantity
                isSplitTp
                tpRetracement
                tpRetracementSplit
                tpRatio
                tpRatioSplit
                divisionAndControlProfit
                stopLossRatio
                stopLossLimit
                coverMultiples
                coverSpreads
                coverSpreadTrends
                pullbackSpreads
                limitPrice
              }
            }
          }
        `,
        variables: {
          botControllerId: botControllerDetailInput.id,
        },
      }),
      transformResponse(res: { botController: BotControllerDetail }) {
        const { botController } = res
        return botController
      },
    }),
  }),
})

export const { useLazyGetBotControllerQuery } = getBotControllerApi
