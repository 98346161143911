import { useSelector } from 'react-redux'
import StrategyDetail from '@features/tradeCopy/components/StrategyDetail'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { UserCircleIcon } from '@components/Icons/Icons'
import withMyStrategyDetailPage from './withMyStrategyDetailPage'
import { MyStrategyDetailPageProps } from './interface'

function MyStrategyDetailPage({ botStrategy }: MyStrategyDetailPageProps) {
  const { profile } = useSelector(authenticationSelector)

  return (
    <div className='max-w-[700px] mx-auto py-[10px] px-[11px]'>
      <div className='border-b border-gray-600 mb-[27px] pb-[15px]'>
        <div className='ml-[18px] relative'>
          <div className='mt-[8px] flex gap-x-[4px] items-center'>
            {profile?.profileImgPath ? (
              <img
                src={profile.profileImgPath}
                alt='Profile'
                className='w-[40px] h-[40px] rounded-full mr-[5px]'
              />
            ) : (
              <UserCircleIcon className='w-[45px] h-[45px]' />
            )}
            <div className='flex flex-col'>
              <div className='text-sm font-bold'>{botStrategy?.name}</div>
              <div className='text-[10px]'>{profile?.name}</div>
            </div>
          </div>
        </div>
      </div>
      <div className='text-sm font-bold pb-[25px]'>Detail</div>
      <StrategyDetail
        strategy={botStrategy}
        isShowSymbol={false}
        className='px-[6px]'
      />
    </div>
  )
}

export default withMyStrategyDetailPage(MyStrategyDetailPage)
