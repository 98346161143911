import { KhotcoinCardProps } from '@features/wallet/pages/KhotcoinTopUpPage/components/KhotcoinCard/interface'
import coinOne from '@assets/images/coinOne.png'
import coinTwo from '@assets/images/coinTwo.png'
import coinThree from '@assets/images/coinThree.png'
import coinFour from '@assets/images/coinFour.png'
import coinBag from '@assets/images/coinBag.png'
import moneyCoin from '@assets/images/moneyCoin.png'
import safeBox from '@assets/images/safeBox.png'

const mappedKhotcoinLogo = {
  1: coinOne,
  2: coinTwo,
  3: coinThree,
  4: coinFour,
  5: moneyCoin,
  6: coinBag,
  7: safeBox,
}

const KhotcoinCard = ({
  isSelected,
  onClick,
  khotcoinPackage,
}: KhotcoinCardProps) => {
  const free = khotcoinPackage.khotcoinAmount - khotcoinPackage.usdtAmount
  return (
    <div
      data-test-id='khotcoin-package-card'
      onClick={onClick}
      className={`h-[70px] border-gray-300 rounded-[5px] px-[10px] py-[15px] cursor-pointer mb-[10px]
      ${
        isSelected
          ? 'border-1 border-primary'
          : 'hover:border-1 hover:border-gray-500'
      }
      `}
    >
      <div className='flex items-center h-full space-x-[10px]'>
        <div className='h-[40px] w-[40px] object-cover'>
          <img
            src={mappedKhotcoinLogo[khotcoinPackage.id]}
            alt={`Khotcoin ${khotcoinPackage.id}`}
            className='w-full h-full'
          />
        </div>
        <div>
          <div>
            <span className='text-sm font-bold text-transparent bg-clip-text bg-primary-gradient '>
              {khotcoinPackage.khotcoinAmount.toLocaleString(undefined)}
            </span>
            <span className='text-xs'> Khotcoin</span>
          </div>
          {free > 0 && (
            <div className='text-[10px] font-bold text-orange-100'>
              Buy {khotcoinPackage.usdtAmount.toLocaleString(undefined)}
              <span>
                {' '}
                + Get free {free.toLocaleString(undefined)} Khotcoin{' '}
              </span>
            </div>
          )}
        </div>
        <div className='flex-1' />
        <div className='text-xs font-bold'>
          {khotcoinPackage.usdtAmount.toLocaleString(undefined)} USDT
        </div>
      </div>
    </div>
  )
}

export default KhotcoinCard
