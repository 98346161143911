import { useBotProfitGroupByCoinQuery } from '@client/botClient/collections/BotProfitGroupByCoin'
import { BotProfitGroupByCoinInput } from '@models/Profit/BotProfitGroupByCoinInput'
import { plainToClass } from 'class-transformer'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { ProfitByCoinAcceptProps, ProfitByCoinProps } from './interface'

const withProfitByCoin = (Component: React.FC<ProfitByCoinProps>) => {
  const Hoc = ({
    sortDate,
    className,
    startDate,
    endDate,
    botMarketType,
  }: ProfitByCoinAcceptProps) => {
    const getBotProfitInput = useMemo(() => {
      const dt = DateTime.now()
      if (sortDate === 'day' && endDate) {
        const diffDay = Math.ceil(endDate.diff(startDate, ['days']).days)

        return Array.from({ length: diffDay }, (_, index) => {
          // start from endDate then minus by index
          return plainToClass(BotProfitGroupByCoinInput, {
            createAtStart: endDate
              .startOf(sortDate)
              .minus({ days: index })
              .toMillis(),
            createAtEnd: endDate
              .endOf(sortDate)
              .minus({ days: index })
              .toMillis(),
            botMarketType,
          })
        })
      }
      if (sortDate === 'week') {
        return Array.from({ length: 4 }, (_, index) => {
          return plainToClass(BotProfitGroupByCoinInput, {
            createAtStart: dt
              .startOf(sortDate)
              .minus({ days: index * 7 })
              .toMillis(),
            createAtEnd: dt
              .endOf(sortDate)
              .minus({ days: index * 7 })
              .toMillis(),
            botMarketType,
          })
        })
      }
      if (sortDate === 'month') {
        return Array.from({ length: 12 }, (_, index) => {
          return plainToClass(BotProfitGroupByCoinInput, {
            createAtStart: dt
              .minus({ month: index })
              .startOf(sortDate)
              .toMillis(),
            createAtEnd: dt.minus({ month: index }).endOf(sortDate).toMillis(),
            botMarketType,
          })
        })
      }
      return [
        plainToClass(BotProfitGroupByCoinInput, {
          createAtStart: dt.startOf(sortDate).toMillis(),
          createAtEnd: dt.endOf(sortDate).toMillis(),
          botMarketType,
        }),
      ]
    }, [sortDate, endDate, botMarketType, startDate])

    const { data, isLoading } = useBotProfitGroupByCoinQuery(
      { input: getBotProfitInput },
      {
        skip: endDate === null,
        refetchOnMountOrArgChange: true,
      }
    )
    const newProps = {
      sortDate,
      className,
      profitCoins: data || [],
      isLoading,
      startDate,
      endDate,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withProfitByCoin
