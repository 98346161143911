import ToggleSwitchSimpleField from '../ToggleSwitchSimpleField/ToggleSwitchSimpleField'
import { ToggleSwitchFieldProps } from './interface'
import { withToggleSwitchField } from './withToggleSwitchField'

const ToggleSwitchField = ({
  isShowValueInText = true,
  ...props
}: ToggleSwitchFieldProps) => {
  return <ToggleSwitchSimpleField {...props} isShowValueInText />
}

export default withToggleSwitchField(ToggleSwitchField)
