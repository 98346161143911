import { useActiveAnnouncementQuery } from '@client/mainClient/collections/ActiveAnnouncementClientAction'
import React from 'react'
import { ActiveAnnouncementProps } from './interface'

const withActiveAnnouncement = (
  Component: React.FC<ActiveAnnouncementProps>
) => {
  const Hoc = (props: ActiveAnnouncementProps) => {
    const { data, isLoading } = useActiveAnnouncementQuery()
    const newProps = {
      announcement: data,
      isLoading,
      ...props,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withActiveAnnouncement
