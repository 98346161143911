import { Expose, Transform } from 'class-transformer'
import { toPercentage } from '@libs/utils'

export class PullbackSpreadsForm {
  @Expose()
  @Transform(
    ({ value }) => {
      return value?.map((pullback: any) => {
        return {
          value: toPercentage(pullback),
        }
      })
    },
    {
      toClassOnly: true,
    }
  )
  @Expose()
  @Transform(
    ({ value }) => value?.map((pullback: any) => pullback.value / 100),
    {
      toPlainOnly: true,
    }
  )
  pullbackSpreads: number[]
}
