import { useField } from 'formik'
import { InputMaskedFieldAcceptProps, InputMaskedFieldProps } from './interface'

const withInputMaskedField = (Component: React.FC<InputMaskedFieldProps>) => {
  function Hoc({ name, ...props }: InputMaskedFieldAcceptProps) {
    const [{ onChange: onFormikChange, ...field }, meta, o] = useField(name)

    const newProps: InputMaskedFieldProps = {
      ...props,
      ...meta,
      ...field,
      onFormikChange,
    }
    return <Component {...newProps} />
  }
  return Hoc
}

export { withInputMaskedField }
