import { BotFormMode } from '@features/bot/pages/CreateBotPage/interface'

export enum StrategyUpdateType {
  CURRENT_STRATEGY = 'CURRENT_STRATEGY',
  MAIN_STRATEGY = 'MAIN_STRATEGY',
  // ALL_STRATEGY = 'ALL_STRATEGY',
}

export const botModeFormToStrategyUpdateType = (botFormMode: BotFormMode) => {
  switch (botFormMode) {
    // case 'bot-update-all-strategy':
    //   return StrategyUpdateType.ALL_STRATEGY
    case 'bot-update-current-strategy':
      return StrategyUpdateType.CURRENT_STRATEGY
    case 'bot-update-main-strategy':
      return StrategyUpdateType.MAIN_STRATEGY
    default:
      return null
  }
}
