import { createContext, useContext } from 'react'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const BotTypeContext = createContext<{
  botMarketType: BotMarketType
}>({
  botMarketType: BotMarketType.FUTURE,
})

export interface UseBotMarketTypeHookType {
  isSpot: boolean
  isFuture: boolean
  botMarketType: BotMarketType
}

export const BotTypeContextProvider = ({
  children,
  botMarketType,
}: {
  children: React.ReactNode
  botMarketType: BotMarketType
}) => {
  return (
    <BotTypeContext.Provider value={{ botMarketType }}>
      {children}
    </BotTypeContext.Provider>
  )
}

export function useBotMarketType(): UseBotMarketTypeHookType {
  const { botMarketType } = useContext(BotTypeContext)
  const isSpot = botMarketType === 'SPOT'
  const isFuture = botMarketType === 'FUTURE'

  return { isSpot, isFuture, botMarketType }
}
