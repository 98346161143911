import { Outlet } from 'react-router-dom'
import { TradingViewRoutesPath } from '@config/Router'
import TradingViewPage from '@features/tradingView/pages/TradingViewPage'
import TickerTapePage from '@features/tradingView/pages/TickerTapePage'
import TradingViewLayout from './components/TradingViewLayout/TradingViewLayout'

export const TradingViewRoutes = {
  path: '',
  element: <Outlet />,
  children: [
    {
      path: TradingViewRoutesPath.tradingView.path,
      element: (
        <TradingViewLayout title='Trading view'>
          <TradingViewPage />
        </TradingViewLayout>
      ),
    },
    {
      path: TradingViewRoutesPath.tradingViewMobile.path,
      element: <TradingViewPage />,
    },

    {
      path: TradingViewRoutesPath.tickerTape.path,
      element: <TickerTapePage />,
    },
  ],
}
