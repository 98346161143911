import { ClientError, gql, GraphQLClient } from 'graphql-request'
import { BaseQueryFn } from '@reduxjs/toolkit/query/react'
import { Mutex } from 'async-mutex'
import {
  logout,
  refreshToken,
} from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { BaseQueryApi } from '@reduxjs/toolkit/dist/query/baseQueryTypes'
import { DocumentNode } from 'graphql/language'
import { ErrorResponse } from '@rtk-query/graphql-request-base-query/dist/GraphqlBaseQueryTypes'
import { MAIN_API_URL } from '@config/config'
import { botClient, exchangeClient, mainClient } from '@client/client'

// eslint-disable-next-line consistent-return
export const getRefreshToken = () => {
  const session = localStorage.getItem('persist:authentication')
  if (session) {
    const sessionObj = JSON.parse(session)
    // DESC: remove double quotes
    return sessionObj.refreshToken?.replaceAll('"', '')
  }
}

const refreshTokenGqlDocument = gql`
  mutation RefreshToken {
    refresh {
      access_token
      refresh_token
    }
  }
`

const mutex = new Mutex()

export const baseQueryWithReauth = async ({
  args,
  api,
  extraOptions,
  baseQuery,
}: {
  args: any
  api: BaseQueryApi
  extraOptions: any
  baseQuery: BaseQueryFn<
    { document: string | DocumentNode; variables?: any },
    unknown,
    ErrorResponse,
    Partial<Pick<ClientError, 'request' | 'response'>>,
    // eslint-disable-next-line @typescript-eslint/ban-types
    any
  >
}) => {
  // wait until the mutex is available without locking it
  await mutex.waitForUnlock()
  let result = await baseQuery(args, api, extraOptions)
  if (
    (result.meta?.response?.errors ?? []).length > 0 &&
    (result.meta!.response!.errors[0].errorCode === 1008 ||
      result.meta!.response!.errors[0].status === 401)
  ) {
    // checking whether the mutex is locked
    if (!mutex.isLocked()) {
      const release = await mutex.acquire()
      try {
        // Call refresh token api
        const refreshTokenClient = new GraphQLClient(MAIN_API_URL, {
          headers: {
            exchange: 'binance',
            authorization: `Bearer ${getRefreshToken()}`,
          },
        })

        const refreshResult = await refreshTokenClient.request(
          refreshTokenGqlDocument
        )

        if (refreshResult && refreshResult.refresh) {
          const aAccessToken = refreshResult.refresh.access_token
          const aRefreshToken = refreshResult.refresh.refresh_token
          // Inject token to store
          api.dispatch(
            refreshToken({
              accessToken: aAccessToken,
              refreshToken: aRefreshToken,
            })
          )
          // Inject token to api
          mainClient.setHeader('authorization', `Bearer ${aAccessToken}`)
          exchangeClient.setHeader('authorization', `Bearer ${aAccessToken}`)
          botClient.setHeader('authorization', `Bearer ${aAccessToken}`)

          // retry the initial query
          result = await baseQuery(args, api, extraOptions)
        } else {
          // Expired => logout
          api.dispatch(logout())
        }
      } catch (e) {
        // Error => logout
        api.dispatch(logout())
      } finally {
        // release must be called once the mutex should be released again.
        release()
      }
    } else {
      // wait until the mutex is available without locking it
      await mutex.waitForUnlock()
      result = await baseQuery(args, api, extraOptions)
    }
  }

  return result
}
