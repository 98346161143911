import { RankCircleNumberProps } from './interface'

const mappedRankNumberStyle: Record<
  number,
  { rankBorderStyle: string; rankBackgroundStyle: string }
> = {
  1: {
    rankBorderStyle: 'bg-gradient-to-b from-[#FBF365] to-[#F3CF51]',
    rankBackgroundStyle: 'bg-gold-background',
  },
  2: {
    rankBorderStyle: 'bg-gradient-to-b from-[#F4F5FA] to-[#ACB2B8]',
    rankBackgroundStyle: 'bg-gray-400',
  },
  3: {
    rankBorderStyle: 'bg-gradient-to-b from-[#E8BDA3] to-[#C17556]',
    rankBackgroundStyle: 'bg-[#C88768]',
  },
}
const defaultRankStyle = {
  rankBorderStyle: 'bg-gradient-to-b from-[#A5BCF3] to-[#5680E4]',
  rankBackgroundStyle: 'bg-secondary',
}

function RankCircleNumber({
  className = 'w-[24px] h-[24px]',
  textClassName = 'text-[12px]',
  rank,
}: RankCircleNumberProps) {
  const colorStyle = mappedRankNumberStyle[rank] ?? defaultRankStyle
  return (
    <div
      className={`flex rounded-full p-[2px] ${colorStyle.rankBorderStyle}  ${className}`}
    >
      <div
        className={`flex-1 rounded-full flex items-center justify-center ${colorStyle.rankBackgroundStyle}`}
      >
        <span className={`text-center font-bold ${textClassName}`}>{rank}</span>
      </div>
    </div>
  )
}

export default RankCircleNumber
