import { TradingViewRoutesPath } from '@config/Router'
import { TickerTape } from 'react-ts-tradingview-widgets'
import { memo } from 'react'

const AppTickerTape = ({ className }: { className?: string }) => {
  return (
    <div className={className}>
      <TickerTape
        copyrightStyles={{
          parent: {
            display: 'flex',
            justifyContent: 'flex-end',
          },
        }}
        largeChartUrl={`${window.location.origin}${TradingViewRoutesPath.tradingView.absolutePath}`}
        colorTheme='dark'
        symbols={[
          { title: 'BTC/USDT', proName: 'BINANCE:BTCUSDT' },
          { title: 'ETH/USDT', proName: 'BINANCE:ETHUSDT' },
          { title: 'BNB/USDT', proName: 'BINANCE:BNBUSDT' },
        ]}
      />
    </div>
  )
}

export default memo(AppTickerTape)
