import { PacmanLoader } from 'react-spinners'

function Migrating() {
  return (
    <div className='w-full h-screen flex justify-center items-center'>
      <div className='w-[500px]'>
        <p className='text-xl text-white font-semibold mx-auto w-[fit-content] mb-2'>
          System is migrating,
        </p>
        <p className='text-sm text-white font-semibold mx-auto w-[fit-content] mb-10'>
          Please wait until app has been migrated
        </p>
        <PacmanLoader size='100' loading color='#21BB8E' />
      </div>
    </div>
  )
}

export default Migrating
