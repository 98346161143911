import React from 'react'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { useModal } from '@components/Modal/context/ModalContext'
import WalletPolicyModal from '@features/wallet/pages/DepositPage/components/WalletPolicyModal'
import { DepositPageProps } from './interface'

const withDepositPage = (Component: React.FC<DepositPageProps>) => {
  function WithDepositPage() {
    const { profile } = useSelector(authenticationSelector)
    const navigate = useNavigate()
    const { openModal: openWalletPolicyModal } = useModal({
      component: () => <WalletPolicyModal />,
    })
    const handleNavigateToTutorial = () => {
      window.open(
        'https://www.khotbot.com/post/how-to-deposit-usdt-wallet',
        '_blank'
      )
    }
    const handleShowWalletPolicyModal = () => openWalletPolicyModal()
    const newProps = {
      profile,
      handleNavigateToTutorial,
      handleShowWalletPolicyModal,
    }
    return <Component {...newProps} />
  }

  return WithDepositPage
}

export default withDepositPage
