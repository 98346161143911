import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'

export const buyTradeCopyByBotApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    buyTradeCopyByBot: builder.mutation<boolean, number>({
      query: tradeCopyByBotId => ({
        document: gql`
          mutation BuyTradeCopyByBot($tradeCopyByBotId: Float!) {
            buyTradeCopyByBot(tradeCopyByBotId: $tradeCopyByBotId)
          }
        `,
        variables: {
          tradeCopyByBotId,
        },
      }),
      transformResponse(res: { buyTradeCopyByBot: boolean }) {
        const { buyTradeCopyByBot } = res
        return buyTradeCopyByBot
      },
    }),
  }),
})

export const { useBuyTradeCopyByBotMutation } = buyTradeCopyByBotApi
