import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { ClosePositionCommandEnum } from '@constant/form/createBotForm/closePositionOptions'

export const closePositionApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    closePosition: builder.mutation<
      boolean,
      { botId: number; closePositionCommand: ClosePositionCommandEnum }
    >({
      query: ({ botId, closePositionCommand }) => ({
        document: gql`
          mutation ClosePosition(
            $botId: Int!
            $botType: BotType!
            $closePosition: ClosePositionCommandEnum!
          ) {
            closePosition(
              botId: $botId
              botType: $botType
              closePosition: $closePosition
            )
          }
        `,
        variables: {
          botId: Number(botId),
          botType: 'Martingale',
          closePosition: closePositionCommand,
        },
      }),
    }),
  }),
})

export const { useClosePositionMutation } = closePositionApi
