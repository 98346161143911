import { WalletSelectPageProps } from '@features/wallet/pages/WalletSelectPage/interface'
import { EmptyWalletIcon } from '@components/Icons/Icons'
import React from 'react'
import SymbolImage from '@components/SymbolImage/SymbolImage'
import khotcoin from '@assets/images/khotcoin.png'
import tether from '@assets/images/tether.png'
import withWalletSelectPage from './withWalletSelectPage'

const WalletSelectPage = ({
  handleOnSelectWalletType,
  tetherBalance,
  khotcoinBalance,
}: WalletSelectPageProps) => {
  return (
    <div className='max-w-[700px] mx-auto px-[17px] pt-[14px]'>
      <div className='flex items-center mb-[24px] h-[40px]'>
        <EmptyWalletIcon />
        <span className='ml-[5px] text-sm font-bold'>Wallet</span>
      </div>
      <div
        className={`flex items-center space-x-[10px] mb-[10px] min-h-[70px] w-full rounded-[5px] px-[10px] py-[15px] cursor-pointer
        border-1 border-gray-300 hover:border-primary
      `}
        onClick={() => handleOnSelectWalletType('TETHER')}
        data-test-id='tether-wallet'
      >
        <SymbolImage path={tether} className='h-[40px] w-[40px]' />
        <div className='text-sm'>USDT wallet</div>
        <div className='flex-1 font-bold text-sm text-right'>
          {tetherBalance.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}{' '}
          USDT
        </div>
      </div>
      <div
        className={`flex items-center space-x-[10px] min-h-[70px] w-full rounded-[5px] px-[10px] py-[15px] cursor-pointer
        border-1 border-gray-300 hover:border-primary
      `}
        onClick={() => handleOnSelectWalletType('KHOTCOIN')}
        data-test-id='khotcoin-wallet'
      >
        <SymbolImage path={khotcoin} className='h-[40px] w-[40px]' />
        <div className='text-sm'>Khotcoin wallet</div>
        <div className='flex-1 font-bold text-sm text-right'>
          {khotcoinBalance.toLocaleString(undefined, {
            maximumFractionDigits: 2,
          })}{' '}
          Khotcoin
        </div>
      </div>
    </div>
  )
}

const WrappedComponent = withWalletSelectPage(WalletSelectPage)
export default WrappedComponent
