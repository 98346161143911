import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import { Accumulate } from '@models/TradeCopy/Accumulate'
import cn from 'classnames'
import ReactECharts from 'echarts-for-react'
import { useMemo, useState } from 'react'

export enum WinRateOptionEnum {
  YESTERDAY = 'yesterday',
  WEEK = '7days',
  HALFMONTH = '15days',
  MONTH = '30days',
}

function PersonalWinRate({
  className = '',
  accmulate,
  handleAccmulateParams,
  name,
}: {
  className?: string
  accmulate?: Accumulate
  handleAccmulateParams: (option: WinRateOptionEnum) => void
  name: string
}) {
  const [selectedDate, setSelectedDate] = useState(WinRateOptionEnum.WEEK)

  const chartOption = useMemo(() => {
    const isNoStat = accmulate?.totalLoss === 0 && accmulate?.totalProfit === 0
    return {
      tooltip: {
        trigger: 'item',
        formatter: '{b} : {c} ({d}%)',
      },
      series: [
        {
          type: 'pie',
          radius: ['75%', '92%'],
          data: [
            { value: accmulate?.totalLoss || 0, name: 'Total loss' },
            { value: accmulate?.totalProfit || 0, name: 'Total profit' },
          ],
          labelLine: {
            show: false,
          },
          label: {
            show: false,
          },
          color: isNoStat ? ['#262829', '#262829'] : ['#262829', '#21BB8E'],
        },
      ],
    }
  }, [accmulate])

  return (
    <div className={cn(className)}>
      <div className='text-sm font-bold pb-[9px]'>
        Personal Win Rate: {name}
      </div>
      <div className='border border-gray-300 rounded-[6px] py-[20px] px-[15px]'>
        <div className='flex items-center mb-[39px]'>
          <div className='text-sm font-bold flex-grow'>
            Accumulate Winning Details
          </div>
          <div className='w-[102px]'>
            <SelectSimpleField
              options={[
                { value: WinRateOptionEnum.YESTERDAY, label: 'Yesterday' },
                { value: WinRateOptionEnum.WEEK, label: '7 days' },
                { value: WinRateOptionEnum.HALFMONTH, label: '15 days' },
                { value: WinRateOptionEnum.MONTH, label: '30 days' },
              ]}
              isBorder
              value={selectedDate}
              onSelectedChange={(value: WinRateOptionEnum) => {
                setSelectedDate(value)
                handleAccmulateParams(value)
              }}
            />
          </div>
        </div>
        <div className='flex justify-center mb-[20px]'>
          <div className='flex gap-x-[10px] items-center'>
            <div className='w-[134px] relative'>
              <ReactECharts option={chartOption} style={{ height: 124 }} />
              <div className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-center'>
                <div className=' text-xl font-bold'>
                  {accmulate?.totalProfit || accmulate?.totalLoss
                    ? (
                        (accmulate.totalProfit * 100) /
                        (accmulate.totalLoss + accmulate.totalProfit)
                      ).toLocaleString(undefined, {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 2,
                      })
                    : 0}
                  %
                </div>
                <div className=' text-xs font-light text-gray-400 w-max'>
                  total win rate
                </div>
              </div>
            </div>
            <div className='text-xs font-light flex flex-col gap-y-[1px]'>
              <div>
                Total trade:{' '}
                {(accmulate?.totalLoss || 0) + (accmulate?.totalProfit || 0)}
              </div>
              <div>Total win: {accmulate?.totalProfit}</div>
              <div>Total lose: {accmulate?.totalLoss}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default PersonalWinRate
