import React from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { TradeCopyByPerson } from '@models/TradeCopy/TradeCopyByPerson'
import { useDropdown } from '@hooks/useDropDown'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { BinIcon, ThreeDotIcon, UserCircleIcon } from '@components/Icons/Icons'
import { TradeCopyRoutesPath } from '@config/Router'

interface MyCopyByPersonalCardProps {
  personal: TradeCopyByPerson
  handleDeleteTradeCopyByPerson: (id: number) => Promise<void>
}

function MyCopyByPersonalCard({
  personal,
  handleDeleteTradeCopyByPerson,
}: MyCopyByPersonalCardProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  const navigate = useNavigate()

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm, message }: ConfirmModalProps) => (
      <ConfirmModal
        showIcon={false}
        message={
          <div className='flex flex-col items-center'>
            <BinIcon className='text-[#EB5D57] w-[24px] h-[26px] mb-[12px]' />
            <div className='text-lg font-medium text-gray-60'>Delete</div>
            <p className='mt-[7px] text-sm text-gray-60'>
              Are you sure to delete bot?
            </p>
          </div>
        }
        onConfirm={onConfirm}
      />
    ),
  })

  return (
    <div
      className='border border-gray-300 rounded-[6px] px-[20px] py-[16px]'
      onClick={e => {
        navigate({
          pathname: TradeCopyRoutesPath.tradeCopy.personal.absolutePath({
            id: personal.id,
          }),
          search: `?${encodeURI(
            createSearchParams({
              name: personal.user.name,
              buyDate: `${personal.buyDate}`,
            }).toString()
          )}`,
        })
      }}
    >
      <div className='relative'>
        <div
          className='absolute right-[-10px] top-0 cursor-pointer'
          onClick={e => {
            e.stopPropagation()
            toggleDropdown()
          }}
        >
          <ThreeDotIcon />
        </div>
        <div
          ref={dropdownRef}
          className={cn(
            'absolute w-[116px] z-10 py-[11px] right-0 top-[20px] bg-navBar border border-gray-600 rounded-[10px]',
            { hidden: !isShowDropdown }
          )}
        >
          <div
            className='px-[11px] text-[10px] font-normal border-b border-gray-600 mb-[6px] pb-[6px] cursor-pointer hover:text-primary'
            onClick={() =>
              navigate({
                pathname: TradeCopyRoutesPath.tradeCopy.personal.absolutePath({
                  id: personal.id,
                }),
                search: `?${encodeURI(
                  createSearchParams({
                    name: personal.user.name,
                    buyDate: `${personal.buyDate}`,
                  }).toString()
                )}`,
              })
            }
          >
            View
          </div>
          <div
            className='px-[11px] text-[10px] font-normal cursor-pointer hover:text-primary'
            onClick={() =>
              openModal({
                onConfirm: async () => {
                  if (personal.id) {
                    await handleDeleteTradeCopyByPerson(personal.id)
                  }
                },
              })
            }
          >
            Delete
          </div>
        </div>
        <div className='flex gap-x-[8px] items-center'>
          {personal.user.profileImgPath ? (
            <img
              src={personal.user.profileImgPath}
              alt='Profile'
              className='w-[40px] h-[40px] rounded-full mr-[5px]'
            />
          ) : (
            <UserCircleIcon className='w-[45px] h-[45px]' />
          )}
          <div className='flex flex-col'>
            <div className='text-sm font-bold leading-[22px]'>
              {personal.user.name}
            </div>
            <div className='text-[10px] font-light'>{`Buy date: ${
              personal.buyDate
                ? DateTime.fromMillis(personal.buyDate).toFormat('dd/MM/yyyy')
                : ''
            }`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyCopyByPersonalCard
