import Loading from '@components/utils/Loading'
import React from 'react'
import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import TrendingCoinCard from './components/TrendingCoinCard'
import { TrendingCoinsProps } from './interface'
import withTrendingCoins from './withTrendingCoins'

const rankingOptions = [
  {
    value: 'ALL',
    label: 'All',
  },
  {
    value: '1-30',
    label: 'Top 30',
  },
  {
    value: '1-50',
    label: 'Top 50',
  },
  {
    value: '1-100',
    label: 'Top 100',
  },
]
const TrendingCoins = ({
  lastUpdate,
  trendingCoins,
  isLoading,
  className = 'px-14 mobile:px-7',
  cardsClassName,
  rank,
  handleOnRankChanged,
}: TrendingCoinsProps) => {
  if (isLoading) return <Loading text='Loading...' />
  return (
    <div className={`flex flex-col ${className}`}>
      <div className='flex gap-3 items-center justify-between mb-5'>
        <div className='flex flex-1 text-xs text-gray-400 '>{`Last update: ${lastUpdate.toFormat(
          'dd/MM/yyyy HH:mm:ss'
        )}`}</div>
        <div className='flex  items-center  gap-2 '>
          <div>Ranking: </div>
          <div className='w-[100px]'>
            <SelectSimpleField
              options={rankingOptions}
              onSelectedChange={v => {
                handleOnRankChanged(v)
              }}
              value={rank || 'ALL'}
            />
          </div>
        </div>
      </div>
      <div
        className={` 
        grid gap-x-8 gap-y-8
        md:grid-cols-2
        lg:grid-cols-3 lg:gap-x-9 lg:gap-y-9
        ${cardsClassName}
        `}
      >
        {trendingCoins.map((coin, index) => (
          <TrendingCoinCard
            key={`${coin.symbol}/USDT`}
            rank={index + 1}
            trendingCoin={coin}
          />
        ))}
      </div>
    </div>
  )
}
export default withTrendingCoins(TrendingCoins)
