import React, { useState } from 'react'
import { useField } from 'formik'
import {
  ToggleSwitchFieldProps,
  ToggleSwitchFieldAcceptProps,
} from './interface'

export function withToggleSwitchField(
  Component: React.FC<ToggleSwitchFieldProps>
) {
  function HOC({ onChange, ...props }: ToggleSwitchFieldAcceptProps) {
    const [field] = useField(props)

    function handleChange(e: React.ChangeEvent<HTMLInputElement>) {
      field.onChange(e)

      if (onChange) {
        onChange(e)
      }
    }

    const newProps = {
      ...props,
      ...field,
      onChange: handleChange,
    }

    return <Component {...newProps} />
  }

  return HOC
}
