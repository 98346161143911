import { Expose } from 'class-transformer'

export class BotProfitGroupByCoin {
  @Expose({ name: 'start_date' })
  startDate: number

  @Expose({ name: 'end_date' })
  endDate: number

  @Expose()
  coins: Array<{
    symbol: string
    profit: number
    imgPath?: string
  }>
}
