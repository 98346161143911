import React from 'react'
import cn from 'classnames'
import { useNavigate } from 'react-router-dom'
import { useDropdown } from '@hooks/useDropDown'
import { useModal } from '@components/Modal/context/ModalContext'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import { BinIcon, ThreeDotIcon } from '@components/Icons/Icons'
import { TradeCopyRoutesPath } from '@config/Router'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'

interface MyCopyBySymbolCardProps {
  bot: TradeCopyByBot
  handleDeleteTradeCopyByBot: (id: number) => Promise<void>
}

function MyCopyBySymbolCard({
  bot,
  handleDeleteTradeCopyByBot,
}: MyCopyBySymbolCardProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  const navigate = useNavigate()

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm, message }: ConfirmModalProps) => (
      <ConfirmModal
        showIcon={false}
        message={
          <div className='flex flex-col items-center'>
            <BinIcon className='text-[#EB5D57] w-[24px] h-[26px] mb-[12px]' />
            <div className='text-lg font-medium text-gray-60'>Delete</div>
            <p className='mt-[7px] text-sm text-gray-60'>
              Are you sure to delete bot?
            </p>
          </div>
        }
        onConfirm={onConfirm}
      />
    ),
  })

  return (
    <div
      className='border border-gray-300 rounded-[6px] px-[20px] py-[16px]'
      onClick={() => {
        navigate({
          pathname: TradeCopyRoutesPath.tradeCopy.strategy.absolutePath({
            id: bot.id,
          }),
          search: `?mode=detail`,
        })
      }}
    >
      <div className='relative'>
        <div
          className='absolute right-[-10px] top-0 cursor-pointer'
          onClick={e => {
            e.stopPropagation()
            toggleDropdown()
          }}
        >
          <ThreeDotIcon />
        </div>
        <div
          ref={dropdownRef}
          className={cn(
            'absolute w-[116px] z-10 py-[11px] right-0 top-[20px] bg-navBar border border-gray-600 rounded-[10px]',
            { hidden: !isShowDropdown }
          )}
        >
          <div
            className='px-[11px] text-[10px] font-normal border-b border-gray-600 mb-[6px] pb-[6px] cursor-pointer hover:text-primary'
            onClick={() =>
              navigate({
                pathname: TradeCopyRoutesPath.tradeCopy.strategy.absolutePath({
                  id: bot.id,
                }),
                search: `?mode=detail`,
              })
            }
          >
            View
          </div>
          <div
            className='px-[11px] text-[10px] font-normal cursor-pointer hover:text-primary'
            onClick={() =>
              openModal({
                onConfirm: async () => {
                  if (bot.id) await handleDeleteTradeCopyByBot(bot.id)
                },
              })
            }
          >
            Delete
          </div>
        </div>
        <div className='flex gap-x-[10px]'>
          <img
            src={bot.baseCurrencyData?.imgPath}
            alt={bot.symbol}
            className='w-[38px] h-[38px] rounded-full'
          />
          <div className='flex flex-col gap-y-[2px]'>
            <div className='text-sm font-bold leading-[22px]'>{bot.symbol}</div>
            <div className='text-xs font-light'>{`Trader: ${bot.user.name}`}</div>
            <div className='text-xs font-light'>{`Buy date: ${bot.buyDate?.toFormat(
              'dd/MM/yyyy'
            )}`}</div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MyCopyBySymbolCard
