import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class TradeCopyByBot {
  @Expose()
  id: number

  @Expose()
  baseCurrencyData: {
    name: string
    symbol: string
    imgPath: string
  }

  @Expose()
  profit: number

  @Expose()
  profitPercentage: number

  @Expose()
  isAlreadyBuy: boolean

  @Expose()
  symbol: string

  @Expose()
  botId: number

  @Expose()
  exchange: string

  @Expose()
  @Transform(({ value }) => DateTime.fromMillis(value), { toClassOnly: true })
  endAt: DateTime

  @Expose()
  user: { name: string; strategyDescription: string; profileImgPath: string }

  @Expose()
  strategyId: number

  @Expose()
  @Transform(({ value }) => (value ? DateTime.fromMillis(value) : undefined), {
    toClassOnly: true,
  })
  buyDate?: DateTime
}
