import { Tab } from '@components/Tabs/components/Tab'
import { TabBody } from '@components/Tabs/components/TabBody'
import { TabHeader } from '@components/Tabs/components/TabHeader'
import { Tabs } from '@components/Tabs/Tabs'
import { ProfitType } from '@models/Profit/ProfitType'
import RankDatas from './component/RankDatas'

import { RankingPageProps } from './interface'
import withRankingPage from './withRankingPage'

const RankingPage = ({
  profitType,
  handleProfitTypeChanged,
  isLoading,
  users,
  usersTopThree,
  isEmpty,
  goToChartPage,
}: RankingPageProps) => {
  return (
    <div className='max-w-[700px] mx-auto desktop:mt-2 mb-9'>
      <Tabs
        initialActiveTab={profitType}
        onActiveTabChange={handleProfitTypeChanged}
      >
        <Tab tabId={ProfitType.ALL_TIME}>
          <TabHeader tabId={ProfitType.ALL_TIME}>
            <span>Total Profit</span>
          </TabHeader>
          <TabBody>
            <RankDatas
              users={users}
              isEmpty={isEmpty}
              isLoading={isLoading}
              profitType={profitType}
              usersTopThree={usersTopThree}
              handleProfitTypeChanged={handleProfitTypeChanged}
              goToChartPage={goToChartPage}
            />
          </TabBody>
        </Tab>
        <Tab tabId={ProfitType.DAILY}>
          <TabHeader tabId={ProfitType.DAILY}>
            <span>Daily Profit</span>
          </TabHeader>
          <TabBody>
            <RankDatas
              users={users}
              isEmpty={isEmpty}
              isLoading={isLoading}
              profitType={profitType}
              usersTopThree={usersTopThree}
              handleProfitTypeChanged={handleProfitTypeChanged}
              goToChartPage={goToChartPage}
            />
          </TabBody>
        </Tab>
      </Tabs>
    </div>
  )
}

export default withRankingPage(RankingPage)
