import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { ExchangeBalance } from '@models/ExchangeBalance/ExchangeBalance'

export const fetchBalanceAPi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    fetchBalance: builder.query<ExchangeBalance[], void>({
      query: () => ({
        document: gql`
          query FetchBalance {
            fetchBalance {
              name
              free
              used
              total
            }
          }
        `,
      }),
      transformResponse(res: { fetchBalance: ExchangeBalance[] }) {
        const { fetchBalance } = res
        return fetchBalance
      },
      providesTags: ['ExchangeBalance'],
    }),
  }),
})

export const { useFetchBalanceQuery, useLazyFetchBalanceQuery } =
  fetchBalanceAPi
