import { Expose, Transform } from 'class-transformer'
import { toPercentage } from '@libs/utils'

export class TpSplitForm {
  @Expose()
  @Transform(({ value }) => value.map(v => toPercentage(v)), {
    toClassOnly: true,
  })
  @Transform(({ value }) => value.map(v => v / 100), { toPlainOnly: true })
  tpSplitField: number[]
}
