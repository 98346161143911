import { Outlet } from 'react-router-dom'
import HomePage from '@features/home/pages/HomePage'
import HomeLayout from './components/HomeLayout/HomeLayout'

export const HomeRoutes = {
  path: 'portal',
  element: (
    <HomeLayout>
      <Outlet />
    </HomeLayout>
  ),
  children: [
    {
      path: '',
      element: <HomePage />,
    },
  ],
}
