import React, { useEffect } from 'react'
import {
  useExpanded,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
} from 'react-table'
import { TablePropsType } from '@components/Table/interface'
import { CaretDownIcon, CaretUpIcon } from '@components/Icons/Icons'
import Loading from '@components/utils/Loading'
import NoData from '@components/NoData/NoData'
import TablePagination from './component/TablePagination/TablePagination'

const EmptyArray = []

// eslint-disable-next-line @typescript-eslint/ban-types
function Table<D extends object>({
  data,
  columns,
  handleOnSortChange,
  isSortable = false,
  manualPagination,
  currentPage = 1,
  totalPage,
  handleOnPageChange,
  isLoading,
  isShowPagination = true,
}: TablePropsType<D>) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    gotoPage,
    state: { pageIndex, pageSize, sortBy },
  } = useTable(
    {
      columns,
      data: !data ? EmptyArray : data,
      manualSortBy: true,
      disableSortBy: !isSortable,
      manualPagination,
    },
    useSortBy,
    useExpanded,
    usePagination,
    useRowSelect
  )

  useEffect(() => {
    if (handleOnSortChange) handleOnSortChange(sortBy)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sortBy])

  return isLoading ? (
    <Loading text='Loading' />
  ) : (
    <>
      {data?.length ? (
        <>
          <div className='max-w-full overflow-x-auto rounded-[15px] min-h-[250px]'>
            <table {...getTableProps()} className='w-full'>
              <thead>
                {headerGroups.map(headerGroup => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers.map(column => (
                      <th
                        {...column.getHeaderProps({
                          ...column.getSortByToggleProps(),
                          style: {
                            minWidth: column.minWidth,
                            width: column.width,
                          },
                        })}
                        className='text-xs font-normal py-3 text-center first:pl-3 last:pr-3  border-b-1 border-gray-300'
                      >
                        <div className='flex items-center justify-center text-sm'>
                          {column.render('Header')}
                          <span>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <CaretDownIcon
                                  width='15px'
                                  height='15px'
                                  className='ml-2'
                                />
                              ) : (
                                <CaretUpIcon
                                  width='15px'
                                  height='15px'
                                  className='ml-2'
                                />
                              )
                            ) : (
                              ''
                            )}
                          </span>
                        </div>
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row)
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        const { length } = row.cells

                        return (
                          <td
                            {...cell.getCellProps()}
                            className='py-[8px] first:pl-[10px] last:pr-[10px] text-xs text-center'
                          >
                            {cell.render('Cell')}
                          </td>
                        )
                      })}
                    </tr>
                  )
                })}
              </tbody>
            </table>
          </div>
          {isShowPagination && (
            <TablePagination
              paginationProps={{
                pageCount: totalPage,
                gotoPage,
                handleOnPageChange,
              }}
              tableState={{
                pageIndex: manualPagination ? currentPage - 1 : pageIndex,
                pageSize,
              }}
            />
          )}
        </>
      ) : (
        <NoData />
      )}
    </>
  )
}

export default Table
