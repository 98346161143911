import React from 'react'
import { useNavigate } from 'react-router-dom'
import TransactionLog from '@components/TransactionLog/TransactionLog'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { HomeRoutesPath } from '@config/Router'
import { GoToTopButton } from '@components/GoToTopButton'

function TransactionLogPage() {
  const navigate = useNavigate()
  return (
    <div className='max-w-[750px] mx-auto px-[15px] desktop:mt-2 mb-9'>
      <TransactionLog limit={20} />
      <PrimaryButton
        type='button'
        className='w-full py-[8px] text-sm font-bold rounded-[18px]'
        onClick={() => navigate(HomeRoutesPath.home.path)}
      >
        กลับ
      </PrimaryButton>
      <GoToTopButton className='desktop:hidden bottom-[72px]' />
    </div>
  )
}

export default TransactionLogPage
