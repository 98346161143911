import { BackIcon } from '@components/Icons/Icons'
import { AuthenticationRoutesPath } from '@config/Router'
import { useLocation, useNavigate } from 'react-router-dom'

const NavBarBackButton = ({ title }: { title: string }) => {
  const navigate = useNavigate()
  const { pathname, state: ab } = useLocation()

  const onNavigateBack = () => {
    if (pathname === AuthenticationRoutesPath.login.absolutePath) {
      navigate('/')
    } else {
      navigate(-1)
    }
  }
  return (
    <div className='fixed top-0 flex items-center w-full px-[17px] py-[23px] z-10 bg-gray-900'>
      <div
        className='flex items-center gap-x-[5px] cursor-pointer'
        onClick={onNavigateBack}
      >
        <BackIcon width='24' height='24' />
        <div className=''>{title}</div>
      </div>
    </div>
  )
}
export default NavBarBackButton
