import React, { useCallback, useEffect, useState } from 'react'
import {
  Tab,
  TabContextType,
  TabsProviderPropsType,
} from '@components/Tabs/interface'
import cn from 'classnames'

export const TabContext = React.createContext<TabContextType>({
  menus: {},
  activeTab: '',
  handleAddMenu: (tab: Tab) => {},
})

export const Tabs = ({
  initialActiveTab,
  onActiveTabChange,
  children,
  tabsClassName,
  tabClassName,
}: TabsProviderPropsType) => {
  const [menus, setMenus] = useState<Tab>({})
  const [activeTab, setActiveTab] = useState<string>(initialActiveTab)
  const handleAddMenu = useCallback((tab: Tab) => {
    setMenus(oldValue => ({ ...oldValue, ...tab }))
  }, [])
  const value = { activeTab, menus, handleAddMenu }
  const menusArray = Object.entries(menus)

  useEffect(() => {
    setActiveTab(initialActiveTab)
  }, [initialActiveTab])

  const handleUpdateActiveTab = (key: string) => {
    setActiveTab(key)
    if (onActiveTabChange) {
      onActiveTabChange(key)
    }
  }
  return (
    <TabContext.Provider value={value}>
      <div className='select-none text-sm'>
        <div
          className={cn(
            `w-full  text-center cursor-pointer flex`,
            tabsClassName
          )}
        >
          {menusArray.map(([key, item]) => (
            <div
              key={key}
              className={cn(
                `flex-1 flex justify-center items-center px-3 py-2  ${
                  key === activeTab
                    ? 'border-primary border-b-1 text-primary font-medium'
                    : 'border-white border-b-0'
                }`,
                tabClassName
              )}
              onClick={() => handleUpdateActiveTab(key)}
            >
              {React.cloneElement(item)}
            </div>
          ))}
        </div>
      </div>
      {children}
    </TabContext.Provider>
  )
}
