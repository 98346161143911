import { TurnOnOffAllButtonProps } from '@features/bot/components/BotPagination/components/TurnOnOffAllButton/interface'
import cn from 'classnames'
import { PauseCircleIcon, PlayCircleIcon } from '@components/Icons/Icons'
import React from 'react'
import withTurnOnOffAllButton from './withTurnOnOffAllButton'

const TurnOnOffAllButton = ({
  isActive,
  handleOnClickButton,
}: TurnOnOffAllButtonProps) => (
  <div
    onClick={handleOnClickButton}
    className={cn(
      `flex flex-col items-center justify-center gap-0.5 h-[60px] w-[60px]`,
      'border-1 rounded-[10px] border-gray-300 cursor-pointer'
    )}
  >
    {isActive ? (
      <PauseCircleIcon height='22' width='22' />
    ) : (
      <PlayCircleIcon height='22' width='22' />
    )}

    <span className={cn('text-[10px]')}>{isActive ? 'Pause' : 'Resume'}</span>
  </div>
)

const WrappedComponent = withTurnOnOffAllButton(TurnOnOffAllButton)
export default WrappedComponent
