import { TFunction } from 'i18next'

const errorCaseData = (t: TFunction) => {
  return [
    {
      title: t('errorCaseModal.marginIsInsufficient.title'),
      causeList: [
        {
          detail: t('errorCaseModal.marginIsInsufficient.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.marginIsInsufficient.solutionDetail1'),
        },
        {
          detail: t('errorCaseModal.marginIsInsufficient.solutionDetail2'),
        },
      ],
    },
    {
      title: t('errorCaseModal.positionSymbolAlreadyExist.title'),
      causeList: [
        {
          detail: t('errorCaseModal.positionSymbolAlreadyExist.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t(
            'errorCaseModal.positionSymbolAlreadyExist.solutionDetail1'
          ),
        },
      ],
    },
    {
      title: t('errorCaseModal.invalidApiKey.title'),
      causeList: [
        {
          detail: t('errorCaseModal.invalidApiKey.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.invalidApiKey.solutionDetail1'),
        },
        {
          detail: t('errorCaseModal.invalidApiKey.solutionDetail2'),
        },
      ],
    },
    {
      title: t('errorCaseModal.permissionAction.title'),
      causeList: [
        {
          detail: t('errorCaseModal.permissionAction.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.permissionAction.solutionDetail1'),
        },
      ],
    },
    {
      title: t('errorCaseModal.botFeeExceedAmountWallet.title'),
      causeList: [
        {
          detail: t('errorCaseModal.botFeeExceedAmountWallet.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.botFeeExceedAmountWallet.solutionDetail1'),
        },
      ],
    },
    {
      title: t('errorCaseModal.reduceOnlyOrderRejected.title'),
      causeList: [
        {
          detail: t('errorCaseModal.reduceOnlyOrderRejected.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.reduceOnlyOrderRejected.solutionDetail1'),
        },
      ],
    },
    {
      title: t('errorCaseModal.requestTimedOut10000ms.title'),
      causeList: [
        {
          detail: t('errorCaseModal.requestTimedOut10000ms.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.requestTimedOut10000ms.solutionDetail1'),
        },
      ],
    },
    {
      title: t('errorCaseModal.binanceLeaverageFetchFailed.title'),
      causeList: [
        {
          detail: t('errorCaseModal.binanceLeaverageFetchFailed.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t(
            'errorCaseModal.binanceLeaverageFetchFailed.solutionDetail1'
          ),
        },
        {
          detail: t(
            'errorCaseModal.binanceLeaverageFetchFailed.solutionDetail2'
          ),
        },
      ],
    },
    {
      title: t('errorCaseModal.liquidatedPosition.title'),
      causeList: [
        {
          detail: t('errorCaseModal.liquidatedPosition.causeDetail1'),
        },
      ],
      solutionList: [
        {
          detail: t('errorCaseModal.liquidatedPosition.solutionDetail1'),
        },
      ],
    },
    {
      title: t('errorCaseModal.orderingSessionTimeOut1Minute.title'),
      causeList: [
        {
          detail: t(
            'errorCaseModal.orderingSessionTimeOut1Minute.causeDetail1'
          ),
        },
        {
          detail: t(
            'errorCaseModal.orderingSessionTimeOut1Minute.causeDetail2'
          ),
        },
      ],
      solutionList: [
        {
          detail: t(
            'errorCaseModal.orderingSessionTimeOut1Minute.solutionDetail1'
          ),
        },
        {
          detail: t(
            'errorCaseModal.orderingSessionTimeOut1Minute.solutionDetail2'
          ),
        },
        {
          detail: t(
            'errorCaseModal.orderingSessionTimeOut1Minute.solutionDetail3'
          ),
        },
      ],
    },
    {
      title: t('errorCaseModal.orderPositionNotMatchUserSetting.title'),
      causeList: [
        {
          detail: t(
            'errorCaseModal.orderPositionNotMatchUserSetting.causeDetail1'
          ),
        },
        {
          detail: t(
            'errorCaseModal.orderPositionNotMatchUserSetting.causeDetail2'
          ),
        },
        {
          detail: t(
            'errorCaseModal.orderPositionNotMatchUserSetting.causeDetail3'
          ),
        },
      ],
      solutionList: [
        {
          detail: t(
            'errorCaseModal.orderPositionNotMatchUserSetting.solutionDetail1'
          ),
        },
        {
          detail: t(
            'errorCaseModal.orderPositionNotMatchUserSetting.solutionDetail2'
          ),
        },
      ],
    },
    {
      title: t('errorCaseModal.binanceAccountInsufficientBalance.title'),
      causeList: [
        {
          detail: t(
            'errorCaseModal.binanceAccountInsufficientBalance.causeDetail1'
          ),
        },
      ],
      solutionList: [
        {
          detail: t(
            'errorCaseModal.binanceAccountInsufficientBalance.solutionDetail1'
          ),
        },
      ],
    },
  ]
}

export default errorCaseData
