import { MyNetworkSortEnum } from '@interfaces/MyNetworkSortEnum'

export const myNetworkSortOptions = [
  { label: 'Name : A-Z', value: MyNetworkSortEnum.NAME_ASC },
  { label: 'Name : Z-A', value: MyNetworkSortEnum.NAME_DESC },
  { label: 'User ID : Low to High', value: MyNetworkSortEnum.ID_ASC },
  { label: 'User ID : High to Low', value: MyNetworkSortEnum.ID_DESC },
  {
    label: 'Total profit : Low to High',
    value: MyNetworkSortEnum.TOTAL_PROFIT_ASC,
  },
  {
    label: 'Total profit : High to Low',
    value: MyNetworkSortEnum.TOTAL_PROFIT_DESC,
  },
]
