import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { FutureMarketDetail } from '@models/FutureMarketDetail/FutureMarketDetail'

export const futureMarketDetailApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    futureMarketDetail: builder.query<FutureMarketDetail, { symbol: string }>({
      query: ({ symbol }) => ({
        document: gql`
          query FutureMarketDetail($symbol: String!) {
            futureMarketDetail(symbol: $symbol) {
              maker
              limits {
                amount {
                  min
                  max
                }
                cost {
                  min
                  max
                }
                leverage {
                  min
                  max
                }
                price {
                  min
                  max
                }
              }
            }
          }
        `,
        variables: {
          symbol,
        },
      }),
      transformResponse(res: { futureMarketDetail: FutureMarketDetail }) {
        const { futureMarketDetail } = res
        return futureMarketDetail
      },
      providesTags: ['MarketDetail'],
    }),
  }),
})

export const { useFutureMarketDetailQuery, useLazyFutureMarketDetailQuery } =
  futureMarketDetailApi
