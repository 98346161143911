export const cycleOptions = [
  {
    label: 'Cycle',
    value: 'cycle',
  },
  {
    label: 'Non-limit',
    value: 'infinity',
  },
  {
    label: 'Single',
    value: 'single',
  },
]
