import { OneCircleIcon } from '@components/Icons/Icons'
import React from 'react'
import { OneOffAllButtonProps } from '@features/bot/components/BotPagination/components/OneOffAllButton/interface'
import cn from 'classnames'
import withOneOffAllButton from './withOneOffAllButton'

const OneOffAllButton = ({
  handleOnOneOffAll,
  isOneOffAll,
}: OneOffAllButtonProps) => {
  const isDisable = isOneOffAll === undefined
  return (
    <div
      onClick={handleOnOneOffAll}
      className={cn(
        `flex flex-col items-center justify-center gap-0.5 h-[60px] w-[60px]`,
        'border-1 rounded-[10px]',
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer',
        isOneOffAll && !isDisable ? 'border-primary' : 'border-gray-300'
      )}
    >
      <OneCircleIcon
        height='22'
        width='22'
        className={cn(
          isDisable && 'text-gray-300',
          isOneOffAll && !isDisable && 'text-primary',
          !isOneOffAll && !isDisable && 'text-white'
        )}
      />
      <span
        className={cn(
          'text-[10px]',
          isDisable && 'text-gray-300',
          isOneOffAll && 'text-primary'
        )}
      >
        One-off
      </span>
    </div>
  )
}

const WrappedComponent = withOneOffAllButton(OneOffAllButton)
export default WrappedComponent
