import { Outlet } from 'react-router-dom'
import { AppLayout } from '@components/AppLayout/AppLayout'
import { TrendingCoinRoutesPath } from '@config/Router'
import TrendingCoinPage from './pages/TrendingCoinPage'

export const TrendingCoinRoutes = {
  path: TrendingCoinRoutesPath.trendingCoin.path,
  element: (
    <AppLayout title='Coin Suggestion'>
      <Outlet />
    </AppLayout>
  ),

  children: [
    {
      path: '',
      element: <TrendingCoinPage />,
    },
  ],
}
