import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const stopCoveringAllApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    stopCoveringAll: builder.mutation<
      boolean,
      { isStopCovering: boolean; botMarketType: BotMarketType }
    >({
      query: ({ isStopCovering, botMarketType }) => ({
        document: gql`
          mutation StopCoveringAll(
            $isStopCovering: Boolean!
            $botType: BotType!
            $botMarketType: BotMarketType!
          ) {
            stopCoveringAll(
              isStopCovering: $isStopCovering
              botType: $botType
              botMarketType: $botMarketType
            )
          }
        `,
        variables: {
          botType: 'Martingale',
          botMarketType,
          isStopCovering,
        },
      }),
    }),
  }),
})

export const { useStopCoveringAllMutation } = stopCoveringAllApi
