import {
  CredentialType,
  LoginPropsType,
} from '@features/authentication/components/Login/interface'
import { useLoginMutation } from '@client/mainClient/collections/AuthenticationClientActions'
import { useLazyProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useState } from 'react'

const initialValue: CredentialType = { username: '', password: '' }

export const withLogin = (Component: React.FC<LoginPropsType>) => {
  function HOC() {
    const [mutate, { isLoading: isLoginLoading }] = useLoginMutation()
    const [getProfile, { isLoading: isProfileLoading }] = useLazyProfileQuery()
    const [isObscurePassword, setIsObscurePassword] = useState(false)

    const handleOnSubmit = ({ username, password }: CredentialType) => {
      mutate({ username, password })
        .unwrap()
        .then(async () => {
          await getProfile({}).unwrap()
        })
        .catch(() => {})
    }

    const handleToggleObscurePassword = () => {
      setIsObscurePassword(prev => !prev)
    }
    const newProps = {
      initialValue,
      isLoading: isLoginLoading || isProfileLoading,
      isObscurePassword,
      handleOnSubmit,
      handleToggleObscurePassword,
    }

    return <Component {...newProps} />
  }

  return HOC
}
