import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { CreateBotForm } from '@models/Bot/CreateBotForm'

export const createBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createBot: builder.mutation<{ id: number }, CreateBotForm>({
      query: newBotInput => ({
        document: gql`
          mutation CreateBotController($newBotInput: NewBotInput!) {
            createBotController(NewBotInput: $newBotInput) {
              id
            }
          }
        `,
        variables: {
          newBotInput,
        },
      }),
    }),
  }),
})

export const { useCreateBotMutation } = createBotApi
