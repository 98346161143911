import { useNavigate, useParams } from 'react-router-dom'
import { plainToClass } from 'class-transformer'
import React, { useCallback, useEffect, useState } from 'react'
import { useLazyGetBotControllerQuery } from '@client/botClient/collections/GetBotClientAction'
import { MainForm } from '@models/Bot/MainForm'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'
import { useGetMyTradeCopyByBotQuery } from '@client/botClient/collections/GetMyTradeCopyByBotClientAction'
import { useGetMyTradeCopyByPersonsQuery } from '@client/botClient/collections/GetMyTradeCopyByPersonsClientAction'
import { CoverSpreadsForm } from '@models/Bot/CoverSpreadsForm'
import { CoverMultiplesForm } from '@models/Bot/CoverMultiplesForm'
import { PullbackSpreadsForm } from '@models/Bot/PullBackSpreadsForm'
import { TpSplitForm } from '@models/Bot/TpSplitForm'
import { useLazyBotStrategyQuery } from '@client/botClient/collections/BotStrategyClientAction'
import { useLazyBotWithoutSubscriptionQuery } from '@client/botClient/collections/BotClientWithoutSubscriptionAction'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { Bot } from '@models/Bot/Bot'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import { CreateBotPageAcceptProps, CreateBotPageProps } from './interface'

const withCreateBotPage = (Component: React.FC<CreateBotPageProps>) => {
  function WithCreateBotPage({ mode }: CreateBotPageAcceptProps) {
    const { id, botId } = useParams()
    const navigate = useNavigate()
    const { isFuture } = useBotMarketType()
    const route = isFuture ? ContractRoutesPath : SpotRoutesPath
    const [editInitialise, setEditInitialise] = useState<MainForm | null>(null)
    const [botDetail, setBotDetail] = useState<Bot | null>(null)
    const [editInitialCoverSpreadsForm, setEditInitialCoverSpreadsForm] =
      useState<CoverSpreadsForm | null>(null)
    const [editInitialCoverMultipleForm, setEditInitialCoverMultipleForm] =
      useState<CoverMultiplesForm | null>(null)
    const [editInitialPullbackSpreadsForm, setEditInitialPullbackSpreadsForm] =
      useState<PullbackSpreadsForm | null>(null)

    const [editInitialTpRatioSplitForm, setEditInitialTpRatioSplitForm] =
      useState<TpSplitForm | null>(null)
    const [
      editInitialTpRetracementSplitForm,
      setEditInitialTpRetracementSplitForm,
    ] = useState<TpSplitForm | null>(null)

    const [getBotController] = useLazyGetBotControllerQuery()

    const [getStrategy] = useLazyBotStrategyQuery()

    const [getBotDetail] = useLazyBotWithoutSubscriptionQuery()

    // Create bot from contract copy by symbol, personal
    const [selectedBot, setSelectedBot] = useState<TradeCopyByBot>()
    const [selectedPersonalId, setSelectedPersonalId] = useState<number>()
    const { data: copyBySymbolBots } = useGetMyTradeCopyByBotQuery(undefined, {
      skip: mode !== 'copy-by-symbol',
    })
    const { data: copyByPersonalBot } = useGetMyTradeCopyByPersonsQuery(
      undefined,
      {
        skip: mode !== 'copy-by-personal',
      }
    )

    const handleSelectedCopyByBotChange = useCallback(
      (bot?: TradeCopyByBot) => {
        setSelectedBot(bot)
        // eslint-disable-next-line react-hooks/exhaustive-deps
      },
      []
    )

    const handleSelectedCopyByPersonalChange = useCallback(
      (personId: number) => {
        setSelectedPersonalId(prev => {
          if (prev !== personId) {
            setSelectedBot(undefined)
          }
          return personId
        })
      },
      []
    )

    useEffect(() => {
      if (copyBySymbolBots) {
        setSelectedBot(copyBySymbolBots[0])
      }
    }, [copyBySymbolBots, setSelectedBot])

    useEffect(() => {
      if (id && mode === 'strategy-update') {
        getStrategyHandle({ strategyId: Number(id) })
      } else if (id && mode === 'bot-update-current-strategy') {
        getBotDetailHandle()
      } else if (id && mode !== 'copy-by') {
        getBotControllerHandle({ botControllerId: Number(id) })
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id, mode])

    const getBotControllerHandle = async ({
      botControllerId,
    }: {
      botControllerId: number
    }) => {
      const botControllerRes = await getBotController({ id: botControllerId })
      if (!botControllerRes.isError && botControllerRes.data) {
        const botController = botControllerRes.data
        const initialValues: MainForm = {
          symbol: botController?.bots?.length
            ? botController.bots[0].symbol
            : '',

          isSaveStrategy: false,
          ...botController,
          ...botController.botStrategy,
          id: botController.id,
        }

        setEditInitialise(plainToClass(MainForm, initialValues))

        setEditInitialCoverMultipleForm(
          plainToClass(CoverMultiplesForm, botController.botStrategy)
        )
        setEditInitialCoverSpreadsForm(
          plainToClass(CoverSpreadsForm, botController.botStrategy)
        )
        setEditInitialPullbackSpreadsForm(
          plainToClass(PullbackSpreadsForm, botController.botStrategy)
        )
        setEditInitialTpRatioSplitForm(
          plainToClass(
            TpSplitForm,
            { tpSplitField: botController.botStrategy.tpRatioSplit },
            {
              excludeExtraneousValues: true,
            }
          )
        )
        setEditInitialTpRetracementSplitForm(
          plainToClass(
            TpSplitForm,
            { tpSplitField: botController.botStrategy.tpRetracementSplit },
            {
              excludeExtraneousValues: true,
            }
          )
        )
      }
    }

    const getBotDetailHandle = async () => {
      const botDetailData = await getBotDetail({ botId: Number(botId) })

      if (!botDetailData.isError && botDetailData.data) {
        await updateBotStrategyForm({
          symbol: botDetailData.data.symbol,
          strategy: botDetailData.data.botStrategy,
        })
        setBotDetail(botDetailData.data)
      }
    }

    const updateBotStrategyForm = async ({
      strategy,
      symbol,
    }: {
      strategy: BotStrategiesDefault
      symbol?: string
    }) => {
      const initialValues: MainForm = {
        symbol: symbol || '',
        isSaveStrategy: false,
        ...strategy,
        // ID must be botControllerId
        id: Number(id),
        isSplitTp: strategy.isSplitTp ? 'split' : 'all',
        isCycle: strategy.isInfinityCycle
          ? 'infinity'
          : strategy.isCycle
          ? 'cycle'
          : 'single',
        isCovering: strategy.isCovering,
      }
      setEditInitialise(plainToClass(MainForm, initialValues))

      setEditInitialCoverMultipleForm(
        plainToClass(CoverMultiplesForm, strategy)
      )
      setEditInitialCoverSpreadsForm(plainToClass(CoverSpreadsForm, strategy))
      setEditInitialPullbackSpreadsForm(
        plainToClass(PullbackSpreadsForm, strategy)
      )
      setEditInitialTpRatioSplitForm(
        plainToClass(
          TpSplitForm,
          { tpSplitField: strategy.tpRatioSplit },
          {
            excludeExtraneousValues: true,
          }
        )
      )
      setEditInitialTpRetracementSplitForm(
        plainToClass(
          TpSplitForm,
          { tpSplitField: strategy.tpRetracementSplit },
          {
            excludeExtraneousValues: true,
          }
        )
      )
    }

    const getStrategyHandle = async ({
      strategyId,
    }: {
      strategyId: number
    }) => {
      const strategyRes = await getStrategy({ botStrategyId: strategyId })
      if (!strategyRes.isError && strategyRes.data) {
        await updateBotStrategyForm({ strategy: strategyRes.data })
      }
    }

    const newProps: CreateBotPageProps = {
      mode,
      editInitialise,
      editInitialCoverMultipleForm,
      editInitialCoverSpreadsForm,
      editInitialPullbackSpreadsForm,
      editInitialTpRatioSplitForm,
      editInitialTpRetracementSplitForm,
      selectedBot,
      selectedPersonalId,
      copyByPersonalBot,
      copyBySymbolBots,
      handleSelectedCopyByBotChange,
      handleSelectedCopyByPersonalChange,
      botDetail,
    }

    return <Component {...newProps} />
  }

  return WithCreateBotPage
}

export default withCreateBotPage
