import { createContext, useContext, useEffect, useState } from 'react'
import { Exchange } from 'ccxt'
import { useSelector } from 'react-redux'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import * as ccxt from 'ccxt'
import { ExchangesEnum } from '@components/Exchange/context/ExchangeContext.interface'
import { APP_ENV } from '@config/config'

export const ExchangeContext = createContext<{
  exchange: Exchange | null
  selectExchange: ExchangesEnum
  setSelectExchange: ((v: ExchangesEnum) => void) | null
}>({
  exchange: null,
  selectExchange: ExchangesEnum.Binance,
  setSelectExchange: null,
})

export const ExchangeContextProvider = ({
  children,
}: {
  children: React.ReactNode
}) => {
  const [exchange, setExchange] = useState<Exchange | null>(null)
  const [selectExchange, setSelectExchange] = useState<ExchangesEnum>(
    ExchangesEnum.Binance
  )
  const { profile } = useSelector(authenticationSelector)

  useEffect(() => {
    // TODO: future need to support many exchange and make change this code.
    const binanceAuthExchange = profile?.authExchanges?.[0]

    if (binanceAuthExchange) {
      const exchangeInstance = new ccxt[selectExchange]()
      if (APP_ENV === 'DEVELOPMENT') {
        exchangeInstance.setSandboxMode(true)
      }
      exchangeInstance.apiKey = binanceAuthExchange.apiKey
      exchangeInstance.secret = binanceAuthExchange.secret
      exchangeInstance.options = {
        ...exchangeInstance.options,
        defaultType: 'future',
        defaultMarket: 'future',
      }

      setExchange(exchangeInstance)
    }
  }, [profile, selectExchange])

  return (
    <ExchangeContext.Provider
      value={{ exchange, selectExchange, setSelectExchange }}
    >
      {children}
    </ExchangeContext.Provider>
  )
}

export const useExchange = () => useContext(ExchangeContext)
