import NoData from '@components/NoData/NoData'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'
import SelectBotCard from './SelectBotCard'

interface SelectCopyBySymbolProps {
  copyBySymbolBots?: TradeCopyByBot[]
  handleSelectedCopyByBotChange: (bot: TradeCopyByBot) => void
  selectedBot?: TradeCopyByBot
}

function SelectCopyBySymbol({
  handleSelectedCopyByBotChange,
  copyBySymbolBots,
  selectedBot,
}: SelectCopyBySymbolProps) {
  return (
    <>
      <span className='text-sm font-medium'>Select strategy</span>
      <div className='grid grid-col-1 gap-y-[8px] pt-[9px] max-h-[50vh] overflow-y-auto'>
        {copyBySymbolBots?.length ? (
          copyBySymbolBots.map(bot => (
            <SelectBotCard
              key={bot.id}
              bot={bot}
              selectedBot={selectedBot}
              handleSelectedCopyByBotChange={handleSelectedCopyByBotChange}
            />
          ))
        ) : (
          <NoData />
        )}
      </div>
    </>
  )
}

export default SelectCopyBySymbol
