import withLiquidateButton from '@features/bot/pages/BotPage/components/LiquidateButton/withLiquidateButton'
import { LiquidateButtonProps } from '@features/bot/pages/BotPage/components/LiquidateButton/interface'
import cn from 'classnames'
import { XLightIcon } from '@components/Icons/Icons'

const LiquidateButton = ({
  handleLiquidatePosition,
  isDisable,
}: LiquidateButtonProps) => {
  return (
    <div
      className={cn(
        'flex flex-col items-center justify-center h-[84px] w-[80px] border-1 py-[10px] px-[20px] rounded-[10px] cursor-pointer border-gray-300',
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer'
      )}
      onClick={handleLiquidatePosition}
    >
      <XLightIcon
        height='40'
        width='40'
        className={cn(isDisable ? 'text-gray-300' : 'text-white')}
      />
      <span className={cn('text-[10px]', isDisable && 'text-gray-300')}>
        Liquidate
      </span>
    </div>
  )
}

const WrappedComponent = withLiquidateButton(LiquidateButton)
export default WrappedComponent
