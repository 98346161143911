import { Expose, Transform } from 'class-transformer'
import { toPercentage } from '@libs/utils'
import { CoverSpreadsType } from './CreateBotForm'

export class CoverSpreadsForm {
  @Expose()
  @Transform(
    ({ value, obj }) =>
      value?.map((spread: number) => ({
        value: toPercentage(spread),
        isTrends: (obj.coverSpreadTrends || []).includes(spread),
      })),
    {
      toClassOnly: true,
    }
  )
  @Transform(
    ({ value }) =>
      value?.map((coverSpread: CoverSpreadsType) => coverSpread.value / 100),
    {
      toPlainOnly: true,
    }
  )
  coverSpreads: CoverSpreadsType[]

  @Expose({ toPlainOnly: true })
  @Transform(
    ({ obj }) =>
      obj.coverSpreads
        ?.filter((coverSpread: CoverSpreadsType) => coverSpread.isTrends)
        ?.map((coverSpread: CoverSpreadsType) => coverSpread.value / 100),
    {
      toPlainOnly: true,
    }
  )
  coverSpreadTrends: number[]
}
