import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { FunctionComponentType } from '@interfaces/FunctionComponentType'

export const withHomeLayout = (Component: React.FC<FunctionComponentType>) => {
  function HOC(props: FunctionComponentType) {
    useProfileQuery({})

    const newProps = { ...props }
    return <Component {...newProps} />
  }

  return HOC
}
