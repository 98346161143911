import React from 'react'
import { useFormikContext } from 'formik'
import InputRange from 'react-input-range'
import SelectButtonField from '@components/form/SelectButtonField/SelectButtonField'
import { sideOptions } from '@constant/form/createBotForm/sideOptions'
import { NumberField } from '@components/form/NumberField'
import { cycleOptions } from '@constant/form/createBotForm/cycleOptions'
import { MainForm } from '@models/Bot/MainForm'
import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { cycleSettingTooltip } from '@constant/tooltips/botTooltipsMessage'

import withSecondStep from './withSecondStep'
import { SecondStepPorps } from './interface'

function SecondStep({
  maxLeverage,
  mode,
  options,
  selectedStrategy,
  handleSelectStrategy,
  onLeverageChange,
  botMarketType,
}: SecondStepPorps) {
  const { values, setFieldValue } = useFormikContext<MainForm>()

  return (
    <>
      {mode === 'bot' && (
        <div className=''>
          <SelectSimpleField
            onSelectedChange={value =>
              handleSelectStrategy(value, values.symbol || '')
            }
            value={selectedStrategy}
            options={options}
            label='Strategy'
            placeholder='Select strategy'
          />
        </div>
      )}
      {botMarketType === BotMarketType.FUTURE && (
        <SelectButtonField
          disabled={
            mode === 'bot-update-current-strategy' ||
            mode === 'bot-update-main-strategy'
          }
          name='side'
          options={sideOptions}
          label='Type of Positioning'
          optionClassName=' px-4 py-2 min-w-0 h-fit-content'
          unActiveClassName='text-white border-1 border-white'
        />
      )}

      {botMarketType === BotMarketType.FUTURE && (
        <NumberField
          disabled={
            mode === 'bot-update-current-strategy' ||
            mode === 'bot-update-main-strategy'
          }
          onValueChange={v => {
            onLeverageChange(v)
          }}
          isOnlyPositiveNumber
          label='Contract multiplier'
          name='leverage'
          className='w-full'
          minLength={0}
        />
      )}
      {botMarketType === BotMarketType.FUTURE && (
        <InputRange
          disabled={
            mode === 'bot-update-current-strategy' ||
            mode === 'bot-update-main-strategy'
          }
          onChangeComplete={v => {
            if (typeof v === 'number') {
              onLeverageChange(v)
            }
          }}
          maxValue={maxLeverage}
          minValue={1}
          value={Number(values.leverage)}
          onChange={value =>
            value > 0 &&
            value <= maxLeverage &&
            setFieldValue('leverage', value)
          }
        />
      )}
      <SelectButtonField
        disabled={mode === 'bot-update-current-strategy'}
        name='isCycle'
        label='Cycle setting'
        options={cycleOptions}
        tooltip={cycleSettingTooltip}
        optionClassName=' px-4 py-2 min-w-0 h-fit-content'
        unActiveClassName='text-white border-1 border-white'
        activeClassName='text-primary border-1 border-primary'
      />
      {values.isCycle === 'cycle' && (
        <NumberField
          disabled={mode === 'bot-update-current-strategy'}
          placeholder='input a number of round'
          onValueChange={v => {
            onLeverageChange(Number(v))
          }}
          name='cycleQuantity'
          className='w-full'
          isOnlyPositiveNumber
          min={2}
        />
      )}
    </>
  )
}

export default withSecondStep(SecondStep)
