import React from 'react'
import * as Yup from 'yup'
import { FieldArray, Form, Formik } from 'formik'
import { Drawer } from '@components/Drawer'
import InputField from '@components/form/InputField/InputField'
import { ordinalNumbers } from '@libs/utils'
import { DrawerContext } from '@hooks/useDrawer'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { requiredNumberMsg } from '@constant/errorMessage'
import { TpSplitForm } from '@models/Bot/TpSplitForm'
import { Bot } from '@models/Bot/Bot'

const validationSchema = Yup.object().shape({
  coverSpreads: Yup.array().of(
    Yup.object().shape({
      value: Yup.number().required(requiredNumberMsg),
      isTrends: Yup.boolean().required(),
    })
  ),
})

const TpSplitDrawer = ({
  drawer,
  title,
  desc,
  onSubmit,
  initialValues,
  bot,
}: {
  bot?: Bot | null
  drawer: DrawerContext
  onSubmit: (form) => void
  initialValues: TpSplitForm
  title: string
  desc: string
}) => {
  return (
    <>
      <Drawer
        isDrawerOpen={drawer.isDrawerOpen}
        handleClickClose={drawer.handleClickClose}
        header={title}
      >
        {drawer.isDrawerOpen && (
          <div className='max-w-[700px] mx-auto'>
            <Formik
              initialValues={initialValues}
              onSubmit={form => {
                onSubmit(form)
                drawer.handleClickClose()
              }}
              validationSchema={validationSchema}
            >
              {({ values }) => (
                <Form>
                  <FieldArray
                    name='tpSplitField'
                    render={() => (
                      <>
                        {values.tpSplitField?.map((coverSpread, i) => (
                          <div key={i} className='flex items-center py-[12px]'>
                            <div className='flex-1'>{`${ordinalNumbers(
                              i + 1
                            )} ${desc}`}</div>
                            <InputField
                              {...(bot && { disabled: i < bot.coverRound })}
                              type='number'
                              name={`tpSplitField.${i}`}
                              className='w-[160px]'
                              showErrorMessage={false}
                              rightIcon={
                                <span className='text-sm font-medium'>%</span>
                              }
                            />
                            {/* <div className='flex-1 flex justify-end'>
                              <ToggleSwitchField
                                name={`coverSpreads.${i}.isTrends`}
                              />
                            </div> */}
                          </div>
                        ))}
                      </>
                    )}
                  />
                  <PrimaryButton
                    type='submit'
                    className='w-full mt-[20px] rounded-[18px]'
                  >
                    Confirm
                  </PrimaryButton>
                </Form>
              )}
            </Formik>
          </div>
        )}
      </Drawer>
    </>
  )
}

export default TpSplitDrawer
