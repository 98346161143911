import { Outlet } from 'react-router-dom'
import { AppLayoutWithNoGuard } from '@components/AppLayout/AppLayout'
import PrivacyPage from '@features/policy/pages/PrivacyPage/PrivacyPage'
import { PolicyRoutesPath } from '@config/Router'
import TermsOfServicePage from '@features/policy/pages/TermsOfServicePage/TermsOfServicePage'

export const PolicyRoutes = {
  path: '',
  element: <Outlet />,
  children: [
    {
      path: PolicyRoutesPath.termsOfService.path,
      element: (
        <AppLayoutWithNoGuard title='Terms of Service'>
          <TermsOfServicePage />
        </AppLayoutWithNoGuard>
      ),
    },
    {
      path: PolicyRoutesPath.privacy.path,
      element: (
        <AppLayoutWithNoGuard title='User privacy notice'>
          <PrivacyPage />
        </AppLayoutWithNoGuard>
      ),
    },
  ],
}
