import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { UsdtTransferInput } from '@models/Wallet/UsdtTransferInput'

export const usdtTransferApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    usdtTransfer: builder.mutation<
      { transactionHash: string },
      UsdtTransferInput
    >({
      query: usdtTransferInput => ({
        document: gql`
          mutation UsdtTransfer($usdtTransferInput: UsdtTransferInput!) {
            usdtTransfer(usdtTransferInput: $usdtTransferInput) {
              transactionHash
            }
          }
        `,
        variables: {
          usdtTransferInput,
        },
      }),
      transformResponse(res: { usdtTransfer: { transactionHash: string } }) {
        const { usdtTransfer } = res
        return usdtTransfer
      },
    }),
  }),
})

export const { useUsdtTransferMutation } = usdtTransferApi
