import React from 'react'
import { useTranslation } from 'react-i18next'
import errorCaseData from '@constant/modal/errorCase'
import { ErrorCaseModalProps } from './interface'

const withErrorCaseModal = (Component: React.FC<ErrorCaseModalProps>) => {
  const Hoc = () => {
    const {
      t,
      i18n: { changeLanguage, language },
    } = useTranslation()

    const handleChangeLanguage = (newLang: 'en' | 'th') => {
      if (newLang !== language) {
        changeLanguage(newLang)
      }
    }

    const errorCaseArray = errorCaseData(t)

    const newProps: ErrorCaseModalProps = {
      errorCaseArray,
      language,
      handleChangeLanguage,
      t,
    }

    return <Component {...newProps} />
  }

  return Hoc
}
export default withErrorCaseModal
