import {
  CycleRoundIcon,
  InfinityRoundIcon,
  OneCircleIcon,
} from '@components/Icons/Icons'

const CycleTag = ({
  cycleSetting,
}: {
  cycleSetting: 'cycle' | 'single' | 'infinity'
}) => {
  const icon =
    cycleSetting === 'cycle' ? (
      <CycleRoundIcon height='12' width='12' className='text-secondary' />
    ) : cycleSetting === 'infinity' ? (
      <InfinityRoundIcon height='12' width='12' className='text-secondary' />
    ) : (
      <OneCircleIcon height='12' width='12' className='text-secondary' />
    )
  return (
    <div className='h-[20px] px-2 flex justify-center items-center border-1 border-secondary'>
      {icon}
    </div>
  )
}

export default CycleTag
