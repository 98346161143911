import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useWalletBalanceQuery } from '@client/exchangeClient/collections/WalletBalanceClientAction'
import { usePayToActivateAccountMutation } from '@client/exchangeClient/collections/PayToActivateAccountClientAction'
import Loading from '@components/utils/Loading'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { WalletRoutesPath } from '@config/Router'
import { ActivatePageProps } from './interface'

const withActivatePage = (Component: React.FC<ActivatePageProps>) => {
  function WithActivatePage() {
    const [isPaidLoading, setIsPaidLoading] = useState<boolean>(false)
    const navigate = useNavigate()
    const { data: profile, refetch: refetchProfile } = useProfileQuery({})

    const {
      data: walletBalance,
      refetch,
      isFetching,
    } = useWalletBalanceQuery(undefined, { refetchOnMountOrArgChange: true })

    const [payToActivateMutate, { isLoading: isActivateLoading }] =
      usePayToActivateAccountMutation()

    const refetchWalletBalance = () => {
      refetch()
    }

    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>มั่นใจจ่ายเงินค่าธรรมเนียม</p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleActivateAccount = async () => {
      openModal({
        onConfirm: async () => {
          if ((walletBalance?.usdt || 0) >= 25) {
            try {
              setIsPaidLoading(true)
              const res = await payToActivateMutate().unwrap()
              toast.success(`Activated!`)
              setIsPaidLoading(false)
              refetchProfile()
              refetchWalletBalance()
            } catch (e) {}
          }
        },
      })
    }

    const onHandleDepositWallet = () => {
      navigate(WalletRoutesPath.deposit.absolutePath)
    }

    if (profile) {
      const newProps = {
        profile,
        isActivateLoading,
        refetchWalletBalance,
        handleActivateAccount,
        isPaidLoading,
        isFetching,
        usdtBalance: walletBalance?.usdt || 0,
        onHandleDepositWallet,
      }
      return <Component {...newProps} />
    }
    return (
      <div className='min-h-[500px] flex flex-col justify-center'>
        <Loading text='Loading' />
      </div>
    )
  }

  return WithActivatePage
}

export default withActivatePage
