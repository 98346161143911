import { botApi } from '@client/client'
import { gql } from 'graphql-request'
import { EasyTradeOwner } from '@models/Bot/EasyTradeOwner'

export const easyTradeOwnersApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    easyTradeOwners: builder.query<EasyTradeOwner[], void>({
      query: () => ({
        operationName: 'EasyTradeOwners',
        document: gql`
          query EasyTradeOwners {
            easyTradeOwners {
              id
              name
              description
              logoImgPath
            }
          }
        `,
      }),
      transformResponse(res: { easyTradeOwners: EasyTradeOwner[] }) {
        const { easyTradeOwners } = res
        return easyTradeOwners
      },
      providesTags: ['EasyTradeOwners'],
    }),
  }),
})

export const { useEasyTradeOwnersQuery } = easyTradeOwnersApi
