import { ProhibitIcon } from '@components/Icons/Icons'
import cn from 'classnames'
import withStopCoveringButton from '@features/bot/pages/BotPage/components/StopCoveringButton/withStopCoveringButton'
import { StopCoveringButtonProps } from '@features/bot/pages/BotPage/components/StopCoveringButton/interface'

const StopCoveringButton = ({
  handleOnStopCovering,
  isStopCovering,
  isDisable,
}: StopCoveringButtonProps) => {
  return (
    <div
      onClick={handleOnStopCovering}
      className={cn(
        `flex flex-col flex-shrink-0 items-center justify-center h-[84px] w-[80px] border-1 py-[10px] rounded-[10px]`,
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer',
        isStopCovering && !isDisable ? 'border-primary' : 'border-gray-300'
      )}
    >
      <ProhibitIcon
        height='40'
        width='40'
        className={cn(
          isDisable && 'text-gray-300',
          isStopCovering && !isDisable && 'text-primary',
          !isStopCovering && !isDisable && 'text-white'
        )}
      />
      <span
        className={cn(
          `text-[10px] `,
          isDisable && 'text-gray-300',
          isStopCovering && 'text-primary'
        )}
      >
        Stop cover
      </span>
    </div>
  )
}

const WrappedComponent = withStopCoveringButton(StopCoveringButton)
export default WrappedComponent
