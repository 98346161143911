import React, { useEffect, useMemo, useState } from 'react'
import { KhotcoinTopUpPageProps } from '@features/wallet/pages/KhotcoinTopUpPage/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { toast } from 'react-toastify'
import { useKhotcoinPackagesQuery } from '@client/mainClient/collections/KhotcoinPackagesClinetAction'
import { KhotcoinPackage } from '@models/Khotcoin/KhotcoinPackage'
import Loading from '@components/utils/Loading'
import { useBuyKhotcoinPackageMutation } from '@client/mainClient/collections/BuyKhotcoinPackageClientAction'
import { useLazyWalletBalanceQuery } from '@client/exchangeClient/collections/WalletBalanceClientAction'
import { exchangeApi, mainApi } from '@client/client'
import { useAppDispatch } from '../../../../store'

const loadingTopUpToastID = 'LOADING_TOP_UP_TOAST_ID'
const withKhotcoinTopUpPage = (Component: React.FC<KhotcoinTopUpPageProps>) => {
  const Hoc = () => {
    const dispatch = useAppDispatch()
    const [selectedKhotcoin, setSelectedKhotcoin] =
      useState<KhotcoinPackage | null>(null)
    const [getWalletBalance] = useLazyWalletBalanceQuery()
    const { data, isLoading: isLoadingPackages } = useKhotcoinPackagesQuery()
    const [topUpMutation, { isLoading: isLoadingTopUp }] =
      useBuyKhotcoinPackageMutation()

    const sortedKhotcoinPackage = useMemo(() => {
      if (data) {
        const clone = [...data]
        clone.sort((a, b) => a.id - b.id)
        return clone
      }
      return undefined
    }, [data])

    useEffect(() => {
      if (isLoadingTopUp) {
        toast.info('Payment is in progress...', {
          toastId: loadingTopUpToastID,
        })
      }
    }, [isLoadingTopUp])

    const { openModal } = useModal({
      component: ({ onConfirm, khotcoin, remainUSDT }) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <div className='mt-[7px] text-sm'>
                <div className='text-sm'>
                  <div className='mb-3'>
                    <span>
                      Are you sure to top up
                      <br />
                    </span>

                    <span className='font-bold'>{khotcoin} Khotcoin?</span>
                  </div>
                  <div className='text-xs text-gray-60'>
                    Remainging: {remainUSDT} USDT
                  </div>
                </div>
              </div>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleSelectKhotcoinOption = coinPackage => {
      setSelectedKhotcoin(coinPackage)
    }
    const handleOnSubmit = () => {
      getWalletBalance(undefined, false)
        .unwrap()
        .then(balance => {
          if ((balance?.usdt ?? 0) < (selectedKhotcoin?.usdtAmount ?? 0)) {
            toast.warning(
              `Your USDT is insufficient. (${balance?.usdt?.toLocaleString(
                undefined,
                { maximumFractionDigits: 2 }
              )} USDT)`
            )
            return
          }
          openModal({
            khotcoin: selectedKhotcoin?.khotcoinAmount?.toLocaleString(
              undefined,
              {
                maximumFractionDigits: 2,
              }
            ),
            remainUSDT: balance?.usdt?.toLocaleString(undefined, {
              maximumFractionDigits: 2,
            }),
            onConfirm: () => {
              topUpMutation({
                input: { khotcoinPackageId: selectedKhotcoin!.id },
              })
                .unwrap()
                .then(result => {
                  toast.dismiss(loadingTopUpToastID)
                  toast.success('Top up successfully')
                  dispatch(mainApi.util?.invalidateTags(['Profile']))
                  dispatch(exchangeApi.util?.invalidateTags(['WalletBalance']))
                })
                .catch(_ => {})
            },
          })
        })
    }

    if (isLoadingPackages) {
      return <Loading text='Loading...' />
    }
    const newProps: KhotcoinTopUpPageProps = {
      selectedKhotcoin,
      khotcoinPackages: sortedKhotcoinPackage,
      isLoadingTopUp,
      handleSelectKhotcoinOption,
      handleOnSubmit,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withKhotcoinTopUpPage
