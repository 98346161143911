import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import PrimaryButton from '@components/buttons/PrimaryButton'
import InputField from '@components/form/InputField/InputField'
import { QuestionIcon } from '@components/Icons/Icons'
import OutlinedButton from '@components/buttons/OutlinedButton'
import { AuthExchangeForm } from '@models/AuthExchange/AuthExchangeForm'
import { requiredMsg } from '@constant/errorMessage'
import InputMaskedField from '@components/form/InputMaskedField/InputMaskedField'
import withSettingKeyPage from './withSettingKeyPage'
import { SettingKeyPageProps } from './interface'

const validationSchema = (initialValues: AuthExchangeForm) => {
  return Yup.object().shape({
    apiKey: Yup.string().required(requiredMsg),
    secret: Yup.string().required(requiredMsg),
    newSecret: initialValues.secret
      ? Yup.string().required(requiredMsg)
      : Yup.mixed().nullable(),
  })
}

function SettingKeyPage({
  initialValues,
  onSubmitKey,
  isLoading,
  handleNavigateToTutorial,
  maskPattern,
  handleRevokeToken,
}: SettingKeyPageProps) {
  return (
    <div className='desktop:mt-2 mb-9 px-[15px] max-w-[700px] mx-auto'>
      <Formik
        initialValues={initialValues}
        onSubmit={(form, { setFieldValue }) => {
          onSubmitKey(form, true)
          setFieldValue('newSecret', '', false)
        }}
        validationSchema={validationSchema(initialValues)}
        enableReinitialize
      >
        {({ setValues }) => (
          <Form>
            <div className='flex flex-col gap-y-[16px]'>
              <InputField
                name='apiKey'
                type='text'
                label='API key'
                placeholder='input your API key'
                required
              />
              {initialValues.secret ? (
                <InputMaskedField
                  mask={maskPattern}
                  name='secret'
                  type='text'
                  label='Secret key'
                  placeholder='input your secret key'
                  disabled
                  required
                />
              ) : (
                <InputField
                  name='secret'
                  type='text'
                  label='Secret key'
                  placeholder='input your secret key'
                  required
                />
              )}

              {initialValues.secret && (
                <InputField
                  name='newSecret'
                  type='text'
                  label='New secret key'
                  placeholder='input your new secret key'
                  required
                />
              )}
            </div>
            <PrimaryButton
              type='submit'
              className='w-full mt-[40px] rounded-[6px]'
              disabled={isLoading}
            >
              Save
            </PrimaryButton>
            {initialValues.secret && (
              <OutlinedButton
                type='button'
                className='w-full h-[48px] mt-[20px] border-1 border-gray-500 rounded-[6px]'
                disabled={isLoading || initialValues.id === undefined}
                onClick={() => {
                  handleRevokeToken()
                  setValues(
                    {
                      id: initialValues.id,
                      apiKey: '',
                      secret: '',
                      newSecret: '',
                    },
                    false
                  )
                }}
              >
                Revoke API Key & Secret key
              </OutlinedButton>
            )}
          </Form>
        )}
      </Formik>
      <div
        className='flex gap-x-2 text-gray-400 justify-center cursor-pointer mt-10'
        onClick={handleNavigateToTutorial}
      >
        <QuestionIcon />
        <span className='underline'>How to connect API key</span>
      </div>
    </div>
  )
}

export default withSettingKeyPage(SettingKeyPage)
