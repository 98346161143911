import React from 'react'
import { useContractualAssetModelQuery } from '@client/botClient/collections/ContractualAssetModelClientAction'
import { usePositionBalancesQuery } from '@client/exchangeClient/collections/PositionBalanceClientAction'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

import { useModal } from '@components/Modal/context/ModalContext'
import ErrorCaseModal from '@components/Modal/components/ErrorCaseModal/ErrorCaseModal'
import { ContractDashboardPageProps } from './interface'

const withBotDashboardPage = (
  Component: React.FC<ContractDashboardPageProps>
) => {
  function WithBotDashboardPage() {
    const { botMarketType, isSpot } = useBotMarketType()
    const { data: positionBalancesData, isLoading: isPositionBalancesLoading } =
      usePositionBalancesQuery({ botMarketType })
    const { data, refetch: refetchContractualAsset } =
      useContractualAssetModelQuery(
        { botMarketType },
        {
          refetchOnMountOrArgChange: true,
        }
      )

    const { openModal: openErrorCaseModal } = useModal({
      component: () => <ErrorCaseModal />,
    })

    const newProps = {
      botMarketType,
      walletUsdtBalance: Number(
        (isSpot
          ? positionBalancesData?.walletBalance
          : positionBalancesData?.crossWalletBalance) ?? 0
      ),
      assetValue: data?.assetValue || 0,
      refetchContractualAsset,
      openErrorCaseModal,
    }
    return <Component {...newProps} />
  }

  return WithBotDashboardPage
}

export default withBotDashboardPage
