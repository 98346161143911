import { LuxonUnitType } from '@interfaces/LuxonUnitType'
import { ProfitGroupBy } from '@models/Profit/ProfitGroupBy'

import React, { useEffect, useMemo, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { usePagination } from '@hooks/usePagination'
import * as luxon from 'luxon'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { ProfitPageProps } from './interface'

const withProfitPage = (Component: React.FC<ProfitPageProps>) => {
  const Hoc = () => {
    const [params, setParams] = useSearchParams()
    const { page, pageChange } = usePagination()
    const [sortDate, setSortDate] = useState<LuxonUnitType>('day')
    const maxDate = useMemo(() => new Date(), [])
    const [groupBy, setGroupBy] = useState<ProfitGroupBy>(
      params.get('by')
        ? ProfitGroupBy[params.get('by')!.toUpperCase()]
        : ProfitGroupBy.COIN
    )
    const [botMarketType, setBotMarketType] = useState<BotMarketType>(
      params.get('type')
        ? BotMarketType[params.get('type')!.toUpperCase()]
        : BotMarketType.FUTURE
    )
    const [startDate, setStartDate] = useState<luxon.DateTime>(
      luxon.DateTime.now().startOf('day')
    )
    const [endDate, setEndDate] = useState<luxon.DateTime | null>(
      luxon.DateTime.now().endOf('day')
    )

    useEffect(() => {
      const paramGroupBy = params.get('by')
        ? ProfitGroupBy[params.get('by')!.toUpperCase()]
        : undefined

      const paramsType = params.get('type')
        ? BotMarketType[params.get('type')!.toUpperCase()]
        : undefined
      if (!paramGroupBy || !paramsType) {
        // if param not in ProfitGroupBy enum
        // reset to default
        setParams(
          `?by=${paramGroupBy ?? ProfitGroupBy.COIN}&type=${
            paramsType ?? BotMarketType.FUTURE
          }`,
          { replace: true }
        )
        setGroupBy(paramGroupBy ?? ProfitGroupBy.COIN)
        setBotMarketType(paramsType ?? BotMarketType.FUTURE)
      }
    }, [params, setParams])

    const handleGroupByChange = (value: string | number) => {
      const newGroupBy = ProfitGroupBy[value]
      setGroupBy(newGroupBy)
      setParams(`?by=${newGroupBy}&type=${botMarketType}`, { replace: true })
    }
    const handleSortDateChange = (value: string) => {
      setSortDate(value as LuxonUnitType)
      pageChange(1)
    }

    const handleOnSelectDates = (
      date: [Date | null, Date | null] | /* for selectsRange */ null
    ) => {
      if (date) {
        const [start, end] = date
        if (start) setStartDate(luxon.DateTime.fromJSDate(start))
        if (end) {
          setEndDate(luxon.DateTime.fromJSDate(end).endOf('day'))
        } else {
          setEndDate(null)
        }
      }
    }
    const handleOnBotMarketTypeChange = value => {
      const newMarketType = BotMarketType[value]
      setBotMarketType(newMarketType)
      setParams(`?by=${groupBy}&type=${newMarketType}`, { replace: true })
    }

    const newProps = {
      groupBy,
      sortDate,
      page,
      startDate,
      endDate,
      botMarketType,
      maxDate,
      handleGroupByChange,
      handleSortDateChange,
      pageChange,
      handleOnSelectDates,
      handleOnBotMarketTypeChange,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withProfitPage
