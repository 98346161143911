import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useResetPasswordMutation } from '@client/mainClient/collections/ResetPasswordClientAction'
import { ResetPasswordForm } from '@models/User/ResetPasswordForm'
import { AuthenticationRoutesPath } from '@config/Router'
import Loading from '@components/utils/Loading'
import { useSendOTPVerifyMutation } from '@client/mainClient/collections/SendOTPVerifyClientAction'
import {
  ForgotPasswordForm,
  ForgotPasswordPageProps,
  ForgotPasswordStep,
} from './interface'

const withForgotPasswordPage = (
  Component: React.FC<ForgotPasswordPageProps>
) => {
  function WithForgotPasswordPage() {
    const [activeStep, setActiveStep] = useState<ForgotPasswordStep>('forgot')
    const [forgotPasswordForm, setForgotPasswordForm] =
      useState<ForgotPasswordForm>({
        phoneNumber: '',
        countryCode: 0,
      })
    const [verifyToken, setVerifyToken] = useState('')
    const [resetPasswordMutate, { isLoading: isResetPasswordLoading }] =
      useResetPasswordMutation()
    const [sendOtpMutate, { isLoading: isSendOtpLoading }] =
      useSendOTPVerifyMutation()
    const navagate = useNavigate()

    const handleForgotPassword = async (form: ForgotPasswordForm) => {
      setForgotPasswordForm(form)
      sendOtpMutate({
        phoneNumber: form.phoneNumber.slice(form.countryCode.toString().length),
        phoneNumberCountryCode: form.countryCode,
      })
        .unwrap()
        .then(() => {
          setActiveStep('otp')
        })
        .catch(() => {})
    }

    const changePhoneNumberStep = () => {
      setActiveStep('forgot')
    }

    const resetPasswordStep = (resp: { verifiedToken: string }) => {
      setVerifyToken(resp.verifiedToken)
      setActiveStep('resetPassword')
    }

    const handleResetPassword = (form: ResetPasswordForm) => {
      resetPasswordMutate({
        id: forgotPasswordForm.userId!,
        password: form.password,
        verifyToken,
      })
        .unwrap()
        .then(() => {
          toast.success('Reset password success')
          navagate(AuthenticationRoutesPath.login.absolutePath)
        })
        .catch(() => {})
    }

    if (!isResetPasswordLoading && !isSendOtpLoading) {
      const newProps = {
        handleForgotPassword,
        activeStep,
        forgotPasswordForm,
        changePhoneNumberStep,
        resetPasswordStep,
        handleResetPassword,
      }
      return <Component {...newProps} />
    }
    return (
      <div className='min-h-[500px] flex flex-col justify-center'>
        <Loading text='Loading' />
      </div>
    )
  }

  return WithForgotPasswordPage
}

export default withForgotPasswordPage
