import { FC, SVGProps } from 'react'
import { IconProps } from '@components/Icons/interface'
import { ReactComponent as Account } from './icons/account.svg'
import { ReactComponent as Time } from './icons/time.svg'
import { ReactComponent as Check } from './icons/check.svg'
import { ReactComponent as Info } from './icons/info.svg'
import { ReactComponent as Plug } from './icons/plug.svg'
import { ReactComponent as CalendarSolid } from './icons/calendar-solid.svg'
import { ReactComponent as Delete } from './icons/delete.svg'
import { ReactComponent as Add } from './icons/add.svg'
import { ReactComponent as Dropdown } from './icons/dropdown.svg'
import { ReactComponent as ClockOutlined } from './icons/clock-outlined.svg'
import { ReactComponent as Warning } from './icons/warning.svg'
import { ReactComponent as Next } from './icons/next.svg'
import { ReactComponent as Prev } from './icons/prev.svg'
import { ReactComponent as CaretDown } from './icons/caretDown.svg'
import { ReactComponent as CaretUp } from './icons/caretUp.svg'
import { ReactComponent as RightArrow } from './icons/right_arrow.svg'
import { ReactComponent as Empty } from './icons/empty.svg'
import { ReactComponent as Home } from './icons/home.svg'
import { ReactComponent as HomeActive } from './icons/home-active.svg'
import { ReactComponent as Contract } from './icons/contract.svg'
import { ReactComponent as ContractActive } from './icons/contract-active.svg'
import { ReactComponent as Profile } from './icons/profile.svg'
import { ReactComponent as Wallet } from './icons/wallet.svg'
import { ReactComponent as WalletActive } from './icons/wallet-active.svg'
import { ReactComponent as Logout } from './icons/logout.svg'
import { ReactComponent as Transaction } from './icons/transaction.svg'
import { ReactComponent as Coin } from './icons/coin.svg'
import { ReactComponent as ArrowDown } from './icons/arrowDown.svg'
import { ReactComponent as CircleCheck } from './icons/circle-check.svg'
import { ReactComponent as Exchange } from './icons/exchange.svg'
import { ReactComponent as NoData } from './icons/no-data.svg'
import { ReactComponent as ArrowRight } from './icons/arrow-right.svg'
import { ReactComponent as Cross } from './icons/cross.svg'
import { ReactComponent as Success } from './icons/success.svg'
import { ReactComponent as Bot } from './icons/bot.svg'
import { ReactComponent as Chart } from './icons/chart.svg'
import { ReactComponent as CreateBot } from './icons/create-bot.svg'
import { ReactComponent as Customize } from './icons/customize.svg'
import { ReactComponent as ArrowUp } from './icons/arrow-up.svg'
import { ReactComponent as Reset } from './icons/reset.svg'
import { ReactComponent as Copy } from './icons/copy.svg'
import { ReactComponent as Avatar } from './icons/avatar.svg'
import { ReactComponent as EmptyWallet } from './icons/empty-wallet.svg'
import { ReactComponent as Check2 } from './icons/check2.svg'
import { ReactComponent as Info2 } from './icons/info2.svg'
import { ReactComponent as CheckCircleOutlined } from './icons/check-circle-outlined.svg'
import { ReactComponent as Search } from './icons/search.svg'
import { ReactComponent as Filter } from './icons/filter.svg'
import { ReactComponent as Filtered } from './icons/filtered.svg'
import { ReactComponent as ArrowLeft } from './icons/arrowLeft.svg'
import { ReactComponent as TradeCopy } from './icons/trade-copy.svg'
import { ReactComponent as TradeCopyActive } from './icons/trade-copy-active.svg'
import { ReactComponent as UserCircle } from './icons/user-circle.svg'
import { ReactComponent as CircleOutlineCheck } from './icons/circle-outline-check.svg'
import { ReactComponent as EyeSlash } from './icons/eye-slash.svg'
import { ReactComponent as Crown } from './icons/crown.svg'
import { ReactComponent as ArrowLightRight } from './icons/arrow-light-right.svg'
import { ReactComponent as Triangle } from './icons/triangle.svg'
import { ReactComponent as ArrowUp2 } from './icons/arrowUp2.svg'
import { ReactComponent as ArrowDown2 } from './icons/arrowDown2.svg'
import { ReactComponent as Notification } from './icons/notification.svg'
import { ReactComponent as Announcement } from './icons/announcement.svg'
import { ReactComponent as WalletCircle } from './icons/wallet-circle.svg'
import { ReactComponent as ListDashes } from './icons/list-dashes.svg'
import { ReactComponent as UsersThree } from './icons/users-three.svg'
import { ReactComponent as CrownWhite } from './icons/crown-white.svg'
import { ReactComponent as Sparkle } from './icons/sparkle.svg'
import { ReactComponent as PlugConnected } from './icons/plug-connected.svg'
import { ReactComponent as Pen } from './icons/pen.svg'
import { ReactComponent as ThreeDot } from './icons/three-dot.svg'
import { ReactComponent as Bin } from './icons/bin.svg'
import { ReactComponent as CopyBySymbol } from './icons/copy-by-symbol.svg'
import { ReactComponent as CopyByPersonal } from './icons/copy-by-personal.svg'
import { ReactComponent as Camera } from './icons/camera.svg'
import { ReactComponent as Question } from './icons/question.svg'
import { ReactComponent as Back } from './icons/back.svg'
import { ReactComponent as Burger } from './icons/burger.svg'
import { ReactComponent as ShareNetwork } from './icons/share-network.svg'
import { ReactComponent as OnceCircle } from './icons/oneCircle.svg'
import { ReactComponent as PlusCircle } from './icons/plusCircle.svg'
import { ReactComponent as PenStrip } from './icons/penStrip.svg'
import { ReactComponent as XCircle } from './icons/xCircle.svg'
import { ReactComponent as UnLock } from './icons/unlock.svg'
import { ReactComponent as Calculator } from './icons/calculator.svg'
import { ReactComponent as Password } from './icons/password.svg'
import { ReactComponent as Terms } from './icons/terms.svg'
import { ReactComponent as Coupon } from './icons/coupon.svg'
import { ReactComponent as Privacy } from './icons/privacy.svg'
import { ReactComponent as Robot } from './icons/robot.svg'
import { ReactComponent as Strategy } from './icons/strategy.svg'
import { ReactComponent as User } from './icons/user.svg'
import { ReactComponent as CurrencyCircle } from './icons/currencyCircle.svg'
import { ReactComponent as CycleRound } from './icons/cycleRound.svg'
import { ReactComponent as InfinityRound } from './icons/infinityRound.svg'
import { ReactComponent as BinOutline } from './icons/bin-outline.svg'
import { ReactComponent as Lock } from './icons/lock.svg'
import { ReactComponent as Coins } from './icons/coins.svg'
import { ReactComponent as ClockCountdown } from './icons/clock-countdown.svg'
import { ReactComponent as CoinSpot } from './icons/coin-spot.svg'
import { ReactComponent as CoinSpotActive } from './icons/coin-spot-active.svg'
import { ReactComponent as Eye } from './icons/eye.svg'
import { ReactComponent as Like } from './icons/like.svg'
import { ReactComponent as TierOne } from './icons/tier-one.svg'
import { ReactComponent as TierTwo } from './icons/tier-two.svg'
import { ReactComponent as TierThree } from './icons/tier-three.svg'
import { ReactComponent as TierFour } from './icons/tier-four.svg'
import { ReactComponent as TierFive } from './icons/tier-five.svg'
import { ReactComponent as TierSix } from './icons/tier-six.svg'
import { ReactComponent as MinusCircle } from './icons/minusCircle.svg'
import { ReactComponent as PauseCircle } from './icons/pause-circle.svg'
import { ReactComponent as PlayCircle } from './icons/play-circle.svg'
import { ReactComponent as Prohibit } from './icons/prohibit.svg'
import { ReactComponent as XLight } from './icons/xLight.svg'
import { ReactComponent as Demo } from './icons/demo.svg'

const Icon = (
  SvgComponent: FC<SVGProps<SVGSVGElement>>
): React.FC<IconProps> => {
  return ({
    height = '20',
    width = '20',
    className = '',
    onClick,
    dataTestId,
  }: IconProps) => {
    const svgProps = {
      height,
      width,
      className: `stroke-current ${className}`,
      onClick,
    }
    return <SvgComponent {...svgProps} data-test-id={dataTestId} />
  }
}

export const AccountIcon = Icon(Account)
export const TimeIcon = Icon(Time)
export const CheckIcon = Icon(Check)
export const InfoIcon = Icon(Info)
export const CalendarIcon = Icon(CalendarSolid)
export const DeleteIcon = Icon(Delete)
export const AddIcon = Icon(Add)
export const DropdownIcon = Icon(Dropdown)
export const ClockOutlinedIcon = Icon(ClockOutlined)
export const WarningIcon = Icon(Warning)
export const NextIcon = Icon(Next)
export const PrevIcon = Icon(Prev)
export const CaretDownIcon = Icon(CaretDown)
export const CaretUpIcon = Icon(CaretUp)
export const EmptyIcon = Icon(Empty)
export const RightArrowIcon = Icon(RightArrow)
export const HomeIcon = Icon(Home)
export const HomeActiveIcon = Icon(HomeActive)
export const ContractIcon = Icon(Contract)
export const ContractActiveIcon = Icon(ContractActive)
export const PlugIcon = Icon(Plug)
export const ProfileIcon = Icon(Profile)
export const WalletIcon = Icon(Wallet)
export const WalletActiveIcon = Icon(WalletActive)
export const LogoutIcon = Icon(Logout)
export const TransactionIcon = Icon(Transaction)
export const CoinIcon = Icon(Coin)
export const ArrowDownIcon = Icon(ArrowDown)
export const CircleCheckIcon = Icon(CircleCheck)
export const ExchangeIcon = Icon(Exchange)
export const NoDataIcon = Icon(NoData)
export const ArrowRightIcon = Icon(ArrowRight)
export const CrossIcon = Icon(Cross)
export const SuccessIcon = Icon(Success)
export const BotIcon = Icon(Bot)
export const ChartIcon = Icon(Chart)
export const CreateBotIcon = Icon(CreateBot)
export const CustomizeIcon = Icon(Customize)
export const ArrowUpIcon = Icon(ArrowUp)
export const ResetIcon = Icon(Reset)
export const CopyIcon = Icon(Copy)
export const AvatarIcon = Icon(Avatar)
export const EmptyWalletIcon = Icon(EmptyWallet)
export const Check2Icon = Icon(Check2)
export const Info2Icon = Icon(Info2)
export const CheckCircleOutlinedIcon = Icon(CheckCircleOutlined)
export const SearchIcon = Icon(Search)
export const FilterIcon = Icon(Filter)
export const FilteredIcon = Icon(Filtered)
export const ArrowLeftIcon = Icon(ArrowLeft)
export const TradeCopyIcon = Icon(TradeCopy)
export const TradeCopyActiveIcon = Icon(TradeCopyActive)
export const UserCircleIcon = Icon(UserCircle)
export const CircleOutlineCheckIcon = Icon(CircleOutlineCheck)
export const EyeSlashIcon = Icon(EyeSlash)
export const CrownIcon = Icon(Crown)
export const ArrowLightRightIcon = Icon(ArrowLightRight)
export const TriangleIcon = Icon(Triangle)
export const ArrowUp2Icon = Icon(ArrowUp2)
export const ArrowDown2Icon = Icon(ArrowDown2)
export const NotificationIcon = Icon(Notification)
export const AnnouncementIcon = Icon(Announcement)
export const WalletCircleIcon = Icon(WalletCircle)
export const ListDashesIcon = Icon(ListDashes)
export const UsersThreeIcon = Icon(UsersThree)
export const CrownWhiteIcon = Icon(CrownWhite)
export const PlugConnectedIcon = Icon(PlugConnected)
export const SparkleIcon = Icon(Sparkle)
export const PenIcon = Icon(Pen)
export const ThreeDotIcon = Icon(ThreeDot)
export const BinIcon = Icon(Bin)
export const CopyBySymbolIcon = Icon(CopyBySymbol)
export const CopyByPersonalIcon = Icon(CopyByPersonal)
export const CameraIcon = Icon(Camera)
export const QuestionIcon = Icon(Question)
export const BackIcon = Icon(Back)
export const BurgerIcon = Icon(Burger)
export const ShareNetworkIcon = Icon(ShareNetwork)
export const OneCircleIcon = Icon(OnceCircle)
export const PlusCircleIcon = Icon(PlusCircle)
export const PenStripIcon = Icon(PenStrip)
export const XCircleIcon = Icon(XCircle)
export const UnLockIcon = Icon(UnLock)

export const CalculatorIcon = Icon(Calculator)

export const PasswordIcon = Icon(Password)

export const TermsIcon = Icon(Terms)
export const CouponIcon = Icon(Coupon)

export const PrivacyIcon = Icon(Privacy)
export const RobotIcon = Icon(Robot)
export const StrategyIcon = Icon(Strategy)
export const CurrencyCircleIcon = Icon(CurrencyCircle)
export const UserIcon = Icon(User)
export const CycleRoundIcon = Icon(CycleRound)
export const InfinityRoundIcon = Icon(InfinityRound)

export const BinOutlineIcon = Icon(BinOutline)

export const LockIcon = Icon(Lock)

export const CoinsIcon = Icon(Coins)

export const ClockCountdownIcon = Icon(ClockCountdown)

export const CoinSpotIcon = Icon(CoinSpot)
export const CoinSpotActiveIcon = Icon(CoinSpotActive)

export const EyeIcon = Icon(Eye)

export const LikeIcon = Icon(Like)

export const TierOneIcon = Icon(TierOne)
export const TierTwoIcon = Icon(TierTwo)
export const TierThreeIcon = Icon(TierThree)
export const TierFourIcon = Icon(TierFour)
export const TierFiveIcon = Icon(TierFive)
export const TierSixIcon = Icon(TierSix)

export const MinusCircleIcon = Icon(MinusCircle)
export const PauseCircleIcon = Icon(PauseCircle)
export const PlayCircleIcon = Icon(PlayCircle)

export const ProhibitIcon = Icon(Prohibit)

export const XLightIcon = Icon(XLight)
export const DemoIcon = Icon(Demo)
