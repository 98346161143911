import { Pagination } from '@models/Pagination/Pagination'
import { Expose, Type } from 'class-transformer'
import { ProfitHistoryBot } from './ProfitHistoryBot'

export class ProfitHistory {
  @Expose()
  @Type(() => ProfitHistoryBot)
  bots: ProfitHistoryBot[]

  @Expose()
  pagination: Pagination
}
