import { useState } from 'react'
import { toast } from 'react-toastify'
import { createSearchParams, useNavigate } from 'react-router-dom'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { CircleOutlineCheckIcon } from '@components/Icons/Icons'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { TradeCopyRoutesPath } from '@config/Router'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'
import { useBuyTradeCopyByBotMutation } from '@client/exchangeClient/collections/BuyTradeCopyByBot'
import { capitalizeFirstLetter } from '@libs/utils'
import TextTitleWithValue from './TextTitleWithValue'

interface SymbolCardProps {
  detail: TradeCopyByBot
  refetch: () => void
}

function SymbolCard({ detail, refetch }: SymbolCardProps) {
  const navigate = useNavigate()
  const [isLoading, setIsLoading] = useState(false)
  const [buyTradeCopyByBotMutate] = useBuyTradeCopyByBotMutation()

  const handleBuyTransaction = (result: boolean) => {
    if (!result) {
      toast.success(`Error occurred`)
      setIsLoading(false)
    } else {
      toast.success(`Successful`)
      setIsLoading(false)
      refetch()
    }
  }

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm }: ConfirmModalProps) => (
      <ConfirmModal
        message={
          <div className='flex flex-col items-center'>
            <div className='text-lg font-medium'>Confirm</div>
            <p className='text-sm'>Are you sure to buy strategy</p>
            <p className=' text-sm font-medium'>(5 Khotcoins)</p>
          </div>
        }
        onConfirm={onConfirm}
      />
    ),
  })

  return (
    <div
      className='border border-gray-300 rounded-[6px] py-[16px] px-[13px] cursor-pointer'
      onClick={() =>
        navigate({
          pathname: TradeCopyRoutesPath.tradeCopy.bySymbol.absolutePath({
            id: detail.id,
          }),
          search: `?${createSearchParams({
            name: encodeURIComponent(detail.user.name),
            description: encodeURIComponent(detail.user.strategyDescription),
            profileImg: encodeURIComponent(detail.user.profileImgPath || ''),
            strategyId: `${detail.strategyId}`,
          })}`,
        })
      }
    >
      <div className='flex items-center gap-x-[11px] border-b border-gray-300  pb-[23px]'>
        <img
          src={detail.baseCurrencyData?.imgPath}
          alt={detail.baseCurrencyData?.symbol}
          className='w-[38px] h-[38px] rounded-full'
        />
        <div className='flex-1'>
          <div className='text-sm font-bold'>{detail.symbol}</div>
          <div className='text-[10px] font-normal'>{detail.user.name}</div>
        </div>
        {detail?.isAlreadyBuy ? (
          <CircleOutlineCheckIcon />
        ) : (
          <PrimaryButton
            type='button'
            className='w-[71px] py-[7px] rounded-[4px] text-xs font-medium'
            disabled={isLoading}
            onClick={event => {
              event.stopPropagation()
              openModal({
                onConfirm: async () => {
                  setIsLoading(true)
                  try {
                    const res = await buyTradeCopyByBotMutate(
                      detail.id
                    ).unwrap()
                    handleBuyTransaction(res)
                  } catch (_) {
                    setIsLoading(false)
                  }
                },
              })
            }}
          >
            Buy
          </PrimaryButton>
        )}
      </div>
      <div className='mt-[14px] grid grid-cols-4'>
        <TextTitleWithValue
          title='TP Time'
          value={detail.endAt.setLocale('us').toFormat('hh:mm:ss a')}
        />
        <TextTitleWithValue
          title='Exchange'
          value={capitalizeFirstLetter(detail.exchange)}
        />
        <TextTitleWithValue
          title='Profit (%)'
          value={detail.profitPercentage.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        />
        <TextTitleWithValue
          title='Profit (USDT)'
          value={detail.profit.toFixed(2)}
        />
      </div>
    </div>
  )
}

export default SymbolCard
