import { BinOutlineIcon } from '@components/Icons/Icons'
import { DeleteBotButtonProps } from '@features/bot/pages/BotPage/components/DeleteBotButton/interface'
import withDeleteBotButton from './withDeleteBotButton'

const DeleteBotButton = ({
  handleDeletePosition,
  isShowOnlyIcon,
}: DeleteBotButtonProps) => {
  return (
    <>
      {!isShowOnlyIcon && (
        <div
          className='flex flex-col items-center justify-center h-[84px] w-[80px] border-1 py-[10px] px-[20px] rounded-[10px] cursor-pointer border-gray-300 '
          onClick={handleDeletePosition}
        >
          <div className='py-1'>
            <BinOutlineIcon className='w-[32px] h-[34px] text-white' />
          </div>

          <span className='text-[10px] text-white'>Delete</span>
        </div>
      )}

      {isShowOnlyIcon && (
        <div className='' onClick={handleDeletePosition}>
          <BinOutlineIcon className='w-[24px] h-[26px] mb-[12px] text-white' />
        </div>
      )}
    </>
  )
}

const WrappedComponent = withDeleteBotButton(DeleteBotButton)
export default WrappedComponent
