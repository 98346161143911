import ProfileImage from '@components/ProfileImage/ProfileImage'
import RankCircleNumber from '../../../../../components/RankCircleNumber/RankCircleNumber'

export interface RankProfileImageProps {
  rank: number
  profileImagePath?: string
  profileImageClassName?: string
  rankCircleClassName?: string
  rankCircleTextClassName?: string
  rankPositionClassName?: string
}

const defaultRankProfileColor = {
  imageBorder: 'bg-gradient-to-r from-[#5680E4] to-[#A1BBFA]',
}
const mappedRankProfileColor: Record<number, { imageBorder: string }> = {
  1: {
    imageBorder: 'bg-gold-tier',
  },
  2: {
    imageBorder: 'bg-silver-tier',
  },
  3: {
    imageBorder: 'bg-bronze-tier',
  },
}

function RankProfileImage({
  rank,
  profileImagePath,
  profileImageClassName = 'w-[40px] h-[40px]',
  rankCircleClassName,
  rankCircleTextClassName,
  rankPositionClassName,
}: RankProfileImageProps) {
  const profileColor = mappedRankProfileColor[rank] ?? defaultRankProfileColor
  return (
    <ProfileImage
      path={profileImagePath ?? ''}
      className={`${profileImageClassName} ${profileColor.imageBorder} rounded-full relative`}
      avatarClassName='text-gray-secondary p-[2px] rounded-full absolute'
      imageClassName='text-gray-secondary p-[2px] rounded-full absolute'
    >
      <div className={`absolute ${rankPositionClassName}`}>
        <RankCircleNumber
          rank={rank}
          className={rankCircleClassName}
          textClassName={rankCircleTextClassName}
        />
      </div>
    </ProfileImage>
  )
}

export default RankProfileImage
