import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Otp from '@features/authentication/components/Otp/Otp'
import PrimaryButton from '@components/buttons/PrimaryButton'
import PhoneNumberInputField from '@components/form/PhoneNumberInputField/PhoneNumberInputField'
import InputField from '@components/form/InputField/InputField'
import { Info2Icon } from '@components/Icons/Icons'
import { useNavigate } from 'react-router-dom'
import { AuthenticationRoutesPath } from '@config/Router'
import { useResponsive } from '@hooks/useResponsive'
import withForgotPasswordPage from './withForgotPasswordPage'
import { ForgotPasswordPageProps } from './interface'

const forgotPasswordSchema = Yup.object()
  .shape({
    phoneNumber: Yup.string().required(),
  })
  .test(function (value) {
    return (
      !!value.userId ||
      // eslint-disable-next-line react/no-this-in-sfc
      this.createError({
        path: 'phoneNumber',
        message: 'phoneNumber does not exist',
      })
    )
  })

const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .min(8, 'Password must be include minimum 8 digits with A-Z, a-z, 0-9')
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      'Password must be include minimum 8 digits with A-Z, a-z, 0-9'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password does not match')
    .required(),
})

function ForgotPasswordPage({
  handleForgotPassword,
  activeStep,
  forgotPasswordForm,
  changePhoneNumberStep,
  resetPasswordStep,
  handleResetPassword,
}: ForgotPasswordPageProps) {
  const navigate = useNavigate()
  const { isMobile, isTablet } = useResponsive()
  const isDesktop = !(isMobile || isTablet)
  return (
    <div className=''>
      {activeStep === 'forgot' && (
        <Formik
          initialValues={forgotPasswordForm}
          onSubmit={handleForgotPassword}
          validationSchema={forgotPasswordSchema}
          enableReinitialize
        >
          {() => (
            <Form className='flex flex-col justify-center m-auto h-4/5-screen'>
              {!isDesktop && (
                <div className='flex justify-center h-[80px] mobile:mb-[30px] mb-[50px]'>
                  <img
                    src='/logoHorizontal.png'
                    alt='logo-horizontal'
                    className='h-[80px] w-fit '
                  />
                </div>
              )}
              <div className='text-white text-4xl font-bold mb-[20px]'>
                Forgot password
              </div>
              <PhoneNumberInputField />
              <PrimaryButton
                type='submit'
                className='w-full rounded-[18px] py-[8px] mt-[20px]'
              >
                <p className='text-lg font-bold'>Next</p>
              </PrimaryButton>
              <div className='mt-[16px]'>
                <span>
                  {`Back to `}
                  <span
                    className='ml-[4px] text-primary underline cursor-pointer'
                    onClick={() =>
                      navigate(AuthenticationRoutesPath.login.absolutePath)
                    }
                  >
                    Sign in
                  </span>
                </span>
              </div>
            </Form>
          )}
        </Formik>
      )}
      {activeStep === 'otp' && (
        <Otp
          phoneNumber={forgotPasswordForm.phoneNumber.slice(
            forgotPasswordForm.countryCode.toString().length
          )}
          phoneNumberCountryCode={forgotPasswordForm.countryCode}
          changePhoneNumberStep={changePhoneNumberStep}
          onSuccess={resetPasswordStep}
          isShowLogo={!isDesktop}
        />
      )}
      {activeStep === 'resetPassword' && (
        <Formik
          initialValues={{ password: '', confirmPassword: '' }}
          onSubmit={handleResetPassword}
          validationSchema={resetPasswordSchema}
        >
          {() => (
            <Form className='flex flex-col justify-center m-auto h-4/5-screen'>
              {!isDesktop && (
                <div className='flex justify-center h-[80px] mobile:mb-[30px] mb-[50px]'>
                  <img
                    src='/logoHorizontal.png'
                    alt='logo-horizontal'
                    className='h-[80px] w-fit '
                  />
                </div>
              )}
              <div className='text-white text-4xl font-bold mb-[20px]'>
                Change password
              </div>
              <div className='flex flex-col space-y-[20px]'>
                <div>
                  <InputField
                    name='password'
                    label='Password'
                    type='password'
                    placeholder='Password'
                    required
                  />
                  <div className='flex space-x-[6px] text-[10px] text-gray-600 mt-[4px]'>
                    <Info2Icon className='text-gray-600 h-[16px] w-[16px]' />
                    <span>Minimum 8 digits with A-Z, a-z, 0-9</span>
                  </div>
                </div>
                <div>
                  <InputField
                    name='confirmPassword'
                    label='Confirm password'
                    type='password'
                    placeholder='Confirm your password'
                    required
                  />
                  <div className='flex space-x-[6px] text-[10px] text-gray-600 mt-[4px]'>
                    <Info2Icon className='text-gray-600 h-[16px] w-[16px]' />
                    <span>Minimum 8 digits with A-Z, a-z, 0-9</span>
                  </div>
                </div>
              </div>
              <PrimaryButton
                type='submit'
                className='w-full rounded-[6px] h-[48px] mt-[50px]'
              >
                <p className='text-lg font-bold'>Confirm</p>
              </PrimaryButton>
            </Form>
          )}
        </Formik>
      )}
    </div>
  )
}

export default withForgotPasswordPage(ForgotPasswordPage)
