import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const buyKhotcoinPackageApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    buyKhotcoinPackage: builder.mutation<
      { id: number },
      { input: { khotcoinPackageId: number } }
    >({
      query: ({ input }) => ({
        operationName: 'BuyKhotcoinPackage',
        document: gql`
          mutation BuyKhotcoinPackage($input: BuyKhotcoinPackageInput!) {
            buyKhotcoinPackage(buyKhotcoinPackageInput: $input) {
              id
            }
          }
        `,
        variables: {
          input,
        },
      }),
    }),
  }),
})

export const { useBuyKhotcoinPackageMutation } = buyKhotcoinPackageApi
