import { exchangeApi } from '@client/client'
import { gql } from 'graphql-request'
import { botWsService } from '@client/botClient/ws/client'
import { PositionBalance } from '@models/ExchangeBalance/PositionBalance'
import { EXCHANGE_WS_API_URL } from '@config/config'

export const PositionBalanceApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    positionBalances: builder.query<PositionBalance, { botMarketType }>({
      query: ({ botMarketType }) => ({
        document: gql`
          query PositionBalances($botMarketType: BotMarketType!) {
            positionBalances(botMarketType: $botMarketType) {
              asset
              walletBalance
              crossWalletBalance
              balanceChangeExceptPnl
            }
          }
        `,
        variables: { botMarketType },
      }),
      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved }
      ) {
        // wait for the initial query to resolve before proceeding
        await cacheDataLoaded

        // create a websocket connection when the cache subscription starts
        const ws = botWsService<{
          subscribeToBalances: PositionBalance
        }>({
          url: EXCHANGE_WS_API_URL,
          queryVariables: { botMarketType: arg.botMarketType },
          queryDocument: `subscription SubscribeToBalances($botMarketType: BotMarketType!){
                                  subscribeToBalances(botMarketType: $botMarketType) {
                                        asset
                                        walletBalance
                                        crossWalletBalance
                                        balanceChangeExceptPnl
                                  }
                                }
                              `,
          onError: () => {
            // console.log('error from socket : ', e)
            // toast.error(`bot ${arg.botId} socket got error: ${e}`)
            // ws.terminate()
          },
          onNext: bot => {
            updateCachedData(() => {
              return bot.data!.subscribeToBalances
            })
          },
          onComplete: () => {},
        })
        await cacheEntryRemoved
        ws.terminate()
      },
      transformResponse(res: { positionBalances: PositionBalance }) {
        const { positionBalances } = res
        return positionBalances
      },
      providesTags: ['WalletBalance'],
    }),
  }),
})

export const {
  usePositionBalancesQuery,
  useLazyPositionBalancesQuery,
  usePrefetch: usePositionBalancesPrefetch,
} = PositionBalanceApi
