import { DrawerAccepctProps, DrawerProps } from './interface'

const withDrawer = (Component: React.FC<DrawerProps>) => {
  function HOC({
    handleClickClose,
    isDrawerOpen,
    header,
    children,
  }: DrawerAccepctProps) {
    const handleClickOutsideDrawer = () => {
      handleClickClose()
    }

    const newProps = {
      handleClickClose,
      handleClickOutsideDrawer,
      isDrawerOpen,
      header,
      children,
    }
    return <Component {...newProps} />
  }
  return HOC
}
export { withDrawer }
