import Privacy from '@components/Privacy/Privacy'

const PrivacyPage = props => {
  return (
    <div className='desktop:mt-2 mb-9 px-[15px] max-w-[700px] mx-auto '>
      <Privacy />
    </div>
  )
}
export default PrivacyPage
