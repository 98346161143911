import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { DepositStepEnum } from '@interfaces/DepositStepEnum'
import { useDebounce } from '@hooks/useDebounce'
import { APP_MAIN_ORIGIN_API_URL } from '@config/config'

import { useTopupSessionQuery } from '@client/mainClient/collections/GetTopupSession'
import { useCreateTopupSessionMutation } from '@client/mainClient/collections/CreateTopupSession'

import { mainApi } from '@client/client'

import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useModal } from '@components/Modal/context/ModalContext'
import { useAppDispatch } from '../../store'

import { DepositAmountProps } from './interface'

import DepositAnnouncementModal from './component/DepositAnnouncementModal/DepositAnnouncementModal'

const wthDepositAmount = (Component: React.FC<DepositAmountProps>) => {
  const Hoc = () => {
    const [depositAmountForm, setDepositAmountForm] = useState<{
      amount: number
    }>({
      amount: 10,
    })

    const [currentStep, setCurrentStep] = useState(DepositStepEnum.INPUT_AMOUNT)
    const [isShowModal, setIsShowModal] = useState(false)

    const { data: profile } = useProfileQuery({})

    const dispatch = useAppDispatch()

    const { debounce } = useDebounce()

    const { data, isLoading, isError, error } = useTopupSessionQuery()
    const [
      mutate,
      {
        isLoading: isCreateMutateLoading,
        data: mutateResponseData,
        error: mutateError,
      },
    ] = useCreateTopupSessionMutation()

    const { openModal: openAnnouncementModal } = useModal({
      component: () => <DepositAnnouncementModal />,
      // modalClassName: 'm-2 lg:m-5 max-w-[700px]',
    })

    useEffect(() => {
      if (currentStep === DepositStepEnum.SEND_USDT && !isShowModal) {
        openAnnouncementModal()
        setIsShowModal(true)
      } else if (currentStep === DepositStepEnum.INPUT_AMOUNT && isShowModal) {
        setIsShowModal(false)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentStep])

    useEffect(() => {
      if (data) {
        setCurrentStep(DepositStepEnum.SEND_USDT)
      }
    }, [data])

    useEffect(() => {
      if (mutateResponseData) {
        dispatch(mainApi.util.invalidateTags([{ type: 'TopupSession' }]))
      }
    }, [dispatch, mutateResponseData])

    useEffect(() => {
      if (data) {
        setCurrentStep(DepositStepEnum.SEND_USDT)
      }
      const eventSource = createEventSource()
      return () => {
        eventSource?.close()
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [profile])

    const timeRemaining = useMemo(() => {
      if (data) {
        const diff = data.expiredAt.diffNow().as('seconds')
        return diff
      }
      return 3600
    }, [data])

    const handleDepositFormSubmit = (form: { amount: number }) => {
      setDepositAmountForm(form)
      setCurrentStep(DepositStepEnum.SEND_USDT)
      mutate({ amount: form.amount }).unwrap()
    }

    const handleStep = (step: number) => {
      setCurrentStep(step)
    }

    const countingTime = ({ remainingTime }) => {
      const minutes = Math.floor(remainingTime / 60)
      const seconds = `${remainingTime % 60}`
      if (+seconds < 10) {
        return `${minutes}:0${seconds}`
      }
      return `${minutes}:${seconds}`
    }

    const handleCopy = (copyStr: string, toastMsg: string) => {
      navigator.clipboard.writeText(copyStr).then(() => {
        toast.info(toastMsg)
      })
    }

    const onHandleExpireTime = () => {
      dispatch(mainApi.util.invalidateTags([{ type: 'TopupSession' }]))
      setCurrentStep(DepositStepEnum.INPUT_AMOUNT)
    }

    const createEventSource = () => {
      if (profile) {
        const userId = profile && profile.id
        const eventSource = new EventSource(
          `${APP_MAIN_ORIGIN_API_URL}/subscribe/topup/${userId}`
        )
        eventSource.onmessage = message => {
          const response: any = JSON.parse(message.data)
          if (response) {
            toast.success('You send USDT to USDT wallet Successfully')
            dispatch(mainApi.util.invalidateTags([{ type: 'TopupSession' }]))
            dispatch(mainApi.util.invalidateTags([{ type: 'Wallets' }]))
            setCurrentStep(DepositStepEnum.INPUT_AMOUNT)
          }
        }
        eventSource.onerror = () => {
          eventSource.close()
          debounce(() => {
            createEventSource()
          }, 1000)
        }
        return eventSource
      }
      return undefined
    }

    const newProps: DepositAmountProps = {
      form: depositAmountForm,
      handleDepositFormSubmit,
      currentStep,
      handleStep,
      countingTime,
      handleCopy,
      timeRemaining,
      isLoading,
      isCreateMutateLoading,
      data,
      isError,
      error,
      onHandleExpireTime,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default wthDepositAmount
