import PrimaryButton from '@components/buttons/PrimaryButton'
import { ContractRoutesPath } from '@config/Router'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { useNavigate, useParams } from 'react-router-dom'
import { toLocaleStringActualDecimal } from '@libs/currencyFormat'
import { toPercentage } from '@libs/utils'

function TitleWithValue({ title, value }: { title: string; value: any }) {
  return (
    <div className='flex text-sm'>
      <span className='flex-1 font-normal text-gray-400'>{title}</span>
      <span className='font-bold'>{value}</span>
    </div>
  )
}

interface StrategyDetailProps {
  symbol?: string
  strategy?: BotStrategiesDefault
  isShowSymbol?: boolean
  className?: string
  personalId?: number
  isShowCreateBotButton?: boolean
}

function StrategyDetail({
  symbol,
  strategy,
  isShowSymbol = true,
  className = '',
  personalId,
  isShowCreateBotButton = false,
}: StrategyDetailProps) {
  const navigate = useNavigate()
  const { id } = useParams()

  return (
    <div className={className}>
      <div className='flex flex-col gap-y-[12px] '>
        {isShowSymbol && <TitleWithValue title='Coin' value={symbol} />}
        <TitleWithValue title='Leverage' value={strategy?.leverage} />
        <TitleWithValue
          title='Cycle/Single Strategy'
          value={
            strategy?.isCycle
              ? strategy?.isInfinityCycle
                ? 'Infinity'
                : strategy?.cycleQuantity
              : 'Single'
          }
        />
        <TitleWithValue
          title='Double Position'
          value={strategy?.isFirstOrderDouble ? 'On' : 'Off'}
        />
        <TitleWithValue
          title='First Order Limit'
          value={`${strategy?.firstOrderRawQuote} USDT`}
        />
        <TitleWithValue
          title='Order Quantity'
          value={strategy?.coveringQuantity}
        />
        {strategy?.isSplitTp && (
          <TitleWithValue
            title='Advance TP Retracement'
            value={`${
              (strategy?.tpRetracementSplit &&
                strategy.tpRetracementSplit
                  .map(tp => toLocaleStringActualDecimal(toPercentage(tp)))
                  .join(', ')) ??
              '-'
            }
            %`}
          />
        )}
        {!strategy?.isSplitTp && (
          <TitleWithValue
            title='TP Retracement'
            value={`${
              (strategy?.tpRetracement &&
                toLocaleStringActualDecimal(
                  toPercentage(strategy.tpRetracement)
                )) ??
              '-'
            }
            %`}
          />
        )}

        {strategy?.isSplitTp && (
          <TitleWithValue
            title='Advance TP Ratio'
            value={`${
              strategy?.tpRatioSplit &&
              strategy.tpRatioSplit
                .map(tp => toLocaleStringActualDecimal(toPercentage(tp)))
                .join(', ')
            }
              %`}
          />
        )}

        {!strategy?.isSplitTp && (
          <TitleWithValue
            title='TP Ratio'
            value={`${strategy?.tpRatio && strategy.tpRatio * 100}
              %`}
          />
        )}

        <TitleWithValue
          title='Stop loss'
          value={
            strategy?.isStopLoss
              ? strategy?.stopLossRatio
                ? `${toLocaleStringActualDecimal(
                    toPercentage(strategy?.stopLossRatio)
                  )}%`
                : toLocaleStringActualDecimal(strategy?.stopLossLimit) ?? '-'
              : 'Off'
          }
        />
        <TitleWithValue
          title='Covering Spread'
          value={`${strategy?.coverSpreads
            ?.map(ratio => toLocaleStringActualDecimal(toPercentage(ratio)))
            .join(', ')}
               %`}
        />
        <TitleWithValue
          title='Covering Multiples'
          value={`${
            strategy?.coverMultiples && strategy.coverMultiples.join(', ')
          } share(s)`}
        />

        <TitleWithValue
          title='Pullback spreads'
          value={`${strategy?.pullbackSpreads
            ?.map(ratio => toLocaleStringActualDecimal(toPercentage(ratio)))
            .join(', ')}
               %`}
        />

        {isShowCreateBotButton && (
          <PrimaryButton
            type='button'
            className='w-full mt-[40px] rounded-[18px]'
            onClick={() =>
              navigate({
                pathname: ContractRoutesPath.copyByBot.absolutePath({ id }),
                search: personalId ? `?personalId=${personalId}` : undefined,
              })
            }
          >
            Create Bot
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}

export default StrategyDetail
