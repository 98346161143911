import 'reflect-metadata'
import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { Settings } from 'luxon'
import { ToastContainer } from 'react-toastify'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistStore } from 'redux-persist'
import { ModalContextProvider } from '@components/Modal/context/ModalContext'
import 'react-toastify/dist/ReactToastify.css'
import 'react-datepicker/dist/react-datepicker.css'
import 'react-responsive-carousel/lib/styles/carousel.min.css'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import { ExchangeContextProvider } from '@components/Exchange/context/ExchangeContext'
import { SystemMigrationGuard } from '@components/SystemMigrationGuard/SystemMigrationGuard'

import './index.css'
import './styles/toggle.css'
import './styles/customReactInputRange.css'
import './i18n'
import { ResponsiveProvider } from '@hooks/useResponsive'
import reportWebVitals from './reportWebVitals'
import Routes from './Routes'
import { store } from './store'

Settings.defaultLocale = 'th'
const persistor = persistStore(store)

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter>
          <ResponsiveProvider userAgent={window.navigator.userAgent}>
            {/* <IdleServiceContextProvider> */}
            <ExchangeContextProvider>
              <ModalContextProvider>
                <SystemMigrationGuard>
                  <Routes />
                  <ToastContainer
                    position='top-center'
                    autoClose={8000}
                    hideProgressBar
                    newestOnTop={false}
                    theme='light'
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    pauseOnHover
                  />
                </SystemMigrationGuard>
              </ModalContextProvider>
            </ExchangeContextProvider>
            {/* </IdleServiceContextProvider> */}
          </ResponsiveProvider>
        </BrowserRouter>
      </PersistGate>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root')
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
