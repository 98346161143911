import React from 'react'
import {
  OneOffAllButtonAcceptProps,
  OneOffAllButtonProps,
} from '@features/bot/components/BotPagination/components/OneOffAllButton/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useOneOffAllMutation } from '@client/botClient/collections/OneOffAllClientActions'
import { toast } from 'react-toastify'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { botApi } from '@client/client'
import { useAppDispatch } from '../../../../../../store'

const withOneOffAllButton = (Component: React.FC<OneOffAllButtonProps>) => {
  const Hoc = ({ isOneOffAll }: OneOffAllButtonAcceptProps) => {
    const { botMarketType } = useBotMarketType()
    const dispatch = useAppDispatch()
    const [oneOffAllMutate, { isLoading: isOneOffLoading }] =
      useOneOffAllMutation()
    const { openModal } = useModal({
      component: (onConfirm: VoidFunction) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to switch one-off all bots cycle
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })
    const handleOnOneOffAll = () => {
      if (isOneOffAll !== undefined) {
        openModal(() => {
          oneOffAllMutate({ botMarketType, isOneOff: !isOneOffAll }).then(
            () => {
              toast.success('One off all successful')
              dispatch(botApi.util.invalidateTags([{ type: 'Bots' }]))
              dispatch(botApi.util.invalidateTags([{ type: 'Bot' }]))
              dispatch(botApi.util.invalidateTags([{ type: 'IsOneOffAll' }]))
            }
          )
        })
      }
    }

    const newProps: OneOffAllButtonProps = {
      handleOnOneOffAll,
      isOneOffAll,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withOneOffAllButton
