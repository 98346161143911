import { useContext } from 'react'
import { TabContext } from '@components/Tabs/Tabs'

interface TabHeaderProps {
  children: React.ReactNode
  tabId?: string
}

export const TabBody = ({ tabId, children }: TabHeaderProps) => {
  const { activeTab } = useContext(TabContext)
  return <div className={`${activeTab !== tabId && 'hidden'}`}>{children}</div>
}
