import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { KhotcoinTransactions } from '@models/Khotcoin/KhotcoinTransactions'
import { KhotcoinTransactionTypeEnum } from '@interfaces/KhotcoinTransactionTypeEnum'

export const khotcoinTransactionsApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    khotcoinTransactions: builder.query<
      KhotcoinTransactions,
      {
        page: number
        limit: number
        type?: KhotcoinTransactionTypeEnum
      }
    >({
      query: ({ page, limit, type }) => ({
        operationName: 'KhotcoinTransactions',
        document: gql`
          query KhotcoinTransactions(
            $page: Int
            $limit: Int
            $type: KhotcoinTransactionType
          ) {
            getTransactions(page: $page, limit: $limit, type: $type) {
              khotcoinTransactions {
                id
                type
                amount
                createdAt
              }
              pagination {
                totalPage
                totalCount
              }
            }
          }
        `,
        variables: {
          page,
          limit,
          type,
        },
      }),
      transformResponse(res: { getTransactions: KhotcoinTransactions }) {
        const { getTransactions } = res
        return getTransactions
      },
      providesTags: ['KhotcoinTransactions'],
    }),
  }),
})

export const { useKhotcoinTransactionsQuery } = khotcoinTransactionsApi
