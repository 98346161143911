import NavBarBackButton from '@components/AppLayout/components/NavBarBackButton/NavBarBackButton'
import MenuBar from '@components/AppLayout/components/MenuBar'

import { withAuthenGuard } from '@libs/hoc/withAuthenGuard'
import DeleteBotButton from '@features/bot/pages/BotPage/components/DeleteBotButton/DeleteBotButton'

import { withUnActivateGuard } from '@libs/hoc/withUnActivateGuard'
import withBotLayout from './withBotLayout'
import { BotLayoutProps } from './interface'

const BotLayout = ({
  children,
  title,
  bot,
  isShowOnlyIcon,
}: BotLayoutProps) => {
  return (
    <>
      <div className='flex'>
        {title && <NavBarBackButton title={title} />}

        <div className='fixed top-5 right-4 z-10 mobile:flex hidden'>
          <DeleteBotButton isShowOnlyIcon={isShowOnlyIcon} bot={bot} />
        </div>
      </div>

      <div className='py-[70px] min-h-screen bg-gray-900'>{children}</div>
      <MenuBar />
    </>
  )
}

const WrappedComponent = withAuthenGuard(
  withBotLayout(withUnActivateGuard(BotLayout))
)

export default WrappedComponent
