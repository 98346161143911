import { CreateEasyBotPageProps } from '@features/bot/pages/CreateEasyBotPage/interface'
import React from 'react'
import { Form, Formik } from 'formik'
import { formEnterPrevent } from '@libs/formEnterPrevent'
import Loading from '@components/utils/Loading'
import SelectEasyTrade from '@features/bot/pages/CreateEasyBotPage/components/SelectEasyTrade/SelectEasyTrade'
import { toPercentage } from '@libs/utils'
import { toLocaleStringActualDecimal } from '@libs/currencyFormat'
import SelectEasySymbol from '@features/bot/pages/CreateEasyBotPage/components/SelectEasySymbol/SelectEasySymbol'
import InputField from '@components/form/InputField/InputField'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { easyTradeInvestmentTooltip } from '@constant/tooltips/botTooltipsMessage'
import withCreateEasyBotPage from './withCreateEasyBotPage'

const CreateEasyBotPage = ({
  handleOnFormSubmit,
  easyTradeTemplateOptions,
  isLoadingTemplateOptions,
  selectedTemplate,
  handleOnSelectTemplate,
  isLoadingTemplateDetail,
  formInitialValue,
  validationSchema,
  isSubmitting,
}: CreateEasyBotPageProps) => {
  if (isLoadingTemplateOptions) {
    return <Loading text='Loading...' />
  }
  return (
    <div className='max-w-[700px] mx-auto '>
      <div className='pt-[22px] px-[15px]'>
        <Formik
          initialValues={formInitialValue}
          onSubmit={handleOnFormSubmit}
          enableReinitialize
          validationSchema={validationSchema}
        >
          {({ values, isValid }) => (
            <Form
              onKeyDown={formEnterPrevent}
              className='flex flex-col space-y-[24px] text-sm'
            >
              <SelectEasyTrade
                easyTradeTemplateOptions={easyTradeTemplateOptions}
                onSelectTemplateCallback={handleOnSelectTemplate}
              />
              {isLoadingTemplateDetail && !selectedTemplate && (
                <Loading text='Loading...' />
              )}
              {selectedTemplate && (
                <>
                  <SelectEasySymbol defaultSymbols={selectedTemplate.symbols} />

                  {/*  Template detail */}
                  <div className='flex flex-col space-y-[16px]'>
                    <div className='flex space-x-3 justify-between'>
                      <div>Covering spread</div>
                      <div>
                        {selectedTemplate?.botStrategy?.isCovering
                          ? selectedTemplate?.botStrategy?.coverSpreads
                              ?.map(
                                spread =>
                                  toLocaleStringActualDecimal(
                                    toPercentage(spread)
                                  ) ?? '-'
                              )
                              .join(', ')
                          : '-'}
                        %
                      </div>
                    </div>
                    <div className='flex space-x-3 justify-between'>
                      <div>TP Ratio</div>
                      <div>
                        {selectedTemplate?.botStrategy?.isSplitTp
                          ? selectedTemplate?.botStrategy?.tpRatioSplit
                              ?.map(
                                ratio =>
                                  toLocaleStringActualDecimal(
                                    toPercentage(ratio)
                                  ) ?? '-'
                              )
                              .join(', ')
                          : toLocaleStringActualDecimal(
                              toPercentage(
                                selectedTemplate?.botStrategy?.tpRatio
                              )
                            ) ?? '-'}
                        %
                      </div>
                    </div>
                    <div className='flex space-x-3 justify-between'>
                      <div>Order quantity</div>
                      <div>
                        {selectedTemplate?.botStrategy.coveringQuantity ?? '-'}
                      </div>
                    </div>
                    <div className='flex space-x-3 justify-between'>
                      <div>Contract multiple</div>
                      <div>
                        {selectedTemplate?.botStrategy?.isCovering
                          ? selectedTemplate?.botStrategy?.coverMultiples?.join(
                              ', '
                            )
                          : '-'}
                      </div>
                    </div>
                  </div>

                  {/*  Investment  */}
                  <div className='flex flex-col space-y-1'>
                    <InputField
                      name='totalInvestment'
                      label='Investment'
                      placeholder='Investment'
                      rightIcon={
                        <span className='text-sm font-medium'>USDT</span>
                      }
                      tooltip={easyTradeInvestmentTooltip}
                      required
                      type='number'
                      data-test-id='easy-test-investment-field'
                    />
                    <div className='flex justify-between'>
                      <div className='text-gray-59'>
                        Investment should greater or equal
                      </div>
                      <div
                        className='text-gray-59'
                        data-test-id='easy-trade-min-investment-suggestion'
                      >
                        {`${(
                          selectedTemplate.minimumPerSymbol *
                          values.symbols.length
                        ).toLocaleString(undefined)}`}{' '}
                        USDT
                      </div>
                    </div>
                  </div>

                  <PrimaryButton
                    type='submit'
                    disabled={!isValid || isSubmitting}
                    data-test-id='easy-trade-submit-btn'
                  >
                    {isSubmitting ? 'Submitting...' : 'Confirm'}
                  </PrimaryButton>
                </>
              )}
            </Form>
          )}
        </Formik>
      </div>
    </div>
  )
}

const WrappedComponent = withCreateEasyBotPage(CreateEasyBotPage)
export default WrappedComponent
