import { Outlet } from 'react-router-dom'
import { ReCaptchaRoutesPath } from '@config/Router'
import ReCaptchaPage from '@features/reCaptcha/ReCaptchaPage'

export const ReCaptchaRoutes = {
  path: '',
  element: <Outlet />,
  children: [
    {
      path: ReCaptchaRoutesPath.recaptcha.path,
      element: <ReCaptchaPage />,
    },
  ],
}
