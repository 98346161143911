import React from 'react'
import { createSearchParams, useNavigate } from 'react-router-dom'
import BotSwitch from '@features/bot/components/BotSwitch/BotSwitch'
import OneOffButton from '@features/bot/pages/BotPage/components/OneOffButton/OneOffButton'
import AddCoverButton from '@features/bot/pages/BotPage/components/AddCoverButton/AddCoverButton'
import EditBotButton from '@features/bot/pages/BotPage/components/EditBotButton/EditBotButton'
import DeleteBotButton from '@features/bot/pages/BotPage/components/DeleteBotButton/DeleteBotButton'
import { Tabs } from '@components/Tabs/Tabs'
import { Tab } from '@components/Tabs/components/Tab'
import TradeDetailTab from '@features/bot/pages/BotPage/components/TradeDetailTab/TradeDetailTab'
import StrategyDetailTab from '@features/bot/pages/BotPage/components/StrategyDetailTab/StrategyDetailTab'
import { TabHeader } from '@components/Tabs/components/TabHeader'
import { TabBody } from '@components/Tabs/components/TabBody'
import OutlinedButton from '@components/buttons/OutlinedButton'
import { ChartIcon } from '@components/Icons/Icons'
import { TradingViewRoutesPath } from '@config/Router'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import LiquidateButton from '@features/bot/pages/BotPage/components/LiquidateButton/LiquidateButton'
import ClosePositionButton from '@features/bot/pages/BotPage/components/ClosePositionButton/ClosePositionButton'
import StopCoveringButton from '@features/bot/pages/BotPage/components/StopCoveringButton/StopCoveringButton'
import { BotPageProps } from './interface'
import withBotPage from './withBotPage'

function BotPage({ bot, refetch, isLoading, botSymbolPath }: BotPageProps) {
  const navigate = useNavigate()
  return (
    <div className='max-w-[700px] mx-auto px-[17px] pt-[14px] text-sm font-medium flex flex-col gap-y-[16px]'>
      <BotSwitch
        bot={bot}
        refetchBot={refetch}
        isLoading={isLoading}
        botSymbolPath={botSymbolPath}
      />
      {/* Action buttons */}
      <div className='flex mobile:space-x-3 mobile:overflow-x-auto mobile:no-scrollbar desktop:justify-evenly'>
        <OneOffButton
          refetch={refetch}
          isOneOff={bot?.isOneOff}
          isDisable={[
            BotStatusEnum.READY,
            BotStatusEnum.FINISHED,
            BotStatusEnum.ERROR,
          ].includes(bot.status)}
        />
        <AddCoverButton
          bot={bot}
          refetch={refetch}
          isDisable={
            [
              BotStatusEnum.READY,
              BotStatusEnum.FINISHED,
              BotStatusEnum.ERROR,
            ].includes(bot.status) ||
            !bot.botStrategy.isCovering ||
            (bot.botStrategy.isCovering &&
              bot.botStrategy.coveringQuantity === bot.coverRound)
          }
        />
        <StopCoveringButton
          refetch={refetch}
          isStopCovering={bot?.isStopCovering}
          isDisable={
            [
              BotStatusEnum.READY,
              BotStatusEnum.FINISHED,
              BotStatusEnum.ERROR,
            ].includes(bot.status) || !bot.botStrategy.isCovering
          }
        />
        <EditBotButton
          botControllerId={bot.botControllerId}
          botId={bot.id}
          botStatus={bot.status}
          isDisable={[BotStatusEnum.FINISHED, BotStatusEnum.ERROR].includes(
            bot.status
          )}
        />

        <LiquidateButton
          bot={bot}
          isDisable={[
            BotStatusEnum.READY,
            BotStatusEnum.FINISHED,
            BotStatusEnum.ERROR,
          ].includes(bot.status)}
        />
        <DeleteBotButton bot={bot} />
      </div>

      {/* Tabs */}
      <div className='mb-[24px]'>
        <Tabs
          initialActiveTab='trade'
          tabsClassName='lg:w-full'
          tabClassName='lg:mx-12'
        >
          <Tab tabId='trade'>
            <TabHeader tabId='trade'>
              <span>Trade details</span>
            </TabHeader>
            <TabBody>
              <TradeDetailTab bot={bot} />
            </TabBody>
          </Tab>
          <Tab tabId='strategy'>
            <TabHeader tabId='strategy'>
              <span>Setting details</span>
            </TabHeader>
            <TabBody>
              <StrategyDetailTab bot={bot} />
            </TabBody>
          </Tab>
        </Tabs>
      </div>

      <div className='flex space-x-3'>
        {/*  Chart info */}
        <OutlinedButton
          type='button'
          className='text-sm font-bold w-full h-[48px] border-1 border-primary text-primary rounded-md'
          onClick={() => {
            let search: string | undefined
            if (bot.symbol) {
              search = `?${createSearchParams({
                tvwidgetsymbol: `BINANCE:${bot.symbol}`,
              })}`
            }
            navigate({
              pathname: TradingViewRoutesPath.tradingView.absolutePath,
              search,
            })
          }}
        >
          <div className='flex item-center justify-center space-x-3 '>
            <span>View chart</span>
            <ChartIcon />
          </div>
        </OutlinedButton>
        {/*  Close position button */}
        {(bot.status === BotStatusEnum.RUNNING ||
          bot.status === BotStatusEnum.STOP) && (
          <ClosePositionButton bot={bot} refetch={refetch} />
        )}
      </div>
    </div>
  )
}

export default withBotPage(BotPage)
