import { useEffect, useState } from 'react'

export function usePagination(value?: number, initialPage = 1) {
  const [page, setPage] = useState(initialPage)
  const [perpage, setPerpage] = useState(value || 10)
  const [total, setTotal] = useState(0)

  useEffect(() => {
    // Update page when parent update
    // example: come from searchParams
    setPage(initialPage)
  }, [initialPage])

  function pageChange(newPage: number) {
    setPage(newPage)
  }

  function perpageChange(newPerpage: number) {
    setPerpage(newPerpage)
    setPage(1)
  }

  function handleTotalChange(newTotal: number) {
    setTotal(newTotal)
  }

  return {
    page,
    perpage,
    total,
    pageChange,
    perpageChange,
    handleTotalChange,
  }
}
