import { Expose } from 'class-transformer'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export class BotProfitGroupByCoinInput {
  @Expose({ toPlainOnly: true, name: 'create_at_start' })
  createAtStart: number

  @Expose({ toPlainOnly: true, name: 'create_at_end' })
  createAtEnd: number

  @Expose({ toPlainOnly: true, name: 'marketType' })
  botMarketType: BotMarketType
}
