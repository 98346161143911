import React from 'react'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import InputField from '@components/form/InputField/InputField'
import PrimaryButton from '@components/buttons/PrimaryButton'
import withChangePasswordPage from './withChangePasswordPage'
import { ChangePasswordPageProps } from './interface'

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required(),
  password: Yup.string()
    .min(8, 'Password must be include minimum 8 digits with A-Z, a-z, 0-9')
    .required()
    .matches(
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
      'Password must be include minimum 8 digits with A-Z, a-z, 0-9'
    ),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password'), null], 'Password does not match')
    .required(),
})

function ChangePasswordPage({
  handleChangePassword,
  isLoading,
}: ChangePasswordPageProps) {
  return (
    <div className='desktop:mt-2 mb-9 px-[15px] max-w-[700px] mx-auto'>
      <Formik
        initialValues={{ password: '', confirmPassword: '', oldPassword: '' }}
        onSubmit={handleChangePassword}
        validationSchema={changePasswordSchema}
      >
        {() => (
          <Form>
            <div className='flex flex-col gap-y-[8px] mb-[24px]'>
              <InputField
                name='oldPassword'
                label='Old password'
                type='password'
                placeholder='Enter old password'
                required
              />
              <InputField
                name='password'
                label='New password'
                type='password'
                placeholder='Minimum 8 digits with A-Z, a-z, 0-9'
                required
              />
              <InputField
                name='confirmPassword'
                label='Confirm new password'
                type='password'
                placeholder='Confirm your new password'
                required
              />
            </div>
            <PrimaryButton
              type='submit'
              className='w-full rounded-[18px] py-[8px] mt-[40px]'
              disabled={isLoading}
            >
              <p className='text-lg font-bold'>Confirm</p>
            </PrimaryButton>
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default withChangePasswordPage(ChangePasswordPage)
