import React from 'react'
import cn from 'classnames'
import { SearchFieldProps } from '@components/form/SearchField/interface'
import { SearchIcon } from '@components/Icons/Icons'
import { withSearchField } from './withSearchField'

const SearchField = ({
  icon: Icon,
  disabled,

  label,
  className = '',
  inputClassName = '',
  rightIcon,

  onChange,
  name,
  placeholder,
}: SearchFieldProps) => {
  return (
    <div className={cn('inline-block w-full', className)}>
      <div className='relative flex-wrap w-full items-stretch'>
        {label && (
          <div className='text-sm mb-[16px] font-medium text-white'>
            {label}
          </div>
        )}
        <label className='relative flex items-center'>
          <input
            className={cn(inputClassName, {
              'bg-navBar border border-gray-300 rounded-md px-[47px] py-[14px] text-white outline-none placeholder-gray-500 text-sm relative w-full disabled:bg-opacity-30 disabled:cursor-not-allowed focus:border-primary':
                !inputClassName,
            })}
            disabled={disabled}
            onChange={e => {
              onChange && onChange(e.target.value)
            }}
            onWheel={(e: any) => e.target?.blur()}
            name={name}
            placeholder={placeholder}
          />
          <SearchIcon className='absolute mx-3 text-white' />
          {rightIcon && (
            <div className={`absolute right-3 ${disabled && 'opacity-30'}`}>
              {rightIcon}
            </div>
          )}
        </label>
      </div>
    </div>
  )
}

export default withSearchField(SearchField)
