import React from 'react'
import { TradeCopyPageProps } from './interface'

const withTradeCopyPage = (Component: React.FC<TradeCopyPageProps>) => {
  function WithTradeCopyPage() {
    const newProps = {}
    return <Component {...newProps} />
  }

  return WithTradeCopyPage
}

export default withTradeCopyPage
