import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const phoneNumberUniqValidateApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    phoneNumberUniqValidate: builder.mutation<
      { status: string; userId?: number },
      { phoneNumber: string; phoneNumberCountryCode: number }
    >({
      query: ({ phoneNumber, phoneNumberCountryCode }) => ({
        document: gql`
          mutation PhoneNumberUniqValidate(
            $phoneNumber: String!
            $phoneNumberCountryCode: Float!
          ) {
            phoneNumberUniqValidate(
              phoneNumber: $phoneNumber
              phoneNumberCountryCode: $phoneNumberCountryCode
            ) {
              status
              userId
            }
          }
        `,
        variables: {
          phoneNumber,
          phoneNumberCountryCode,
        },
      }),
      transformResponse(res: {
        phoneNumberUniqValidate: { status: string; userId?: number }
      }) {
        const { phoneNumberUniqValidate } = res
        return phoneNumberUniqValidate
      },
    }),
  }),
})

export const { usePhoneNumberUniqValidateMutation } = phoneNumberUniqValidateApi
