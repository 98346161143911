import NavBarBackButton from '@components/AppLayout/components/NavBarBackButton/NavBarBackButton'
import React from 'react'

import cn from 'classnames'
import { useResponsive } from '@hooks/useResponsive'
import { AuthenticationLayoutProps } from './interface'

const AuthenticationLayout = function ({
  children,
  className = 'h-screen',
  title,
  isResponsiveBackgroundImage,
}: AuthenticationLayoutProps) {
  const { isMobile, isTablet } = useResponsive()
  const isDesktop = !(isMobile || isTablet)
  const isShowResponsiveBackgroundImage =
    isDesktop && isResponsiveBackgroundImage

  return (
    <div className={cn(isShowResponsiveBackgroundImage && 'flex flex-wrap')}>
      {title && <NavBarBackButton title={title} />}
      {isShowResponsiveBackgroundImage && (
        <div className='fixed w-1/2 h-screen'>
          <div className='relative  h-full'>
            <img
              src='/authenticateBackground.png'
              alt='Authenticate background'
              className='relative object-cover w-full h-full z-1'
            />
            <img
              src='/logoHorizontal.png'
              alt='Authenticate background'
              className='absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 object-contain w-[65%] z-2'
            />
          </div>
        </div>
      )}

      <div
        className={cn(
          `bg-gray-900 relative py-[40px] pt-[80px]`,
          className,
          isShowResponsiveBackgroundImage && 'ml-[50%] w-full'
        )}
      >
        {/*  Children [Contents] */}
        <div className={cn('h-full max-w-[445px] m-auto mobile:px-[24px]')}>
          {children}
        </div>
      </div>
    </div>
  )
}
export default AuthenticationLayout
