import React, { useMemo } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useBotQuery } from '@client/botClient/collections/BotClientAction'
import Loading from '@components/utils/Loading'

import { BotPageProps } from './interface'

const withBotPage = (Component: React.FC<BotPageProps>) => {
  function WithBotPage() {
    const { id } = useParams()
    const [params] = useSearchParams()
    const botSymbolPath = useMemo(() => {
      return params.get('botSymbolPath') === null
        ? undefined
        : decodeURIComponent(params.get('botSymbolPath')!)
    }, [params])

    const { data, isLoading, refetch } = useBotQuery(
      { botId: Number(id) },
      { refetchOnMountOrArgChange: true }
    )

    if (!isLoading && data) {
      const newProps: BotPageProps = {
        bot: data,
        isLoading,
        refetch,
        botSymbolPath,
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }

  return WithBotPage
}

export default withBotPage
