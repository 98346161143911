import React from 'react'

import { useBotStrategiesDefaultQuery } from '@client/botClient/collections/BotStrategiesDefaultClientAction'
import { toast } from 'react-toastify'
import { useDeleteBotStrategyMutation } from '@client/botClient/collections/DeleteBotStrategyClientAction'
import { ManageStrategyProps } from '@features/bot/pages/ManageStrategy/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

const WithManageStrategy = (Component: React.FC<ManageStrategyProps>) => {
  const Hoc = () => {
    const { botMarketType } = useBotMarketType()
    const [deleteStrategy] = useDeleteBotStrategyMutation()
    const {
      data: strategies,
      isLoading,
      refetch,
    } = useBotStrategiesDefaultQuery(
      { botMarketType },
      {
        refetchOnMountOrArgChange: true,
      }
    )

    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to delete strategy
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleDeleteStrategy = async (id: number) => {
      openModal({
        onConfirm: async () => {
          try {
            await deleteStrategy({ botStrategyId: id }).unwrap()
            refetch()
            toast.success('Delete strategy success')
          } catch (_) {}
        },
      })
    }

    const newProps: ManageStrategyProps = {
      botMarketType,
      strategies,
      isLoading,
      handleDeleteStrategy,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithManageStrategy
