import React, { useContext, useState } from 'react'
import OutlinedButton from '@components/buttons/OutlinedButton'
import PrimaryButton from '@components/buttons/PrimaryButton'
import TextAreaSimpleField from '@components/form/TextAreaSimpleField/TextAreaSimpleField'
import { ModalContext } from '@components/Modal/context/ModalContext'

export interface EditDescriptionModalProps {
  initDescription: string
  onConfirm: (value: string) => Promise<void>
}

function EditDescriptionModal({
  initDescription,
  onConfirm,
}: EditDescriptionModalProps) {
  const [description, setDescription] = useState(initDescription)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const { handleToggleModal } = useContext(ModalContext)

  return (
    <div className='w-[304px] px-[11px] pb-[18px] pt-[32px]'>
      <div className=' text-sm font-normal mb-[12px]'>
        Edit your description
      </div>
      <TextAreaSimpleField
        className='border border-gray-400 bg-navBar w-full rounded-[10px] h-[170px] text-[10px] px-[14px] py-[17px] focus:outline-none'
        value={description}
        placeholder='Enter your description'
        onChange={event => setDescription(event.target.value)}
      />
      <div className='mt-[20px] flex items-center justify-center gap-x-[10px]'>
        <OutlinedButton
          type='button'
          className='px-[15px] py-[6px] font-normal w-full border-[#DCDFE6]'
          onClick={handleToggleModal}
        >
          <span className='text-sm'>Cancel</span>
        </OutlinedButton>
        <PrimaryButton
          type='button'
          className='py-[7px] px-[15px] rounded-[4px] w-full'
          onClick={async () => {
            setIsSubmitting(true)
            try {
              await onConfirm(description)
            } catch (error) {
              // TODO: notify error
            } finally {
              setIsSubmitting(false)
              handleToggleModal()
            }
          }}
          disabled={isSubmitting}
        >
          <span className='text-sm font-medium'>Save</span>
        </PrimaryButton>
      </div>
    </div>
  )
}

export default EditDescriptionModal
