import { CrownIcon } from '@components/Icons/Icons'
import Loading from '@components/utils/Loading'
import { ProfitType } from '@models/Profit/ProfitType'
import { RankingPageProps } from '../interface'
import RankCard from './RankCard'
import TopRank from './TopRank'

function RankDatas({
  profitType,
  isLoading,
  users,
  usersTopThree,
  isEmpty,
  goToChartPage,
}: RankingPageProps) {
  if (isLoading) {
    return <Loading text='Loading...' />
  }
  if (isEmpty) {
    return (
      <div className='flex flex-col items-center justify-center py-[30vh]'>
        <CrownIcon width='60' height='60' />
        <span className='ml-[5px] text-gray-400 text-sm font-bold'>
          There are not ranking
        </span>
      </div>
    )
  }
  if (!isEmpty) {
    return (
      <div className='flex flex-col mt-[70px] mobile:mt-[40px] mx-[22px]'>
        {/* TOP RANK */}
        <div className='relative w-full h-[220px]'>
          {usersTopThree && usersTopThree[0] && (
            <div className='absolute left-1/2 transform -translate-x-1/2'>
              <TopRank
                profileImageClassName='w-[80px] h-[80px]'
                profileImagePath={usersTopThree[0].profileImgPath}
                name={usersTopThree[0].name}
                profit={
                  profitType === ProfitType.ALL_TIME
                    ? usersTopThree[0].totalProfit
                    : usersTopThree[0].dailyProfit
                }
                rank={1}
                onClickCallback={() =>
                  goToChartPage({
                    userId: usersTopThree[0].id!,
                    rank: 1,
                    strategyDescription: usersTopThree[0].strategyDescription,
                    profileImgPath: usersTopThree[0].profileImgPath,
                    userName: usersTopThree[0].name,
                  })
                }
              />
            </div>
          )}
          {usersTopThree && usersTopThree[1] && (
            <div className='absolute top-[50px] left-[0]'>
              <TopRank
                profileImageClassName='w-[70px] h-[70px]'
                profileImagePath={usersTopThree[1].profileImgPath}
                name={usersTopThree[1].name}
                profit={
                  profitType === ProfitType.ALL_TIME
                    ? usersTopThree[1].totalProfit
                    : usersTopThree[1].dailyProfit
                }
                rank={2}
                onClickCallback={() =>
                  goToChartPage({
                    userId: usersTopThree[1].id!,
                    rank: 2,
                    strategyDescription: usersTopThree[1].strategyDescription,
                    profileImgPath: usersTopThree[1].profileImgPath,
                    userName: usersTopThree[1].name,
                  })
                }
              />
            </div>
          )}
          {usersTopThree && usersTopThree[2] && (
            <div className='absolute top-[50px] right-0'>
              <TopRank
                profileImageClassName='w-[70px] h-[70px]'
                profileImagePath={usersTopThree[2].profileImgPath}
                name={usersTopThree[2].name}
                profit={
                  profitType === ProfitType.ALL_TIME
                    ? usersTopThree[2].totalProfit
                    : usersTopThree[2].dailyProfit
                }
                rank={3}
                onClickCallback={() =>
                  goToChartPage({
                    userId: usersTopThree[2].id!,
                    rank: 3,
                    strategyDescription: usersTopThree[2].strategyDescription,
                    profileImgPath: usersTopThree[2].profileImgPath,
                    userName: usersTopThree[2].name,
                  })
                }
              />
            </div>
          )}
        </div>
        {/* OTHER RANK */}
        <div className='flex flex-col items-center top-[220px] mobile:top-[190px] w-full  space-y-[6px]'>
          {users &&
            users.map((user, index) => {
              return (
                <RankCard
                  key={index}
                  profileImagePath={user.profileImgPath}
                  name={user.name}
                  profit={
                    profitType === ProfitType.ALL_TIME
                      ? user.totalProfit
                      : user.dailyProfit
                  }
                  rank={index + 1 + 3}
                  onClickCallback={() =>
                    goToChartPage({
                      userId: user.id!,
                      strategyDescription: user.strategyDescription,
                      profileImgPath: user.profileImgPath,
                      userName: user.name,
                    })
                  }
                />
              )
            })}
        </div>
      </div>
    )
  }
  return <></>
}

export default RankDatas
