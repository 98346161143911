import { WalletRoutesPath } from '@config/Router'
import { toCompactNumber } from '@libs/currencyFormat'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import tether from '@assets/images/tether.png'
import SymbolImage from '@components/SymbolImage/SymbolImage'
import { WalletBoxProps } from './interface'
import withWalletBox from './withWalletBox'

const WalletBox = ({ walletBalance, className }: WalletBoxProps) => {
  const navigate = useNavigate()
  return (
    <div
      className={`flex border-1 border-gray-300 rounded-[10px] p-[7.5px] items-center cursor-pointer ${className}`}
      onClick={() => navigate(WalletRoutesPath.deposit.absolutePath)}
    >
      <SymbolImage path={tether} className='h-[20px] w-[20px] mr-[5px]' />
      <span className='text-base mobile:text-[10px] whitespace-nowrap'>{`${toCompactNumber(
        walletBalance
      )}`}</span>
    </div>
  )
}
export default withWalletBox(WalletBox)
