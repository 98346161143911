import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { User } from '@models/User/User'

export const uplineUserApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    uplineUser: builder.query<User, void>({
      query: () => ({
        document: gql`
          query GetUplineUserDetail {
            getUplineUserDetail {
              id
              name
              profileImgPath
            }
          }
        `,
      }),
      transformResponse(res: { getUplineUserDetail: User }) {
        const { getUplineUserDetail } = res
        return getUplineUserDetail
      },
      providesTags: ['Upline'],
    }),
  }),
})

export const { useUplineUserQuery, useLazyUplineUserQuery } = uplineUserApi
