import React from 'react'
import cn from 'classnames'
import { OptionType } from '@interfaces/OptionType'
import { CircleCheckIcon } from '@components/Icons/Icons'
import { RadioInputProps } from './interface'

export function RadioInput({
  className,
  options,
  value,
  direction,
  ...props
}: RadioInputProps) {
  return (
    <div
      className={cn('gap-x-[20px] text-[16px] font-normal', className, {
        'flex flex-col': direction === 'vertical',
        flex: direction === 'horizontal',
      })}
    >
      {options.map((option: OptionType, index: number) => (
        <label
          className='flex items-center cursor-pointer'
          key={`${option.value}`}
        >
          <div className='rounded-full border-[1px] border-gray-300 w-[21px] h-[21px] mr-[15px]'>
            {value === option.value && (
              <CircleCheckIcon className='stroke-0 text-white' />
            )}
          </div>
          <input
            className='mr-[10px] hidden'
            type='radio'
            value={option.value}
            checked={value === option.value}
            {...props}
          />
          <span>{option.label}</span>
        </label>
      ))}
    </div>
  )
}
