import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const oneOffApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    oneOff: builder.mutation<boolean, { botId: number; isOneOff: boolean }>({
      query: ({ botId, isOneOff }) => ({
        document: gql`
          mutation OneOff(
            $botId: Int!
            $botType: BotType!
            $isOneOff: Boolean!
          ) {
            oneOff(botId: $botId, botType: $botType, isOneOff: $isOneOff)
          }
        `,
        variables: {
          botId: Number(botId),
          botType: 'Martingale',
          isOneOff,
        },
      }),
    }),
  }),
})

export const { useOneOffMutation } = oneOffApi
