import enGB from 'date-fns/locale/en-GB'
import DatePicker, { registerLocale } from 'react-datepicker'
import { CalendarIcon } from '@components/Icons/Icons'
import { withDatePickerSimpleField } from './withDatePickerSimpleField'
import { DatePickerSimpleFieldProps } from './interface'

registerLocale('en-GB', enGB)

const DatePickerSimpleField = ({
  value,
  maxDate,
  minDate,
  handleOnSelectDate,
  placeholder,
  disabled,
  selectsStart,
  selectsEnd,
  startDate,
  endDate,
  label,
  error,
  showMonthYearPicker = false,
  selectsRange = false,
  handleOnSelectDateRange,
  ...props
}: DatePickerSimpleFieldProps) => {
  return (
    <div className='w-full'>
      <style>{css}</style>
      <div className='relative flex w-full flex-wrap items-stretch'>
        {label && <div className='text-sm mb-2 font-normal'>{label}</div>}
        <label
          data-test-id={props['data-test-id']}
          className={`w-full flex items-center rounded-[5px] overflow-hidden border-gray-600 border-1 bg-gray-900
          ${error && 'border-red-600'}`}
        >
          <DatePicker
            showYearDropdown
            scrollableYearDropdown
            yearDropdownItemNumber={10}
            placeholderText={placeholder || 'DD/MM/YYYY'}
            minDate={minDate}
            maxDate={maxDate}
            locale='en-GB'
            dateFormat={showMonthYearPicker ? 'MMM-yyyy' : 'dd-MM-yyyy'}
            className='!w-full bg-gray-900 px-[18px] py-[14px] text-white rounded-[5px] outline-none placeholder-gray-400 text-sm relative  disabled:cursor-not-allowed'
            selected={value}
            startDate={startDate}
            endDate={endDate}
            selectsStart={selectsStart}
            selectsEnd={selectsEnd}
            onChange={e => {
              if (selectsRange && handleOnSelectDateRange) {
                handleOnSelectDateRange(e as [Date | null, Date | null] | null)
              } else if (handleOnSelectDate) {
                handleOnSelectDate(e as Date | null)
              }
            }}
            disabled={disabled}
            showMonthYearPicker={showMonthYearPicker}
            selectsRange={selectsRange}
          />
          <div
            className={`px-2 ${
              disabled ? 'cursor-not-allowed' : 'cursor-pointer'
            }`}
          >
            <CalendarIcon width='15' height='15' />
          </div>
        </label>
      </div>
      {error && <span className='text-error text-xs mt-1 mb-3'>* {error}</span>}
    </div>
  )
}

const css = `.react-datepicker-wrapper { display: block; width: 100%; }
.react-datepicker__input-container { width: inherit;}
.react-datepicker__header {
  background-color: #0F0F10;
}
.react-datepicker {
  background-color: #0F0F10;
  color: white;
  border: 1px solid #484C4E;
  border-radius: 5px;
}
.react-datepicker__current-month, .react-datepicker-time__header, .react-datepicker-year-header {color: white;}
.react-datepicker__day-name, .react-datepicker__day, .react-datepicker__time-name {color: white;}

.react-datepicker__year-dropdown, .react-datepicker__month-dropdown, .react-datepicker__month-year-dropdown {
  background-color: #0F0F10;
  color: white;
}

.react-datepicker__triangle {
  visibility: hidden;
}

.react-datepicker__navigation--years {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
  position: relative;
  display: none;
  margin-left: auto;
  margin-right: auto;
}
.react-datepicker__day:hover {
  color: #21BB8E;
  border-radius: 100%;
}
.react-datepicker__day--today, .react-datepicker__day--today:hover {
  color: #21BB8E;
}
.react-datepicker__day--in-range, .react-datepicker__day--in-selecting-range {
  color: white;
  background: rgba(0,207,222,0.5);
  border-radius: 100%; 
}
.react-datepicker__day--in-range:hover, .react-datepicker__day--in-selecting-range:hover {
  color: #21BB8E;
  border-radius: 100%; 
  background: white;
}

.react-datepicker__day--selected, .react-datepicker__day--range-end, 
.react-datepicker__day--selected:hover, .react-datepicker__day--range-end:hover { 
  color: white;
  background: #21BB8E;
  border-radius: 100%;
}

.react-datepicker__day--disabled {
  color: #666B70;
  cursor: not-allowed
}
.react-datepicker__day--disabled:hover {
  color: #666B70;
}

`

export default withDatePickerSimpleField(DatePickerSimpleField)
