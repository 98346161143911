import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'

export const upsertBotStrategiesDefaultApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    upsertBotStrategiesDefault: builder.mutation<
      { id: number },
      BotStrategiesDefault
    >({
      query: newBotStrategyInput => ({
        document: gql`
          mutation Mutation($newBotStrategyInput: NewBotStrategyInput!) {
            upsertBotStrategiesDefault(
              NewBotStrategyInput: $newBotStrategyInput
            ) {
              id
            }
          }
        `,
        variables: {
          newBotStrategyInput,
        },
      }),
    }),
  }),
})

export const { useUpsertBotStrategiesDefaultMutation } =
  upsertBotStrategiesDefaultApi
