import {
  CreateBotIcon,
  CustomizeIcon,
  ExchangeIcon,
} from '@components/Icons/Icons'
import React, { useMemo, useState } from 'react'
import { CreateBotPageAcceptProps, CreateGridBotPageProps } from './interface'

const withCreateGridBotPage = (Component: React.FC<CreateGridBotPageProps>) => {
  function WithCreateBotPage({ mode, ...props }: CreateBotPageAcceptProps) {
    const [activeStep, setActiveStep] = useState(0)

    const nextStep = () => {
      setActiveStep(prev => prev + 1)
    }

    const backStep = () => {
      setActiveStep(prev => prev - 1)
    }

    const progressPercent = useMemo(() => {
      return activeStep === 0 ? 'w-[0%]' : 'w-[100%]'
    }, [activeStep])

    const steps = useMemo(() => {
      return [
        {
          title: mode === 'bot' ? 'Select symbol' : 'Create strategy name',
          icon: <ExchangeIcon width='10.5' height='10.5' />,
          className: '-left-1',
        },
        {
          title: 'Create strategy',
          icon: <CustomizeIcon width='16' height='16' />,
          className: '-right-1',
        },
      ]
    }, [mode])

    const newProps = {
      nextStep,
      backStep,
      activeStep,
      progressPercent,
      mode,
      steps,
    }

    return <Component {...newProps} />
  }

  return WithCreateBotPage
}

export default withCreateGridBotPage
