import React from 'react'
import { toast } from 'react-toastify'
import { useBotStrategiesDefaultQuery } from '@client/botClient/collections/BotStrategiesDefaultClientAction'
import { useDeleteBotStrategyMutation } from '@client/botClient/collections/DeleteBotStrategyClientAction'
import Loading from '@components/utils/Loading'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { MyStrategyTabProps } from './interface'

const withMyStrategyTab = (Component: React.FC<MyStrategyTabProps>) => {
  function WithMyStrategyTab() {
    const {
      data: strategies,
      isLoading,
      refetch,
    } = useBotStrategiesDefaultQuery({ botMarketType: BotMarketType.FUTURE })

    const [deleteStrategy] = useDeleteBotStrategyMutation()

    const handleDeleteStrategy = async (id: number) => {
      try {
        await deleteStrategy({ botStrategyId: id }).unwrap()
        refetch()
        toast.success('Delete strategy success')
      } catch (_) {}
    }

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const newProps = { strategies, handleDeleteStrategy }
    return <Component {...newProps} />
  }

  return WithMyStrategyTab
}

export default withMyStrategyTab
