import { TradingViewLayoutProps } from '@features/tradingView/components/TradingViewLayout/interface'
import { BackIcon } from '@components/Icons/Icons'
import { useLocation, useNavigate } from 'react-router-dom'
import { AuthenticationRoutesPath } from '@config/Router'

const TradingViewLayout = ({ children, title }: TradingViewLayoutProps) => {
  const navigate = useNavigate()
  const { pathname, state } = useLocation()

  const onNavigateBack = () => {
    if (pathname === AuthenticationRoutesPath.login.absolutePath) {
      navigate('/')
    } else if (window.history.length > 1) {
      navigate(-1)
    } else {
      window.close()
    }
  }
  return (
    <>
      <div className='fixed top-0 flex items-center w-full px-[17px] py-[23px] z-10 bg-gray-900'>
        <div
          className='flex items-center gap-x-[5px] cursor-pointer'
          onClick={onNavigateBack}
        >
          <BackIcon width='24' height='24' />
          <div className=''>{title}</div>
        </div>
      </div>
      <div className='py-[80px] min-h-screen bg-gray-900'>{children}</div>
    </>
  )
}

export default TradingViewLayout
