import Table from '@components/Table/Table'
import { DateTime } from 'luxon'
import WalletContainer from '@features/wallet/components/WalletContainer/WalletContainer'
import { WithdrawRequestedPageProps } from '@features/wallet/pages/WithdrawRequestedPage/interface'
import { WithdrawRequestStatusEnum } from '@interfaces/WithdrawRequestStatusEnum'
import withWithdrawRequestedPage from './withWithdrawRequestedPage'

const WithdrawRequestedPage = ({
  withdrawRequests,
  page,
  pageChange,
  perpageChange,
  isLoading,
  perpage,
}: WithdrawRequestedPageProps) => {
  return (
    <WalletContainer type='withdraw-requested'>
      <div className='mt-[24px]'>
        <Table
          data={withdrawRequests?.withdrawRequests}
          columns={[
            {
              Header: 'Request Date',
              id: 'createdAt',
              accessor: 'createdAt',
              Cell: ({ value }) => (
                <span>
                  {value
                    ? DateTime.fromMillis(value).toFormat('dd-MM-yyyy HH:mm:ss')
                    : '-'}
                </span>
              ),
              minWidth: 130,
            },
            {
              Header: 'Amount (USDT)',
              id: 'amount',
              accessor: 'amount',
              Cell: ({ value }) => (
                <span>
                  {value.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })}
                </span>
              ),
              minWidth: 90,
            },
            {
              Header: 'Status',
              id: 'status',
              accessor: 'status',
              Cell: ({ value }) => {
                return (
                  <span
                    className={
                      value === WithdrawRequestStatusEnum.APPROVE
                        ? 'text-green-100'
                        : value === WithdrawRequestStatusEnum.REJECT
                        ? 'text-red'
                        : 'text-orange'
                    }
                  >
                    {value === WithdrawRequestStatusEnum.APPROVE
                      ? 'Approve'
                      : value === WithdrawRequestStatusEnum.REJECT
                      ? 'Reject'
                      : 'Pending'}
                  </span>
                )
              },
              minWidth: 90,
            },
            {
              Header: 'Reject reason',
              id: 'rejectReason',
              accessor: 'rejectReason',
              Cell: ({ value }) => {
                return <span className='text-red'>{value}</span>
              },
              minWidth: 40,
            },
          ]}
          totalCount={withdrawRequests?.pagination?.totalCount || 0}
          totalPage={withdrawRequests?.pagination?.totalPage || 1}
          manualPagination
          currentPage={page}
          currentPageSize={perpage}
          handleOnPageChange={pageChange}
          handleOnPageSizeChange={perpageChange}
          isLoading={isLoading}
          isShowPagination
        />
      </div>
    </WalletContainer>
  )
}

const WrappedComponent = withWithdrawRequestedPage(WithdrawRequestedPage)
export default WrappedComponent
