import { ArrowRightIcon } from '@components/Icons/Icons'
import Tooltip from '@components/Tooltip/Tooltip'
import { ButtonInterfaceProps } from './interface'

interface ButtonWithArrowProps extends ButtonInterfaceProps {
  label?: string
  tooltip?: string
  'data-test-id'?: string
}

function ButtonWithArrow({
  type,
  className,
  children,
  label,
  tooltip,
  ...props
}: ButtonWithArrowProps) {
  return (
    <div className={className}>
      {label && (
        <div className='flex flex-row space-x-2'>
          <div className='text-sm mb-[16px] font-medium text-white'>
            {label}
          </div>
          {tooltip && <Tooltip title={label} text={tooltip} />}
        </div>
      )}
      <div className='relative'>
        <button
          type={type}
          className='p-[14px] w-full rounded-[4px] bg-navBar disabled:opacity-40 text-white'
          {...props}
        >
          <div className='select-none text-left text-sm'>{children}</div>
        </button>
        <ArrowRightIcon className='absolute right-[12px] top-[14px]' />
      </div>
    </div>
  )
}

export default ButtonWithArrow
