import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { MinInitialMarginModel } from '@models/MinInitialMargin/MinInitialMargin'

export const minInitialMarginApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    minInitialMargin: builder.query<
      MinInitialMarginModel,
      { symbol: string; leverage: number }
    >({
      transformResponse(res: { minInitialMargin }) {
        const { minInitialMargin } = res
        return minInitialMargin
      },
      query: ({ symbol, leverage }) => ({
        document: gql`
          query MinInitialMargin($symbol: String!, $leverage: Int!) {
            minInitialMargin(symbol: $symbol, leverage: $leverage) {
              symbol
              minInitialMargin
            }
          }
        `,
        variables: {
          symbol,
          leverage,
        },
      }),
      providesTags: ['MarketDetail'],
    }),
  }),
})

export const { useLazyMinInitialMarginQuery, useMinInitialMarginQuery } =
  minInitialMarginApi
