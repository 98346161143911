import { Navigate, useRoutes } from 'react-router-dom'
import { HomeRoutes } from '@features/home/HomeRoutes'
import { AuthenticationRoutes } from '@features/authentication/AuthenticationRoutes'
import { ContractRoutes } from '@features/bot/ContractRoutes'
import { ProfitRoutes } from '@features/profit/ProfitRoutes'
import { TransactionLogRoutes } from '@features/transactionLog/TransactionLogRoutes'
import { WalletRoutes } from '@features/wallet/WalletRoutes'
import { TrendingCoinRoutes } from '@features/trendingCoin/TrendingCoinRoutes'
import { TradeCopyRoutes } from '@features/tradeCopy/TradeCopyRoutes'
import { TutorialRoutes } from '@features/tutorial/TutorialRoutes'
import { OtherRoutes } from '@features/other/OtherRoutes'
import { TradingViewRoutes } from '@features/tradingView/TradingViewRoutes'
import { ReCaptchaRoutes } from '@features/reCaptcha/ReCaptchaRoutes'
import { SpotRoutes } from '@features/bot/SpotRoutes'
import { PolicyRoutes } from '@features/policy/PolicyRoutes'

const Routes = () => {
  return useRoutes([
    {
      path: '',
      element: <Navigate to={HomeRoutes.path} replace />,
    },
    HomeRoutes,
    ContractRoutes,
    SpotRoutes,
    AuthenticationRoutes,
    ProfitRoutes,
    TransactionLogRoutes,
    OtherRoutes,
    WalletRoutes,
    TrendingCoinRoutes,
    TradeCopyRoutes,
    TutorialRoutes,
    TradingViewRoutes,
    ReCaptchaRoutes,
    PolicyRoutes,
    {
      path: '*',
      element: <div>Page not found</div>,
    },
  ])
}

export default Routes
