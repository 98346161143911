import OutlinedButton from '@components/buttons/OutlinedButton'
import { UserCircleIcon } from '@components/Icons/Icons'
import { TradeCopyRoutesPath } from '@config/Router'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

function TradeCopyHeader() {
  const { profile } = useSelector(authenticationSelector)
  const navigate = useNavigate()

  return (
    <div className='flex items-center gap-x-[5px] py-[10px] px-[16px]'>
      {profile?.profileImgPath ? (
        <img
          src={profile.profileImgPath}
          alt='Profile'
          className='w-[45px] h-[45px] rounded-full mr-[5px]'
        />
      ) : (
        <UserCircleIcon className='w-[61px] h-[61px]' />
      )}

      <span className='text-sm font-bold flex-1'>{profile?.name}</span>
      <OutlinedButton
        type='button'
        className='px-[16px] py-[7px] text-xs font-medium border-primary text-primary'
        onClick={() =>
          navigate(TradeCopyRoutesPath.tradeCopy.myStrategy.absolutePath)
        }
      >
        My strategy
      </OutlinedButton>
    </div>
  )
}

export default TradeCopyHeader
