import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const turnOffBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    turnOffBot: builder.mutation<{ id: number }, number>({
      query: botId => ({
        document: gql`
          mutation TurnOffBot($botId: Int!, $botType: BotType!) {
            turnOffBot(botId: $botId, botType: $botType) {
              id
            }
          }
        `,
        variables: {
          botId,
          botType: 'Martingale',
        },
      }),
    }),
  }),
})

export const { useTurnOffBotMutation } = turnOffBotApi
