import { useProfitQuery } from '@client/mainClient/collections/ProfitClientAction'
import { ProfitFilterParams } from '@models/Profit/ProfitFilterParams'
import { DateTime } from 'luxon'
import React, { useEffect, useState } from 'react'
import { BotMarketType } from '@interfaces/BotMarketEnum'
import { TotalProfitAcceptProps, TotalProfitProps } from './interface'

const withTotalProfit = (Component: React.FC<TotalProfitProps>) => {
  const Hoc = ({ className }: TotalProfitAcceptProps) => {
    const [profitParams, setProfitParams] = useState<{
      today: ProfitFilterParams
      thisMonth: ProfitFilterParams
    }>()

    useEffect(() => {
      const dt = DateTime.now()
      setProfitParams({
        today: {
          startAt: dt.startOf('day').toMillis(),
          endAt: dt.endOf('day').toMillis(),
        },
        thisMonth: {
          startAt: dt.startOf('month').toMillis(),
          endAt: dt.endOf('month').toMillis(),
        },
      })
    }, [])

    const { data: profitToday } = useProfitQuery(
      { ...profitParams?.today, botMarketType: BotMarketType.FUTURE },
      {
        skip: !(profitParams?.today?.startAt && profitParams?.today?.endAt),
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    )
    const { data: profitThisMonth } = useProfitQuery(
      { ...profitParams?.thisMonth, botMarketType: BotMarketType.FUTURE },
      {
        skip: !(
          profitParams?.thisMonth?.startAt && profitParams?.thisMonth?.endAt
        ),
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    )
    const { data: profitAll } = useProfitQuery(
      { botMarketType: BotMarketType.FUTURE },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    )

    const { data: profitTodaySpot } = useProfitQuery(
      { ...profitParams?.today, botMarketType: BotMarketType.SPOT },
      {
        skip: !(profitParams?.today?.startAt && profitParams?.today?.endAt),
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    )
    const { data: profitThisMonthSpot } = useProfitQuery(
      { ...profitParams?.thisMonth, botMarketType: BotMarketType.SPOT },
      {
        skip: !(
          profitParams?.thisMonth?.startAt && profitParams?.thisMonth?.endAt
        ),
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    )
    const { data: profitAllSpot } = useProfitQuery(
      { botMarketType: BotMarketType.SPOT },
      {
        refetchOnMountOrArgChange: true,
        refetchOnFocus: true,
      }
    )

    const newProps: TotalProfitProps = {
      contractProfit: {
        today: profitToday?.profitUsd || 0,
        month: profitThisMonth?.profitUsd || 0,
        total: profitAll?.profitUsd || 0,
      },
      spotProfit: {
        today: profitTodaySpot?.profitUsd || 0,
        month: profitThisMonthSpot?.profitUsd || 0,
        total: profitAllSpot?.profitUsd || 0,
      },

      className,
    }
    return <Component {...newProps} />
  }
  return Hoc
}

export default withTotalProfit
