import React, { useMemo } from 'react'
import { useFormikContext } from 'formik'
import { CreateBotFromEasyTradeForm } from '@models/Bot/CreateBotFromEasyTradeForm'
import {
  SelectEasySymbolAcceptProps,
  SelectEasySymbolProps,
} from '@features/bot/pages/CreateEasyBotPage/components/SelectEasySymbol/interface'
import { OptionType } from '@interfaces/OptionType'

const withSelectEasySymbol = (Component: React.FC<SelectEasySymbolProps>) => {
  const Hoc = ({ defaultSymbols }: SelectEasySymbolAcceptProps) => {
    const {
      values: { symbols },
      setFieldValue,
      errors: { symbols: symbolError },
    } = useFormikContext<CreateBotFromEasyTradeForm>()

    const isAllowSelect = defaultSymbols.length > symbols.length
    const excludeSymbolOptions = useMemo(() => {
      const options = defaultSymbols.map(defaultSymbol => {
        const isAlreadyIn = symbols.some(
          value => value.symbol === defaultSymbol.symbol
        )
        if (!isAlreadyIn) {
          return {
            label: defaultSymbol.symbol,
            value: defaultSymbol.symbol,
          } as OptionType
        }
        return undefined
      })

      return options.filter(Boolean) as OptionType[]
    }, [defaultSymbols, symbols])

    const handleOnRemoveSymbol = (index: number) => {
      const updated = [...symbols]
      updated.splice(index, 1)
      setFieldValue('symbols', updated)
    }
    const handleOnAddSymbol = (symbol: string) => {
      const targetSymbol = defaultSymbols.find(v => v.symbol === symbol)
      const updated = [...symbols, targetSymbol]
      setFieldValue('symbols', updated)
    }

    const newProps: SelectEasySymbolProps = {
      symbols,
      isAllowSelect,
      excludeSymbolOptions,
      symbolError,
      handleOnRemoveSymbol,
      handleOnAddSymbol,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withSelectEasySymbol
