import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const sendOTPVerifyApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    sendOTPVerify: builder.mutation<
      { status: string },
      { phoneNumber: string; phoneNumberCountryCode: number }
    >({
      query: ({ phoneNumber, phoneNumberCountryCode }) => ({
        document: gql`
          mutation SendOTPVerify(
            $phoneNumber: String!
            $phoneNumberCountryCode: Float!
          ) {
            sendOTPVerify(
              phoneNumber: $phoneNumber
              phoneNumberCountryCode: $phoneNumberCountryCode
            ) {
              status
            }
          }
        `,
        variables: {
          phoneNumber,
          phoneNumberCountryCode,
        },
      }),
    }),
  }),
})

export const { useSendOTPVerifyMutation } = sendOTPVerifyApi
