import { useGetAccumulateByStrategyIdQuery } from '@client/botClient/collections/GetAccumulateByStrategyIdInput'
import { useGetTradeCopyByBotQuery } from '@client/botClient/collections/GetTradeCopyByBot'
import { WinRateOptionEnum } from '@features/tradeCopy/components/PersonalWinRate'
import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { CopyBySymbolPageProps } from './interface'

const withCopyBySymbolPage = (Component: React.FC<CopyBySymbolPageProps>) => {
  function WithCopyBySymbolPage() {
    const { id } = useParams()
    const [params] = useSearchParams()

    const name =
      params.get('name') === 'null'
        ? ''
        : decodeURIComponent(params.get('name')!)
    const description =
      params.get('description') === 'null'
        ? ''
        : decodeURIComponent(params.get('description')!)
    const strategyId = Number(params.get('strategyId'))
    const profileImg = decodeURIComponent(params.get('profileImg')!)

    const [accmulateParams, setAccmulateParams] = useState({
      endAtStart: DateTime.now().startOf('day').minus({ days: 7 }).toMillis(),
      endAtEnd: DateTime.now().endOf('day').toMillis(),
    })

    const {
      data: detail,
      isLoading: isLoadingDetail,
      refetch: refetchTradeCopyByBot,
    } = useGetTradeCopyByBotQuery({
      tradeCopyByBotId: Number(id),
    })

    const { data: accmulate } = useGetAccumulateByStrategyIdQuery({
      strategyId,
      endAtStart: accmulateParams.endAtStart,
      endAtEnd: accmulateParams.endAtEnd,
    })

    const handleAccmulateParams = (option: WinRateOptionEnum) => {
      let start = DateTime.now().startOf('day')
      const end = DateTime.now().minus({ days: 1 }).endOf('day')
      if (option === WinRateOptionEnum.YESTERDAY) {
        start = start.minus({ days: 1 })
      } else if (option === WinRateOptionEnum.WEEK) {
        start = start.minus({ days: 7 })
      } else if (option === WinRateOptionEnum.HALFMONTH) {
        start = start.minus({ days: 15 })
      } else if (option === WinRateOptionEnum.MONTH) {
        start = start.minus({ days: 30 })
      }
      setAccmulateParams({
        endAtStart: start.toMillis(),
        endAtEnd: end.toMillis(),
      })
    }

    const newProps = {
      id,
      accmulate,
      handleAccmulateParams,
      name,
      description,
      strategyId,
      detail,
      isLoadingDetail,
      profileImg,
      refetchTradeCopyByBot,
    }
    return <Component {...newProps} />
  }

  return WithCopyBySymbolPage
}

export default withCopyBySymbolPage
