import { gql } from 'graphql-request'
import { botClient, exchangeClient, mainApi, mainClient } from '@client/client'
import { CredentialType } from '@features/authentication/components/Login/interface'
import { Login } from '@models/Login/Login'
import { plainToClass } from 'class-transformer'

export const authenticationApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    login: builder.mutation<Login, CredentialType>({
      query: ({ username, password }) => ({
        document: gql`
          mutation Login($userLoginInput: UserLoginInput!) {
            login(userLoginInput: $userLoginInput) {
              access_token
              refresh_token
            }
          }
        `,
        variables: {
          userLoginInput: {
            username,
            password,
          },
        },
      }),
      transformResponse(res: { login: Login }) {
        const { login } = res
        return plainToClass(Login, login)
      },
      async onQueryStarted(arg, { queryFulfilled }) {
        const { data } = await queryFulfilled
        mainClient.setHeader('authorization', `Bearer ${data?.accessToken}`)
        exchangeClient.setHeader('authorization', `Bearer ${data?.accessToken}`)
        botClient.setHeader('authorization', `Bearer ${data?.accessToken}`)
      },
      invalidatesTags: ['Profile'],
    }),
  }),
})

export const { useLoginMutation } = authenticationApi
