import OutlinedButton from '@components/buttons/OutlinedButton'
import PrimaryButton from '@components/buttons/PrimaryButton'

import InputField from '@components/form/InputField/InputField'
import { Form, Formik } from 'formik'
import * as Yup from 'yup'
import Divider from '@components/utils/Divider'
import withBudgetCalculatorPage from './withBudgetCalculatorPage'
import { BudgetCalculatorPageProps } from './interface'
import BudgetOptionContainer from './components/BudgetOptionContainer'

const validationSchema = Yup.object().shape({
  budget: Yup.number().min(2000).max(120000).required(),
})

const BudgetCalculatorPage = ({
  handleOnSubmit,
  handleOnClear,
  initialValue,
  budgetOptions,
}: BudgetCalculatorPageProps) => {
  return (
    <div className='max-w-[700px] mx-auto px-[17px] desktop:mt-2 mb-9'>
      <Formik
        initialValues={initialValue}
        onSubmit={handleOnSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, dirty, isValid }) => {
          return (
            <Form>
              <div className='flex flex-col gap-y-[16px]'>
                <InputField
                  name='budget'
                  label='Input budget (USDT)'
                  type='number'
                  placeholder='Input your budget (2,000 ~ 120,000 USDT)'
                  required
                />
              </div>
              <div className='flex gap-x-4'>
                <OutlinedButton
                  type='button'
                  className='w-full mt-[40px] rounded-[18px]'
                  disabled={values.budget === ''}
                  onClick={() => handleOnClear(setFieldValue)}
                >
                  Clear
                </OutlinedButton>
                <PrimaryButton
                  type='submit'
                  className='w-full mt-[40px] rounded-[18px]'
                  disabled={dirty && !isValid}
                >
                  Calculate
                </PrimaryButton>
              </div>
            </Form>
          )
        }}
      </Formik>
      <div className='mt-6'>
        {budgetOptions.map((budgetOption, index) => (
          <div className='my-4'>
            <BudgetOptionContainer
              key={`BudgetOption - ${index}`}
              optionName={budgetOption.optionName}
              leverage={budgetOption.leverage}
              long={budgetOption.long}
              short={budgetOption.short}
              initialMarginLong={budgetOption.initialMarginLong}
              initialMarginShort={budgetOption.initialMarginShort}
            />
            <Divider className='my-3' />
          </div>
        ))}
      </div>
    </div>
  )
}
export default withBudgetCalculatorPage(BudgetCalculatorPage)
