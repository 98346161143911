import { useUserProfitRankQuery } from '@client/mainClient/collections/UserProfitRankClientAction'
import { ProfitType } from '@models/Profit/ProfitType'
import { DateTime } from 'luxon'
import React, { useMemo } from 'react'
import { GoToChartPageProps } from '@features/other/pages/Ranking/interface'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { OtherRoutesPath } from '@config/Router'
import { HomeTopFiveUsersProps } from './interface'

const withHomeTopFiveUsers = (Component: React.FC<HomeTopFiveUsersProps>) => {
  const Hoc = () => {
    const navigate = useNavigate()
    const { data, isLoading } = useUserProfitRankQuery({
      profitType: ProfitType.DAILY,
      limit: 5,
      page: 1,
    })
    const lastUpdate = useMemo(() => {
      return DateTime.now()
    }, [])

    const goToChartPage = ({
      userId,
      profileImgPath,
      rank,
      strategyDescription,
      userName,
    }: GoToChartPageProps) => {
      const searchData: Record<string, any> = { userName }
      if (profileImgPath) searchData.profileImgPath = profileImgPath
      if (rank) searchData.rank = rank
      if (strategyDescription)
        searchData.strategyDescription = strategyDescription
      const search = `?${createSearchParams(searchData)}`
      navigate({
        pathname: OtherRoutesPath.ranking.chart.absolutePath({
          userId,
          mode: 'daily',
        }),
        search,
      })
    }
    const newProps = {
      users: data?.users,
      isLoading,
      lastUpdate,
      goToChartPage,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withHomeTopFiveUsers
