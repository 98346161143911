import React from 'react'

import cn from 'classnames'
import Tooltip from '@components/Tooltip/Tooltip'
import InputMask from 'react-input-mask'
import { InputMaskedFieldProps } from './interface'
import { withInputMaskedField } from './withInputMaskedField'

function InputMaskedField({
  name,
  label,
  mask,
  guide = false,
  touched,
  error,
  value,
  placeholder,
  onChange,
  onFormikChange,
  disabled,
  inputClassName,
  className,
  required,
  tooltip,
}: InputMaskedFieldProps) {
  return (
    <div className={cn('inline-block w-full', className)}>
      <div className='relative flex-wrap w-full items-stretch'>
        {label && (
          <div className='flex flex-row space-x-2'>
            <div className='text-sm mb-[16px] font-medium text-white'>
              {label} {required && <span className='text-red-dark'>*</span>}
            </div>
            {tooltip && (
              <Tooltip
                title={typeof label === 'string' ? label : undefined}
                text={tooltip}
              />
            )}
          </div>
        )}
        <div className='relative flex items-center'>
          <InputMask
            mask={mask}
            className={cn(inputClassName, {
              'border-red border': touched && error,
              'bg-gray-secondary px-[18px] py-[14px] text-white rounded-[5px] outline-none placeholder-gray-400 text-sm relative w-full disabled:bg-opacity-30 disabled:cursor-not-allowed':
                !inputClassName,
            })}
            disabled={disabled}
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={e => {
              onFormikChange(e)
              onChange && onChange(e.target.value)
            }}
          />
        </div>
        {error && touched && (
          <div className='mt-[2px] text-xs text-red top-full'>{error}</div>
        )}
      </div>
    </div>
  )
}

export default withInputMaskedField(InputMaskedField)
