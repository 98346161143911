import { createSlice } from '@reduxjs/toolkit'
import { AuthenticationState } from '@features/authentication/AuthenticaitonSlice/interface'
import { authenticationApi } from '@client/mainClient/collections/AuthenticationClientActions'
import { profileApi } from '@client/mainClient/collections/ProfileClientAction'

import { RootState } from '../../../store'

const initialState = {
  isAuthentication: false,
  profile: undefined,
  accessToken: undefined,
  refreshToken: undefined,
} as AuthenticationState

export const authenticationSlice = createSlice({
  name: 'authentication',
  initialState,
  reducers: {
    logout: () => initialState,
    refreshToken: (state, action) => {
      return {
        ...state,
        accessToken: action.payload.accessToken,
        refreshToken: action.payload.refreshToken,
      }
    },
  },
  extraReducers: builder => {
    builder.addMatcher(
      authenticationApi.endpoints.login.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          accessToken: payload.accessToken,
          refreshToken: payload.refreshToken,
        }
      }
    )
    builder.addMatcher(
      profileApi.endpoints.profile.matchFulfilled,
      (state, { payload }) => {
        return {
          ...state,
          isAuthentication: true,
          profile: payload,
        }
      }
    )
  },
})

export const authenticationSelector = ({ authentication }: RootState) =>
  authentication
export const { logout, refreshToken } = authenticationSlice.actions
