import TrendingCoin from '@components/TrendingCoin/TrendingCoins'

const TrendingCoinPage = props => {
  return (
    <div className='mx-auto desktop:mt-2 mb-9'>
      <TrendingCoin limit={20} />
    </div>
  )
}
export default TrendingCoinPage
