import NoData from '@components/NoData/NoData'
import TablePagination from '@components/Table/component/TablePagination/TablePagination'
import Loading from '@components/utils/Loading'
import ProfitByBotCard from './components/ProfitByBotCard'
import { ProfitByBotProps } from './interface'
import withProfitByBot from './withProfitByBot'

const ProfitByBot = ({
  isLoading,
  page,
  pageChange,
  bots,
  className,
  isShowPagination,
  botClassName = 'flex flex-col space-y-3',
  isHomeUi,
}: ProfitByBotProps) => {
  if (isLoading) {
    return <Loading text='Loading...' />
  }
  if (!bots?.bots || bots?.bots?.length <= 0) {
    return <NoData className={`${isHomeUi && 'py-0'}`} />
  }

  return (
    <div className={`${className}`}>
      <div className={`mb-5 ${botClassName}`}>
        {bots?.bots.map(bot => {
          return <ProfitByBotCard key={bot.id} bot={bot} />
        })}
      </div>
      {isShowPagination && (
        <TablePagination
          paginationProps={{
            pageCount: bots?.pagination?.totalPage || 0,
            handleOnPageChange: pageChange,
          }}
          tableState={{
            pageIndex: page - 1,
          }}
        />
      )}
    </div>
  )
}
export default withProfitByBot(ProfitByBot)
