import React from 'react'
import Loading from '@components/utils/Loading'
import BotSettingForm from '@features/bot/pages/CreateBotPage/components/BotSettingForm/BotSettingForm'
import withCreateBotPage from './withCreateBotPage'

import { CreateBotPageProps } from './interface'

const CreateBotPage = ({
  mode,
  editInitialise,
  editInitialCoverMultipleForm,
  editInitialCoverSpreadsForm,
  editInitialPullbackSpreadsForm,
  editInitialTpRatioSplitForm,
  editInitialTpRetracementSplitForm,
  selectedBot,
  selectedPersonalId,
  copyByPersonalBot,
  copyBySymbolBots,
  handleSelectedCopyByBotChange,
  handleSelectedCopyByPersonalChange,
  botDetail,
}: CreateBotPageProps) => {
  if (
    (mode === 'bot-update-all-strategy' ||
      mode === 'bot-update-current-strategy' ||
      mode === 'bot-update-main-strategy' ||
      mode === 'strategy-update') &&
    (!editInitialise ||
      !editInitialCoverMultipleForm ||
      !editInitialCoverSpreadsForm ||
      !editInitialPullbackSpreadsForm ||
      !editInitialTpRatioSplitForm ||
      !editInitialTpRetracementSplitForm)
  ) {
    return <Loading text='Loading...' />
  }

  return (
    <div className='max-w-[700px] mx-auto '>
      <div className='pt-[22px] px-[15px]'>
        <BotSettingForm
          mode={mode}
          bot={botDetail}
          initialForm={editInitialise}
          selectedBot={selectedBot}
          selectedPersonalId={selectedPersonalId}
          copyByPersonalBot={copyByPersonalBot}
          copyBySymbolBots={copyBySymbolBots}
          handleSelectedCopyByBotChange={handleSelectedCopyByBotChange}
          handleSelectedCopyByPersonalChange={
            handleSelectedCopyByPersonalChange
          }
          initialCoverMultipleForm={editInitialCoverMultipleForm}
          initialCoverSpreadsForm={editInitialCoverSpreadsForm}
          initialPullbackSpreadsForm={editInitialPullbackSpreadsForm}
          initialTpRatioSplitForm={editInitialTpRatioSplitForm}
          initialTpRetracementSplitForm={editInitialTpRetracementSplitForm}
        />
      </div>
    </div>
  )
}

export default withCreateBotPage(CreateBotPage)
