import { Tab } from '@components/Tabs/components/Tab'
import { TabBody } from '@components/Tabs/components/TabBody'
import { TabHeader } from '@components/Tabs/components/TabHeader'
import { Tabs } from '@components/Tabs/Tabs'
import Loading from '@components/utils/Loading'
import BuyToSeeDetail from '@features/tradeCopy/components/BuyToSeeDetail'
import PersonalWinRate from '@features/tradeCopy/components/PersonalWinRate'
import ProfileHeader from '@components/ProfileHeader/ProfileHeader'
import StrategyDetail from '@features/tradeCopy/components/StrategyDetail'
import { CopyBySymbolPageProps } from './interface'
import withCopyBySymbolPage from './withCopyBySymbolPage'

function CopyBySymbolPage({
  handleAccmulateParams,
  accmulate,
  name,
  description,
  detail,
  isLoadingDetail,
  refetchTradeCopyByBot,
  id,
  profileImg,
}: CopyBySymbolPageProps) {
  return (
    <div className='py-[10px] max-w-[700px] mx-auto'>
      <ProfileHeader
        name={name}
        description={description}
        profileImgPath={profileImg}
      />
      <Tabs initialActiveTab='personalProfile'>
        <Tab tabId='personalProfile'>
          <TabHeader>
            <span className='desktop:w-max'>Personal Profile</span>
          </TabHeader>
          <TabBody>
            <PersonalWinRate
              name={name}
              accmulate={accmulate}
              handleAccmulateParams={handleAccmulateParams}
              className='px-[16px] py-[20px]'
            />
          </TabBody>
        </Tab>
        <Tab tabId='strategyDetail'>
          <TabHeader>
            <span className='desktop:w-max'>Strategy Detail</span>
          </TabHeader>
          <TabBody>
            {isLoadingDetail ? (
              <Loading text='Loading' />
            ) : (
              <>
                {detail ? (
                  <StrategyDetail
                    className='px-[24px] py-[30px]'
                    symbol={detail.symbol}
                    strategy={detail.strategyData}
                    isShowCreateBotButton
                  />
                ) : (
                  <BuyToSeeDetail
                    mode='bySymbol'
                    id={Number(id)}
                    refetchDetail={refetchTradeCopyByBot}
                  />
                )}
              </>
            )}
          </TabBody>
        </Tab>
      </Tabs>
    </div>
  )
}

export default withCopyBySymbolPage(CopyBySymbolPage)
