import { Expose } from 'class-transformer'
import { MarginTypeEnum } from '@interfaces/MarginTypeEnum'

export class GridBotForm {
  @Expose()
  symbol: string

  @Expose()
  marginType: MarginTypeEnum

  @Expose()
  initialMargin: number

  @Expose()
  leverage: number

  @Expose()
  lowerPrice: number

  @Expose()
  upperPrice: number

  @Expose()
  gridCount: number

  @Expose()
  gridQty: number

  @Expose()
  profitPerGridLower: number

  @Expose()
  profitPerGridHigher: number

  @Expose()
  isStopTrigger: boolean
}
