import React from 'react'
import { SelectEasySymbolProps } from '@features/bot/pages/CreateEasyBotPage/components/SelectEasySymbol/interface'
import { TimeIcon } from '@components/Icons/Icons'
import SelectSimpleField from '@components/form/SelectSimpleField/SelectSimpleField'
import withSelectEasySymbol from './withSelectEasySymbol'

const SelectEasySymbol = ({
  symbols,
  handleOnRemoveSymbol,
  isAllowSelect,
  excludeSymbolOptions,
  handleOnAddSymbol,
  symbolError,
}: SelectEasySymbolProps) => {
  return (
    <div className='flex flex-col space-y-[10px]'>
      {isAllowSelect && (
        <SelectSimpleField
          label='Select symbol'
          placeholder='Select symbol...'
          options={excludeSymbolOptions}
          onSelectedChange={handleOnAddSymbol}
        />
      )}
      {symbolError && <div className='text-red text-sm'>{symbolError}</div>}
      <div className='flex flex-wrap gap-[8px]'>
        {symbols.map((symbol, index) => {
          return (
            <div
              className='flex items-center p-[8px] border border-gray-300 rounded-[5px] space-x-4 min-w-[150px]'
              key={index}
            >
              <div className='flex items-center flex-1 space-x-[8px] '>
                <img
                  src={symbol.symbolImgPath}
                  alt={symbol.symbol}
                  className='w-[20px] h-[20px] object-cover'
                />
                <span className='text-[12px]'>{symbol.symbol}</span>
              </div>
              <TimeIcon
                className='h-[16px] w-[16px] cursor-pointer'
                onClick={() => handleOnRemoveSymbol(index)}
              />
            </div>
          )
        })}
      </div>
    </div>
  )
}

const WrappedComponent = withSelectEasySymbol(SelectEasySymbol)
export default WrappedComponent
