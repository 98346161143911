import { useBannersQuery } from '@client/mainClient/collections/BannersClientAction'
import React from 'react'
import { BannerAcceptProps, BannerProps } from './interface'

const withBanner = (Component: React.FC<BannerProps>) => {
  const Hoc = ({ className }: BannerAcceptProps) => {
    const { data, isLoading } = useBannersQuery(
      { isPopUp: false },
      {
        refetchOnMountOrArgChange: true,
      }
    )

    const newProps = {
      isLoading,
      banners: data,
      className,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withBanner
