import { botApi } from '@client/client'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { gql } from 'graphql-request'

export const botStrategyApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    botStrategy: builder.query<BotStrategiesDefault, { botStrategyId: number }>(
      {
        query: ({ botStrategyId }) => ({
          document: gql`
            query BotStrategy($botStrategyId: Float!) {
              botStrategy(BotStrategyId: $botStrategyId) {
                id
                isCycle
                name
                isStopLoss
                isHedgeMode
                isSplitTp
                isInfinityCycle
                marginType
                side
                isCovering
                cycleQuantity
                isFirstOrderDouble
                firstOrderRawQuote
                firstOrderTradeQuote
                leverage
                coveringQuantity
                tpRetracement
                tpRatio
                tpRatioSplit
                tpRetracementSplit
                stopLossRatio
                stopLossLimit
                createdAt
                divisionAndControlProfit
                coverSpreads
                coverSpreadTrends
                coverMultiples
                pullbackSpreads
                profitProportion
              }
            }
          `,
          variables: { botStrategyId },
        }),
        transformResponse(res: { botStrategy: BotStrategiesDefault }) {
          const { botStrategy } = res
          return botStrategy
        },
        providesTags: (result, error, arg) => [{ type: 'BotStrategy', ...arg }],
      }
    ),
  }),
})

export const { useLazyBotStrategyQuery, useBotStrategyQuery } = botStrategyApi
