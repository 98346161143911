import { Expose, Transform } from 'class-transformer'

export class ProfitProportionForm {
  @Expose()
  @Transform(
    ({ value }) => value?.filter(proportion => proportion !== undefined) || [],
    {
      toPlainOnly: true,
    }
  )
  profitProportion: number[] | undefined[]
}
