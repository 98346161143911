import { botApi } from '@client/client'
import { gql } from 'graphql-request'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { BotCount } from '@models/Bot/BotCount'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const countBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    countBot: builder.query<
      BotCount,
      { botStatus?: BotStatusEnum; botMarketType?: BotMarketType }
    >({
      query: ({ botStatus, botMarketType }) => ({
        document: gql`
          query CountBotLongShort(
            $botStatus: BotStatus
            $botMarketType: BotMarketType!
          ) {
            countBotLongShort(
              status: $botStatus
              botMarketType: $botMarketType
            ) {
              botLong
              botShort
            }
          }
        `,
        variables: { botStatus, botMarketType },
      }),
      transformResponse(res: { countBotLongShort: BotCount }) {
        const { countBotLongShort } = res
        return countBotLongShort
      },
      providesTags: ['BotCount'],
    }),
  }),
})

export const { useCountBotQuery } = countBotApi
