import { GraphQLClient } from 'graphql-request'
import {
  ADMIN_API_URL,
  BOT_API_URL,
  EXCHANGE_API_URL,
  MAIN_API_URL,
} from '@config/config'
import { createApi } from '@reduxjs/toolkit/query/react'
import { graphqlRequestBaseQuery } from '@rtk-query/graphql-request-base-query'
import { baseQueryWithReauth } from '@client/baseQueryWithReAuth'

// eslint-disable-next-line consistent-return
export const getToken = () => {
  const session = localStorage.getItem('persist:authentication')
  if (session) {
    const sessionObj = JSON.parse(session)
    // DESC: remove double quotes
    return sessionObj.accessToken?.replaceAll('"', '')
  }
}

export const mainClient = new GraphQLClient(MAIN_API_URL, {
  headers: { exchange: 'binance', authorization: `Bearer ${getToken()}` },
})

export const mainApi = createApi({
  baseQuery: (args, api, extraOptions) =>
    baseQueryWithReauth({
      args,
      api,
      extraOptions,
      baseQuery: graphqlRequestBaseQuery({
        client: mainClient,
      }),
    }),
  reducerPath: 'mainApi',
  endpoints: () => ({}),
  tagTypes: [
    'Profile',
    'Profit',
    'AuthExchange',
    'Tier',
    'Tiers',
    'Downlines',
    'Upline',
    'ProfitRank',
    'ActiveAnnouncement',
    'Banners',
    'UserProfitByDay',
    'UserProfitByMonth',
    'KhotcoinPackages',
    'KhotcoinTransactions',
    'TopupSession',
    'Wallets',
  ],
})

export const exchangeClient = new GraphQLClient(EXCHANGE_API_URL, {
  headers: { exchange: 'binance', authorization: `Bearer ${getToken()}` },
})
export const exchangeApi = createApi({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseQuery: (args, api, extraOptions) =>
    baseQueryWithReauth({
      args,
      api,
      extraOptions,
      baseQuery: graphqlRequestBaseQuery({ client: exchangeClient }),
    }),
  reducerPath: 'exchangeApi',
  endpoints: () => ({}),
  tagTypes: [
    'Symbols',
    'Leverage',
    'WalletBalance',
    'Transaction',
    'ExchangeBalance',
    'MarketDetail',
    'TrendingCoin',
    'SumAmountWalletTransaction',
    'WithdrawRequested',
  ],
})

export const botClient = new GraphQLClient(BOT_API_URL, {
  headers: { exchange: 'binance', authorization: `Bearer ${getToken()}` },
})
export const botApi = createApi({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseQuery: (args, api, extraOptions) =>
    baseQueryWithReauth({
      api,
      args,
      extraOptions,
      baseQuery: graphqlRequestBaseQuery({ client: botClient }),
    }),
  reducerPath: 'botApi',
  endpoints: () => ({}),
  tagTypes: [
    'BotStrategies',
    'BotStrategy',
    'BotStrategiesOptions',
    'Bots',
    'Bot',
    'TradeActions',
    'ContractualAsset',
    'BotProfitByCoin',
    'TradeCopyByBots',
    'TradeCopyByBot',
    'AccumulateByStrategyId',
    'AccumulateByUserId',
    'TradeCopyPersons',
    'TradeCopyByPersonBotDetails',
    'TpHistory',
    'MyTradeCopyByBot',
    'BotCount',
    'BotWithoutSubscription',
    'EasyTradeTemplateOptions',
    'EasyTradeTemplateDefault',
    'EasyTradeOwners',
    'IsOneOffAll',
    'IsStopCoveringAll',
  ],
})

export const adminClient = new GraphQLClient(ADMIN_API_URL, {
  headers: { exchange: 'binance', authorization: `Bearer ${getToken()}` },
})
export const adminApi = createApi({
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  baseQuery: (args, api, extraOptions) =>
    baseQueryWithReauth({
      args,
      api,
      extraOptions,
      baseQuery: graphqlRequestBaseQuery({ client: adminClient }),
    }),
  reducerPath: 'adminApi',
  endpoints: () => ({}),
  tagTypes: ['SystemMigration'],
})
