import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const updateStrategyDescriptionApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    updateStrategyDescription: builder.mutation<
      void,
      { strategyDescription: string }
    >({
      query: ({ strategyDescription }) => ({
        document: gql`
          mutation UpdateStrategyDescription($strategyDescription: String!) {
            updateStrategyDescription(
              strategyDescription: $strategyDescription
            ) {
              id
            }
          }
        `,
        variables: {
          strategyDescription,
        },
      }),
    }),
  }),
})

export const { useUpdateStrategyDescriptionMutation } =
  updateStrategyDescriptionApi
