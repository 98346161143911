import React from 'react'
import cn from 'classnames'
import { ButtonInterfaceProps } from './interface'

function PrimaryButton({
  type,
  className,
  children,
  isError,
  disabled,
  disableClassName,
  ...props
}: ButtonInterfaceProps) {
  const bgColor = disabled ? 'bg-gray-28' : isError ? 'bg-red' : 'bg-primary'
  return (
    <button
      type={type}
      className={cn(
        'p-[12px] text-sm font-bold disabled:opacity-40 text-white disabled:cursor-not-allowed',
        className,
        { 'rounded-[18px]': !className },
        { [bgColor]: true },
        disableClassName
      )}
      disabled={disabled}
      {...props}
    >
      <div className='select-none'>{children}</div>
    </button>
  )
}

export default PrimaryButton
