import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { TimeSeries } from '@models/Chart/TimeSeries'

export const userProfitByMonthApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    userProfitByMonth: builder.query<TimeSeries[], { userId: number }>({
      query: ({ userId }) => ({
        document: gql`
          query GetUserProfitByMonthGraphData($userId: Float!) {
            getUserProfitByMonthGraphData(userId: $userId) {
              date
              value
            }
          }
        `,
        variables: { userId },
      }),
      transformResponse(res: { getUserProfitByMonthGraphData: TimeSeries[] }) {
        const { getUserProfitByMonthGraphData } = res
        return getUserProfitByMonthGraphData
      },
      providesTags: ['UserProfitByMonth'],
    }),
  }),
})

export const { useLazyUserProfitByMonthQuery } = userProfitByMonthApi
