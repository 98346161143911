import React from 'react'
import { useNavigate } from 'react-router-dom'
import { CheckIcon } from '@components/Icons/Icons'
import PrimaryButton from '@components/buttons/PrimaryButton'
import OutlinedButton from '@components/buttons/OutlinedButton'
import GridBotSettingForm from '@features/bot/pages/CreateGridBotPage/components/GridBotSettingForm/GridBotSettingForm'
import { ContractRoutesPath } from '@config/Router'
import withCreateGridBotPage from './withCreateGridBotPage'
import { CreateGridBotPageProps } from './interface'

const tabNavigates = [
  {
    id: 'dashboard',
    title: 'Dashboard',
  },
  {
    id: 'create',
    title: 'Create',
  },
]

const CreateGridBotPage = ({
  nextStep,
  backStep,
  activeStep,
  progressPercent,
  mode,
  steps,
}: CreateGridBotPageProps) => {
  const navigate = useNavigate()
  return (
    <div className='max-w-[700px] mx-auto '>
      <div className='border-gray-secondary border-b select-none text-sm'>
        <div className='w-full lg:w-1/2 text-center cursor-pointer flex'>
          {tabNavigates.map(tab => (
            <div
              key={tab.id}
              className={`flex-1 flex justify-center items-center px-3 py-2 border-b ${
                tab.id === 'create'
                  ? 'border-primary text-primary font-medium'
                  : 'border-gray-secondary'
              } `}
              onClick={() =>
                tab.id === 'dashboard' &&
                navigate(ContractRoutesPath.dashboard.absolutePath)
              }
            >
              {tab.title}
            </div>
          ))}
        </div>
      </div>
      <div className='pt-[51px] px-[15px]'>
        <div className='relative px-[23px] py-[12px] mb-[65px]'>
          <div className='w-full h-[1px] bg-gray-59'>
            <div
              className={`transition-all duration-500 bg-green h-full ${progressPercent}`}
            />
          </div>
          {steps.map((step, i) => (
            <div
              key={i}
              className={`absolute top-0 flex flex-col items-center ${step.className}`}
            >
              <div
                className={`flex transition-all duration-500 items-center justify-center bg-gray-dark w-[24px] h-[24px] rounded-full border ${
                  activeStep === i
                    ? 'border-primary text-primary'
                    : i < activeStep
                    ? 'border-green bg-green text-white'
                    : 'border-gray-59 text-gray-59'
                }`}
              >
                {i < activeStep ? (
                  <CheckIcon width='16' height='16' />
                ) : (
                  step.icon
                )}
              </div>
              <div
                className={`mt-[8px] text-[10px] w-[74px] text-center ${
                  i === activeStep ? 'font-bold' : 'text-gray-59'
                }`}
              >
                {step.title}
              </div>
            </div>
          ))}
        </div>
        <GridBotSettingForm
          activeStep={activeStep}
          backStep={backStep}
          mode={mode}
        />

        {activeStep === 0 && (
          <PrimaryButton
            type='button'
            className='w-full mt-[10px] rounded-[18px]'
            onClick={nextStep}
          >
            Next
          </PrimaryButton>
        )}
      </div>
    </div>
  )
}

export default withCreateGridBotPage(CreateGridBotPage)
