import PrimaryButton from '@components/buttons/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'

import Loading from '@components/utils/Loading'
import NoData from '@components/NoData/NoData'
import React from 'react'
import { TradeSideEnum } from '@interfaces/TradeSideEnum'
import CycleTag from '@features/bot/components/CycleTag/CycleTag'
import { DateTime } from 'luxon'
import { BinOutlineIcon } from '@components/Icons/Icons'
import { ManageStrategyProps } from '@features/bot/pages/ManageStrategy/interface'
import { BotMarketType } from '@interfaces/BotMarketEnum'

import withManageStrategy from './withManageStrategy'

const ManageStrategy = ({
  strategies,
  isLoading,
  handleDeleteStrategy,
  botMarketType,
}: ManageStrategyProps) => {
  const navigate = useNavigate()
  const handleStrategyNavigate = (id: number) => {
    if (botMarketType === BotMarketType.FUTURE)
      navigate(ContractRoutesPath.updateStrategy.absolutePath({ id }))
    else navigate(SpotRoutesPath.updateStrategy.absolutePath({ id }))
  }
  return (
    <div className='max-w-[700px] mx-auto px-[17px] desktop:mt-2 mb-9'>
      {/* Create button */}
      <div className='flex justify-end mb-[25px]'>
        <PrimaryButton
          type='button'
          className='rounded-md h-[32px] text-sm py-[5px] px-[16px]'
          onClick={() =>
            navigate(
              botMarketType === BotMarketType.FUTURE
                ? ContractRoutesPath.createStrategy.absolutePath
                : SpotRoutesPath.createStrategy.absolutePath
            )
          }
        >
          Create Strategy
        </PrimaryButton>
      </div>
      {isLoading && <Loading text='Loading...' />}
      {(strategies === undefined || !strategies.length) && <NoData />}
      {/*   Strategy list */}
      <div className='flex flex-col space-y-[9px]'>
        {strategies?.map(strategy => (
          <div
            className='flex items-center  border-1 border-gray-300 rounded-md py-[10px] px-[15px] cursor-pointer'
            key={strategy.id}
            onClick={() => handleStrategyNavigate(strategy.id!)}
          >
            {/* Logo */}
            <img
              src='/logo512.png'
              alt='logo'
              width={40}
              height={40}
              className='mr-[10px]'
            />
            {/*  Strategy detail */}
            <div className='flex flex-1 flex-col space-y-[12px]'>
              {/* Name */}
              <div className='flex space-x-1'>
                <span className='flex-grow text-sm font-bold'>
                  {strategy.name}
                </span>
                <BinOutlineIcon
                  className='flex-shrink-0'
                  height='20px'
                  width='20px'
                  onClick={e => {
                    e.stopPropagation()
                    handleDeleteStrategy(strategy.id!)
                  }}
                />
              </div>
              {/* Tags */}
              <div className='flex space-x-2'>
                {/* Side */}
                <div
                  className={`flex items-center px-2 h-[20px] text-xs border-1 ${
                    strategy?.side === TradeSideEnum.LONG
                      ? 'text-green border-green'
                      : 'text-red border-red'
                  }`}
                >
                  {strategy?.side === TradeSideEnum.LONG ? 'Long' : 'Short'}
                </div>
                {/* Leverage */}
                <div className='flex items-center px-2 h-[20px] text-xs border-1 text-primary border-primary'>
                  x{strategy?.leverage}
                </div>
                {/* Cycle tag */}
                <CycleTag
                  cycleSetting={
                    strategy?.isInfinityCycle
                      ? 'infinity'
                      : strategy?.isCycle
                      ? 'cycle'
                      : 'single'
                  }
                />
              </div>
              <span className='text-[10px]'>
                created date:{' '}
                {DateTime.fromMillis(strategy?.createdAt).toFormat(
                  'dd-MM-yyyy HH:mm:ss'
                ) ?? '-'}
              </span>
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

const WrappedComponent = withManageStrategy(ManageStrategy)
export default WrappedComponent
