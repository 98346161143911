import React from 'react'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { ChangePasswordForm } from '@models/User/ChangePasswordForm'
import { useUpdateUserPasswordMutation } from '@client/mainClient/collections/UpdateUserPasswordClientAction'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { OtherRoutesPath } from '@config/Router'
import { ChangePasswordPageProps } from './interface'

const withChangePasswordPage = (
  Component: React.FC<ChangePasswordPageProps>
) => {
  function WithChangePasswordPage() {
    const [changePasswordMutate, { isLoading }] =
      useUpdateUserPasswordMutation()
    const { profile } = useSelector(authenticationSelector)
    const navigate = useNavigate()

    const handleChangePassword = (form: ChangePasswordForm) => {
      if (profile?.id) {
        changePasswordMutate({
          id: Number(profile.id),
          password: form.password,
          oldPassword: form.oldPassword,
        })
          .unwrap()
          .then(() => {
            navigate(OtherRoutesPath.other.path)
            toast.success('Change password success')
          })
          .catch(() => {})
      }
    }

    const newProps = { handleChangePassword, isLoading }
    return <Component {...newProps} />
  }

  return WithChangePasswordPage
}

export default withChangePasswordPage
