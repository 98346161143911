import { gql } from 'graphql-request'
import { plainToClass } from 'class-transformer'
import { botApi } from '@client/client'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'

export const getMyTradeCopyByBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMyTradeCopyByBot: builder.query<TradeCopyByBot[], void>({
      query: () => ({
        document: gql`
          query GetMyTradeCopyByBot {
            getMyTradeCopyByBot {
              id
              symbol
              baseCurrencyData {
                imgPath
              }
              buyDate
              user {
                name
              }
              endAt
              profit
            }
          }
        `,
      }),
      transformResponse(res: { getMyTradeCopyByBot: TradeCopyByBot[] }) {
        return plainToClass(TradeCopyByBot, <any[]>res.getMyTradeCopyByBot)
      },
    }),
  }),
})

export const { useGetMyTradeCopyByBotQuery } = getMyTradeCopyByBotApi
