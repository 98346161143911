import { useBuyTradeCopyByBotMutation } from '@client/exchangeClient/collections/BuyTradeCopyByBot'
import { useBuyTradeCopyByPersonMutation } from '@client/exchangeClient/collections/BuyTradeCopyByPerson'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { EyeSlashIcon } from '@components/Icons/Icons'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { useState } from 'react'
import { toast } from 'react-toastify'

function BuyToSeeDetail({
  mode,
  id,
  refetchDetail,
  numberOfSymbol,
}: {
  mode: 'bySymbol' | 'byPersonal'
  id: number
  refetchDetail: () => void
  numberOfSymbol?: number
}) {
  const [buyTradeCopyByBotMutate] = useBuyTradeCopyByBotMutation()
  const [buyTradeCopyByPersonMutation] = useBuyTradeCopyByPersonMutation()
  const [isLoading, setIsLoading] = useState(false)

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm }: ConfirmModalProps) => (
      <ConfirmModal
        message={
          <div className='flex flex-col items-center'>
            <div className='text-lg font-medium'>Confirm</div>
            <p className='text-sm'>Are you sure to buy strategy</p>
            <p className=' text-sm font-medium'>
              ({mode === 'bySymbol' ? 5 : ((numberOfSymbol || 1) * 5) / 2}{' '}
              Khotcoins)
            </p>
          </div>
        }
        onConfirm={onConfirm}
      />
    ),
  })

  const handleBuyTransaction = (result: boolean) => {
    if (!result) {
      toast.success(`Error occurred`)
      setIsLoading(false)
    } else {
      toast.success(`Successful`)
      setIsLoading(false)
      refetchDetail()
    }
  }

  return (
    <div className='mt-[134px] flex flex-col items-center gap-y-[24px]'>
      <EyeSlashIcon className='w-[60px] h-[60px]' />
      <span className='text-xs font-light text-gray-400'>
        Please buy to see the strategy detail.
      </span>
      <PrimaryButton
        type='button'
        className='w-[71px] py-[7px] rounded-[4px] text-xs font-medium'
        disabled={isLoading}
        onClick={() =>
          openModal({
            onConfirm: async () => {
              setIsLoading(true)
              try {
                if (mode === 'bySymbol') {
                  const res = await buyTradeCopyByBotMutate(id).unwrap()
                  handleBuyTransaction(res)
                } else {
                  const res = await buyTradeCopyByPersonMutation(id).unwrap()
                  handleBuyTransaction(res)
                }
              } catch (_) {
                setIsLoading(false)
              }
            },
          })
        }
      >
        Buy
      </PrimaryButton>
    </div>
  )
}

export default BuyToSeeDetail
