import React, { useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import Loading from '@components/utils/Loading'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { AuthenticationRoutesPath } from '@config/Router'

export const withUnActivateGuard = (Component: React.FC<any>) => {
  function HOC(props: any) {
    const navigate = useNavigate()
    const location = useLocation()
    const { isAuthentication, profile } = useSelector(authenticationSelector)

    useEffect(() => {
      if (!isAuthentication) {
        navigate(AuthenticationRoutesPath.login.absolutePath)
      } else if (!profile?.isActivate) {
        navigate(AuthenticationRoutesPath.activate.absolutePath, {
          replace: true,
          state: location,
        })
      }
    }, [isAuthentication, location, navigate, profile])

    if (!isAuthentication) {
      return <Loading text='Loading' />
    }

    return <Component {...props} />
  }
  return HOC
}
