import { useState } from 'react'
import { toast } from 'react-toastify'
import { createSearchParams, useNavigate } from 'react-router-dom'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { CircleOutlineCheckIcon, UserCircleIcon } from '@components/Icons/Icons'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import { TradeCopyRoutesPath } from '@config/Router'
import { TradeCopyByPerson } from '@models/TradeCopy/TradeCopyByPerson'
import { useBuyTradeCopyByPersonMutation } from '@client/exchangeClient/collections/BuyTradeCopyByPerson'
import TextTitleWithValue from './TextTitleWithValue'

interface PersonalCardProps {
  personal: TradeCopyByPerson
  refetch: () => void
}

function PersonalCard({ personal, refetch }: PersonalCardProps) {
  const navigate = useNavigate()

  const [buyTradeCopyByPersonMutation] = useBuyTradeCopyByPersonMutation()
  const [isLoading, setIsLoading] = useState(false)

  const handleBuyTransaction = (result: boolean) => {
    if (!result) {
      toast.success(`Error occurred`)
      setIsLoading(false)
    } else {
      toast.success(`Successful`)
      setIsLoading(false)
      refetch()
    }
  }

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm }: ConfirmModalProps) => (
      <ConfirmModal
        message={
          <div className='flex flex-col items-center'>
            <div className='text-lg font-medium'>Confirm</div>
            <p className='text-sm'>Are you sure to buy strategy</p>
            <p className=' text-sm font-medium'>
              ({(personal.numberOfSymbol * 5) / 2} Khotcoins)
            </p>
          </div>
        }
        onConfirm={onConfirm}
      />
    ),
  })

  return (
    <div
      className='border border-gray-300 rounded-[6px] py-[16px] px-[13px] cursor-pointer'
      onClick={() =>
        navigate({
          pathname: TradeCopyRoutesPath.tradeCopy.byPersonal.absolutePath({
            id: personal.id,
          }),
          search: `?${createSearchParams({
            name: encodeURIComponent(personal.user.name),
            description: encodeURIComponent(personal.user.strategyDescription),
            userId: `${personal.user.id}`,
            profileImg: encodeURIComponent(personal.user.profileImgPath || ''),
            numberOfSymbol: encodeURIComponent(personal.numberOfSymbol),
          })}`,
        })
      }
    >
      <div className='flex items-center gap-x-[11px] border-b border-gray-600 pb-[9px]'>
        {personal.user.profileImgPath ? (
          <img
            src={personal.user.profileImgPath}
            alt='Profile'
            className='w-[40px] h-[40px] rounded-full'
          />
        ) : (
          <UserCircleIcon className='w-[50px] h-[50px]' />
        )}
        <div className='flex-1 text-sm font-bold'>{personal.user.name}</div>
        <div className='flex flex-col items-end gap-y-[6px]'>
          {personal.isAlreadyBuy ? (
            <CircleOutlineCheckIcon />
          ) : (
            <PrimaryButton
              type='button'
              className='w-[71px] py-[7px] rounded-[4px] text-xs font-medium'
              disabled={isLoading}
              onClick={event => {
                event.stopPropagation()
                openModal({
                  onConfirm: async () => {
                    setIsLoading(true)
                    try {
                      const res = await buyTradeCopyByPersonMutation(
                        personal.id
                      ).unwrap()
                      handleBuyTransaction(res)
                    } catch (_) {
                      setIsLoading(false)
                    }
                  },
                })
              }}
            >
              Buy
            </PrimaryButton>
          )}
          <div className='text-[10px]'>
            <span className='text-gray-400'>Yesterday profit (USDT): </span>
            <span className='font-bold text-green-100'>
              {personal.yesterdayProfit.toLocaleString(undefined, {
                minimumFractionDigits: 0,
                maximumFractionDigits: 2,
              })}
            </span>
          </div>
        </div>
      </div>
      <div className='mt-[14px] grid grid-cols-3'>
        <TextTitleWithValue
          title='No of Symbol'
          value={personal.numberOfSymbol.toString()}
        />
        <TextTitleWithValue
          title='TP Count'
          value={personal.tpCount.toString()}
        />
        <TextTitleWithValue
          title='Total Profit (USDT)'
          value={personal.totalProfit.toLocaleString(undefined, {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
          })}
        />
      </div>
    </div>
  )
}

export default PersonalCard
