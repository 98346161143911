import rankOne from '@assets/icons/rankOne.png'
import rankTwo from '@assets/icons/rankTwo.png'
import rankThree from '@assets/icons/rankThree.png'
import { RankCardProps } from '../interface'
import RankProfileImage from './RankProfileImage'

function TopRank({
  name,
  profit,
  rank,
  profileImagePath,
  profileImageClassName,
  onClickCallback,
}: RankCardProps) {
  return (
    <div
      className='flex flex-col items-center space-y-2 min-w-[150px] mobile:min-w-[100px] cursor-pointer'
      onClick={onClickCallback}
    >
      <RankProfileImage
        rank={rank}
        profileImagePath={profileImagePath}
        profileImageClassName={profileImageClassName}
      />
      <div>
        <div className='flex justify-center items-center'>
          <p className='text-center text-xs font-bold inline-block pr-[4px]'>
            {name}
          </p>
          {rank === 1 ? (
            <img src={rankOne} alt='rank-1' className='h-[20px] w-[20px]' />
          ) : rank === 2 ? (
            <img src={rankTwo} alt='rank-2' className='h-[20px] w-[20px]' />
          ) : rank === 3 ? (
            <img src={rankThree} alt='rank-3' className='h-[20px] w-[20px]' />
          ) : (
            <div />
          )}
        </div>
        <p className='text-center text-[10px] text-gray-400'>
          {profit.toFixed(2)} USDT
        </p>
      </div>
    </div>
  )
}

export default TopRank
