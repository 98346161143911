import NoData from '@components/NoData/NoData'
import Loading from '@components/utils/Loading'
import ProfitByCoinCard from './components/ProfitByCoinCard'
import { ProfitByCoinProps } from './interface'
import withProfitByCoin from './withProfitByCoin'

const ProfitByCoin = ({
  className,
  sortDate,
  profitCoins,
  isLoading,
}: ProfitByCoinProps) => {
  if (isLoading) {
    return <Loading text='Loading...' />
  }
  if (profitCoins.length <= 0) {
    return <NoData />
  }
  return (
    <div className={`flex flex-col space-y-3 ${className}`}>
      {profitCoins.map((profitCoin, index) => {
        return (
          <ProfitByCoinCard
            key={`${sortDate}+${index}`}
            sortDate={sortDate}
            profitCoin={profitCoin}
          />
        )
      })}
    </div>
  )
}
export default withProfitByCoin(ProfitByCoin)
