import { gql } from 'graphql-request'
import { plainToClass } from 'class-transformer'
import { mainApi } from '@client/client'
import { AuthExchange } from '@models/AuthExchange/AuthExchange'

export const authExchangesApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    authExchanges: builder.query<AuthExchange[], void>({
      query: () => ({
        document: gql`
          query AuthExchanges {
            authExchanges {
              id
              apiKey
              secret
            }
          }
        `,
      }),
      transformResponse(res: { authExchanges: AuthExchange[] }) {
        const { authExchanges } = res
        return plainToClass(AuthExchange, <any[]>authExchanges)
      },
      providesTags: ['AuthExchange'],
    }),
  }),
})

export const { useAuthExchangesQuery } = authExchangesApi
