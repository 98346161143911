import React from 'react'
import { toast } from 'react-toastify'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useParams } from 'react-router-dom'
import {
  StopCoveringAllButtonAcceptProps,
  StopCoveringAllButtonProps,
} from '@features/bot/components/BotPagination/components/StopCoveringAllButton/interface'
import { botApi } from '@client/client'
import { useStopCoveringAllMutation } from '@client/botClient/collections/StopCoveringAllClientAction'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { useAppDispatch } from '../../../../../../store'

const WithStopCoveringAllButton = (
  Component: React.FC<StopCoveringAllButtonProps>
) => {
  const Hoc = ({ isStopCoveringAll }: StopCoveringAllButtonAcceptProps) => {
    const isDisable = isStopCoveringAll === undefined
    const { botMarketType } = useBotMarketType()
    const { id } = useParams()
    const dispatch = useAppDispatch()
    const [stopCoveringAllMutate, { isLoading: isOneOffLoading }] =
      useStopCoveringAllMutation()
    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to {!isStopCoveringAll ? 'stop' : 'resume'} cover
                all bots
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleOnStopCovering = () => {
      if (isDisable) return
      openModal({
        onConfirm: async () => {
          await stopCoveringAllMutate({
            botMarketType,
            isStopCovering: !isStopCoveringAll,
          })
          toast.success(
            `${
              !isStopCoveringAll ? 'Stop' : 'Resume'
            } covering all bots successful`
          )
          dispatch(botApi.util.invalidateTags([{ type: 'Bots' }]))
          dispatch(botApi.util.invalidateTags([{ type: 'Bot' }]))
          dispatch(botApi.util.invalidateTags([{ type: 'IsStopCoveringAll' }]))
        },
      })
    }
    const newProps: StopCoveringAllButtonProps = {
      handleOnStopCovering,
      isStopCoveringAll,
      isDisable,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithStopCoveringAllButton
