import React, { useEffect, useMemo, useState } from 'react'
import { RankingChartProps } from '@features/other/pages/RankingChartPage/interface'
import { useParams, useSearchParams } from 'react-router-dom'
import { useLazyUserProfitByMonthQuery } from '@client/mainClient/collections/UserProfitByMonthClientAction'
import { useLazyUserProfitByDayQuery } from '@client/mainClient/collections/UserProfitByDayClientAction'
import { TimeSeries } from '@models/Chart/TimeSeries'
import { EChartsOption } from 'echarts'
import { DateTime } from 'luxon'

const WithRankingChartPage = (Component: React.FC<RankingChartProps>) => {
  const Hoc = () => {
    const { userId, mode } = useParams()

    const [params] = useSearchParams()
    const [data, setData] = useState<TimeSeries[]>([])

    const profileImgPath = useMemo(() => {
      return params.get('profileImgPath') === null
        ? undefined
        : decodeURIComponent(params.get('profileImgPath')!)
    }, [params])

    const rank = useMemo(() => {
      return params.get('rank') === null
        ? undefined
        : Number(decodeURIComponent(params.get('rank')!))
    }, [params])

    const userName = useMemo(() => {
      return params.get('userName') === null
        ? undefined
        : decodeURIComponent(params.get('userName')!)
    }, [params])

    const strategyDescription = useMemo(() => {
      return params.get('strategyDescription') === null
        ? undefined
        : decodeURIComponent(params.get('strategyDescription')!)
    }, [params])

    const echartOption: EChartsOption = useMemo(() => {
      return {
        tooltip: { trigger: 'axis' },
        grid: {
          top: '10%',
          left: '3%',
          right: '5%',
          bottom: '3%',
          containLabel: true,
        },
        xAxis: {
          data: data.map(e => {
            if (mode === 'daily') {
              return DateTime.fromFormat(e.date, 'yyyy-MM-dd').toFormat(
                'MMM dd',
                {
                  locale: 'En',
                }
              )
            }
            return DateTime.fromFormat(e.date, 'yyyy-MM').toFormat('MMM ', {
              locale: 'En',
            })
          }),
        },
        yAxis: {
          type: 'value',
        },
        series: [
          {
            name: 'Profit (USDT)',
            type: 'line',
            data: data.map(e => e.value),
            color: '#21BB8E',
          },
        ],
      }
    }, [data, mode])

    const [getProfitByDay] = useLazyUserProfitByDayQuery()
    const [getProfitByMonth] = useLazyUserProfitByMonthQuery()

    const handleQuery = async () => {
      if (mode === 'daily') {
        const timeSeries = await getProfitByDay({
          userId: Number(userId),
        }).unwrap()
        setData(timeSeries)
      } else {
        const timeSeries = await getProfitByMonth({
          userId: Number(userId),
        }).unwrap()
        setData(timeSeries)
      }
    }

    useEffect(() => {
      handleQuery()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [mode, userId])

    const newProps: RankingChartProps = {
      timeSeries: [...data].reverse(),
      rank,
      userName: userName ?? '',
      strategyDescription: strategyDescription ?? '',
      profileImgPath,
      echartOption,
      mode: mode as 'total' | 'daily',
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithRankingChartPage
