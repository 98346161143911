import React, { useEffect } from 'react'
import { Field, useFormikContext } from 'formik'
import { useSelector } from 'react-redux'
import PhoneInput, { CountryData } from 'react-phone-input-2'
import { FieldPropsType } from '@interfaces/FieldPropsType'
import { useDebounce } from '@hooks/useDebounce'
import { RegisterForm } from '@models/User/RegisterForm'
import { usePhoneNumberUniqValidateMutation } from '@client/mainClient/collections/PhoneNumberUniqValidateClientAction'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import '../../../styles/phoneNumberInput.css'

interface PhoneNumberInputFieldProps {
  label?: string
  disabled?: boolean
}

function PhoneNumberInputField({
  label = 'Phone number',
  disabled = false,
}: PhoneNumberInputFieldProps) {
  const { values, setFieldValue, errors, touched } =
    useFormikContext<RegisterForm>()
  const { debounce } = useDebounce()
  const [mutate] = usePhoneNumberUniqValidateMutation()
  const { profile } = useSelector(authenticationSelector)

  useEffect(() => {
    if (values.phoneNumber && values.countryCode) {
      debounce(() => {
        mutate({
          phoneNumber: values.phoneNumber.slice(
            values.countryCode.toString().length
          ),
          phoneNumberCountryCode: values.countryCode,
        })
          .unwrap()
          .then(res => {
            setFieldValue('existPhoneNumber', res.status === 'exist')
            setFieldValue('userId', res.userId)
            setFieldValue(
              'myUserId',
              profile ? Number(profile?.id) === res.userId : undefined
            )
          })
          .catch(() => {
            setFieldValue('existPhoneNumber', true)
          })
      }, 400)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.phoneNumber, values.countryCode, profile])

  return (
    <div className='relative flex-wrap w-full items-stretch'>
      <div className='text-sm mb-2 font-medium text-white'>
        {label} <span className='text-red-dark'>*</span>
      </div>
      <Field name='phoneNumber'>
        {({ field, form }: FieldPropsType<any>) => (
          <PhoneInput
            country='th'
            value={field.value}
            onChange={(value, data: CountryData) => {
              form.setFieldValue('phoneNumber', value)
              form.setFieldValue('countryCode', Number(data.dialCode))
            }}
            disabled={disabled}
            containerStyle={{
              border:
                errors.phoneNumber && touched.phoneNumber
                  ? '1px solid #E55454'
                  : 'none',
            }}
          />
        )}
      </Field>
      {errors.phoneNumber && touched.phoneNumber && (
        <div className='mt-[2px] text-xs text-red top-full'>
          {errors.phoneNumber}
        </div>
      )}
    </div>
  )
}

export default PhoneNumberInputField
