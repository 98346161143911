import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import enJSON from './locales/en/translation.json'
import thJSON from './locales/th/translation.json'

i18n.use(initReactI18next).init({
  resources: { en: enJSON, th: thJSON },
  lng: 'en',
})

export default i18n
