export abstract class ApiKeyTutorialMessage {
  static step1 = '1. ไปที่แอพ Binance และกดเมนู “More”'

  static step2 =
    '2. เลื่อนลงมาที่หมวดหมู่เมนู “Other” หรือพิมพ์ค้นหา API management'

  static step3 = '3. กดเมนู “API Management”'

  static step4 = '4. กด “Create API”'

  static step5 = '5. เลือก “System generated” และกด “Next”'

  static step6 = '6. กรอกชื่อที่ต้องการ และกด “Next”'

  static step7 =
    '7. ทำตามกระบวนการ “Security Verification” ตามที่ตั้งค่าไว้ และกด “Submit”'

  static step8 = '8. กด “Edit” และกดไอคอนรูปสามเหลี่ยม'

  static step9 =
    '9. ติ๊กเลือก “Enable Futures” และ “Permits Universal Transfer”'

  static step10 = '10. กด “Save”'

  static step11 =
    '11. ทำตามกระบวนการ “Security Verification” ตามที่ตั้งค่าไว้ และกด “Submit”'

  static step12 = '12. คัดลอก “API Key” และ “Secret Key”'

  static step13 = '13. กลับไปที่ “Khotbot”'

  static step14 = '14. เลือกเมนู “Profile” กด “API Management”'

  static step15 = '15. กรอก “API Key” และ “Secret Key” และกด “Save”'

  static step16 =
    '16. ระบบจะแสดงยอดเงินใน Wallet Binance แสดงว่าดำเนินการสำเร็จ'
}
