import React from 'react'
import { Bot } from '@models/Bot/Bot'
import BotTabData from '@features/bot/pages/BotPage/components/BotTabData'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'

const TradeDetailTab = ({ bot }: { bot: Bot }) => {
  return (
    <div className='flex flex-col space-y-3 mt-[22px] lg:mx-11'>
      <div className='flex space-x-2'>
        {/* Total profit USDT */}
        <BotTabData
          title='Total profit (USDT)'
          isShowColorValue
          value={
            bot?.status === BotStatusEnum.RUNNING ||
            bot?.status === BotStatusEnum.STOP
              ? bot?.position?.unrealizedPnl?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                }) ?? '-'
              : bot?.status === BotStatusEnum.ERROR ||
                bot?.status === BotStatusEnum.FINISHED
              ? bot?.profit?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                }) ?? '-'
              : '-'
          }
        />
        {/* Total profit % */}
        <BotTabData
          title='Total profit (%)'
          isShowColorValue
          value={
            bot?.status === BotStatusEnum.RUNNING ||
            bot?.status === BotStatusEnum.STOP
              ? bot?.position?.percentage?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                }) ?? '-'
              : bot?.status === BotStatusEnum.ERROR ||
                bot?.status === BotStatusEnum.FINISHED
              ? bot?.profitPercentage?.toLocaleString(undefined, {
                  maximumFractionDigits: 4,
                }) ?? '-'
              : '-'
          }
        />
      </div>

      <div className='flex space-x-2'>
        {/* Current covering round */}
        <BotTabData
          title='Current covering round'
          value={bot?.botStrategy?.isCovering ? bot?.coverRound ?? '-' : '-'}
        />
        {/* Cover quantity % */}
        <BotTabData
          title='All covering round'
          value={
            bot?.botStrategy?.isCovering
              ? bot?.botStrategy?.coveringQuantity ?? '-'
              : '-'
          }
        />
      </div>

      <div className='flex space-x-2'>
        {/* Position held (Coin) */}
        <BotTabData
          title='Position held (Coin)'
          value={
            bot.position?.contracts?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
        {/* Average held price (USDT) */}
        <BotTabData
          title={`Average held price (${bot.symbol.split('USDT')[0]}/USDT)`}
          value={
            bot.positionAvgPrice.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
      </div>

      <div className='flex space-x-2'>
        {/* Contractual asset (USDT) */}
        <BotTabData
          title='Contractual asset (USDT)'
          value={
            (
              (bot.position?.collateral ?? 0) -
              (bot.position?.unrealizedPnl ?? 0)
            ).toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
        {/* Guarantee (USDT) */}
        <BotTabData
          title='Guarantee (USDT)'
          value={
            bot.position?.collateral.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
      </div>
      {/* Divider */}
      <div className='w-full h-[1px] my-[30px] bg-gray-500' />

      <div className='flex space-x-2'>
        {/* Limit price show only first cycle round */}
        {bot.cycleNo === 1 && (
          <BotTabData
            title='Limit price (USDT)'
            value={
              bot.botStrategy.limitPrice?.toLocaleString(undefined, {
                maximumFractionDigits: 4,
              }) ?? '-'
            }
          />
        )}
        {/* Market price (USDT) */}
        <BotTabData
          title='Market price (USDT)'
          value={
            bot.lastPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
      </div>

      <div className='flex space-x-2'>
        {/* Next order price (USDT) */}
        <BotTabData
          title='Next order price (USDT)'
          value={
            bot.nextOrderPrice.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
        {/* Next take profit price (USDT) */}
        <BotTabData
          title='Next take profit price (USDT)'
          value={
            bot.nextTakeProfitPrice.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
      </div>

      <div className='flex space-x-2'>
        {/* Pull back boundary price (USDT) */}
        <BotTabData
          title='Pull back boundary price (USDT)'
          value={
            bot.pullbackBoundaryPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
        {/* TPR boundary price (USDT) */}
        <BotTabData
          title='TPR boundary price (USDT)'
          value={
            bot.tprBoundaryPrice?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
      </div>

      <div className='flex space-x-2'>
        {/* Pull back active price (USDT) */}
        <BotTabData
          title='Pull back active price (USDT)'
          value={
            bot.pullbackActivePrice?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
        {/* TPR active price (USDT) */}
        <BotTabData
          title='TPR active price (USDT)'
          value={
            bot.tprActivePrice?.toLocaleString(undefined, {
              maximumFractionDigits: 4,
            }) ?? '-'
          }
        />
      </div>
    </div>
  )
}

export default TradeDetailTab
