import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { Bot } from '@models/Bot/Bot'

export const botWithoutSubscriptionAction = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    botWithoutSubscription: builder.query<
      Bot,
      {
        botId: number
      }
    >({
      query: ({ botId }) => ({
        document: gql`
          query Bot($botId: Float!) {
            bot(BotID: $botId) {
              id
              symbol
              isOneOff
              lastPrice
              nextOrderPrice
              nextTakeProfitPrice
              botControllerId
              botController {
                createdAt
                totalCycle
                cycleRound
              }
              botStrategy {
                id
                isCycle
                name
                isStopLoss
                isHedgeMode
                isSplitTp
                isInfinityCycle
                marginType
                side
                isCovering
                cycleQuantity
                isFirstOrderDouble
                firstOrderRawQuote
                firstOrderTradeQuote
                leverage
                coveringQuantity
                tpRetracement
                tpRatio
                tpRatioSplit
                tpRetracementSplit
                stopLossRatio
                stopLossLimit
                createdAt
                divisionAndControlProfit
                coverSpreads
                coverSpreadTrends
                coverMultiples
                pullbackSpreads
                profitProportion
                limitPrice
              }
              positionAvgPrice
              position {
                contracts
                collateral
                unrealizedPnl
              }
              profit
              profitPercentage
              status
              coverRound
              endAt
              createdAt
              errorDescription
              pullbackBoundaryPrice
              pullbackActivePrice
              tprBoundaryPrice
              tprActivePrice
              cycleNo
              startedAt
            }
          }
        `,
        variables: { botId },
      }),
      transformResponse(res: { bot: Bot }) {
        const { bot } = res
        return bot
      },
      providesTags: (result, error, arg) => [
        { type: 'BotWithoutSubscription', ...arg },
      ],
    }),
  }),
})

export const {
  useBotWithoutSubscriptionQuery,
  useLazyBotWithoutSubscriptionQuery,
} = botWithoutSubscriptionAction
