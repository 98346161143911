import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { UserTier } from '@models/User/UserTier'

export const allTierApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    allTier: builder.query<UserTier[], void>({
      query: () => ({
        document: gql`
          query GetAllTier {
            getAllTier {
              id
              name
              minimumTeamMembersAmount
              directReferralAmount
              minimumTeamAmount
              minimumEachTeamMemberStarLevel
              serviceCharge
              membershipFee
            }
          }
        `,
      }),
      transformResponse(res: { getAllTier: UserTier[] }) {
        const { getAllTier } = res
        return getAllTier
      },
      providesTags: ['Tiers'],
    }),
  }),
})

export const { useAllTierQuery, useLazyAllTierQuery } = allTierApi
