import { exchangeApi } from '@client/client'
import { MarketTickerSortEnum } from '@interfaces/MarketTickerSortEnum'
import { gql } from 'graphql-request'
import { MarketTicker } from '@models/MarketTicker/MarketTicker'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const marketTickersApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    marketTickers: builder.query<
      MarketTicker[],
      {
        limit?: number
        sort?: MarketTickerSortEnum
        botMarketType: BotMarketType
      }
    >({
      query: ({ limit, sort, botMarketType }) => ({
        document: gql`
          query MarketTickersApi(
            $sort: MarketTickerSort
            $limit: Float
            $botMarketType: BotMarketType!
          ) {
            marketTickers(
              sort: $sort
              limit: $limit
              bot_market_type: $botMarketType
            ) {
              symbol
              percentage
              last
            }
          }
        `,
        variables: {
          limit,
          sort,
          botMarketType,
        },
      }),
      transformResponse(res: { marketTickers: MarketTicker[] }) {
        const { marketTickers } = res
        return marketTickers
      },
      providesTags: (result, error, arg) => [{ type: 'Symbols', ...arg }],
    }),
  }),
})

export const { useMarketTickersQuery } = marketTickersApi
