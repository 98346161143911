import { gql } from 'graphql-request'
import { mainApi, mainClient } from '@client/client'
import { getRefreshToken } from '@client/baseQueryWithReAuth'

export const logoutApi = mainApi.injectEndpoints({
  overrideExisting: false,

  endpoints: builder => ({
    logout: builder.mutation<boolean, void>({
      query: () => ({
        document: gql`
          mutation Logout {
            logout
          }
        `,
      }),
      transformResponse(res: { logout: boolean }) {
        const { logout } = res
        return logout
      },
      async onQueryStarted() {
        const aRefreshToken = getRefreshToken()
        mainClient.setHeader('authorization', `Bearer ${aRefreshToken}`)
      },
    }),
  }),
})

export const { useLogoutMutation } = logoutApi
