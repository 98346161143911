import React from 'react'
import { TabBarProps } from './interface'

const withTabBar = (Component: React.FC<TabBarProps>) => {
  const Hoc = ({ ...props }: TabBarProps) => {
    const newProps = { ...props }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withTabBar
