import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

export const resetPasswordApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    resetPassword: builder.mutation<
      { id: number },
      { id: number; password: string; verifyToken: string }
    >({
      query: resetPasswordInput => ({
        document: gql`
          mutation ResetPassword($resetPasswordInput: ResetPasswordInput!) {
            resetPassword(resetPasswordInput: $resetPasswordInput) {
              id
            }
          }
        `,
        variables: {
          resetPasswordInput,
        },
      }),
    }),
  }),
})

export const { useResetPasswordMutation } = resetPasswordApi
