import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { WithdrawRequests } from '@models/Wallet/WithdrawRequests'

export const withdrawRequestedApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    withdrawRequested: builder.query<
      WithdrawRequests,
      { limit: number; page: number }
    >({
      query: ({ limit, page }) => ({
        document: gql`
          query GetWithdrawRequestByUser($page: Int, $limit: Int) {
            getWithdrawRequestsByUser(page: $page, limit: $limit) {
              withdrawRequests {
                id
                userId
                username
                name
                createdAt
                amount
                status
                tempWalletAmount
                toWalletAddress
                rejectReason
              }
              pagination {
                page
                limit
                totalPage
                totalCount
              }
            }
          }
        `,
        variables: {
          limit,
          page,
        },
      }),
      transformResponse(res: { getWithdrawRequestsByUser: WithdrawRequests }) {
        const { getWithdrawRequestsByUser } = res
        return getWithdrawRequestsByUser
      },
      providesTags: ['WithdrawRequested'],
    }),
  }),
})

export const { useLazyWithdrawRequestedQuery, useWithdrawRequestedQuery } =
  withdrawRequestedApi
