import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class ProfitHistoryBot {
  @Expose()
  id: number

  @Expose()
  @Transform(({ value }) => DateTime.fromMillis(value), { toClassOnly: true })
  endAt: DateTime

  @Expose()
  profitPercentage: number

  @Expose()
  botStrategy: {
    side: string
  }

  @Expose()
  baseCurrencyData: {
    imgPath: string
    symbol: string
  }

  @Expose()
  tradeActions: {
    quoteCurrency: string
  }[]
}
