import React from 'react'
import cn from 'classnames'
import { ToggleSwitchSimpleFieldProps } from './interface'

function ToggleSwitchSimpleField({
  value,
  disabled,
  color = 'bg-secondary',
  isShowValueInText,
  switchClassName = 'w-[52px] h-[32px]',
  dotClassName = 'w-[28px] h-[28px]',
  ...props
}: ToggleSwitchSimpleFieldProps) {
  return (
    <label className='cursor-pointer flex justify-center items-center space-x-1'>
      <div
        className={cn('toggle-switch relative', {
          'opacity-30 cursor-not-allowed': disabled,
        })}
      >
        <input
          type='checkbox'
          className='sr-only'
          disabled={disabled}
          checked={value}
          {...props}
        />

        <div
          className={cn(
            `block rounded-3xl transition ${value ? color : 'bg-gray-59'}`,
            switchClassName
          )}
        />

        <div
          className={cn(
            `dot drop-shadow absolute left-[2px] top-[2px] bg-white rounded-full transition`,
            dotClassName
          )}
        />
      </div>
      {isShowValueInText && (
        <span className='text-sm'>{value ? 'on' : 'off'}</span>
      )}
    </label>
  )
}

export default ToggleSwitchSimpleField
