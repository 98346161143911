import React from 'react'
import { WithdrawRequestedPageProps } from '@features/wallet/pages/WithdrawRequestedPage/interface'
import { usePagination } from '@hooks/usePagination'
import { useWithdrawRequestedQuery } from '@client/exchangeClient/collections/WithdrawRequestedClientAction'

const WithWithdrawRequestedPage = (
  Component: React.FC<WithdrawRequestedPageProps>
) => {
  const Hoc = () => {
    const { page, perpage, pageChange, perpageChange } = usePagination()
    const { data, isLoading } = useWithdrawRequestedQuery({
      limit: perpage,
      page,
    })
    const newProps: WithdrawRequestedPageProps = {
      page,
      perpage,
      pageChange,
      perpageChange,
      withdrawRequests: data,
      isLoading,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithWithdrawRequestedPage
