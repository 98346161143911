import { MinusCircleIcon, PlusCircleIcon } from '@components/Icons/Icons'
import { useState } from 'react'
import withAccordionErrorCase from './withAccordionErrorCase'
import { AccordionErrorCaseProps } from './interface'

const AccordionErrorCase = ({
  accordionOpen,
  title,
  causeTitle,
  solutionTitle,
  detailList,
  causeList,
  solutionList,
  handleAccordionOpen,
}: AccordionErrorCaseProps) => {
  return (
    <div
      className='mx-2 py-3 sm:mx-5 sm:py-5 border-b  border-gray-600 rounded-sm'
      onClick={handleAccordionOpen}
    >
      <button className='flex justify-between w-full'>
        <span
          className={`text-sm sm:text-md text-left text-gray-100 font-bold  max-w-[300px] sm:max-w-[700px] flex gap-x-1  ${
            accordionOpen && 'mb-2'
          }`}
        >
          {title}
        </span>

        {accordionOpen ? (
          <MinusCircleIcon height='20' width='20' />
        ) : (
          <PlusCircleIcon height='20' width='20' />
        )}
      </button>
      <div
        className={`grid overflow-hidden transition-all duration-300 ease-in-out text-gray-300 text-sm sm:text-md  ${
          accordionOpen
            ? 'grid-rows-[1fr] opacity-100'
            : 'grid-rows-[0fr] opacity-0'
        }`}
      >
        <div className='overflow-hidden'>
          {/* Case  */}

          <div>
            {detailList &&
              detailList.map((item, index) => <p key={index}>{item.detail}</p>)}
          </div>

          <div>
            {causeList && (
              <h3 className='text-bold mb-2 mt-3'>
                <span className='border-b'>{causeTitle}</span>
              </h3>
            )}

            {causeList &&
              causeList.map((item, index) => (
                <p className='mb-2' key={index}>
                  - {item.detail}
                </p>
              ))}
          </div>

          <div>
            {solutionList && (
              <h3 className='text-bold mb-2 mt-3'>
                <span className='border-b'>{solutionTitle}</span>
              </h3>
            )}

            {solutionList &&
              solutionList.map((item, index) => (
                <p key={index} className='mb-2'>
                  - {item.detail}
                </p>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

const WrappedComponent = withAccordionErrorCase(AccordionErrorCase)
export default WrappedComponent
