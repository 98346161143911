import { TimeIcon } from '@components/Icons/Icons'
import cn from 'classnames'
import { ModalProps } from './Modalinterface'
import { withModal } from './withModal'

function Modal({
  children,
  onClose,
  handleToggleModal,
  disabeldClose,
  modalClassName = 'bg-modalBg',
  closeIconClassName,
}: ModalProps & { handleToggleModal: () => void }) {
  return (
    <div className='fixed right-0 left-0 top-0 bottom-0 bg-opacity-50 flex justify-center items-center z-10 bg-black'>
      <div
        className={cn(
          'p-[12px] rounded-[10px] flex flex-col h-max w-fit',
          modalClassName
        )}
      >
        {!disabeldClose && (
          <div className='relative text-neutral-body'>
            <TimeIcon
              width='24'
              height='24'
              className={cn(
                'absolute right-0 top-0 cursor-pointer z-1 ',
                closeIconClassName
              )}
              onClick={handleToggleModal}
            />
          </div>
        )}
        {children}
      </div>
    </div>
  )
}

export default withModal(Modal)
