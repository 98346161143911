import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useWalletBalanceQuery } from '@client/exchangeClient/collections/WalletBalanceClientAction'
import { useSendOTPVerifyMutation } from '@client/mainClient/collections/SendOTPVerifyClientAction'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { WithdrawForm } from '@models/Wallet/WithdrawForm'
import Loading from '@components/utils/Loading'
import { useUsdtTransferMutation } from '@client/exchangeClient/collections/UsdtTransferClientAction'
import { WalletRoutesPath } from '@config/Router'
import { WithdrawPageProps, WithdrawStep } from './interface'

const withWithdrawPage = (Component: React.FC<WithdrawPageProps>) => {
  function WithWithdrawPage() {
    const { profile } = useSelector(authenticationSelector)
    const navigate = useNavigate()
    const [isPaidLoading, setIsPaidLoading] = useState(false)
    const [paidTime, setPaidTime] = useState('')
    const [withdrawStep, setWithdrawStep] = useState<WithdrawStep>('form')
    const [withdrawForm, setWithdrawForm] = useState<WithdrawForm>({
      amount: 0,
      toAddress: '',
    })
    const { data, isFetching: isBalanceFetching } = useWalletBalanceQuery(
      undefined,
      { refetchOnMountOrArgChange: true }
    )
    const [sendOtpMutate, { isLoading: isSendOtpLoading }] =
      useSendOTPVerifyMutation()
    const [tranferMutate, { isLoading: isTranferLoading }] =
      useUsdtTransferMutation()

    const handleWithdrawFormSubmit = (form: WithdrawForm) => {
      if (profile) {
        sendOtpMutate({
          phoneNumber: profile.phoneNumber,
          phoneNumberCountryCode: profile.phoneNumberCountryCode,
        })
          .unwrap()
          .then(() => {
            setWithdrawForm(form)
            setWithdrawStep('otp')
          })
          .catch(() => {})
      }
    }

    const handleVerifiedOtp = async (resp: { verifiedToken: string }) => {
      try {
        await tranferMutate({
          toAddress: withdrawForm.toAddress,
          amount: withdrawForm.amount,
          verifyToken: resp.verifiedToken,
        }).unwrap()
        toast.success(
          `Withdraw was requested!. Please wait for admin confirmation within approximately 2 hours.`
        )
        setIsPaidLoading(false)
        setPaidTime(DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'))
        setWithdrawStep('success')
      } catch (_) {}
    }

    const goToWithdrawRequestdPage = () => {
      navigate(WalletRoutesPath.withdrawRequested.absolutePath)
    }

    if (!isSendOtpLoading && !isTranferLoading && !isPaidLoading) {
      const newProps = {
        withdrawStep,
        withdrawForm,
        balance: data?.usdt || 0,
        isBalanceFetching,
        handleWithdrawFormSubmit,
        handleVerifiedOtp,
        profile,
        goToWithdrawRequestdPage,
        paidTime,
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }

  return WithWithdrawPage
}

export default withWithdrawPage
