import { gql } from 'graphql-request'
import { mainApi } from '@client/client'

import { WalletsModel } from '@models/Wallet/Wallets'

export const walletsApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    wallets: builder.query<WalletsModel, void>({
      query: () => ({
        document: gql`
          query {
            profile {
              tempWalletAmount
              khotCoinsAmount
            }
          }
        `,
      }),
      transformResponse(res: { profile: WalletsModel }) {
        const { profile } = res
        return profile
      },
      providesTags: [`Wallets`],
    }),
  }),
})

export const { useWalletsQuery } = walletsApi
