import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { Bot } from '@models/Bot/Bot'
import { botWsService } from '@client/botClient/ws/client'
import { botQueryDocument } from '@client/botClient/collections/ws/bot.queryDocument'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { BOT_WS_API_URL } from '@config/config'

export const botsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    bot: builder.query<
      Bot,
      {
        botId: number
        status?: string
      }
    >({
      query: ({ botId }) => ({
        document: gql`
          query Bot($botId: Float!) {
            bot(BotID: $botId) {
              id
              symbol
              isOneOff
              lastPrice
              nextOrderPrice
              nextTakeProfitPrice
              botControllerId
              botController {
                createdAt
                totalCycle
                cycleRound
              }
              botStrategy {
                tpRetracement
                leverage
                firstOrderRawQuote
                isInfinityCycle
                name
                side
                coveringQuantity
                tpRatio
                isCycle
                cycleQuantity
                isFirstOrderDouble
                isCovering
                coverSpreads
                coverMultiples
                pullbackSpreads
                isSplitTp
                tpRatioSplit
                tpRetracementSplit
                isStopLoss
                stopLossRatio
                stopLossLimit
                limitPrice
              }
              positionAvgPrice
              position {
                contracts
                collateral
                unrealizedPnl
              }
              profit
              profitPercentage
              status
              coverRound
              endAt
              createdAt
              errorDescription
              pullbackBoundaryPrice
              pullbackActivePrice
              tprBoundaryPrice
              tprActivePrice
              cycleNo
              startedAt
              isStopCovering
            }
          }
        `,
        variables: { botId },
      }),

      async onCacheEntryAdded(
        arg,
        { updateCachedData, cacheDataLoaded, cacheEntryRemoved, getCacheEntry }
      ) {
        // wait for the initial query to resolve before proceeding

        await cacheDataLoaded

        const cache = getCacheEntry()

        if (
          cache.data!.status === BotStatusEnum.RUNNING ||
          cache.data!.status === BotStatusEnum.STOP
        ) {
          // create a websocket connection when the cache subscription starts
          const ws = botWsService<{ subscribeToBot: Bot }>({
            queryVariables: { botId: arg.botId },
            url: BOT_WS_API_URL,
            queryDocument: botQueryDocument,
            onError: () => {
              // console.log('error from socket : ', e)
              // toast.error(`bot ${arg.botId} socket got error: ${e}`)
              // ws.terminate()
            },
            onNext: bot => {
              updateCachedData(() => {
                return {
                  ...bot.data!.subscribeToBot,
                  position:
                    bot.data!.subscribeToBot.status === 'RUNNING'
                      ? bot.data!.subscribeToBot.position
                      : null,
                }
              })
            },
            onComplete: () => {},
          })
          await cacheEntryRemoved
          ws.terminate()
        }
      },
      transformResponse(res: { bot: Bot }) {
        const { bot } = res
        return bot
      },
      providesTags: ['Bot'],
    }),
  }),
})

export const { useBotQuery } = botsApi
