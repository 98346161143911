import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const stopCoveringApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    stopCovering: builder.mutation<
      boolean,
      { botId: number; isStopCovering: boolean }
    >({
      query: ({ botId, isStopCovering }) => ({
        document: gql`
          mutation StopCovering(
            $botId: Int!
            $botType: BotType!
            $isStopCovering: Boolean!
          ) {
            stopCovering(
              botId: $botId
              botType: $botType
              isStopCovering: $isStopCovering
            )
          }
        `,
        variables: {
          botId: Number(botId),
          botType: 'Martingale',
          isStopCovering,
        },
      }),
    }),
  }),
})

export const { useStopCoveringMutation } = stopCoveringApi
