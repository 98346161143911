import React from 'react'

import { BudgetOptionType } from '@interfaces/BudgetOptionType'

const BudgetOptionContainer = ({
  initialMarginLong,
  initialMarginShort,
  leverage,
  long,
  short,
  optionName,
}: BudgetOptionType) => {
  return (
    <div className='flex flex-col gap-y-4'>
      <span className='text-sm underline'>Option {optionName}</span>
      <div className='flex justify-between'>
        <span className='text-sm'>Leverage</span>
        <span className='text-sm font-bold'>{leverage}</span>
      </div>
      <div className='flex justify-between'>
        <span className='text-sm'>Total bot (Long/Short)</span>
        <span className='text-sm font-bold'>
          {long + short} ({long}, {short})
        </span>
      </div>
      <div className='flex justify-between'>
        <span className='text-sm'>Initial Margin Long (USDT)</span>
        <span className='text-sm font-bold'>{initialMarginLong}</span>
      </div>
      <div className='flex justify-between'>
        <span className='text-sm'>Initial Margin Short (USDT)</span>
        <span className='text-sm font-bold'>{initialMarginShort}</span>
      </div>
    </div>
  )
}
export default BudgetOptionContainer
