import { OneOffButtonProps } from '@features/bot/pages/BotPage/components/OneOffButton/interface'
import { OneCircleIcon } from '@components/Icons/Icons'
import cn from 'classnames'
import withOneOffButton from './withOneOffButton'

const OneOffButton = ({
  isOneOff,
  handleOnOneOff,
  isDisable,
}: OneOffButtonProps) => {
  return (
    <div
      onClick={handleOnOneOff}
      className={cn(
        `flex flex-col items-center justify-center h-[84px] w-[80px] border-1 py-[10px] px-[20px] rounded-[10px]  `,
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer',
        isOneOff && !isDisable ? 'border-primary' : 'border-gray-300'
      )}
    >
      <OneCircleIcon
        height='40'
        width='40'
        className={cn(
          isDisable && 'text-gray-300',
          isOneOff && !isDisable && 'text-primary',
          !isOneOff && !isDisable && 'text-white'
        )}
      />
      <span
        className={cn(
          `text-[10px]`,
          isDisable && 'text-gray-300',
          isOneOff && 'text-primary'
        )}
      >
        One-off
      </span>
    </div>
  )
}

const WrappedComponent = withOneOffButton(OneOffButton)
export default WrappedComponent
