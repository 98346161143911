import React from 'react'
import {
  DatePickerSimpleFieldAcceptProps,
  DatePickerSimpleFieldProps,
} from './interface'

const withDatePickerSimpleField = (
  Component: React.FC<DatePickerSimpleFieldProps>
) => {
  function Hoc(props: DatePickerSimpleFieldAcceptProps) {
    const newProps = {
      ...props,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withDatePickerSimpleField }
