import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { Expose, Transform } from 'class-transformer'
import { DateTime } from 'luxon'

export class GetTradeCopyByBot {
  @Expose()
  symbol: string

  @Expose()
  @Transform(({ value }) => DateTime.fromMillis(value), { toClassOnly: true })
  buyDate: DateTime

  @Expose()
  baseCurrencyData: {
    imgPath: string
    symbol: string
  }

  @Expose()
  strategyData: BotStrategiesDefault

  @Expose()
  user: { name: string }
}
