import { Outlet } from 'react-router-dom'
import { tradeCopyPath, TradeCopyRoutesPath } from '@config/Router'
import { AppLayoutWithActivateGuard } from '@components/AppLayout/AppLayout'
import TradeCopyPage from './pages/TradeCopyPage/TradeCopyPage'
import CopyBySymbolPage from './pages/CopyBySymbolPage/CopyBySymbolPage'
import CopyByPersonalPage from './pages/CopyByPersonalPage/CopyByPersonalPage'
import StrategyDetailPage from './pages/StrategyDetailPage/StrategyDetailPage'
import MyStrategyPage from './pages/MyStrategyPage/MyStrategyPage'
import MyStrategyDetailPage from './pages/MyStrategyDetailPage/MyStrategyDetailPage'
import CopyByPersonalBotListPage from './pages/CopyByPersonalBotListPage/CopyByPersonalBotListPage'

export const TradeCopyRoutes = {
  path: tradeCopyPath,
  element: <Outlet />,
  children: [
    {
      path: '',
      element: (
        <AppLayoutWithActivateGuard>
          <TradeCopyPage />
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: TradeCopyRoutesPath.tradeCopy.bySymbol.path,
      element: (
        <AppLayoutWithActivateGuard title='Copy by symbol'>
          <CopyBySymbolPage />
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: TradeCopyRoutesPath.tradeCopy.byPersonal.path,
      element: (
        <AppLayoutWithActivateGuard title='Copy by personal'>
          <CopyByPersonalPage />
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: TradeCopyRoutesPath.tradeCopy.strategy.path,
      element: (
        <AppLayoutWithActivateGuard title='Strategy detail'>
          <StrategyDetailPage />
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: TradeCopyRoutesPath.tradeCopy.myStrategy.path,
      element: (
        <AppLayoutWithActivateGuard title='My Strategy'>
          <MyStrategyPage />
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: TradeCopyRoutesPath.tradeCopy.myStrategy.detail.path,
      element: (
        <AppLayoutWithActivateGuard title='Strategy detail'>
          <MyStrategyDetailPage />
        </AppLayoutWithActivateGuard>
      ),
    },
    {
      path: TradeCopyRoutesPath.tradeCopy.personal.path,
      element: (
        <AppLayoutWithActivateGuard title='Copy by personal'>
          <CopyByPersonalBotListPage />
        </AppLayoutWithActivateGuard>
      ),
    },
  ],
}
