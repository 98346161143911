import React from 'react'
import { useGetTradeCopyByBotsQuery } from '@client/botClient/collections/GetTradeCopyByBotsClientAction'
import Loading from '@components/utils/Loading'
import { TradeCopySymbolProps } from './interface'

const withTradeCopySymbol = (Component: React.FC<TradeCopySymbolProps>) => {
  function WithTradeCopySymbol() {
    const {
      data: tradeCopyByBots,
      isLoading,
      refetch,
    } = useGetTradeCopyByBotsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    })

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const newProps = { tradeCopyByBots, isLoading, refetch }
    return <Component {...newProps} />
  }

  return WithTradeCopySymbol
}

export default withTradeCopySymbol
