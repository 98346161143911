import { SortEnum } from './SortEnum'

export enum BotSortEnum {
  INITIAL_MARGIN = 'INITIAL_MARGIN',
  TP_RATIO = 'TP_RATIO',
  TOTAL_COVERING = 'TOTAL_COVERING',
  CONTRACTUAL_ASSET = 'CONTRACTUAL_ASSET',
  CURRENT_COVERING = 'CURRENT_COVERING',
  PROFIT_USDT = 'PROFIT_USDT',
  AVERAGE_PRICE = 'AVERAGE_PRICE',
  END_AT = 'END_AT',
  CREATED_AT = 'CREATED_AT',
  SYMBOL = 'SYMBOL',
}

export const botSortEnumToJson = (sort?: {
  sortType: BotSortEnum
  sortValue: SortEnum
}): Record<string, SortEnum> | undefined => {
  if (sort) {
    const { sortType, sortValue } = sort
    switch (sortType) {
      case BotSortEnum.INITIAL_MARGIN:
        return { botStrategyInitialMargin: sortValue }
      case BotSortEnum.TP_RATIO:
        return { botStrategyTpRatio: sortValue }
      case BotSortEnum.TOTAL_COVERING:
        return { botStrategyCoveringQuantity: sortValue }
      case BotSortEnum.CONTRACTUAL_ASSET:
        return { initialMargin: sortValue }
      case BotSortEnum.CURRENT_COVERING:
        return { coverRound: sortValue }
      case BotSortEnum.PROFIT_USDT:
        return { profit: sortValue }
      case BotSortEnum.AVERAGE_PRICE:
        return { positionAvgPrice: sortValue }
      case BotSortEnum.END_AT:
        return { endAt: sortValue }
      case BotSortEnum.CREATED_AT:
        return { createdAt: sortValue }
      case BotSortEnum.SYMBOL:
        return { symbol: sortValue }
      default:
        return undefined
    }
  }

  return undefined
}
