import React from 'react'
import { useField } from 'formik'

import { NumberFieldProps } from './interface'

export function withNumberField(Component: React.FC<NumberFieldProps>) {
  function WithNumberField({
    name,
    min,
    max,
    isOnlyPositiveNumber = false,
    ...props
  }: React.HTMLProps<HTMLInputElement> & {
    min?: number
    max?: number
    isOnlyPositiveNumber?: boolean
    onValueChange?: (v: number) => void
    tooltip?: string
  }) {
    const [field, { touched, error }, { setValue }] = useField(name!)

    function increase() {
      if (max) {
        if (field.value + 1 > max) {
          return
        }
      }
      setValue(field.value + 1)
      if (props.onValueChange) props.onValueChange(field.value + 1)
    }

    function decrease() {
      if (min) {
        if (field.value - 1 < min) {
          return
        }
      }
      if (isOnlyPositiveNumber) {
        if (field.value < 1) {
          return
        }
      }
      setValue(field.value - 1)
      if (props.onValueChange) props.onValueChange(field.value - 1)
    }

    const componentProps = {
      ...props,
      ...field,
      onChange: e => {
        // if (min) {
        //   if (e.currentTarget.value < min) {
        //     return
        //   }
        // }
        if (max) {
          if (e.currentTarget.value > max) {
            return
          }
        }

        if (isOnlyPositiveNumber) {
          e.currentTarget.value = Math.abs(e.currentTarget.value)
        }

        field.onChange(e)
        if (props.onValueChange)
          props.onValueChange(Number(e.currentTarget.value))
      },
      touched,
      error,
      increase,
      decrease,
    }

    return <Component {...componentProps} />
  }

  return WithNumberField
}
