import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { SortEnum } from '@interfaces/SortEnum'
import { Users } from '@models/User/Users'
import { DateRangeTypeEnum } from '@interfaces/DateRangeTypeEnum'

export const downlineUsersApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    downlineUsers: builder.query<
      Users,
      {
        page: number
        limit: number
        search?: string
        sort?: Record<string, SortEnum>
        calculatedTeamProfit?: DateRangeTypeEnum
        calculatedTotalProfitDateRangeType?: DateRangeTypeEnum
        calculatedTotalReferralDateRangeType?: DateRangeTypeEnum
      }
    >({
      query: ({
        page,
        limit,
        search,
        sort,
        calculatedTeamProfit,
        calculatedTotalProfitDateRangeType,
        calculatedTotalReferralDateRangeType,
      }) => ({
        document: gql`
          query MyNetwork(
            $page: Int
            $limit: Int
            $search: String
            $sort: MyNetworkSortInput
            $calculatedTeamProfit: DateRangeType
            $calculatedTotalProfitDateRangeType: DateRangeType
            $calculatedTotalReferralDateRangeType: DateRangeType
          ) {
            myNetwork(
              page: $page
              limit: $limit
              search: $search
              sort: $sort
            ) {
              pagination {
                limit
                page
                totalPage
                totalCount
              }
              users {
                rank
                id
                name
                phoneNumber
                totalProfit
                teamProfit
                positiveTotalProfit
                isTeamMember
                isDirectReferral
                teamCount
                userTier {
                  name
                }
                calculatedTeamProfit(dateRangeType: $calculatedTeamProfit)
                calculatedTotalProfit(
                  dateRangeType: $calculatedTotalProfitDateRangeType
                )
                calculatedTotalReferral(
                  dateRangeType: $calculatedTotalReferralDateRangeType
                )
              }
            }
          }
        `,
        variables: {
          page,
          limit,
          search,
          sort,
          calculatedTeamProfit,
          calculatedTotalProfitDateRangeType,
          calculatedTotalReferralDateRangeType,
        },
      }),
      transformResponse(res: { myNetwork: Users }) {
        const { myNetwork } = res
        return myNetwork
      },
      providesTags: (result, error, arg) => [{ type: 'Downlines', ...arg }],
    }),
  }),
})

export const { useDownlineUsersQuery, useLazyDownlineUsersQuery } =
  downlineUsersApi
