import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { HomeRoutesPath } from '@config/Router'

export const withUnAuthenGuard = (Component: React.FC) => {
  function HOC() {
    const navigate = useNavigate()
    const { isAuthentication } = useSelector(authenticationSelector)

    useEffect(() => {
      if (isAuthentication) {
        navigate(HomeRoutesPath.home.path)
      }
    }, [isAuthentication, navigate])

    return <Component />
  }
  return HOC
}
