import { Tab } from '@components/Tabs/components/Tab'
import { TabBody } from '@components/Tabs/components/TabBody'
import { TabHeader } from '@components/Tabs/components/TabHeader'
import { Tabs } from '@components/Tabs/Tabs'
import TradeCopyHeader from '@features/tradeCopy/components/TradeCopyHeader'
import TradeCopyPersonal from './components/TradeCopyPersonal/TradeCopyPersonal'
import TradeCopySymbol from './components/TradeCopySymbol/TradeCopySymbol'
import withTradeCopyPage from './withTradeCopyPage'

function TradeCopyPage() {
  return (
    <div className='max-w-[700px] mx-auto'>
      <TradeCopyHeader />
      <Tabs initialActiveTab='bySymbol'>
        <Tab tabId='bySymbol'>
          <TabHeader>
            <span>Copy by Symbol</span>
          </TabHeader>
          <TabBody>
            <TradeCopySymbol />
          </TabBody>
        </Tab>
        <Tab tabId='byPersonal'>
          <TabHeader>
            <span>Copy by Personal</span>
          </TabHeader>
          <TabBody>
            <TradeCopyPersonal />
          </TabBody>
        </Tab>
      </Tabs>
    </div>
  )
}

export default withTradeCopyPage(TradeCopyPage)
