import ProfileImage from '@components/ProfileImage/ProfileImage'
import React from 'react'
import { CheckIcon, WarningIcon } from '@components/Icons/Icons'

interface ReferralCardProps {
  name: string
  image: string
  order: number
  tierName?: string
  isUpline?: boolean
  profit?: number
  teamProfit?: number
  teamCount?: number
  isDirectReferral?: boolean
  isTeamMember?: boolean
  className?: string
}

function ReferralCard({
  name,
  image,
  order,
  profit,
  teamProfit,
  className,
  isTeamMember,
  isDirectReferral,
  teamCount,
  isUpline,
  tierName,
}: ReferralCardProps) {
  return (
    <>
      <div
        className={`flex-1 flex items-center min-h-[82px] border-1 border-gray-300 h-fit rounded-[8px] pl-[16px] pr-[8px] py-[10px] text-xs ${className}`}
      >
        <div className='mr-[8px]'>#{order}</div>
        <ProfileImage path={image} className='mr-[17px] w-[40px] h-[40px]' />
        <div>
          <div className='text-primary mb-[8px]'>{name}</div>
          {!isUpline && (
            <div className='flex flex-col gap-[3px] flex-1'>
              <div>{`Tier: ${tierName}`}</div>

              {profit !== undefined && (
                <div>
                  {`Personal profit : ${profit?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} USDT`}
                </div>
              )}
              {teamProfit !== undefined && (
                <div>
                  {`Team profit : ${teamProfit?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  })} USDT`}
                </div>
              )}

              {teamCount !== undefined && (
                <div>{`Total direct downline : ${teamCount.toLocaleString(
                  undefined,
                  {
                    minimumFractionDigits: 0,
                    maximumFractionDigits: 0,
                  }
                )}`}</div>
              )}

              {isTeamMember !== undefined && (
                <div className='flex items-center'>
                  Activated Status :
                  {isTeamMember ? (
                    <CheckIcon className='w-[18px] h-[18px] ml-1 text-green' />
                  ) : (
                    <div className='flex-1 text-[#FBA04F] flex items-center'>
                      <WarningIcon className='w-[14px] h-[14px] mx-1' /> Pending
                      for first bot
                    </div>
                  )}
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default ReferralCard
