import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { User } from '@models/User/User'
import { DateRangeTypeEnum } from '@interfaces/DateRangeTypeEnum'

export const profileApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    profile: builder.query<
      User,
      {
        calculatedTeamProfit?: DateRangeTypeEnum
        calculatedTotalProfitDateRangeType?: DateRangeTypeEnum
        calculatedTotalReferralDateRangeType?: DateRangeTypeEnum
      }
    >({
      query: ({
        calculatedTeamProfit,
        calculatedTotalProfitDateRangeType,
        calculatedTotalReferralDateRangeType,
      }) => ({
        operationName: 'Profile',
        document: gql`
          query Profile(
            $calculatedTeamProfit: DateRangeType
            $calculatedTotalProfitDateRangeType: DateRangeType
            $calculatedTotalReferralDateRangeType: DateRangeType
          ) {
            profile {
              id
              name
              email
              username
              isActivate
              isTeamMember
              activateUntilAt
              walletAddress
              base54WalletAddress
              phoneNumber
              referralCode
              lineId
              phoneNumberCountryCode
              profileImgPath
              totalProfit
              authExchanges {
                id
                exchangeName
                apiKey
                secret
                userId
                createdAt
              }
              strategyDescription
              oneStarCount
              twoStarCount
              threeStarCount
              fourStarCount
              fiveStarCount
              teamMemberCount
              teamCount
              teamProfit
              userTier {
                id
                name
                minimumTeamMembersAmount
                directReferralAmount
                minimumTeamAmount
                minimumEachTeamMemberStarLevel
                serviceCharge
                membershipFee
                nextTierId
              }
              downLineUsers {
                id
              }
              khotCoinsAmount
              calculatedTeamProfit(dateRangeType: $calculatedTeamProfit)
              calculatedTotalProfit(
                dateRangeType: $calculatedTotalProfitDateRangeType
              )
              calculatedTotalReferral(
                dateRangeType: $calculatedTotalReferralDateRangeType
              )
            }
          }
        `,
        variables: {
          calculatedTeamProfit,
          calculatedTotalProfitDateRangeType,
          calculatedTotalReferralDateRangeType,
        },
      }),
      transformResponse(res: { profile: User }) {
        const { profile } = res
        return profile
      },
      providesTags: ['Profile'],
    }),
  }),
})

export const { useProfileQuery, useLazyProfileQuery } = profileApi
