import React, { useEffect, useState } from 'react'
import {
  BotSwitchAcceptProps,
  BotSwitchProps,
} from '@features/bot/components/BotSwitch/interface'
import { useTurnOnBotMutation } from '@client/botClient/collections/TurnOnBotClientAction'
import { useTurnOffBotMutation } from '@client/botClient/collections/TurnOffBotClientAction'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { botApi } from '@client/client'
import { useAppDispatch } from '../../../../store'

const WithBotSwitch = (Component: React.FC<BotSwitchProps>) => {
  const Hoc = ({
    refetchBot,
    isLoading,
    botSymbolPath,
    bot,
  }: BotSwitchAcceptProps) => {
    const { id: botId } = bot
    const dispatch = useAppDispatch()
    const [isBotOn, setIsBotOn] = useState(false)
    const [isBotDeserveOn, setIsBotDeserveOn] = useState<boolean | null>(null)

    const [trunOnMutate, { isLoading: isTurnOnBotLoading }] =
      useTurnOnBotMutation()
    const [trunOffMutate, { isLoading: isTurnOffBotLoading }] =
      useTurnOffBotMutation()

    const isBotStatusLoading =
      isBotDeserveOn !== null &&
      ((bot.status !== BotStatusEnum.RUNNING && isBotDeserveOn) ||
        (bot.status === BotStatusEnum.RUNNING && !isBotDeserveOn))

    useEffect(() => {
      if (!isBotStatusLoading) {
        setIsBotOn(bot.status === BotStatusEnum.RUNNING)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [bot])

    const handleSwitchChange = (checked: boolean) => {
      if (checked) {
        trunOnMutate(Number(botId))
          .unwrap()
          .then(() => {
            setIsBotOn(prev => !prev)
            if (bot.status === BotStatusEnum.READY) {
              dispatch(botApi.util.invalidateTags([{ type: 'Bots' }]))
            } else {
              refetchBot()
            }
          })
          .catch(() => {})
        setIsBotDeserveOn(true)
      } else {
        trunOffMutate(Number(botId))
          .unwrap()
          .then(() => {
            setIsBotOn(prev => !prev)
            refetchBot()
          })
          .catch(() => {})
        setIsBotDeserveOn(false)
      }
    }

    const newProps: BotSwitchProps = {
      bot,
      isBotOn,
      handleSwitchChange,
      disabledSwitch:
        isLoading ||
        isTurnOnBotLoading ||
        isTurnOffBotLoading ||
        isBotStatusLoading,
      botSymbolPath,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithBotSwitch
