import React, { useEffect, useState } from 'react'
import { useField } from 'formik'
import {
  InputFieldAcceptProps,
  InputFieldProps,
} from '@components/form/InputField/interface'

const withInputField = (Component: React.FC<InputFieldProps>) => {
  function Hoc({
    type,
    isShowPassword,
    showErrorMessage = true,
    name,
    onChange,
    ...props
  }: InputFieldAcceptProps) {
    const [{ onChange: onFormikChange, ...field }, meta] = useField(name)
    const isPasswordType = type === 'password'

    const newProps = {
      type: isPasswordType ? (isShowPassword ? 'text' : 'password') : type,
      showErrorMessage,
      ...props,
      ...meta,
      ...field,
      onFormikChange,
      onChange,
    }

    return <Component {...newProps} />
  }

  return Hoc
}

export { withInputField }
