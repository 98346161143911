import { RadioInput } from '@components/RadioInput/RadioInput'
import Tooltip from '@components/Tooltip/Tooltip'
import { RadioInputFieldProps } from './interface'
import { withRadioInputField } from './withRadioInputField'

const RadioInputField = ({
  className,
  radioClassName,
  label,
  tooltip,
  ...props
}: RadioInputFieldProps) => {
  return (
    <div className={className}>
      {label && (
        <div className='flex flex-row space-x-2'>
          <label className='block mb-[16px] text-[14px] font-medium'>
            {label}
          </label>
          {tooltip && <Tooltip title={label} text={tooltip} />}
        </div>
      )}

      <RadioInput {...props} className={radioClassName} />
    </div>
  )
}

export default withRadioInputField(RadioInputField)
