import { DateTime } from 'luxon'
import React, { useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { useGetAccumulateByUserIdQuery } from '@client/botClient/collections/GetAccumulateByUserId'
import { WinRateOptionEnum } from '@features/tradeCopy/components/PersonalWinRate'
import { CopyByPersonalPageProps } from './interface'

const withCopyByPersonalPage = (
  Component: React.FC<CopyByPersonalPageProps>
) => {
  function WithCopyByPersonalPage() {
    const [params] = useSearchParams()

    const userId = Number(params.get('userId'))
    const name =
      params.get('name') === 'null'
        ? ''
        : decodeURIComponent(params.get('name')!)
    const description =
      params.get('description') === 'null'
        ? ''
        : decodeURIComponent(params.get('description')!)
    const profileImg = decodeURIComponent(params.get('profileImg')!)

    const [accmulateParams, setAccmulateParams] = useState({
      endAtStart: DateTime.now().startOf('day').minus({ days: 7 }).toMillis(),
      endAtEnd: DateTime.now().endOf('day').toMillis(),
    })

    const { data: accumulate } = useGetAccumulateByUserIdQuery({
      userId,
      endAtStart: accmulateParams.endAtStart,
      endAtEnd: accmulateParams.endAtEnd,
    })

    const handleAccmulateParams = (option: WinRateOptionEnum) => {
      let start = DateTime.now().startOf('day')
      const end = DateTime.now().minus({ days: 1 }).endOf('day')
      if (option === WinRateOptionEnum.YESTERDAY) {
        start = start.minus({ days: 1 })
      } else if (option === WinRateOptionEnum.WEEK) {
        start = start.minus({ days: 7 })
      } else if (option === WinRateOptionEnum.HALFMONTH) {
        start = start.minus({ days: 15 })
      } else if (option === WinRateOptionEnum.MONTH) {
        start = start.minus({ days: 30 })
      }
      setAccmulateParams({
        endAtStart: start.toMillis(),
        endAtEnd: end.toMillis(),
      })
    }

    const newProps = {
      name,
      description,
      userId,
      accumulate,
      profileImg,
      handleAccmulateParams,
    }
    return <Component {...newProps} />
  }

  return WithCopyByPersonalPage
}

export default withCopyByPersonalPage
