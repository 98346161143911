import * as Yup from 'yup'

export const gridBotValidateSchema = ({
  max,
  initialMarginMin,
}: {
  max: number
  initialMarginMin: number | null
}) =>
  Yup.object().shape({
    symbol: Yup.string().required(),
    leverage: Yup.number().min(1).max(max).required(),
    lowerPrice: Yup.number().min(1).required(),
    gridCount: Yup.number().min(2).required(),
    gridQty: Yup.number().required(),
    profitPerGridLower: Yup.number().required(),
    profitPerGridHigher: Yup.number().required(),
    upperPrice: Yup.number()
      .required()
      .when('lowerPrice', (lowerPrice, schema) => schema.min(lowerPrice)),
    isStopTrigger: Yup.boolean().required(),
    initialMargin: Yup.number()
      .required()
      .when({
        is: () => {
          return initialMarginMin != null
        },
        then: schema => {
          return schema.min(initialMarginMin!)
        },
      }),
  })
