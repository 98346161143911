import React from 'react'
import { useParams, useSearchParams } from 'react-router-dom'
import { useGetTradeCopyByPersonBotDetailsQuery } from '@client/botClient/collections/GetTradeCopyByPersonBotDetails'
import Loading from '@components/utils/Loading'
import { CopyByPersonalBotListPageProps } from './interface'

const withCopyByPersonalBotListPage = (
  Component: React.FC<CopyByPersonalBotListPageProps>
) => {
  function WithCopyByPersonalBotListPage() {
    const { id } = useParams()
    const [params] = useSearchParams()

    const { data: personalBotDetails, isLoading } =
      useGetTradeCopyByPersonBotDetailsQuery(Number(id))

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const name =
      params.get('name') === 'null' ? '' : decodeURI(params.get('name')!)

    const buyDate = Number(params.get('buyDate'))

    const newProps = { name, buyDate, personalBotDetails, id }
    return <Component {...newProps} />
  }

  return WithCopyByPersonalBotListPage
}

export default withCopyByPersonalBotListPage
