import React, { useEffect, useRef, useState } from 'react'
import { ProfileImageAcceptProps, ProfileImageProps } from './interface'

const withProfileImage = (Component: React.FC<ProfileImageProps>) => {
  const Hoc = ({
    onSelectImageCallback,
    ...acceptProps
  }: ProfileImageAcceptProps) => {
    const [pickImage, setPickImage] = useState<File>()
    const [previewImage, setPreviewImage] = useState<string>()
    const pickerRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
      if (!pickImage) {
        setPreviewImage(undefined)
      } else {
        const objectUrl = URL.createObjectURL(pickImage)
        setPreviewImage(objectUrl)
        // CLEAN UP
        return () => {
          URL.revokeObjectURL(objectUrl)
        }
      }
      return () => {}
    }, [pickImage])

    const handleSelectImage = (e: React.FormEvent<HTMLInputElement>) => {
      const { files } = e.target as HTMLInputElement

      if (files && files.length) {
        const file = files[0]
        setPickImage(file)
        if (onSelectImageCallback) {
          onSelectImageCallback(file)
        }
      } else {
        setPickImage(undefined)
        if (onSelectImageCallback) {
          onSelectImageCallback(undefined)
        }
      }
    }

    const handlePickerClick = () => {
      pickerRef.current?.click()
    }

    const newProps = {
      pickerRef,
      pickImage,
      previewImage,
      handlePickerClick,
      handleSelectImage,
      ...acceptProps,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withProfileImage
