import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotStrategiesOption } from '@models/Bot/BotStrategiesOption'
import { Option } from '@components/form/SelectField/interface'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const botStrategiesOptionsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    botStrategiesOptions: builder.query<
      Option[],
      { botMarketType: BotMarketType }
    >({
      query: ({ botMarketType }) => ({
        document: gql`
          query BotStrategiesDefault($botMarketType: BotMarketType!) {
            botStrategiesDefault(bot_market_type: $botMarketType) {
              id
              name
            }
          }
        `,
        variables: {
          botMarketType,
        },
      }),
      transformResponse(res: { botStrategiesDefault: BotStrategiesOption[] }) {
        const { botStrategiesDefault } = res
        return botStrategiesDefault?.map(strategy => ({
          value: strategy.id,
          label: strategy.name,
        }))
      },
      providesTags: ['BotStrategiesOptions'],
    }),
  }),
})

export const { useBotStrategiesOptionsQuery } = botStrategiesOptionsApi
