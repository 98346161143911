import { Outlet } from 'react-router-dom'
import { AppLayout } from '@components/AppLayout/AppLayout'
import { walletPath, WalletRoutesPath } from '@config/Router'
import WalletSelectPage from '@features/wallet/pages/WalletSelectPage/WalletSelectPage'
import KhotcoinTopUpPage from '@features/wallet/pages/KhotcoinTopUpPage/KhotcoinTopUpPage'
import KhotcoinTransactionPage from '@features/wallet/pages/KhotcoinTransactionPage/KhotcoinTransactionPage'
import WalletPage from './pages/DepositPage/DepositPage'
import WithdrawPage from './pages/WithdrawPage/WithdrawPage'
import TransactionPage from './pages/TransactionPage/TransactionPage'
import TransferPage from './pages/TransferPage/TransferPage'
import WithdrawRequestedPage from './pages/WithdrawRequestedPage/WithdrawRequestedPage'

export const WalletRoutes = {
  path: walletPath,
  element: (
    <AppLayout>
      <Outlet />
    </AppLayout>
  ),

  children: [
    {
      path: WalletRoutesPath.walletSelect.path,
      element: <WalletSelectPage />,
    },
    {
      path: WalletRoutesPath.deposit.path,
      element: <WalletPage />,
    },
    {
      path: WalletRoutesPath.withdraw.path,
      element: <WithdrawPage />,
    },
    {
      path: WalletRoutesPath.transaction.path,
      element: <TransactionPage />,
    },
    {
      path: WalletRoutesPath.transfer.path,
      element: <TransferPage />,
    },
    {
      path: WalletRoutesPath.withdrawRequested.path,
      element: <WithdrawRequestedPage />,
    },
    {
      path: WalletRoutesPath.khotcoinTopUp.path,
      element: <KhotcoinTopUpPage />,
    },
    {
      path: WalletRoutesPath.khotcoinTransaction.path,
      element: <KhotcoinTransactionPage />,
    },
  ],
}
