import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { Accumulate } from '@models/TradeCopy/Accumulate'

export const getAccumulateByUserIdApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getAccumulateByUserId: builder.query<
      Accumulate,
      { userId: number; endAtStart: number; endAtEnd: number }
    >({
      query: getAccumulateInput => ({
        document: gql`
          query GetAccumulateByUserId(
            $getAccumulateInput: GetAccumulateByUserIdInput!
          ) {
            getAccumulateByUserId(getAccumulateInput: $getAccumulateInput) {
              totalLoss
              totalProfit
            }
          }
        `,
        variables: {
          getAccumulateInput,
        },
      }),
      transformResponse(res: { getAccumulateByUserId: Accumulate }) {
        const { getAccumulateByUserId } = res
        return getAccumulateByUserId
      },
      providesTags: (result, error, arg) => [
        { type: 'AccumulateByUserId', ...arg },
      ],
    }),
  }),
})

export const { useGetAccumulateByUserIdQuery } = getAccumulateByUserIdApi
