import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const isStopCoveringAllApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    isStopCoveringAll: builder.query<
      boolean,
      {
        botMarketType: BotMarketType
        dateNow?: Date
      }
    >({
      query: ({ botMarketType }) => ({
        document: gql`
          query IsStopCoveringAll(
            $botType: BotType!
            $botMarketType: BotMarketType!
          ) {
            isStopCoveringAll(botType: $botType, botMarketType: $botMarketType)
          }
        `,
        variables: {
          botMarketType,
          botType: 'Martingale',
        },
      }),
      transformResponse(res: { isStopCoveringAll: boolean }) {
        const { isStopCoveringAll } = res
        return isStopCoveringAll
      },
      providesTags: ['IsStopCoveringAll'],
    }),
  }),
})

export const { useIsStopCoveringAllQuery } = isStopCoveringAllApi
