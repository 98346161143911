import React from 'react'
import cn from 'classnames'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'

interface SelectBotCardProps {
  bot: TradeCopyByBot
  handleSelectedCopyByBotChange: (bot: TradeCopyByBot) => void
  selectedBot?: TradeCopyByBot
}

function SelectBotCard({
  bot,
  selectedBot,
  handleSelectedCopyByBotChange,
}: SelectBotCardProps) {
  return (
    <div
      className={cn(
        'border border-gray-300 rounded-[6px] px-[20px] py-[16px] cursor-pointer',
        { '!border-primary': bot.id === selectedBot?.id }
      )}
      onClick={() => handleSelectedCopyByBotChange(bot)}
    >
      <div className='flex gap-x-[10px]'>
        <img
          src={bot.baseCurrencyData?.imgPath}
          alt={bot.symbol}
          className='w-[38px] h-[38px] rounded-full'
        />
        <div className='flex flex-col gap-y-[2px]'>
          <div className='text-sm font-bold leading-[22px]'>{bot.symbol}</div>
          <div className='text-xs font-light'>{`Buy date: ${bot.buyDate?.toFormat(
            'dd/MM/yyyy'
          )}`}</div>
        </div>
      </div>
    </div>
  )
}

export default SelectBotCard
