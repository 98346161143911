import React, { useState } from 'react'
import { toast } from 'react-toastify'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import { HomeRoutesPath } from '@config/Router'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useRedeemCouponMutation } from '@client/mainClient/collections/RedeemCouponClientAction'
import { useGetUsedCouponsApiQuery } from '@client/mainClient/collections/GetUsedCouponsClientAction'
import { RedeemCouPageFormDataType, RedeemCouPageProps } from './interface'

const withRedeemCouponPage = (Component: React.FC<RedeemCouPageProps>) => {
  function WithChangePasswordPage() {
    const { refetch } = useProfileQuery({})
    const [redeemCoupon, { isLoading }] = useRedeemCouponMutation()
    const [startDate, setStartDate] = useState<DateTime | null>(null)
    const [endDate, setEndDate] = useState<DateTime | null>(null)
    const { data: usedCoupons, isLoading: isUsedCouponsLoading } =
      useGetUsedCouponsApiQuery({
        endAt: endDate?.toJSDate(),
        startAt: startDate?.toJSDate(),
      })
    const navigate = useNavigate()

    const handleOnSelectDates = (
      filterDates: [Date | null, Date | null] | null
    ) => {
      if (filterDates) {
        const [startAt, endAt] = filterDates
        setStartDate(startAt ? DateTime.fromJSDate(startAt) : null)
        setEndDate(endAt ? DateTime.fromJSDate(endAt) : null)
      } else {
        setStartDate(null)
        setStartDate(null)
      }
    }

    const handleRedeemCoupon = (form: RedeemCouPageFormDataType) => {
      redeemCoupon({
        couponCode: form.couponCode,
      })
        .unwrap()
        .then(() => {
          toast.success('Redeemed coupon Successful')
          refetch()
          navigate(HomeRoutesPath.home.path)
        })
        .catch(() => {})
    }

    const newProps: RedeemCouPageProps = {
      handleRedeemCoupon,
      isLoading,
      startDate,
      endDate,
      handleOnSelectDates,
      usedCoupons,
      isUsedCouponsLoading,
    }
    return <Component {...newProps} />
  }

  return WithChangePasswordPage
}

export default withRedeemCouponPage
