import WalletContainer from '@features/wallet/components/WalletContainer/WalletContainer'
import { QuestionIcon } from '@components/Icons/Icons'
import DepositAmount from '@components/DepositAmount/DepositAmount'

import withDepositPage from './withDepositPage'
import { DepositPageProps } from './interface'

function DepositPage({
  handleNavigateToTutorial,
  profile,
  handleShowWalletPolicyModal,
}: DepositPageProps) {
  return (
    <WalletContainer type='deposit'>
      <DepositAmount />
      <div
        className='flex gap-x-2 text-gray-400 justify-center cursor-pointer'
        onClick={handleNavigateToTutorial}
      >
        <QuestionIcon />
        <span className='underline text-sm'>
          How to add money to your wallet
        </span>
      </div>
      <div
        className='flex gap-x-2 text-gray-400 justify-center cursor-pointer mt-[8px]'
        onClick={handleShowWalletPolicyModal}
      >
        <QuestionIcon />
        <span className='underline text-sm'>Wallet policy</span>
      </div>
    </WalletContainer>
  )
}

export default withDepositPage(DepositPage)
