import {
  ArrowDown2Icon,
  ArrowUp2Icon,
  CaretDownIcon,
  CaretUpIcon,
} from '@components/Icons/Icons'
import RankCircleNumber from '@components/RankCircleNumber/RankCircleNumber'
import { toCompactNumber } from '@libs/currencyFormat'
import { TrendingCoin } from '@models/TrendingCoin/TrendingCoin'
import { createSearchParams, useNavigate } from 'react-router-dom'
import { TradingViewRoutesPath } from '@config/Router'
import React from 'react'

const TrendingCoinCard = ({
  rank,
  trendingCoin,
}: {
  rank: number
  trendingCoin: TrendingCoin
}) => {
  const navigate = useNavigate()
  return (
    <div
      className='flex flex-col space-y-1 relative border-gray-300 border-1 rounded-lg cursor-pointer'
      onClick={() => {
        let search: string | undefined
        if (trendingCoin.symbol) {
          search = `?${createSearchParams({
            tvwidgetsymbol: `BINANCE:${`${trendingCoin.symbol}USDT`}`,
          })}`
        }
        navigate({
          pathname: TradingViewRoutesPath.tradingView.absolutePath,
          search,
        })
      }}
    >
      <RankCircleNumber
        rank={rank}
        className='absolute w-[20px] h-[20px] transform -translate-x-1/2 -translate-y-1/2'
      />
      <div className='py-2 px-5'>
        <div className='text-xs flex space-x-1 items-end mb-2'>
          <img
            src={trendingCoin.imgPath}
            alt={trendingCoin.symbol ?? trendingCoin.slug}
            className='w-[20px] h-[20px] rounded-full'
          />
          <span>{`${trendingCoin.symbol ?? trendingCoin.slug}/USDT`}</span>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Ranking (Market cap)
          <span className='font-bold'>{trendingCoin.rank}</span>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Market cap
          <span className='font-bold'>
            ${toCompactNumber(trendingCoin.marketCap)}
          </span>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Market price
          <span className='font-bold'>
            ${toCompactNumber(trendingCoin.marketPrice)}
          </span>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Price Change (1 hr.)
          <div className='flex items-center '>
            {trendingCoin.priceChange1h > 0 ? (
              <CaretUpIcon className='w-[18px] h-[18px] text-[#60C97E]' />
            ) : (
              <CaretDownIcon className='w-[18px] h-[18px] text-[#EB5D57]' />
            )}
            <span
              className={`font-bold ${
                trendingCoin.priceChange1h > 0
                  ? 'text-[#60C97E]'
                  : 'text-[#EB5D57]'
              }`}
            >
              {trendingCoin.priceChange1h.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </span>
          </div>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Price Change (24 hr.)
          <div className='flex items-center '>
            {trendingCoin.percentChange24h > 0 ? (
              <CaretUpIcon className='w-[18px] h-[18px] text-[#60C97E]' />
            ) : (
              <CaretDownIcon className='w-[18px] h-[18px] text-[#EB5D57]' />
            )}
            <span
              className={`font-bold ${
                trendingCoin.percentChange24h > 0
                  ? 'text-[#60C97E]'
                  : 'text-[#EB5D57]'
              }`}
            >
              {trendingCoin.percentChange24h.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </span>
          </div>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Vol. (24 hr.)
          <span className='font-bold'>
            ${toCompactNumber(trendingCoin.volume24h)}
          </span>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Vol Change (24 hr.)
          <div className='flex items-center '>
            {trendingCoin.volumeChange24h > 0 ? (
              <CaretUpIcon className='w-[18px] h-[18px] text-[#60C97E]' />
            ) : (
              <CaretDownIcon className='w-[18px] h-[18px] text-[#EB5D57]' />
            )}
            <span
              className={`font-bold ${
                trendingCoin.volumeChange24h > 0
                  ? 'text-[#60C97E]'
                  : 'text-[#EB5D57]'
              }`}
            >
              {trendingCoin.volumeChange24h.toLocaleString(undefined, {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}
              %
            </span>
          </div>
        </div>
        <div className='flex justify-between items-center text-[10px] text-gray-400 min-h-[20px]'>
          Vol. (hr.)
          <div className='flex'>
            {trendingCoin.volume1hr.length <= 0 && <span>-</span>}
            {trendingCoin.volume1hr.map((value, index) => {
              if (value === 'down') {
                return (
                  <ArrowDown2Icon key={value + index} height='10' width='10' />
                )
              }
              return <ArrowUp2Icon key={value + index} height='10' width='10' />
            })}
          </div>
        </div>
      </div>
    </div>
  )
}
export default TrendingCoinCard
