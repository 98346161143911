import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { CreateUser } from '@models/User/CreateUser'

export const createUserApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createUser: builder.mutation<{ id: number }, CreateUser>({
      query: newUserInput => ({
        document: gql`
          mutation CreateUser($newUserInput: NewUserInput!) {
            createUser(newUserInput: $newUserInput) {
              id
            }
          }
        `,
        variables: {
          newUserInput,
        },
      }),
    }),
  }),
})

export const { useCreateUserMutation } = createUserApi
