import { useEffect, useState } from 'react'
import ReactMarkdown from 'react-markdown'

const Privacy = ({ className }: { className?: string }) => {
  const [content, setContent] = useState('')

  useEffect(() => {
    fetch('/privacy/privacy.md')
      .then(res => res.text())
      .then(text => {
        return setContent(text)
      })
  }, [])

  return (
    <>
      <div className={`flex flex-1 justify-center ${className}`}>
        <strong> User privacy notice</strong>
      </div>
      <ReactMarkdown className={`text-justify markdown ${className}`}>
        {content}
      </ReactMarkdown>
    </>
  )
}
export default Privacy
