import { useDispatch, useSelector } from 'react-redux'
import {
  authenticationSelector,
  logout,
} from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { botApi, exchangeApi, mainApi } from '@client/client'
import { useLogoutMutation } from '@client/mainClient/collections/LogOutClientAction'
import { NavBarProps } from './interface'

export const withNavBar = (Component: React.FC<NavBarProps>) => {
  function WithNavBar() {
    const { profile } = useSelector(authenticationSelector)

    const dispatch = useDispatch()
    const [logoutMutate] = useLogoutMutation()

    const handleLogout = () => {
      logoutMutate()
      dispatch(logout())
      dispatch(botApi.util.resetApiState())
      dispatch(mainApi.util.resetApiState())
      dispatch(exchangeApi.util.resetApiState())
    }

    const newProps = { handleLogout, profile }

    return <Component {...newProps} />
  }
  return WithNavBar
}
