import { Expose, Transform } from 'class-transformer'

export class MarketTickerOption {
  @Expose({ name: 'symbol' })
  value: string

  @Expose()
  @Transform(({ obj }) => obj?.symbol || '')
  label: string
}
