import { BotMarketType } from '@interfaces/BotMarketEnum'
import { MarginTypeEnum } from '@interfaces/MarginTypeEnum'
import { TradeSideEnum } from '@interfaces/TradeSideEnum'

export const initialValues = (botMarketType: BotMarketType) => {
  if (botMarketType === BotMarketType.SPOT) {
    return {
      symbol: null,
      marginType: MarginTypeEnum.CROSSED,
      side: TradeSideEnum.LONG,
      isCovering: true,
      isFirstOrderDouble: false,
      firstOrderRawQuote: 11, // Make first orderRawQuote 11 here
      firstOrderTradeQuote: 50,
      leverage: 4,
      coveringQuantity: 6,
      tpRetracement: 0.001,
      tpRatio: 0.03,
      isStopLoss: false,
      isSplitTp: false,
      stopLossRatio: undefined,
      stopLossLimit: undefined,
      divisionAndControlProfit: 0,
      isCycle: false,
      cycleQuantity: 20,
      isCopyable: true,
      limitPrice: undefined,
    }
  }
  return {
    symbol: null,
    marginType: MarginTypeEnum.CROSSED,
    side: TradeSideEnum.LONG,
    isCovering: true,
    isFirstOrderDouble: false,
    firstOrderRawQuote: 10,
    firstOrderTradeQuote: 50,
    leverage: 4,
    coveringQuantity: 6,
    tpRetracement: 0.001,
    tpRatio: 0.03,
    isStopLoss: false,
    isSplitTp: false,
    stopLossRatio: undefined,
    stopLossLimit: undefined,
    divisionAndControlProfit: 0,
    isCycle: false,
    cycleQuantity: 20,
    isCopyable: true,
    limitPrice: undefined,
  }
}

export const initialCoverSpreadsValue = {
  // Length = coverQuantity
  coverSpreads: [0.02, 0.035, 0.045, 0.06, 0.08, 0.1],
  coverSpreadTrends: [],
}

export const initialTpRetracementSplitValue = {
  // Length = coverQuantity + 1
  tpSplitField: [0.001, 0.001, 0.001, 0.001, 0.001, 0.001, 0.001],
}

export const initialTpRatioSplitValue = {
  // Length = coverQuantity + 1
  tpSplitField: [0.01, 0.02, 0.03, 0.04, 0.05, 0.06, 0.07],
}

export const initialCoverMultiplesValue = {
  // Length = coverQuantity
  coverMultiples: [2, 4, 8, 16, 32, 64],
}

export const initialProfitProportionValue = {
  // Length = coverQuantity
  profitProportion: [
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
    undefined,
  ],
}

export const initialPullbackSpreadsValue = {
  // Length = coverQuantity
  pullbackSpreads: [0.001, 0.001, 0.001, 0.001, 0.001, 0.001],
}
