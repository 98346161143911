import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const turnOnBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    turnOnBot: builder.mutation<{ id: number }, number>({
      query: botId => ({
        document: gql`
          mutation TurnOnBot($botId: Int!, $botType: BotType!) {
            turnOnBot(botId: $botId, botType: $botType) {
              id
            }
          }
        `,
        variables: {
          botId,
          botType: 'Martingale',
        },
      }),
    }),
  }),
})

export const { useTurnOnBotMutation } = turnOnBotApi
