import { CopyIcon, ShareNetworkIcon } from '@components/Icons/Icons'

import { AuthenticationRoutesPath } from '@config/Router'
import { useMemo } from 'react'

import { toast } from 'react-toastify'

const ReferralButton = ({ referralCode }: { referralCode: string }) => {
  const inviteLink = useMemo(
    () =>
      `${window.location.origin}${AuthenticationRoutesPath.register.absolutePath}?referral=${referralCode}`,
    [referralCode]
  )
  const handleCopyReferralCode = () => {
    navigator.clipboard.writeText(referralCode).then(() => {
      toast.info('Referral code copied')
    })
  }
  const handleCopyInviteLinkCode = () => {
    navigator.clipboard.writeText(inviteLink).then(() => {
      toast.info('Invitation link copied')
    })
  }

  return (
    <div className='flex flex-col items-start justify-start'>
      <div
        className='flex items-center cursor-pointer'
        onClick={handleCopyInviteLinkCode}
      >
        <span className='text-[10px] mr-[8px]'>Invitation link</span>
        <ShareNetworkIcon height='14' width='14' />
      </div>
      {/* ------------- */}
      <div
        className='flex items-center cursor-pointer'
        onClick={handleCopyReferralCode}
      >
        <span className='text-[10px] mr-[8px]'>
          Referral code : {referralCode}
        </span>
        <CopyIcon height='14' width='14' />
      </div>
    </div>
  )
}
export default ReferralButton
