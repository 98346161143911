import React from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'
import { useNavigate } from 'react-router-dom'
import { BinIcon, ThreeDotIcon } from '@components/Icons/Icons'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { useDropdown } from '@hooks/useDropDown'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { TradeCopyRoutesPath } from '@config/Router'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'

interface StrategyCardProps {
  strategy: BotStrategiesDefault
  handleDeleteStrategy: (id: number) => Promise<void>
}

function StrategyCard({ strategy, handleDeleteStrategy }: StrategyCardProps) {
  const { dropdownRef, isShowDropdown, toggleDropdown } = useDropdown()
  const navigate = useNavigate()

  const { openModal } = useModal({
    isHideCloseButton: true,
    component: ({ onConfirm, message }: ConfirmModalProps) => (
      <ConfirmModal
        showIcon={false}
        message={
          <div className='flex flex-col items-center'>
            <BinIcon className='text-[#EB5D57] w-[24px] h-[26px] mb-[12px]' />
            <div className='text-lg font-medium text-gray-60'>Delete</div>
            <p className='mt-[7px] text-sm text-gray-60'>
              Are you sure to delete bot?
            </p>
          </div>
        }
        onConfirm={onConfirm}
      />
    ),
  })

  return (
    <div
      className='border border-gray-300 rounded-[6px] px-[20px] py-[16px] cursor-pointer'
      onClick={e => {
        navigate(
          TradeCopyRoutesPath.tradeCopy.myStrategy.detail.absolutePath({
            id: strategy.id,
          })
        )
      }}
    >
      <div className='relative'>
        <div
          className='absolute right-[-10px] top-0 cursor-pointer'
          onClick={e => {
            toggleDropdown()
            e.stopPropagation()
          }}
        >
          <ThreeDotIcon />
        </div>
        <div
          ref={dropdownRef}
          className={cn(
            'absolute w-[116px] z-10 py-[11px] right-0 top-[20px] bg-navBar border border-gray-600 rounded-[10px]',
            { hidden: !isShowDropdown }
          )}
        >
          <div
            className='px-[11px] text-[10px] font-normal border-b border-gray-600 mb-[6px] pb-[6px] cursor-pointer hover:text-primary'
            onClick={() =>
              navigate(
                TradeCopyRoutesPath.tradeCopy.myStrategy.detail.absolutePath({
                  id: strategy.id,
                })
              )
            }
          >
            View
          </div>
          <div
            className='px-[11px] text-[10px] font-normal cursor-pointer hover:text-primary'
            onClick={() =>
              openModal({
                onConfirm: async () => {
                  if (strategy.id) await handleDeleteStrategy(strategy.id)
                },
              })
            }
          >
            Delete
          </div>
        </div>
        <div className=' flex flex-col gap-y-[2px]'>
          <div className='text-sm font-bold'>{strategy.name}</div>
          <div className='text-xs font-light'>{`Leverage: X${strategy.leverage}`}</div>
          <div className='text-xs font-light'>{`Created date: ${DateTime.fromMillis(
            strategy.createdAt
          ).toFormat('dd/MM/yyyy')}`}</div>
        </div>
      </div>
    </div>
  )
}

export default StrategyCard
