import React from 'react'
import Select, { components } from 'react-select'
import {
  Option,
  SelectFieldProps,
} from '@components/form/SelectField/interface'
import { ArrowDownIcon } from '@components/Icons/Icons'
import { withSelectField } from './withSelectField'

const SelectField = ({
  error,
  touched,
  options,
  name,
  setValue,
  value,
  isMulti = false,
  disabled,
  leftIcon,
  icon,
  label,
  placeholder,
  isLoading = false,
  isSearchable = false,
  className,
  onChange,
}: SelectFieldProps) => {
  return (
    <div className={`inline-block w-full ${className}`}>
      <div className='relative flex-wrap w-full items-stretch text-sm'>
        {label && <div className='text-sm mb-2 font-normal'>{label}</div>}
        <Select
          value={
            Array.isArray(value)
              ? value.map(id => options.find(option => option.value === id))
              : options.find(option => option.value === value)
          }
          isLoading={isLoading}
          options={options}
          name={name}
          onChange={v => {
            const val = v as Option | Option[]
            const selectedValue = Array.isArray(val)
              ? val.map(option => option.value)
              : val.value
            setValue(selectedValue)
            if (onChange) {
              onChange(selectedValue)
            }
          }}
          isMulti={isMulti}
          defaultValue={null}
          defaultInputValue={undefined}
          className='basic-multi-select'
          classNamePrefix='select'
          placeholder={placeholder}
          isDisabled={disabled}
          isSearchable={isSearchable}
          components={{
            DropdownIndicator: props => (
              <components.DropdownIndicator {...props}>
                {icon || <ArrowDownIcon width='14' height='14' />}
              </components.DropdownIndicator>
            ),
            Control: ({ children, ...rest }) => (
              <components.Control {...rest}>
                {leftIcon}
                {children}
              </components.Control>
            ),
          }}
          styles={{
            placeholder: base => ({
              ...base,
              color: '#ffffff',
            }),
            control: (base, state) => ({
              ...base,
              borderRadius: '4px',
              overflow: 'hidden',
              backgroundColor: state.isDisabled ? '#e5e7eb' : '#1D2533F2',
              border: '0',
              borderColor: '#484C4E',
              boxShadow: 'none',
              paddingTop: 9,
              paddingBottom: 9,
            }),
            input: base => ({
              ...base,
              color: '#ffffff',
            }),
            singleValue: base => ({
              ...base,
              color: '#ffffff',
            }),
            valueContainer: base => ({
              ...base,
            }),
            menu: base => ({
              ...base,
              zIndex: 10,
              backgroundColor: '#1D2533F2',
            }),
            option: (base, { isSelected }) => ({
              ...base,
              color: '#ffffff',
              backgroundColor: isSelected ? '#21BB8E' : '#1D2533F2',
              ':hover': {
                backgroundColor: '#21BB8E',
              },
            }),
            indicatorSeparator: base => ({
              ...base,
              backgroundColor: 'none',
            }),
            dropdownIndicator: base => ({
              ...base,
              paddingLeft: 0,
            }),
          }}
        />

        {error && touched && (
          <div className='mt-[2px] text-xs text-red top-full'>{error}</div>
        )}
      </div>
    </div>
  )
}

export default withSelectField(SelectField)
