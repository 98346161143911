import React from 'react'

import { useBotQuery } from '@client/botClient/collections/BotClientAction'
import { useParams } from 'react-router-dom'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import Loading from '@components/utils/Loading'
import { BotLayoutAcceptProps, BotLayoutProps } from './interface'

const withBotLayout = (Component: React.FC<BotLayoutProps>) => {
  const Hoc = ({ title, children }: BotLayoutAcceptProps) => {
    useProfileQuery({})
    const { id } = useParams()

    const isShowOnlyIcon = true

    const {
      data: bot,
      isLoading,
      refetch,
    } = useBotQuery({ botId: Number(id) }, { refetchOnMountOrArgChange: true })

    if (isLoading || !bot) {
      return <Loading text='Loading' />
    }

    const newProps: BotLayoutProps = {
      title,
      children,
      bot,
      isShowOnlyIcon,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withBotLayout
