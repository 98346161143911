import React from 'react'
import { Duration } from 'luxon'
import { Field, Form, Formik } from 'formik'
import OtpInput from 'react-otp-input'
import { FieldPropsType } from '@interfaces/FieldPropsType'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { OtpProps } from './interface'
import withOtp from './withOtp'

function Otp({
  handleOtpFormSubmit,
  phoneNumber,
  phoneNumberCountryCode,
  changePhoneNumberStep,
  resendOtp,
  isInvalidToken,
  resendCount,
  isShowLogo = true,
}: OtpProps) {
  return (
    <div className='max-w-[335px] m-auto'>
      <Formik
        initialValues={{
          otp: '',
        }}
        onSubmit={handleOtpFormSubmit}
      >
        <Form className='flex flex-col justify-center h-4/5-screen'>
          {isShowLogo && (
            <div className='flex justify-center h-[80px] mobile:mb-[30px] mb-[50px]'>
              <img
                src='/logoHorizontal.png'
                alt='logo-horizontal'
                className='h-[80px] w-fit '
              />
            </div>
          )}
          <div className='text-white text-4xl font-bold mb-[12px]'>
            OTP Verification
          </div>
          <div className='text-white text-base mb-[20px]'>
            Enter OTP sent to +{phoneNumberCountryCode}
            {phoneNumber}
          </div>
          <div className='relative'>
            <Field name='otp'>
              {({ field, meta, form }: FieldPropsType<any>) => (
                <OtpInput
                  value={field.value}
                  onChange={value => form.setFieldValue('otp', value)}
                  numInputs={6}
                  isInputNum
                  separator={<span className='w-[8px]' />}
                  containerStyle={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                  inputStyle={{
                    backgroundColor: 'unset',
                    borderBottom: '3px solid #3040C9',
                    color: '#21BB8E',
                    fontSize: '36px',
                    outlineStyle: 'none',
                    width: '42px',
                  }}
                  focusStyle={{ borderBottom: '3px solid #21BB8E' }}
                />
              )}
            </Field>
            {isInvalidToken && (
              <div className='text-red text-xs font-normal px-[21px] pt-[16px] text-center'>
                Invalid OTP code please try again
              </div>
            )}
          </div>
          <div
            className='text-sm font-medium text-center mt-[53px] mb-[68px]'
            onClick={resendOtp}
          >
            <span>
              {`Didn't receive OTP? `}{' '}
              <span
                className={`text-sm font-medium text-center mt-[20px] mb-[50px] cursor-pointer underline ${
                  resendCount > 0 ? 'text-gray-59' : 'text-primary'
                }`}
                onClick={resendOtp}
              >
                Resend OTP
              </span>
            </span>
            {resendCount > 0 && (
              <span className=' text-primary ml-[5px]'>
                {Duration.fromMillis(resendCount).toFormat('mm:ss')}
              </span>
            )}
          </div>
          <PrimaryButton
            type='submit'
            className='w-full rounded-[6px] h-[48px] mb-[20px]'
          >
            <p className='text-lg font-bold'>Verify</p>
          </PrimaryButton>
          {changePhoneNumberStep && (
            <div
              className='text-sm font-medium underline text-primary text-center mb-[68px] cursor-pointer'
              onClick={changePhoneNumberStep}
            >
              Try a different phone number
            </div>
          )}
        </Form>
      </Formik>
    </div>
  )
}

export default withOtp(Otp)
