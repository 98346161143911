import { useField } from 'formik'
import { RadioInputFieldProps, WithRadioInputFieldProps } from './interface'

export function withRadioInputField(Component: React.FC<RadioInputFieldProps>) {
  function WithRadioInputField({
    className,
    radioClassName,
    name,
    label,
    direction,
    options,
    tooltip,
  }: WithRadioInputFieldProps) {
    const [field] = useField(name)

    const componentProps = {
      ...field,
      className,
      radioClassName,
      label,
      direction,
      options,
      tooltip,
    }
    return <Component {...componentProps} />
  }

  WithRadioInputField.defaultProps = {
    direction: 'horizontal',
  }

  return WithRadioInputField
}
