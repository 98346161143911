import { CaretDownIcon, CaretUpIcon } from '@components/Icons/Icons'
import SymbolImage from '@components/SymbolImage/SymbolImage'
import { LuxonUnitType } from '@interfaces/LuxonUnitType'
import { BotProfitGroupByCoin } from '@models/Profit/BotProfitGroupByCoin'
import { DateTime } from 'luxon'
import { useState } from 'react'

const ProfitByCoinCard = ({
  profitCoin,
  sortDate,
}: {
  profitCoin: BotProfitGroupByCoin
  sortDate: LuxonUnitType
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  const handleToggleExpanded = () => setIsExpanded(!isExpanded)
  const sumProfit = profitCoin.coins.reduce((a, b) => a + b.profit, 0)
  return (
    <div className='flex flex-col border-gray-300 border-1 rounded-[10px] py-4 px-8'>
      <div className='mb-[8px] flex'>
        <span className='text-sm font-bold'>
          {sortDate === 'day'
            ? `${DateTime.fromMillis(profitCoin.startDate).toFormat(
                'dd/MM/yyyy'
              )}`
            : sortDate === 'month'
            ? `${DateTime.fromMillis(profitCoin.startDate).toFormat(
                'MMMM yyyy',
                {
                  locale: 'EN',
                }
              )}`
            : `${DateTime.fromMillis(profitCoin.startDate).toFormat(
                'dd/MM/yyyy'
              )} - ${DateTime.fromMillis(profitCoin.endDate).toFormat(
                'dd/MM/yyyy'
              )}`}
        </span>
      </div>
      <div className='flex justify-between'>
        <span className='text-xs'>Total profit</span>
        <span className='text-xs font-bold'>
          {sumProfit.toLocaleString(undefined, {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
          })}{' '}
          USDT
        </span>
      </div>
      <>
        {!isExpanded && profitCoin.coins.length > 0 && (
          <span
            className='flex items-center justify-end mt-[15px] text-[10px] text-gray-400 cursor-pointer'
            onClick={handleToggleExpanded}
          >
            See more
            <CaretDownIcon className='w-[10px] h-[10px]' />
          </span>
        )}
        {isExpanded &&
          profitCoin.coins.map((coin, index) => {
            return (
              <div
                className='flex justify-between items-center space-y-2 mt-[5px]'
                key={index}
              >
                <div className='flex items-center'>
                  <SymbolImage
                    path={coin.imgPath}
                    className='w-[20px] h-[20px] mr-[5px]'
                  />
                  <span className='text-xs'>{coin?.symbol || '-'}</span>
                </div>
                <span className='text-xs font-bold'>
                  {coin?.profit?.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                  }) || '-'}{' '}
                  USDT
                </span>
              </div>
            )
          })}
        {isExpanded && (
          <span
            className='flex items-center justify-end mt-[15px] text-[10px] text-secondary cursor-pointer'
            onClick={handleToggleExpanded}
          >
            Hide
            <CaretUpIcon className='w-[10px] h-[10px]' />
          </span>
        )}
      </>
    </div>
  )
}
export default ProfitByCoinCard
