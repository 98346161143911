import CurrentTierCard from './components/CurrentTierCard'
import TierSteps from './components/TierSteps'
import { TierProps } from './interface'
import withTier from './withTier'

function Tier({
  allTier,
  isMobile,
  referralCode,
  handleInfoClick,
  profile,
}: TierProps) {
  return (
    <div className='flex flex-col'>
      <CurrentTierCard
        referralCode={referralCode}
        handleInfoClick={handleInfoClick}
        profile={profile}
        nextTier={allTier.find(t => t.id === profile.userTier.nextTierId)}
      />
      {!isMobile && (
        <TierSteps allTier={allTier} currentTierId={profile.userTier.id} />
      )}
    </div>
  )
}

export default withTier(Tier)
