import React from 'react'
import {
  KhotcoinBoxAcceptProps,
  KhotcoinBoxProps,
} from '@features/home/components/KhotcoinBox/interface'

import { useWalletsQuery } from '@client/mainClient/collections/GetWallets'

const withKhotcoinBox = (Component: React.FC<KhotcoinBoxProps>) => {
  const Hoc = ({ className }: KhotcoinBoxAcceptProps) => {
    const { data: dataWallet } = useWalletsQuery()

    const newProps: KhotcoinBoxProps = {
      khotcoinBalance: dataWallet?.khotCoinsAmount ?? 0,
      className,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withKhotcoinBox
