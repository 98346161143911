import { KhotcoinTopUpPageProps } from '@features/wallet/pages/KhotcoinTopUpPage/interface'
import React from 'react'
import KhotcoinContainer from '@features/wallet/components/KhotcoinContainer/KhotcoinContainer'
import KhotcoinCard from '@features/wallet/pages/KhotcoinTopUpPage/components/KhotcoinCard/KhotcoinCard'
import PrimaryButton from '@components/buttons/PrimaryButton'
import withKhotcoinTopUpPage from './withKhotcoinTopUpPage'

const KhotcoinTopUpPage = ({
  handleSelectKhotcoinOption,
  selectedKhotcoin,
  handleOnSubmit,
  isLoadingTopUp,
  khotcoinPackages,
}: KhotcoinTopUpPageProps) => {
  return (
    <KhotcoinContainer type='top-up'>
      <div className='my-[24px]'>
        {khotcoinPackages?.map(khotcoin => (
          <KhotcoinCard
            key={khotcoin.id}
            onClick={() => {
              handleSelectKhotcoinOption(khotcoin)
            }}
            isSelected={selectedKhotcoin?.id === khotcoin.id}
            khotcoinPackage={khotcoin}
          />
        ))}
      </div>
      <PrimaryButton
        type='button'
        className='w-full rounded-[18px] mb-[20px]'
        disabled={!selectedKhotcoin || isLoadingTopUp}
        onClick={handleOnSubmit}
        data-test-id='khotcoin-top-up-submit-btn'
      >
        Purchase
      </PrimaryButton>
    </KhotcoinContainer>
  )
}

const WrappedComponent = withKhotcoinTopUpPage(KhotcoinTopUpPage)
export default WrappedComponent
