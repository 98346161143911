import { Expose, Transform } from 'class-transformer'

export class AuthExchange {
  @Expose()
  @Transform(({ value }) => Number(value), {
    toClassOnly: true,
  })
  id: number

  @Expose()
  apiKey?: string

  @Expose()
  secret?: string
}
