import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { CreateBotFromEasyTradeForm } from '@models/Bot/CreateBotFromEasyTradeForm'

export const createBotFromEasyTradeApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createBotFromEasyTrade: builder.mutation<
      boolean,
      CreateBotFromEasyTradeForm
    >({
      query: newBotFromEasyTradeInput => ({
        operationName: 'CreateBotFromEasyTradeTemplate',
        document: gql`
          mutation CreateBotFromEasyTradeTemplate(
            $newBotFromEasyTradeInput: NewBotFromEasyTradeInput!
          ) {
            createBotFromEasyTradeTemplate(
              NewBotFromEasyTrade: $newBotFromEasyTradeInput
            )
          }
        `,
        variables: {
          newBotFromEasyTradeInput,
        },
      }),
    }),
  }),
})

export const { useCreateBotFromEasyTradeMutation } = createBotFromEasyTradeApi
