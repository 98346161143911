import { TimeSeries } from '@models/Chart/TimeSeries'
import { DateTime } from 'luxon'

const RankingChartCard = ({
  timeSeries: { value, date },
  mode,
}: {
  timeSeries: TimeSeries
  mode: 'total' | 'daily'
}) => {
  const luxonDate =
    mode === 'daily'
      ? DateTime.fromFormat(date, 'yyyy-MM-dd')
      : DateTime.fromFormat(date, 'yyyy-MM')

  return (
    <div className='flex flex-col space-y-1 py-[15px] px-[22px] border-1 border-gray-300 rounded-[10px]'>
      {/* Date */}
      <div className='text-sm font-bold'>
        {mode === 'daily'
          ? luxonDate.toFormat('dd/MM/yyyy')
          : `${luxonDate.startOf('month').toFormat('dd/MM/yyyy')} - ${luxonDate
              .endOf('month')
              .toFormat('dd/MM/yyyy')}`}
      </div>
      {/*  Value  */}
      <div className='flex justify-between items-center text-xs'>
        <span>Total profit</span>
        <span className='font-bold'>
          {value.toLocaleString(undefined, { maximumFractionDigits: 2 })} USDT
        </span>
      </div>
    </div>
  )
}

export default RankingChartCard
