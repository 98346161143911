import React, { useEffect, useMemo, useState } from 'react'
import { toast } from 'react-toastify'
import { classToPlain, plainToClass } from 'class-transformer'
import { useNavigate } from 'react-router-dom'
import { ContractRoutesPath } from '@config/Router'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useLazyFutureMarketLeverageQuery } from '@client/exchangeClient/collections/FutureMarketLeverage'
import { useFutureMarketDetailQuery } from '@client/exchangeClient/collections/FutureMarketDetail'
import { FutureMarketLeverage } from '@models/FutureMarketLeverage/FutureMarketLeverage'
import { GridBotForm } from '@models/Bot/GridBotForm'
import { ADJUST_COEF } from '@config/config'
import { MinMaxType } from '@models/FutureMarketDetail/FutureMarketDetail'
import { gridBotValidateSchema } from '@features/bot/pages/CreateGridBotPage/components/GridBotSettingForm/gridBotValidateSchema'
import { useCreateGridBotMutation } from '@client/botClient/collections/CreateGridBotClientAction'
import { MarketTickerOption } from '@models/MarketTicker/MarketTickerOption'
import { useMarketTickersQuery } from '@client/exchangeClient/collections/MargetTickersClientAction'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

import {
  GridBotSettingFormAcceptProps,
  GridBotSettingFormProps,
} from './interface'

const withGridBotSettingForm = (
  Component: React.FC<GridBotSettingFormProps>
) => {
  function WithBotSettingForm({
    mode,
    ...props
  }: GridBotSettingFormAcceptProps) {
    const { botMarketType } = useBotMarketType()
    const navigate = useNavigate()
    const [maxLeverage, setMaxLeverage] = useState(125)
    const [selectedSymbol, setSelectedSymbol] = useState('BCH/USDT')

    const [initialMarginMin, setInitialMarginMin] = useState<number | null>(
      null
    )
    const [profitPerGrid, setProfitPerGrid] = useState<MinMaxType | null>(null)

    const [leveragesData, setLeveragesData] = useState<FutureMarketLeverage[]>(
      []
    )
    const [getFutureMarketLeverage] = useLazyFutureMarketLeverageQuery()
    const { data: futureMarketData } = useFutureMarketDetailQuery({
      symbol: selectedSymbol,
    })

    const getSymbols = async () => {
      try {
        const data = await getFutureMarketLeverage({
          symbol: selectedSymbol,
        }).unwrap()
        setLeveragesData(data)
      } catch (_) {}
    }

    useEffect(() => {
      getSymbols()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to create grid bot
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const navigateToCreateBotPage = () => {
      navigate(ContractRoutesPath.createBot.absolutePath)
    }

    const onSymbolChange = value => {
      setSelectedSymbol(value)
    }

    const { data: symbolsData } = useMarketTickersQuery({ botMarketType })

    const symbolOptions = useMemo(() => {
      return plainToClass(MarketTickerOption, symbolsData || [])
    }, [symbolsData])

    const [createGridBotMutate] = useCreateGridBotMutation()

    const onMainFormSubmit = (form: any) => {
      openModal({
        onConfirm: () => {
          const plainForm = {
            ...classToPlain(form, {
              excludeExtraneousValues: true,
            }),
          }
          createGridBotMutate(plainForm as GridBotForm)
            .unwrap()
            .then(() => {
              toast.success('Create grid bot success')
              navigate(ContractRoutesPath.dashboard.absolutePath)
            })
            .catch(() => {})
        },
      })
    }

    const onFormChange = (
      formikValue: GridBotForm,
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean
      ) => void
    ) => {
      const validation =
        formikValue.gridCount &&
        formikValue.lowerPrice &&
        formikValue.upperPrice &&
        futureMarketData?.maker

      if (validation && futureMarketData) {
        const priceDiff =
          (formikValue.upperPrice - formikValue.lowerPrice) /
          formikValue.gridCount
        const gridsPriceOrder = Array.from(
          { length: formikValue.gridCount + 1 },
          (_, i) => formikValue.lowerPrice + priceDiff * i
        )

        const sumPrice = gridsPriceOrder.reduce((sum, all) => all + sum, 0)

        const minInitialMargin =
          (futureMarketData.limits.amount.min * sumPrice) /
          (formikValue.leverage * ADJUST_COEF)

        setInitialMarginMin(Number(minInitialMargin.toFixed(2)))

        const d =
          (formikValue.upperPrice - formikValue.lowerPrice) /
          formikValue.gridCount

        const lowerProfitPerGrid =
          (formikValue.upperPrice * (1 - futureMarketData.maker!)) /
            (formikValue.upperPrice - d) -
          1 -
          futureMarketData.maker!
        const higherProfitPerGrid =
          ((1 - futureMarketData.maker!) * d) / formikValue.lowerPrice -
          2 * futureMarketData.maker!

        const lowerProfitPerGridRounded = +lowerProfitPerGrid.toFixed(4)
        const higherProfitPerGridRounded = +higherProfitPerGrid.toFixed(4)

        if (
          lowerProfitPerGridRounded !== profitPerGrid?.min ||
          higherProfitPerGridRounded !== profitPerGrid?.max
        ) {
          setProfitPerGrid({
            min: lowerProfitPerGridRounded,
            max: higherProfitPerGridRounded,
          })
        }
      }
    }

    const validationSchema = useMemo(() => {
      const leverages = leveragesData?.map(l => l.maxLeverage) || [125]
      const max = Math.max(...leverages)
      return gridBotValidateSchema({
        max,
        initialMarginMin,
      })
    }, [leveragesData, initialMarginMin])

    const newProps = {
      onMainFormSubmit,
      symbolOptions,
      navigateToCreateBotPage,
      validationSchema,
      maxLeverage,
      onSymbolChange,
      mode,
      onFormChange,
      initialMarginMin,
      mainFormInitValue: plainToClass(GridBotForm, {
        leverage: 10,
        isStopTrigger: false,
      }),
      profitPerGrid,
      ...props,
    }
    return <Component {...newProps} />
  }
  return WithBotSettingForm
}
export default withGridBotSettingForm
