import React from 'react'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useNavigate } from 'react-router-dom'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'
import {
  LiquidateButtonAcceptProps,
  LiquidateButtonProps,
} from '@features/bot/pages/BotPage/components/LiquidateButton/interface'
import { useLiquidateBotMutation } from '@client/botClient/collections/LiquidateBotClientAction'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

const withLiquidateButton = (Component: React.FC<LiquidateButtonProps>) => {
  const Hoc = ({ bot, isDisable }: LiquidateButtonAcceptProps) => {
    const navigate = useNavigate()
    const { isFuture } = useBotMarketType()
    const route = isFuture ? ContractRoutesPath : SpotRoutesPath

    const [liquidateBot, { isLoading: isLiquidateBotLoading }] =
      useLiquidateBotMutation()

    const { openModal } = useModal<ConfirmModalProps>({
      component: ({ onConfirm, message }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>{message}</p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleLiquidatePosition = async () => {
      if (isDisable) return
      await openModal({
        onConfirm: async () => {
          if (bot != null) {
            await liquidateBot({ botId: bot.id })
            // Bot deleted.. go back to dashboard page
            navigate(route.dashboard.absolutePath)
          }
        },
        message: `Are you sure to liquidate`,
      })
    }

    const newProps: LiquidateButtonProps = {
      handleLiquidatePosition,
      isDisable,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withLiquidateButton
