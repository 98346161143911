import { Expose, Transform, Type } from 'class-transformer'
import { EasyTradeTemplateSymbol } from '@models/Bot/EasyTradeTemplateSymbol'

export class CreateBotFromEasyTradeForm {
  @Expose()
  easyTradeId: number | null

  @Expose()
  @Type(() => EasyTradeTemplateSymbol)
  @Transform(({ value }) => value.map(v => v.symbol), { toPlainOnly: true })
  symbols: Array<EasyTradeTemplateSymbol>

  @Expose()
  totalInvestment: number
}
