import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'

import Loading from '@components/utils/Loading'
import withBanner from './withBanner'
import { BannerProps } from './interface'

const Banner = ({ banners, isLoading, className }: BannerProps) => {
  if (isLoading) {
    return (
      <div className={className}>
        <Loading text='Loading...' />
      </div>
    )
  }
  if (banners) {
    return (
      <Swiper
        className={className}
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        pagination={{ clickable: true }}
        autoplay={{
          delay: 2500,
          disableOnInteraction: false,
        }}
      >
        {banners.map(banner => (
          <SwiperSlide
            key={banner.id}
            className='aspect-w-3 aspect-h-1 relative'
          >
            <a href={banner.link}>
              <img
                src={banner.imgPath}
                alt='banner'
                className='w-full h-full absolute top-0 bottom-0 object-cover rounded-[12px]'
              />
            </a>
          </SwiperSlide>
        ))}
      </Swiper>
    )
  }
  return <></>
}
export default withBanner(Banner)
