import React from 'react'

const StopCoveringTag = () => {
  return (
    <div className='flex items-center px-2 h-[20px] text-xs border-1 text-gray-400 border-gray-400'>
      stop-cover
    </div>
  )
}
export default React.memo(StopCoveringTag)
