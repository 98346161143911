import { exchangeApi } from '@client/client'
import { gql } from 'graphql-request'

export const walletBalanceApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    walletBalance: builder.query<{ usdt: number }, void>({
      query: () => ({
        operationName: 'WalletBalance',
        document: gql`
          query WalletBalance {
            walletBalance {
              usdt
            }
          }
        `,
      }),
      transformResponse(res: { walletBalance: { usdt: number } }) {
        const { walletBalance } = res
        return walletBalance
      },
      providesTags: ['WalletBalance'],
    }),
  }),
})

export const { useWalletBalanceQuery, useLazyWalletBalanceQuery } =
  walletBalanceApi
