import React, { useMemo } from 'react'
import { toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { useLazyFetchBalanceQuery } from '@client/exchangeClient/collections/Balance'
import { useUpdateAuthExchangeMutation } from '@client/mainClient/collections/UpdateAuthExchangeClientAction'
import { AuthExchangeForm } from '@models/AuthExchange/AuthExchangeForm'
import Loading from '@components/utils/Loading'
import { useAuthExchangesQuery } from '@client/mainClient/collections/AuthExchangesClientAction'
import { TutorialRoutesPath } from '@config/Router'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { plainToClass } from 'class-transformer'
import { SettingKeyPageProps } from './interface'

const withSettingKeyPage = (Component: React.FC<SettingKeyPageProps>) => {
  function WithSettingKeyPage() {
    const {
      data = [],
      refetch,
      isLoading: isLoadingAuthExchanges,
    } = useAuthExchangesQuery(undefined, {
      refetchOnMountOrArgChange: true,
    })

    const [fetchBalance, { isLoading: isFetchBalanceLoading }] =
      useLazyFetchBalanceQuery()
    const navigate = useNavigate()

    const [updateAuthExchangeMutate, { isLoading }] =
      useUpdateAuthExchangeMutation()

    const handleNavigateToTutorial = () => {
      navigate(TutorialRoutesPath.apiKey.absolutePath)
    }

    const maskPattern: string | Array<string | RegExp> = useMemo(() => {
      if (data && data.length) {
        const { secret } = data[0]
        if (secret) {
          const firstThree = secret.slice(0, 3)
          const lastThree = secret.slice(-3)
          const maskedValue = '•'.repeat(24)
          return `${firstThree}${maskedValue}${lastThree}`
        }
      }
      return ''
    }, [data])

    const { openModal: openRevokeToken } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to revoke <br />
                Api key & Secret key
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const onSubmitKey = (
      form: AuthExchangeForm,
      shouldFetchBalance: boolean
    ) => {
      const correctForm: AuthExchangeForm = {
        id: form.id,
        apiKey: form.apiKey,
        secret: form.newSecret ? form.newSecret : form.secret,
      }
      delete correctForm.newSecret
      updateAuthExchangeMutate(correctForm)
        .unwrap()
        .then(() => {
          refetch()
          if (!shouldFetchBalance) {
            toast.success(
              <div className='flex flex-col '>
                <div className='font-bold text-green'>Successful!</div>
              </div>
            )
            return
          }
          fetchBalance()
            .unwrap()
            .then(balance => {
              const usdtAmount = balance.find(asset => asset.name === 'USDT')
              toast.success(
                <div className='flex flex-col '>
                  <div className='font-bold text-green'>Successful!</div>
                  <div>
                    Your USDT balance is:{' '}
                    <span className='font-bold'>
                      {usdtAmount!.total.toFixed(2)}$
                    </span>
                  </div>
                </div>
              )
            })
            .catch(() => {})
        })
    }

    const handleRevokeToken = async () => {
      openRevokeToken({
        onConfirm: async () => {
          onSubmitKey(
            plainToClass(AuthExchangeForm, {
              id: data[0].id!,
              apiKey: '',
              secret: '',
            }),
            false
          )
        },
      })
    }

    if (data || isLoadingAuthExchanges) {
      const newProps = {
        initialValues: {
          id: data[0]?.id ?? '',
          apiKey: data[0]?.apiKey ?? '',
          secret: data[0]?.secret ?? '',
          newSecret: '',
        } as AuthExchangeForm,
        onSubmitKey,
        isLoading: isFetchBalanceLoading || isLoading,
        handleNavigateToTutorial,
        maskPattern,
        handleRevokeToken,
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }

  return WithSettingKeyPage
}

export default withSettingKeyPage
