import AppTickerTape from '@components/AppTickerTape/AppTickerTape'
import { useEffect } from 'react'

const TickerTapePage = () => {
  useEffect(() => {
    document.body.classList.add('bg-transparent')
    document.body.parentElement?.classList.add('bg-transparent')

    return () => {
      document.body.classList.add('bg-[#1F1F1F]')
      document.body.parentElement?.classList.add('bg-[#1F1F1F]')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return <AppTickerTape />
}

export default TickerTapePage
