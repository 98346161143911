import React from 'react'
import * as Yup from 'yup'
import { Form, Formik } from 'formik'
import WalletContainer from '@features/wallet/components/WalletContainer/WalletContainer'
import InputField from '@components/form/InputField/InputField'
import PrimaryButton from '@components/buttons/PrimaryButton'
import Otp from '@features/authentication/components/Otp/Otp'
import { SuccessIcon } from '@components/Icons/Icons'
import PhoneNumberInputField from '@components/form/PhoneNumberInputField/PhoneNumberInputField'
import { transactionFee } from '@constant/transactionFee'
import { withTransferPage } from '@features/wallet/pages/TransferPage/withTransferPage'
import { TransferPageProps } from './interface'

const schema = Yup.object()
  .shape({
    phoneNumber: Yup.string().required(),
    amount: Yup.number().min(1).required(),
  })
  .test(function (value) {
    if (!value.userId) {
      return this.createError({
        path: 'phoneNumber',
        message: 'phoneNumber does not exist',
      })
    }
    if (!!value.myUserId) {
      return this.createError({
        path: 'phoneNumber',
        message: 'phoneNumber is your account',
      })
    }
    return false
  })

function TransferPage({
  transferForm,
  transferStep,
  isBalanceFetching,
  profile,
  balance,
  paidTime,
  goToTransactionPage,
  handleVerifiedOtp,
  handletransferFormSubmit,
}: TransferPageProps) {
  return (
    <>
      {transferStep === 'form' && (
        <WalletContainer type='transfer'>
          <Formik
            initialValues={transferForm}
            onSubmit={handletransferFormSubmit}
            validationSchema={schema}
            enableReinitialize
          >
            {({ setFieldValue }) => (
              <Form className='mt-[16px] flex flex-col gap-y-[16px]'>
                <PhoneNumberInputField label={`Recipient's phone number`} />
                <InputField
                  name='amount'
                  type='number'
                  label='Amount (USDT)'
                  placeholder='Enter amount'
                  rightIcon={
                    <div
                      onClick={() => setFieldValue('amount', balance)}
                      className='underline text-primary text-sm font-medium cursor-pointer'
                    >
                      Max
                    </div>
                  }
                  required
                />
                <div className='flex text-sm font-medium'>
                  <div className='flex-1'>Transfer fee (USDT)</div>
                  <div>
                    {`${transactionFee.toLocaleString(undefined, {
                      minimumFractionDigits: 2,
                      maximumFractionDigits: 2,
                    })} USDT`}
                  </div>
                </div>
                <PrimaryButton
                  type='submit'
                  className='w-full rounded-[18px] py-[8px] mt-[60px]'
                  disabled={isBalanceFetching}
                >
                  <p className='text-lg font-bold'>Next</p>
                </PrimaryButton>
              </Form>
            )}
          </Formik>
        </WalletContainer>
      )}
      {transferStep === 'otp' && profile && (
        <div className='max-w-[700px] mx-auto px-[17px] pt-[14px]'>
          <Otp
            phoneNumber={profile?.phoneNumber}
            phoneNumberCountryCode={profile?.phoneNumberCountryCode}
            onSuccess={handleVerifiedOtp}
          />
        </div>
      )}
      {transferStep === 'success' && (
        <div className='max-w-[700px] mx-auto px-[17px] pt-[14px] flex flex-col items-center'>
          <SuccessIcon width='58' height='58' />
          <div className='text-primary font-bold mt-[20px] mb-[62px]'>
            Transfering Successfully
          </div>
          <div className='grid grid-cols-2 gap-y-[16px] text-sm w-full'>
            <div className='text-left'>Transfer amount</div>
            <div className='text-right'>{transferForm.amount} USDT</div>
            <div className='text-left'>Fee</div>
            <div className='text-right'>{transactionFee} USDT</div>
            <div className='text-left'>Time</div>
            <div className='text-right'>{paidTime}</div>
          </div>
          <PrimaryButton
            type='button'
            className='w-full rounded-[18px] py-[8px] mt-[60px]'
            onClick={goToTransactionPage}
          >
            <p className='text-lg font-bold'>View transaction history</p>
          </PrimaryButton>
        </div>
      )}
    </>
  )
}

export default withTransferPage(TransferPage)
