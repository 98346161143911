export const MAIN_API_URL = process.env.REACT_APP_MAIN_API_URL!
export const BOT_API_URL = process.env.REACT_APP_BOT_API_URL!
export const EXCHANGE_API_URL = process.env.REACT_APP_EXCHANGE_API_URL!
export const ADMIN_API_URL = process.env.REACT_APP_ADMIN_API_URL!
export const ADMIN_ORIGINAL_API_URL =
  process.env.REACT_APP_ADMIN_ORIGINAL_API_URL!
export const EXCHANGE_WS_API_URL = process.env.REACT_APP_EXCHANGE_WS_API_URL!
export const BOT_WS_API_URL = process.env.REACT_APP_BOT_WS_API_URL!
export const EXCHANGE_ORIGIN_API_URL =
  process.env.REACT_APP_EXCHANGE_ORIGIN_API_URL!
export const RECAPTCHA_SITE_KEY = process.env.REACT_APP_RECAPTCHA_SITE_KEY!
export const APP_ENV = process.env.REACT_APP_APP_ENV!
export const ADJUST_COEF = 0.9
export const PROFIT_FEE_USDT_RATIO = 0.2

export const APP_MAIN_ORIGIN_API_URL =
  process.env.REACT_APP_MAIN_ORIGIN_API_URL!
