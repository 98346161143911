import { Expose, Transform } from 'class-transformer'
import { MarginTypeEnum } from '@interfaces/MarginTypeEnum'
import { TradeSideEnum } from '@interfaces/TradeSideEnum'
import { toPercentage } from '@libs/utils'

export class MainForm {
  @Expose({ toClassOnly: true })
  id: number

  @Expose()
  symbol: string

  @Expose()
  name: string

  @Expose()
  @Transform(
    ({ obj }) => {
      return obj.isInfinityCycle
        ? 'infinity'
        : obj.cycleQuantity > 1
        ? 'cycle'
        : 'single'
    },
    {
      toClassOnly: true,
    }
  )
  @Transform(({ value }) => value === 'cycle' || value === 'infinity', {
    toPlainOnly: true,
  })
  isCycle: 'cycle' | 'single' | 'infinity'

  @Expose()
  @Transform(({ obj }) => obj.isCycle === 'infinity', { toPlainOnly: true })
  isInfinityCycle: boolean

  @Expose()
  marginType: MarginTypeEnum

  // DESC: P'Ohm suggest us to let every bot could be hedge mode.
  @Expose()
  @Transform(
    ({ value }) => (value === TradeSideEnum.HEDGE_MODE ? null : value),
    {
      toPlainOnly: true,
    }
  )
  @Transform(({ value }) => value || TradeSideEnum.HEDGE_MODE, {
    toClassOnly: true,
  })
  side: TradeSideEnum

  @Expose()
  isCovering: boolean

  @Expose()
  // DESC: P'Ohm suggest us to let every bot could be hedge mode.
  @Transform(() => true, {
    toPlainOnly: true,
  })
  isHedgeMode: boolean

  @Expose()
  @Transform(
    ({ value }) => {
      return value === true || value === 'split' ? 'split' : 'all'
    },
    {
      toClassOnly: true,
    }
  )
  @Transform(({ value }) => value === 'split', { toPlainOnly: true })
  isSplitTp: 'all' | 'split'

  @Expose()
  @Transform(({ obj, value }) => (obj.isCycle === 'cycle' ? value : 1), {
    toPlainOnly: true,
  })
  cycleQuantity: number

  @Expose()
  isStopLoss: boolean

  @Expose()
  isFirstOrderDouble: boolean

  @Expose()
  firstOrderRawQuote: number

  @Expose({ toPlainOnly: true })
  @Transform(
    ({ obj }) =>
      obj.isFirstOrderDouble
        ? obj.firstOrderRawQuote * 2
        : obj.firstOrderRawQuote,
    {
      toPlainOnly: true,
    }
  )
  firstOrderTradeQuote: number

  @Expose()
  leverage: number

  @Expose()
  coveringQuantity: number

  @Expose()
  @Transform(({ value }) => toPercentage(value), {
    toClassOnly: true,
  })
  @Transform(({ value }) => value / 100, {
    toPlainOnly: true,
  })
  tpRetracement: number

  @Expose()
  @Transform(({ value }) => value * 100, {
    toClassOnly: true,
  })
  @Transform(({ value }) => value / 100, {
    toPlainOnly: true,
  })
  tpRatio: number

  @Expose()
  divisionAndControlProfit: number

  @Expose()
  @Transform(({ value }) => (!!value ? toPercentage(value) : undefined), {
    toClassOnly: true,
  })
  @Transform(
    ({ value, obj }) =>
      obj.stopLossLimit ? null : !!value ? value / 100 : undefined,
    {
      toPlainOnly: true,
    }
  )
  stopLossRatio?: number

  @Expose()
  @Transform(({ value }) => (!!value ? value : undefined), {
    toClassOnly: true,
  })
  @Transform(
    ({ value, obj }) =>
      obj.stopLossRatio ? null : !!value ? value : undefined,
    {
      toPlainOnly: true,
    }
  )
  stopLossLimit?: number

  @Expose({ toClassOnly: true })
  isSaveStrategy: boolean

  @Expose()
  isCopyable?: boolean

  @Expose()
  limitPrice?: number
}
