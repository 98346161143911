import { BotCardStatusDetailProps } from '@features/bot/components/BotCard/interface'
import { DateTime } from 'luxon'
import React from 'react'
import { toPercentage } from '@libs/utils'
import { toLocaleStringActualDecimal } from '@libs/currencyFormat'

export const BotCardReadyState = ({ bot }: BotCardStatusDetailProps) => {
  return (
    <>
      <div className='mb-[24px] flex-col items-start space-y-3'>
        <div className='px-[17px] text-sm font-medium flex flex-col gap-y-[16px] '>
          {/* Initial margin */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Initial margin</span>
            <span>
              {toLocaleStringActualDecimal(
                bot.botStrategy?.firstOrderRawQuote *
                  (bot.botStrategy?.isFirstOrderDouble ? 2 : 1)
              ) ?? '-'}
              {' USDT'}
            </span>
          </div>

          {/* TP Ratio / Split TP Ratio */}
          {bot.botStrategy?.isSplitTp && bot.botStrategy?.isCovering ? (
            //   Split ap
            <div className='flex space-x-2'>
              <span className='flex-1'>Advance TP Ratio</span>
              <span>
                {bot?.botStrategy?.tpRatioSplit
                  ?.map(ratio => {
                    return toLocaleStringActualDecimal(toPercentage(ratio))
                  })
                  ?.join(', ') ?? '-'}
                {' %'}
              </span>
            </div>
          ) : (
            //   Normal tp
            <div className='flex space-x-2'>
              <span className='flex-1'>TP Ratio</span>
              <span>
                {toLocaleStringActualDecimal(
                  toPercentage(bot?.botStrategy?.tpRatio)
                ) ?? '-'}
                {' %'}
              </span>
            </div>
          )}

          {/* Covering round */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Current covering</span>
            <span>
              {bot?.botStrategy?.isCovering
                ? `${bot?.coverRound}/${
                    bot?.botStrategy?.coveringQuantity ?? '-'
                  }`
                : '-'}
            </span>
          </div>

          {/* Created At */}
          <div className='flex space-x-2'>
            <span className='flex-1'>Created time</span>
            <span>
              {DateTime.fromMillis(bot.createdAt).toFormat(
                'dd-MM-yyyy HH:mm:ss'
              )}
            </span>
          </div>
        </div>
      </div>
    </>
  )
}
