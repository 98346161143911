import React, { useEffect, useState } from 'react'
import {
  SystemMigrationGuardInterface,
  SystemMigrationInterface,
} from '@components/SystemMigrationGuard/SystemMigrationGuard.interface'
import { ADMIN_ORIGINAL_API_URL } from '@config/config'
import { useAdminSystemMigrationQuery } from '@client/botClient/collections/AdminSystemMigration'
import Loading from '@components/utils/Loading'
import Migrating from '@components/utils/Migrating'
import { useDebounce } from '@hooks/useDebounce'

export const SystemMigrationGuard = ({
  children,
}: SystemMigrationGuardInterface) => {
  const [isMigrating, setIsMigrating] = useState<boolean>(false)
  const { data, isLoading } = useAdminSystemMigrationQuery()
  const { debounce } = useDebounce()

  const createEventSource = () => {
    const eventSource = new EventSource(
      `${ADMIN_ORIGINAL_API_URL}/subscribe/system-migration`
    )
    eventSource.onmessage = message => {
      const systemMigrationInterface: SystemMigrationInterface = JSON.parse(
        message.data
      )
      setIsMigrating(systemMigrationInterface.systemMigration === 'MIGRATING')
    }
    eventSource.onerror = () => {
      eventSource.close()
      debounce(() => {
        createEventSource()
      }, 1000)
    }

    return eventSource
  }

  useEffect(() => {
    if (data) {
      setIsMigrating(data!.systemMigration === 'MIGRATING')
    }
  }, [data])

  useEffect(() => {
    const eventSource = createEventSource()
    return () => {
      eventSource.close()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      {isLoading ? (
        <Loading text='Loading' />
      ) : isMigrating ? (
        <Migrating />
      ) : (
        children
      )}
    </>
  )
}
