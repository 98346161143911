import React from 'react'
import { CrossIcon } from '@components/Icons/Icons'
import { DrawerProps } from './interface'

function Drawer({
  isDrawerOpen,
  header,
  children,
  handleClickClose,
  handleClickOutsideDrawer,
}: DrawerProps) {
  return (
    <>
      <div
        className={`fixed top-0 left-0  min-w-full w-full min-h-screen h-full bg-[#454545] bg-opacity-60 z-30 ${
          isDrawerOpen ? 'block' : 'hidden'
        }`}
        onClick={handleClickOutsideDrawer}
      />
      <div
        className={`fixed bottom-0 left-0 w-full h-[55vh] z-40 bg-gray-900 transition ease-out duration-200 transform rounded-t-[15px] ${
          isDrawerOpen ? 'translate-y-0' : 'translate-y-[55vh]'
        }`}
      >
        <div className='flex items-center pt-[20px] px-[20px] pb-[18px] border-b-[1px] border-gray-secondary'>
          <div className='w-full text-sm font-bold text-center'>{header}</div>
          <button type='button' onClick={handleClickClose}>
            <CrossIcon />
          </button>
        </div>
        <div className='px-[20px] pt-[20px] pb-[80px] h-full overflow-y-auto'>
          {children}
        </div>
      </div>
    </>
  )
}

export default Drawer
