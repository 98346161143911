import OutlinedButton from '@components/buttons/OutlinedButton'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { Check2Icon, CheckIcon, SuccessIcon } from '@components/Icons/Icons'
import { ModalContext } from '@components/Modal/context/ModalContext'
import React, { useContext, useState } from 'react'
import { SortModalProps } from './interface'

function SortModal({
  onClear,
  sortData,
  initValue,
  onSelectData,
}: SortModalProps) {
  // const { handleToggleModal } = useContext(ModalContext)
  const [selectedData, setSelectedData] = useState(initValue)
  const handleOnClick = (data?: any) => {
    setSelectedData(data)
    if (onSelectData) onSelectData(data)
  }
  const handleOnClear = () => {
    setSelectedData(undefined)
    if (onClear) onClear()
  }

  return (
    <div className='w-[304px] relative'>
      <div className='flex flex-col  mx-8 my-8'>
        <div className='mb-5 flex text-gray-secondary justify-between items-center w-full'>
          <span className='font-bold text-base'>Sort by</span>{' '}
          <span
            className='underline text-sm cursor-pointer'
            onClick={handleOnClear}
          >
            Clear
          </span>
        </div>
        <div>
          {sortData &&
            sortData.map(item => {
              const isSelect = selectedData === item.value
              return (
                <div
                  key={item.value}
                  className='flex flex-row justify-between items-center '
                  onClick={() => handleOnClick(item.value)}
                >
                  <span
                    className={`${
                      isSelect ? 'text-primary' : 'text-black'
                    } cursor-pointer`}
                  >
                    {item.label}
                  </span>
                  {isSelect && <Check2Icon height='24' width='24' />}
                </div>
              )
            })}
        </div>
      </div>
    </div>
  )
}

export default SortModal
