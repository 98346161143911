import React from 'react'
import { InputFieldProps } from '@components/form/CheckboxField/interface'
import { CheckIcon } from '@components/Icons/Icons'
import { withCheckboxField } from './withCheckboxField'

const CheckboxField = ({
  error,
  label,
  className,
  value,
  onChange,
  name,
}: InputFieldProps) => {
  return (
    <label
      className={`relative flex w-full flex-wrap items-center gap-x-3 select-none ${className}`}
    >
      <input
        type='checkbox'
        className='hidden'
        value={value}
        onChange={onChange}
        name={name}
      />
      <div
        className={`flex items-center justify-center w-5 h-5 rounded-[4px] border border-gray-secondary ${
          error ? 'border-red' : ''
        } ${value ? 'bg-primary' : 'bg-none'}`}
      >
        {value ? <CheckIcon /> : null}
      </div>
      <div>{label}</div>
    </label>
  )
}

export default withCheckboxField(CheckboxField)
