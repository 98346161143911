import { BudgetOptionType } from '@interfaces/BudgetOptionType'

export const calculateBudgetByFormula = (
  budget: number
): BudgetOptionType[] => {
  if (budget < 2000) {
    throw Error('budget insufficient')
  }
  //  [2000, 3000)
  if (budget >= 2000 && budget < 3000) {
    return [
      {
        optionName: 'A',
        long: 1,
        short: 1,
        initialMarginLong: 6,
        initialMarginShort: 6,
        leverage: 4,
      },
    ]
  }
  //  [3000, 4000)
  if (budget >= 3000 && budget < 4000) {
    return [
      {
        optionName: 'A',
        long: 2,
        short: 1,
        initialMarginLong: 6,
        initialMarginShort: 6,
        leverage: 4,
      },
    ]
  }
  //  [4000, 5000)
  if (budget >= 4000 && budget < 5000) {
    return [
      {
        optionName: 'A',
        long: 3,
        short: 3,
        initialMarginLong: 6,
        initialMarginShort: 6,
        leverage: 4,
      },
    ]
  }
  //  [5000, 6000)
  if (budget >= 5000 && budget < 6000) {
    return [
      {
        optionName: 'A',
        long: 4,
        short: 3,
        initialMarginLong: 6,
        initialMarginShort: 6,
        leverage: 4,
      },
    ]
  }
  //  [6000, 7000)
  if (budget >= 6000 && budget < 7000) {
    return [
      {
        optionName: 'A',
        long: 5,
        short: 4,
        initialMarginLong: 7,
        initialMarginShort: 7,
        leverage: 4,
      },
    ]
  }
  //  [7000, 8000)
  if (budget >= 7000 && budget < 8000) {
    return [
      {
        optionName: 'A',
        long: 6,
        short: 5,
        initialMarginLong: 7,
        initialMarginShort: 7,
        leverage: 4,
      },
    ]
  }
  //  [8000, 9000)
  if (budget >= 8000 && budget < 9000) {
    return [
      {
        optionName: 'A',
        long: 7,
        short: 6,
        initialMarginLong: 8,
        initialMarginShort: 8,
        leverage: 4,
      },
    ]
  }
  //  [9000, 10000)
  if (budget >= 9000 && budget < 10000) {
    return [
      {
        optionName: 'A',
        long: 8,
        short: 7,
        initialMarginLong: 8,
        initialMarginShort: 8,
        leverage: 4,
      },
    ]
  }
  //  [10000, 12000)
  if (budget >= 10000 && budget < 12000) {
    return [
      {
        optionName: 'A',
        long: 10,
        short: 8,
        initialMarginLong: 9,
        initialMarginShort: 9,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 9,
        initialMarginShort: 8,
        leverage: 4,
      },
    ]
  }
  //  [12000, 15000)
  if (budget >= 12000 && budget < 15000) {
    return [
      {
        optionName: 'A',
        long: 12,
        short: 10,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 10,
        initialMarginShort: 9,
        leverage: 4,
      },
    ]
  }
  //  [15000, 20000)
  if (budget >= 15000 && budget < 20000) {
    return [
      {
        optionName: 'A',
        long: 14,
        short: 12,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 15,
        initialMarginShort: 12,
        leverage: 4,
      },
    ]
  }
  //  [20000, 25000)
  if (budget >= 20000 && budget < 25000) {
    return [
      {
        optionName: 'A',
        long: 18,
        short: 14,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 18,
        initialMarginShort: 15,
        leverage: 4,
      },
    ]
  } //  [25000, 30000)
  if (budget >= 25000 && budget < 30000) {
    return [
      {
        optionName: 'A',
        long: 22,
        short: 17,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 20,
        initialMarginShort: 18,
        leverage: 4,
      },
    ]
  } //  [30,000, 40,000)
  if (budget >= 30000 && budget < 40000) {
    return [
      {
        optionName: 'A',
        long: 25,
        short: 20,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 25,
        initialMarginShort: 20,
        leverage: 4,
      },
      {
        optionName: 'C',
        long: 20,
        short: 15,
        initialMarginLong: 12,
        initialMarginShort: 12,
        leverage: 4,
      },
    ]
  } //  [40,000, 50,000)
  if (budget >= 40000 && budget < 50000) {
    return [
      {
        optionName: 'A',
        long: 30,
        short: 24,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 30,
        initialMarginShort: 24,
        leverage: 4,
      },
      {
        optionName: 'C',
        long: 20,
        short: 15,
        initialMarginLong: 16,
        initialMarginShort: 16,
        leverage: 4,
      },
    ]
  }
  //  [50,000, 75,000)
  if (budget >= 50000 && budget < 75000) {
    return [
      {
        optionName: 'A',
        long: 40,
        short: 30,
        initialMarginLong: 10,
        initialMarginShort: 10,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 40,
        initialMarginShort: 30,
        leverage: 4,
      },
      {
        optionName: 'C',
        long: 20,
        short: 15,
        initialMarginLong: 20,
        initialMarginShort: 20,
        leverage: 4,
      },
    ]
  } //  [75,000, 100,000)
  if (budget >= 75000 && budget < 100000) {
    return [
      {
        optionName: 'A',
        long: 40,
        short: 30,
        initialMarginLong: 15,
        initialMarginShort: 15,
        leverage: 4,
      },
      {
        optionName: 'B',
        long: 10,
        short: 10,
        initialMarginLong: 60,
        initialMarginShort: 45,
        leverage: 4,
      },
      {
        optionName: 'C',
        long: 20,
        short: 15,
        initialMarginLong: 30,
        initialMarginShort: 30,
        leverage: 4,
      },
    ]
  }
  //  [100,000, ~)
  return [
    {
      optionName: 'A',
      long: 40,
      short: 30,
      initialMarginLong: 20,
      initialMarginShort: 20,
      leverage: 4,
    },
    {
      optionName: 'B',
      long: 10,
      short: 10,
      initialMarginLong: 80,
      initialMarginShort: 60,
      leverage: 4,
    },
    {
      optionName: 'C',
      long: 20,
      short: 15,
      initialMarginLong: 40,
      initialMarginShort: 40,
      leverage: 4,
    },
  ]
}
