import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { GridBotForm } from '@models/Bot/GridBotForm'

export const createGridBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    createGridBot: builder.mutation<{ id: number }, GridBotForm>({
      query: newGridBotInput => ({
        document: gql`
          mutation CreateGridBot($newGridBotInput: NewGridBotInput!) {
            createGridBot(newGridBotInput: $newGridBotInput) {
              id
            }
          }
        `,
        variables: {
          newGridBotInput,
        },
      }),
    }),
  }),
})

export const { useCreateGridBotMutation } = createGridBotApi
