import React from 'react'
import { Form, Formik } from 'formik'
import SelectField from '@components/form/SelectField/SelectField'
import { ExchangeIcon } from '@components/Icons/Icons'
import ToggleSwitchField from '@components/form/ToggleSwitchField/ToggleSwitchField'
import InputField from '@components/form/InputField/InputField'
import withGridBotSettingForm from '@features/bot/pages/CreateGridBotPage/components/GridBotSettingForm/withGridBotSettingForm'
import GridBotCompose from '@features/bot/pages/CreateGridBotPage/components/GridBotCompose/GridBotCompose'
import PrimaryButton from '@components/buttons/PrimaryButton'
import OutlinedButton from '@components/buttons/OutlinedButton'
import { NumberField } from '@components/form/NumberField'
import { formEnterPrevent } from '@libs/formEnterPrevent'
import { GridBotSettingFormProps } from './interface'

const GridBotSettingForm = ({
  onMainFormSubmit,
  symbolOptions,
  validationSchema,
  onSymbolChange,
  activeStep,
  backStep,
  mode,
  mainFormInitValue,
  onFormChange,
  initialMarginMin,
  profitPerGrid,
}: GridBotSettingFormProps) => {
  return (
    <div>
      <Formik
        initialValues={mainFormInitValue}
        enableReinitialize
        onSubmit={onMainFormSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue, errors }) => (
          <Form onKeyDown={formEnterPrevent}>
            <GridBotCompose
              initialMarginMin={initialMarginMin}
              profitPerGrid={profitPerGrid}
            />
            {onFormChange(values, setFieldValue)}
            {activeStep === 0 && (
              <>
                {mode === 'bot' ? (
                  <SelectField
                    name='symbol'
                    options={symbolOptions}
                    label='Select symbol'
                    placeholder='Select symbol'
                    leftIcon={
                      <div className='ml-[10px]'>
                        <ExchangeIcon width='16' height='16' />
                      </div>
                    }
                    className='mb-[16px]'
                    onChange={onSymbolChange}
                    isSearchable
                  />
                ) : (
                  <InputField
                    name='name'
                    type='text'
                    label='Strategy name'
                    placeholder='input your strategy name'
                    className='mb-[16px]'
                  />
                )}
              </>
            )}
            {activeStep === 1 && (
              <>
                <div className='grid grid-cols-1 gap-y-[16px] text-sm font-medium'>
                  <NumberField
                    label='Contract multiplier'
                    name='leverage'
                    className='w-full'
                    minLength={0}
                  />
                  <InputField
                    name='lowerPrice'
                    type='number'
                    label='Lower price'
                    rightIcon={
                      <span className='text-sm font-medium'>USDT</span>
                    }
                  />
                  <InputField
                    name='upperPrice'
                    type='number'
                    label='Upper price'
                    showErrorMessage
                    rightIcon={
                      <span className='text-sm font-medium'>USDT</span>
                    }
                  />
                  <InputField
                    name='gridCount'
                    type='number'
                    label='Grids amount'
                    rightIcon={
                      <span className='text-sm font-medium'>2-149</span>
                    }
                  />
                  <div className='flex justify-between'>
                    <div className='text-gray-59'>
                      profit/grid (fee deducted)
                    </div>
                    <div className='text-white'>
                      ${profitPerGrid && +(profitPerGrid?.min * 100).toFixed(4)}
                      % - $
                      {profitPerGrid && +(profitPerGrid?.max * 100).toFixed(4)}%
                    </div>
                  </div>
                  <InputField
                    name='initialMargin'
                    type='number'
                    label='Initial margin'
                    rightIcon={
                      <span className='text-sm font-medium'>USDT</span>
                    }
                  />
                  <div className='flex justify-between'>
                    <div className='text-gray-59'>
                      initial margin should greater than
                    </div>
                    <div className='text-white'>
                      {initialMarginMin || '-'} USDT
                    </div>
                  </div>
                  <div className='flex items-center'>
                    <div className='flex-1'>Stop Trigger</div>
                    <ToggleSwitchField name='isStopTrigger' />
                  </div>
                </div>
                <OutlinedButton
                  type='button'
                  className='w-full mt-[27px] p-[12px] rounded-[18px]'
                  onClick={backStep}
                >
                  Back
                </OutlinedButton>
                <PrimaryButton
                  type='submit'
                  className='w-full mt-[10px] rounded-[18px]'
                >
                  Confirm
                </PrimaryButton>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  )
}

export default withGridBotSettingForm(GridBotSettingForm)
