import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const deleteTradeCopyByBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    deleteTradeCopyByBot: builder.mutation<
      boolean,
      { tradeCopyByBotId: number }
    >({
      query: ({ tradeCopyByBotId }) => ({
        document: gql`
          mutation DeleteTradeCopyByBot($tradeCopyByBotId: Float!) {
            deleteTradeCopyByBot(tradeCopyByBotId: $tradeCopyByBotId)
          }
        `,
        variables: {
          tradeCopyByBotId,
        },
      }),
    }),
  }),
})

export const { useDeleteTradeCopyByBotMutation } = deleteTradeCopyByBotApi
