import React from 'react'
import { usePagination } from '@hooks/usePagination'
import { useTradeActionsQuery } from '@client/botClient/collections/TradeActionsClientAction'
import { SortEnum } from '@interfaces/SortEnum'
import { TransactionLogAcceptProps, TransactionLogProps } from './interface'

const withTransactionLog = (Component: React.FC<TransactionLogProps>) => {
  function Hoc({ limit, ...props }: TransactionLogAcceptProps) {
    const { page, pageChange, perpageChange } = usePagination()

    const { data: transactionLogs, isLoading } = useTradeActionsQuery(
      {
        page,
        limit,
        sort: { createdAt: SortEnum.DESCENDING },
      },
      { refetchOnMountOrArgChange: true }
    )

    const newProps = {
      transactionLogs,
      page,
      perpage: limit,
      pageChange,
      perpageChange,
      isLoading,
      ...props,
    }
    return <Component {...newProps} />
  }

  return Hoc
}

export default withTransactionLog
