import { AppLayout } from '@components/AppLayout/AppLayout'
import { TransactionLogRoutesPath } from '@config/Router'
import TransactionLogPage from './pages/TransactionLogPage'

export const TransactionLogRoutes = {
  path: TransactionLogRoutesPath.transactionLog.path,
  element: (
    <AppLayout title='Transaction log'>
      <TransactionLogPage />,
    </AppLayout>
  ),
}
