import NoData from '@components/NoData/NoData'
import { CopyBySymbolTabProps } from './interface'
import withCopyBySymbolTab from './withCopyBySymbolTab'
import MyCopyBySymbolCard from '../MyCopyBySymbolCard'

function CopyBySymbolTab({
  bots,
  handleDeleteTradeCopyByBot,
}: CopyBySymbolTabProps) {
  return (
    <div className='grid grid-col-1 gap-y-[8px] pt-[21px] px-[16px]'>
      {bots?.length ? (
        bots.map(bot => (
          <MyCopyBySymbolCard
            key={bot.id}
            bot={bot}
            handleDeleteTradeCopyByBot={handleDeleteTradeCopyByBot}
          />
        ))
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default withCopyBySymbolTab(CopyBySymbolTab)
