import { TablePaginationPropsType } from './interface'

const withTablePagination = (Component: React.FC<TablePaginationPropsType>) => {
  function Hoc({ paginationProps, tableState }: TablePaginationPropsType) {
    const newProps = {
      data: [],
      paginationProps,
      tableState,
    }
    return <Component {...newProps} />
  }
  return Hoc
}

export { withTablePagination }
