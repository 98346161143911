import { adminApi } from '@client/client'

import { gql } from 'graphql-request'
import { SystemMigrationInterface } from '@components/SystemMigrationGuard/SystemMigrationGuard.interface'

export const adminSystemMigrationApi = adminApi.injectEndpoints({
  overrideExisting: true,
  endpoints: builder => ({
    adminSystemMigration: builder.query<SystemMigrationInterface, void>({
      query: () => ({
        document: gql`
          query SystemMigrationQuery {
            systemMigrationQuery {
              systemMigration
            }
          }
        `,
      }),
      transformResponse(res: {
        systemMigrationQuery: SystemMigrationInterface
      }) {
        const { systemMigrationQuery } = res
        return systemMigrationQuery
      },
      providesTags: ['SystemMigration'],
    }),
  }),
})

export const { useAdminSystemMigrationQuery } = adminSystemMigrationApi
