import React from 'react'

interface TabPropsType {
  tabId: string
  children: JSX.Element[]
}

function TabComp({ tabId, children }: TabPropsType) {
  return (
    <div>
      {React.Children.map(children, child => {
        return React.cloneElement(child, {
          ...child.props,
          tabId,
        })
      })}
    </div>
  )
}

export const Tab = TabComp
