import React from 'react'
import { useWalletsQuery } from '@client/mainClient/collections/GetWallets'
import { WalletBoxAcceptProps, WalletBoxProps } from './interface'

const withWalletBox = (Component: React.FC<WalletBoxProps>) => {
  const Hoc = ({ ...acceptProps }: WalletBoxAcceptProps) => {
    const { data: walletBalance, isFetching } = useWalletsQuery(undefined, {
      refetchOnMountOrArgChange: true,
    })

    const newProps = {
      walletBalance: walletBalance?.tempWalletAmount || 0,
      isFetching,
      ...acceptProps,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withWalletBox
