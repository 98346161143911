import React from 'react'
import { toast } from 'react-toastify'
import { useGetMyTradeCopyByPersonsQuery } from '@client/botClient/collections/GetMyTradeCopyByPersonsClientAction'
import { useDeleteTradeCopyByPersonMutation } from '@client/botClient/collections/DeleteTradeCopyByPersonClientAction'
import Loading from '@components/utils/Loading'
import { CopyByPersonalTabProps } from './interface'

const withCopyByPersonalTab = (Component: React.FC<CopyByPersonalTabProps>) => {
  function WithCopyByPersonalTab() {
    const {
      data: personals,
      isLoading,
      refetch,
    } = useGetMyTradeCopyByPersonsQuery()
    const [deleteTradeCopyByPerson] = useDeleteTradeCopyByPersonMutation()

    const handleDeleteTradeCopyByPerson = async (id: number) => {
      try {
        await deleteTradeCopyByPerson({ tradeCopyByPersonId: id }).unwrap()
        refetch()
        toast.success('Delete trade copy by person success')
      } catch (_) {}
    }

    if (isLoading) {
      return <Loading text='Loading' />
    }

    const newProps = { personals, handleDeleteTradeCopyByPerson }
    return <Component {...newProps} />
  }

  return WithCopyByPersonalTab
}

export default withCopyByPersonalTab
