import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const deleteTradeCopyByPersonApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    deleteTradeCopyByPerson: builder.mutation<
      boolean,
      { tradeCopyByPersonId: number }
    >({
      query: ({ tradeCopyByPersonId }) => ({
        document: gql`
          mutation DeleteTradeCopyByPerson($tradeCopyByPersonId: Float!) {
            deleteTradeCopyByPerson(tradeCopyByPersonId: $tradeCopyByPersonId)
          }
        `,
        variables: {
          tradeCopyByPersonId,
        },
      }),
    }),
  }),
})

export const { useDeleteTradeCopyByPersonMutation } = deleteTradeCopyByPersonApi
