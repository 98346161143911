import { useParams, useSearchParams } from 'react-router-dom'
import { Waypoint } from 'react-waypoint'
import BuyToSeeDetail from '@features/tradeCopy/components/BuyToSeeDetail'
import Loading from '@components/utils/Loading'
import { useGetTpHistoryQuery } from '@client/botClient/collections/GetTpHistory'
import useInfiniteScroll from '@hooks/useInfiniteScroll'
import ProfitHistoryCard from './ProfitHistoryCard'

function ProfitHistory() {
  const { id } = useParams()
  const [params] = useSearchParams()
  const userId = Number(params.get('userId'))
  const numberOfSymbol = Number(params.get('numberOfSymbol'))

  const { combinedData, isLoading, refresh, readMore } = useInfiniteScroll(
    useGetTpHistoryQuery,
    {
      userId,
      tradeCopyByPersonId: Number(id),
    },
    'bots'
  )

  return (
    <div className='px-[7px] pt-[36px]'>
      {isLoading ? (
        <Loading text='Loading' />
      ) : (
        <>
          {combinedData.length ? (
            <div>
              <div className='grid grid-cols-7 px-[10px] pb-[25px] text-xs font-bold'>
                <div className='col-span-2'>Coin</div>
                <div className='col-span-2'>Type</div>
                <div className='col-span-2'>P/L</div>
                <div className=''>Date</div>
              </div>
              <div className='grid grid-cols-1 gap-y-[10px]'>
                {combinedData?.map(bot => (
                  <ProfitHistoryCard key={bot.id} {...bot} />
                ))}
              </div>
              <Waypoint onEnter={readMore} />
            </div>
          ) : (
            <BuyToSeeDetail
              mode='byPersonal'
              id={Number(id)}
              refetchDetail={refresh}
              numberOfSymbol={numberOfSymbol}
            />
          )}
        </>
      )}
    </div>
  )
}

export default ProfitHistory
