import { useEffect, useState } from 'react'
import { GoToTopButtonProps, WithGoToTopButtonProps } from './interface'

export function withGoToTopButton(Component: React.FC<GoToTopButtonProps>) {
  function WithGoToTopButton({ className }: WithGoToTopButtonProps) {
    const [isShowButton, setIsShowButton] = useState(false)

    function handleVisibleButton() {
      setIsShowButton(window.pageYOffset > 50)
    }

    function handleScrollUp() {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth' })
    }

    useEffect(() => {
      if (window !== undefined) {
        window.addEventListener('scroll', handleVisibleButton)
      }

      return () => {
        window.removeEventListener('scroll', handleVisibleButton)
      }
    }, [])

    const componentProps = {
      isShowButton,
      className,
      handleScrollUp,
    }

    return <Component {...componentProps} />
  }

  return WithGoToTopButton
}
