import { useLazyTrendingCoinsQuery } from '@client/exchangeClient/collections/TrendingCoins'
import { useExchange } from '@components/Exchange/context/ExchangeContext'
import { TrendingCoin } from '@models/TrendingCoin/TrendingCoin'
import { DateTime } from 'luxon'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import {
  TrendingCoinRanking,
  TrendingCoinsAcceptProps,
  TrendingCoinsProps,
} from './interface'

const withTrendingCoins = (Component: React.FC<TrendingCoinsProps>) => {
  const Hoc = ({ limit, ...acceptProps }: TrendingCoinsAcceptProps) => {
    const { exchange } = useExchange()

    const [isLoading, setIsLoading] = useState(false)
    const [originData, setOriginData] = useState<TrendingCoin[]>([])
    const [trendingCoins, setTrendingCoins] = useState<TrendingCoin[]>([])
    const [rank, setRank] = useState<TrendingCoinRanking>()
    const [getTrendingCoins] = useLazyTrendingCoinsQuery()

    const lastUpdate = useMemo(() => {
      return DateTime.now()
    }, [])

    const trendingFetch = useCallback(async () => {
      setIsLoading(true)

      const coins = await getTrendingCoins({ limit })
      if (coins.data) {
        setTrendingCoins(coins.data!)
        setOriginData(coins.data!)
      }
      setIsLoading(false)
    }, [getTrendingCoins, limit])

    useEffect(() => {
      trendingFetch()
    }, [trendingFetch])

    const handleOnRankChanged = newRank => {
      const isAll = newRank === 'ALL'
      setRank(isAll ? undefined : newRank)
      if (!isAll) {
        const start = Number(newRank.split('-')[0])
        const end = Number(newRank.split('-')[1])
        const cloneData = [...originData]
        const filterData = cloneData.filter(
          data => data.rank >= start && data.rank <= end
        )
        setTrendingCoins(filterData)
      } else {
        setTrendingCoins(originData)
      }
    }

    const newProps: TrendingCoinsProps = {
      ...acceptProps,
      lastUpdate,
      trendingCoins,
      isLoading,
      rank,
      handleOnRankChanged,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withTrendingCoins
