import React from 'react'
import { ArrowLightRightIcon } from '@components/Icons/Icons'
import { useNavigate } from 'react-router-dom'
import { TradeCopyRoutesPath } from '@config/Router'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'

interface PersonalBotCardProps {
  personalId: string
  detail: TradeCopyByBot
  mode?: string
}

function PersonalBotCard({
  personalId,
  detail,
  mode = '',
}: PersonalBotCardProps) {
  const navigate = useNavigate()
  return (
    <div
      className='border border-gray-300 rounded-[6px] py-[16px] px-[13px] cursor-pointer'
      onClick={() => {
        navigate({
          pathname: TradeCopyRoutesPath.tradeCopy.strategy.absolutePath({
            id: detail.id,
          }),
          search: `?personalId=${personalId}${mode ? `&mode=${mode}` : ''}`,
        })
      }}
    >
      <div className='flex items-center'>
        <div className='flex gap-x-[5px] flex-1'>
          <img
            src={detail.baseCurrencyData?.imgPath}
            alt={detail.symbol}
            className='w-[20px] h-[20px] rounded-full'
          />
          <div>
            <div className='text-sm font-bold pb-[12px]'>{detail.symbol}</div>
            <div className='text-[10px]'>
              {detail.endAt.toFormat('dd/MM/yyyy HH:mm:ss')}
            </div>
          </div>
        </div>
        <div className='flex items-center gap-x-[32px]'>
          <div className='text-xs font-bold'>
            {detail.profit.toLocaleString(undefined, {
              minimumFractionDigits: 0,
              maximumFractionDigits: 2,
            })}{' '}
            USDT
          </div>
          <ArrowLightRightIcon />
        </div>
      </div>
    </div>
  )
}

export default PersonalBotCard
