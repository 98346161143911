import React from 'react'
import { Outlet } from 'react-router-dom'
import { AppLayout } from '@components/AppLayout/AppLayout'
import { tutorialPath, TutorialRoutesPath } from '@config/Router'
import DepositTutorialPage from './pages/DepositTutorialPage/DepositTutorialPage'
import ApiKeyTutorialPage from './pages/ApiKeyTutorialPage/ApiKeyTutorialPage'

export const TutorialRoutes = {
  path: tutorialPath,
  element: <Outlet />,
  children: [
    {
      path: TutorialRoutesPath.deposit.path,
      element: (
        <AppLayout title='Deposit tutorial'>
          <DepositTutorialPage />
        </AppLayout>
      ),
    },
    {
      path: TutorialRoutesPath.apiKey.path,
      element: (
        <AppLayout title='API key setup tutorial'>
          <ApiKeyTutorialPage />
        </AppLayout>
      ),
    },
  ],
}
