import { AppLayout } from '@components/AppLayout/AppLayout'
import { ProfitRoutesPath } from '@config/Router'
import ProfitPage from './pages/ProfitPage'

export const ProfitRoutes = {
  path: ProfitRoutesPath.profit.path,
  element: (
    <AppLayout title='My Profit'>
      <ProfitPage />,
    </AppLayout>
  ),
}
