import { createClient, ExecutionResult } from 'graphql-ws'
import { getToken } from '@client/client'

export interface BotWsServiceType<T> {
  onNext: (value: ExecutionResult<T, unknown>) => void
  onError: (e: unknown) => void
  onComplete: () => void
  queryDocument: string
  queryVariables?: Record<string, any>
  url: string
}

export const botWsService = <T>({
  onNext,
  onError,
  onComplete,
  queryVariables,
  queryDocument,
  url,
}: BotWsServiceType<T>) => {
  const client = createClient({
    url,
    connectionParams: () => {
      return {
        authorization: `Bearer ${getToken()}`,
        exchange: 'binance',
      }
    },
  })

  client.subscribe<T>(
    {
      query: queryDocument,
      variables: queryVariables,
    },
    {
      next: onNext,
      error: onError,
      complete: onComplete,
    }
  )

  return client
}
