import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { AppLayoutProps } from './interface'

export const withAppLayout = (Component: React.FC<AppLayoutProps>) => {
  function HOC(props: AppLayoutProps) {
    useProfileQuery({})

    const newProps = { ...props }
    return <Component {...newProps} />
  }

  return HOC
}
