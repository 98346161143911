import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { plainToClass } from 'class-transformer'
import { TradeCopyByPerson } from '@models/TradeCopy/TradeCopyByPerson'

export const getTradeCopyByPersonsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTradeCopyByPersons: builder.query<TradeCopyByPerson[], void>({
      query: () => ({
        document: gql`
          query GetTradeCopyByPersons {
            getTradeCopyByPersons {
              id
              numberOfSymbol
              yesterdayProfit
              totalProfit
              tpCount
              isAlreadyBuy
              user {
                id
                name
                strategyDescription
                profileImgPath
              }
            }
          }
        `,
      }),
      transformResponse(res: { getTradeCopyByPersons: TradeCopyByPerson[] }) {
        return plainToClass(TradeCopyByPerson, <any[]>res.getTradeCopyByPersons)
      },
      providesTags: ['TradeCopyPersons'],
    }),
  }),
})

export const { useGetTradeCopyByPersonsQuery } = getTradeCopyByPersonsApi
