import React from 'react'
import { NoDataIcon } from '@components/Icons/Icons'

function NoData({ className }: { className?: string }) {
  return (
    <div
      className={`flex flex-col items-center justify-center py-[47.5px] ${className}`}
    >
      <NoDataIcon width='126' height='171' className='text-white' />
      <div className='text-sm text-white'>No data</div>
    </div>
  )
}

export default NoData
