export class TradeCopyByPerson {
  id: number

  numberOfSymbol: number

  yesterdayProfit: number

  totalProfit: number

  tpCount: number

  isAlreadyBuy: boolean

  user: {
    id: number
    name: string
    strategyDescription: string
    profileImgPath?: string
  }

  buyDate?: number
}
