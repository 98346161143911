import React from 'react'
import { ScaleLoader } from 'react-spinners'
import PrimaryButton from '@components/buttons/PrimaryButton'
import WalletBalance from '@components/WalletBalance'

import { LockIcon, UnLockIcon } from '@components/Icons/Icons'
import { diffNow } from '@libs/utils'
import withActivatePage from './withActivatePage'
import { ActivatePageProps } from './interface'

function ActivatePage({
  profile,
  refetchWalletBalance,
  usdtBalance,
  handleActivateAccount,
  isActivateLoading,
  isFetching,
  isPaidLoading,
  onHandleDepositWallet,
}: ActivatePageProps) {
  return (
    <div className='max-w-[700px] mx-auto px-[17px] desktop:mt-2 mb-9'>
      <WalletBalance
        balance={usdtBalance}
        isFetching={isFetching}
        refetchBalance={refetchWalletBalance}
        className='mb-[20px]'
      />

      <div className='flex space-x-2 justify-center items-center mb-[23px]'>
        {profile.isActivate ? (
          <UnLockIcon className='text-green-100' height='20' width='20' />
        ) : (
          <LockIcon className='text-red' height='20' width='20' />
        )}
        {profile.isActivate ? (
          <span className='text-xs text-green-100'>
            Your account is activated.
          </span>
        ) : (
          <span className='text-xs text-red'>
            Your account is not activate.
          </span>
        )}
      </div>

      <div className='flex items-center justify-center text-xs mb-[20px]'>
        Expired date :{' '}
        {profile?.isActivate && !!profile?.activateUntilAt
          ? diffNow(Number(profile.activateUntilAt))
          : '-'}
      </div>

      <picture className='flex justify-center mb-5'>
        <source srcSet='/khotbot_thumbup.webp' type='image/webp' />
        <img
          src='/khotbot_thumbup.png'
          alt='khotnot mascot'
          title='khotnot mascot'
          loading='lazy'
          className='w-auto h-72'
        />
      </picture>

      <div className='flex flex-col justify-center items-center mb-[40px]'>
        <span className='text-sm mb-[10px] text-center'>
          ระบบจะคิดค่าธรรมเนียมการสมัคร 25 USDT/ปี
          <br />
          ระบบจะคิดค่าธรรมเนียม 20% ของกำไร
          <br />
          (ระบบจะทำการหักค่าธรรมเนียมจาก Khotcoin กรุณาเติม Khotcoin ให้เพียงพอ)
        </span>
      </div>

      <div className='flex gap-3'>
        <PrimaryButton
          type='button'
          className='w-full rounded-[6px] h-[48px] bg-white text-gray-800 text-lg'
          onClick={onHandleDepositWallet}
        >
          Deposit
        </PrimaryButton>
        <PrimaryButton
          type='button'
          className='w-full rounded-[6px] h-[48px] '
          disableClassName='disabled:bg-gray-300 disabled:text-gray-500 disabled:opacity-100'
          disabled={usdtBalance < 25 || isActivateLoading || isPaidLoading}
          onClick={handleActivateAccount}
        >
          {isActivateLoading || isPaidLoading ? (
            <div className='mt-[5px]'>
              <ScaleLoader
                height='10px'
                width='4px'
                radius='10px'
                margin='4px'
                loading
                color='#FFFFFF'
              />
            </div>
          ) : (
            <p className='text-lg font-bold'>Pay Now</p>
          )}
        </PrimaryButton>
      </div>
    </div>
  )
}

export default withActivatePage(ActivatePage)
