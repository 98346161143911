import { SelectEasyTradeProps } from '@features/bot/pages/CreateEasyBotPage/components/SelectEasyTrade/interface'
import NoData from '@components/NoData/NoData'
import cn from 'classnames'
import React from 'react'
import withSelectEasyTrade from '@features/bot/pages/CreateEasyBotPage/components/SelectEasyTrade/withSelectEasyTrade'

const SelectEasyTrade = ({
  easyTradeTemplateOptions,
  selectedEasyTradeTemplate,
  handleOnSelectTemplate,
}: SelectEasyTradeProps) => {
  return (
    <div>
      <span className='text-sm font-medium'>Select template</span>
      <div className='grid grid-col-1 gap-y-[8px] pt-[9px] max-h-[50vh] overflow-y-auto'>
        {easyTradeTemplateOptions?.length ? (
          easyTradeTemplateOptions.map((template, index) => (
            <div
              key={template.id}
              className={cn(
                'border border-gray-300 rounded-[6px] px-[20px] py-[16px] cursor-pointer',
                {
                  '!border-primary':
                    template.id === selectedEasyTradeTemplate?.id,
                }
              )}
              onClick={() => handleOnSelectTemplate(template)}
              data-test-id={`easy-trade-card-${index}`}
            >
              <div className='flex gap-x-[10px]'>
                <img
                  src={template.logoImgPath}
                  alt={template.name}
                  className='w-[38px] h-[38px] rounded-full'
                />
                <div className='flex flex-col gap-y-[2px]'>
                  <div className='text-sm font-bold leading-[22px]'>
                    {template.name}
                  </div>
                  <div className='text-xs font-light'>
                    {template.description}
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}
const WrappedComponent = withSelectEasyTrade(SelectEasyTrade)
export default WrappedComponent
