import { AdvancedRealTimeChart } from 'react-ts-tradingview-widgets'
import {
  createSearchParams,
  useNavigate,
  useSearchParams,
} from 'react-router-dom'
import { useEffect } from 'react'
import { TradingViewRoutesPath } from '@config/Router'
import Loading from '@components/utils/Loading'

const TradingViewPage = () => {
  const navigate = useNavigate()
  const [params] = useSearchParams()

  const symbol = params.get('tvwidgetsymbol')
    ? decodeURIComponent(params.get('tvwidgetsymbol')!)
    : undefined

  useEffect(() => {
    if (!params.get('tvwidgetsymbol')) {
      const search = `?${createSearchParams({
        tvwidgetsymbol: `BINANCE:BTCUSDT`,
      })}`

      navigate({
        pathname: TradingViewRoutesPath.tradingView.absolutePath,
        search,
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (!symbol) {
    return <Loading text='Loading...' />
  }
  return (
    <div className='max-w-[700px] h-4/5-screen mx-auto px-[17px] pt-[14px] text-sm font-medium flex flex-col gap-y-[16px]'>
      <AdvancedRealTimeChart
        symbol={symbol}
        hide_side_toolbar
        autosize
        width='100%'
        interval='60'
        theme='dark'
      />
    </div>
  )
}

export default TradingViewPage
