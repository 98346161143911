import React, { useState } from 'react'
import { usePagination } from '@hooks/usePagination'
import { KhotcoinTransactionPageProps } from '@features/wallet/pages/KhotcoinTransactionPage/interface'
import { KhotcoinTransactionTypeEnum } from '@interfaces/KhotcoinTransactionTypeEnum'
import { useKhotcoinTransactionsQuery } from '@client/mainClient/collections/KhotcoinTransactionsClientActions'

const withKhotcoinTransactionPage = (
  Component: React.FC<KhotcoinTransactionPageProps>
) => {
  function WithTransactionPage() {
    const { page, perpage, pageChange, perpageChange } = usePagination()
    const [filter, setFilter] = useState<KhotcoinTransactionTypeEnum>()

    const { data, isLoading } = useKhotcoinTransactionsQuery({
      limit: perpage,
      page,
      type: filter,
    })

    const handleFilterChanged = (
      value: KhotcoinTransactionTypeEnum | undefined
    ) => {
      setFilter(value)
    }

    const newProps: KhotcoinTransactionPageProps = {
      transactions: data,
      isLoading,
      page,
      perpage,
      filter,
      pageChange,
      perpageChange,
      handleFilterChanged,
    }
    return <Component {...newProps} />
  }

  return WithTransactionPage
}

export default withKhotcoinTransactionPage
