import { OptionType } from '@interfaces/OptionType'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { BotSortEnum } from '@interfaces/BotSortEnum'

export const botSortTypeOptionsStatusReady: OptionType[] = [
  { label: 'Created at', value: BotSortEnum.CREATED_AT },
  { label: 'Symbol', value: BotSortEnum.SYMBOL },
  { label: 'Initial margin', value: BotSortEnum.INITIAL_MARGIN },
  { label: 'TP ratio', value: BotSortEnum.TP_RATIO },
  { label: 'Total covering', value: BotSortEnum.TOTAL_COVERING },
]

export const botSortTypeOptionsStatusRunning: OptionType[] = [
  { label: 'Created at', value: BotSortEnum.CREATED_AT },
  { label: 'Symbol', value: BotSortEnum.SYMBOL },
  { label: 'Contractual asset', value: BotSortEnum.CONTRACTUAL_ASSET },
  { label: 'Current covering', value: BotSortEnum.CURRENT_COVERING },
]

export const botSortTypeOptionsStatusFinished: OptionType[] = [
  { label: 'Created at', value: BotSortEnum.CREATED_AT },
  { label: 'End at', value: BotSortEnum.END_AT },
  { label: 'Symbol', value: BotSortEnum.SYMBOL },
  { label: 'Initial margin', value: BotSortEnum.INITIAL_MARGIN },
  { label: 'Current covering', value: BotSortEnum.CURRENT_COVERING },
  { label: 'Profit & loss (USDT)', value: BotSortEnum.PROFIT_USDT },
]

export const botSortTypeOptionsStatusStop: OptionType[] = [
  { label: 'Created at', value: BotSortEnum.CREATED_AT },
  { label: 'Symbol', value: BotSortEnum.SYMBOL },
  { label: 'Current covering', value: BotSortEnum.CURRENT_COVERING },
  { label: 'Average price', value: BotSortEnum.AVERAGE_PRICE },
]

export const botSortTypeOptionsStatusError: OptionType[] = [
  { label: 'Created at', value: BotSortEnum.CREATED_AT },
  { label: 'Symbol', value: BotSortEnum.SYMBOL },
  { label: 'Initial margin', value: BotSortEnum.INITIAL_MARGIN },
  { label: 'Error time', value: BotSortEnum.END_AT },
]

export const botSortTypeOptionFromBotStatus = (
  botStatus?: BotStatusEnum
): OptionType[] => {
  if (botStatus === BotStatusEnum.READY) {
    return botSortTypeOptionsStatusReady
  }
  if (botStatus === BotStatusEnum.RUNNING) {
    return botSortTypeOptionsStatusRunning
  }
  if (botStatus === BotStatusEnum.FINISHED) {
    return botSortTypeOptionsStatusFinished
  }
  if (botStatus === BotStatusEnum.STOP) {
    return botSortTypeOptionsStatusStop
  }
  if (botStatus === BotStatusEnum.ERROR) {
    return botSortTypeOptionsStatusError
  }
  return [{ label: 'Created at', value: BotSortEnum.CREATED_AT }]
}
