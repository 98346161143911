import { gql } from 'graphql-request'
import { exchangeApi } from '@client/client'
import { WalletTransactions } from '@models/Wallet/WalletTransactions'
import { SuccessfulEnum } from '@interfaces/StatusEnum'
import { TransactionTypeEnum } from '@interfaces/TransactionTypeEnum'

export const walletTransactionsApi = exchangeApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    walletTransactions: builder.query<
      WalletTransactions,
      {
        page: number
        limit: number
        status: SuccessfulEnum
        type: TransactionTypeEnum | undefined
      }
    >({
      query: ({ page, limit, status, type }) => ({
        document: gql`
          query WalletTransactions(
            $page: Int
            $limit: Int
            $status: SuccessfulEnum
            $type: TransactionType
          ) {
            walletTransactions(
              page: $page
              limit: $limit
              status: $status
              type: $type
            ) {
              walletTransactions {
                type
                amount
                status
                createdAt
                userId
                transactionDetail {
                  to
                  from
                }
              }
              pagination {
                limit
                page
                totalPage
                totalCount
              }
            }
          }
        `,
        variables: { page, limit, status, type },
      }),
      transformResponse(res: { walletTransactions: WalletTransactions }) {
        const { walletTransactions } = res
        return walletTransactions
      },
      providesTags: ['Transaction'],
    }),
  }),
})

export const { useWalletTransactionsQuery } = walletTransactionsApi
