import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { UpdateBotForm } from '@models/Bot/UpdateBotForm'

export const updateBotApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    updateBot: builder.mutation<{ id: number }, UpdateBotForm>({
      query: updateBotController => ({
        document: gql`
          mutation UpdateBotController(
            $updateBotController: UpdateBotControllerInput!
          ) {
            updateBotController(updateBotController: $updateBotController) {
              id
            }
          }
        `,
        variables: {
          updateBotController,
        },
      }),
    }),
  }),
})

export const { useUpdateBotMutation } = updateBotApi
