import NoData from '@components/NoData/NoData'
import PersonalCard from '@features/tradeCopy/components/PersonalCard'
import { DateTime } from 'luxon'
import { useState } from 'react'
import { TradeCopyPersonalProps } from './interface'
import withTradeCopyPersonal from './withTradeCopyPersonal'

function TradeCopyPersonal({
  tradeCopyByPersons,
  refetch,
}: TradeCopyPersonalProps) {
  const [lastUpdate, setLastUpdate] = useState(
    DateTime.now().startOf('day').plus({ seconds: 1 })
  )

  return (
    <div className='py-[18px] px-[16px]'>
      <div className='text-xs font-light text-right text-gray-400'>
        Last update: {lastUpdate.toFormat('dd/MM/yyyy HH:mm:ss')}
      </div>
      {tradeCopyByPersons?.length ? (
        <div className='grid grid-cols-1 gap-y-[9px] mt-[18px]'>
          {tradeCopyByPersons.map(personal => (
            <PersonalCard
              key={personal.id}
              personal={personal}
              refetch={refetch}
            />
          ))}
        </div>
      ) : (
        <NoData />
      )}
    </div>
  )
}

export default withTradeCopyPersonal(TradeCopyPersonal)
