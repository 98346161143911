import { botApi } from '@client/client'
import { BotStrategiesDefault } from '@models/Bot/BotStrategiesDefault'
import { gql } from 'graphql-request'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const botStrategiesDefaultApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    botStrategiesDefault: builder.query<
      BotStrategiesDefault[],
      { botMarketType?: BotMarketType } | void
    >({
      query: queryArgs => ({
        document: gql`
          query BotStrategiesDefault($botMarketType: BotMarketType!) {
            botStrategiesDefault(bot_market_type: $botMarketType) {
              id
              name
              isCycle
              isStopLoss
              marginType
              side
              isCovering
              cycleQuantity
              isFirstOrderDouble
              firstOrderRawQuote
              firstOrderTradeQuote
              leverage
              coveringQuantity
              tpRetracement
              tpRatio
              stopLossRatio
              stopLossLimit
              divisionAndControlProfit
              createdAt
              coverSpreads
              coverSpreadTrends
              coverMultiples
              profitProportion
              createdAt
            }
          }
        `,
        variables: {
          botMarketType: queryArgs?.botMarketType,
        },
      }),

      transformResponse(res: { botStrategiesDefault: BotStrategiesDefault[] }) {
        const { botStrategiesDefault } = res
        return botStrategiesDefault
      },
      providesTags: ['BotStrategies'],
    }),
  }),
})

export const { useBotStrategiesDefaultQuery } = botStrategiesDefaultApi
