import { DateTransformFromMillis } from '@libs/decorators/DateTransformFromMillis'
import { Expose } from 'class-transformer'
import { DateTime } from 'luxon'

export class TopupSessionModel {
  @Expose()
  @DateTransformFromMillis()
  expiredAt: DateTime

  @Expose()
  amount: number

  @Expose()
  masterWalletAddress: string
}
