import React from 'react'
import { DateTime } from 'luxon'
import { UserCircleIcon } from '@components/Icons/Icons'
import NoData from '@components/NoData/NoData'
import withCopyByPersonalBotListPage from './withCopyByPersonalBotListPage'
import { CopyByPersonalBotListPageProps } from './interface'
import PersonalBotCard from '../CopyByPersonalPage/components/PersonalBotCard'

function CopyByPersonalBotListPage({
  id,
  name,
  buyDate,
  personalBotDetails,
  profileImgPath,
}: CopyByPersonalBotListPageProps) {
  return (
    <div className='max-w-[700px] mx-auto py-[10px] px-[22px]'>
      <div className='border-b border-gray-600 mb-[22px] pb-[32px]'>
        <div className='text-sm'>Copy by personal</div>
        <div className='ml-[18px] relative'>
          <div className='mt-[8px] flex gap-x-[4px] items-center'>
            {profileImgPath ? (
              <img
                src={profileImgPath}
                alt='Profile'
                className='w-[45px] h-[45px] rounded-full'
              />
            ) : (
              <UserCircleIcon className='w-[45px] h-[45px]' />
            )}
            <div className='flex flex-col'>
              <div className='text-sm font-bold'>{name}</div>
              <div className='text-[10px]'>
                Buy date: {DateTime.fromMillis(buyDate).toFormat('dd/MM/yyyy')}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-y-[10px]'>
        {personalBotDetails?.length ? (
          personalBotDetails.map(bot => (
            <PersonalBotCard
              key={bot.id}
              detail={bot}
              personalId={id}
              mode='detail'
            />
          ))
        ) : (
          <NoData />
        )}
      </div>
    </div>
  )
}

export default withCopyByPersonalBotListPage(CopyByPersonalBotListPage)
