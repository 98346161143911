import React, { useState } from 'react'
import * as Yup from 'yup'
import { RegisterForm } from '@models/User/RegisterForm'
import { useSendOTPVerifyMutation } from '@client/mainClient/collections/SendOTPVerifyClientAction'
import Loading from '@components/utils/Loading'
import { useCreateUserMutation } from '@client/mainClient/collections/CreateUserClientAction'
import { useLoginMutation } from '@client/mainClient/collections/AuthenticationClientActions'
import { useLazyProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import PrivacyModal from '@components/Privacy/PrivacyModal'
import TermsOfServiceModal from '@components/TermsOfService/TermsOfServiceModal'
import { useModal } from '@components/Modal/context/ModalContext'
import { useSearchParams } from 'react-router-dom'
import { RegisterPageProps, RegisterStep } from './interface'

const validationSchema = Yup.object()
  .shape({
    username: Yup.string().min(4).max(50).required(),
    password: Yup.string()
      .min(8, 'Password must be include minimum 8 digits with A-Z, a-z, 0-9')
      .required()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/,
        'Password must be include minimum 8 digits with A-Z, a-z, 0-9'
      ),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Password does not match')
      .required(),
    phoneNumber: Yup.string().required().min(11).max(13),
    countryCode: Yup.number().required(),
    email: Yup.string().required(),
    name: Yup.string().required(),
    recaptchaToken: Yup.mixed().required(),
  })
  .test(function (value) {
    return value.phoneNumber &&
      value.countryCode &&
      value.phoneNumber
        .slice(value.countryCode.toString().length)
        .match(/^[1-9][0-9]*$/)
      ? false
      : this.createError({
          path: 'phoneNumber',
          message: 'phoneNumber first digit must not be 0',
        })
  })
  .test(function (value) {
    return (
      !value.existPhoneNumber ||
      // eslint-disable-next-line react/no-this-in-sfc
      this.createError({
        path: 'phoneNumber',
        message: 'phoneNumber is already exist',
      })
    )
  })

const withRegisterPage = (Component: React.FC<RegisterPageProps>) => {
  function WithRegisterPage() {
    const [params] = useSearchParams()
    const referralCode =
      params.get('referral') !== null
        ? decodeURIComponent(params.get('referral') || '')
        : undefined

    const [activeStep, setActiveStep] = useState<RegisterStep>('register')
    const [registerForm, setRegisterForm] = useState<RegisterForm>({
      name: '',
      email: '',
      username: '',
      password: '',
      confirmPassword: '',
      phoneNumber: '',
      recaptchaToken: '',
      countryCode: 0,
      existPhoneNumber: true,
      referralCode,
      lineId: undefined,
    })

    const [sendOtpMutate, { isLoading: isSendOtpLoading }] =
      useSendOTPVerifyMutation()
    const [createUserMutate, { isLoading: isCreateUserLoading }] =
      useCreateUserMutation()
    const [loginMutate, { isLoading: isLoginLoading }] = useLoginMutation()
    const [getProfile, { isLoading: isProfileLoading }] = useLazyProfileQuery()

    const { openModal: openPrivacyModal } = useModal({
      component: () => <PrivacyModal />,
    })
    const { openModal: openTermsOfServiceModal } = useModal({
      component: () => <TermsOfServiceModal />,
    })

    const handleRegisterFormSubmit = (form: RegisterForm) => {
      setRegisterForm(form)
      sendOtpMutate({
        phoneNumber: form.phoneNumber.slice(form.countryCode.toString().length),
        phoneNumberCountryCode: form.countryCode,
      })
        .unwrap()
        .then(() => {
          setActiveStep('otp')
        })
        .catch(() => {})

      setActiveStep('otp')
    }

    const createUser = async (resp: { verifiedToken: string }) => {
      try {
        await createUserMutate({
          name: registerForm.name,
          email: registerForm.email,
          username: registerForm.username,
          password: registerForm.password,
          recaptchaToken: registerForm.recaptchaToken,
          referralCode: registerForm.referralCode,
          lineId: registerForm.lineId,
          phoneNumberCountryCode: registerForm.countryCode,
          phoneNumber: registerForm.phoneNumber.slice(
            registerForm.countryCode.toString().length
          ),
          verifyToken: resp.verifiedToken,
        }).unwrap()
        await loginMutate({
          username: registerForm.username,
          password: registerForm.password,
        }).unwrap()
        await getProfile({}).unwrap()
      } catch (e) {
        setActiveStep('register')
      }
    }

    const changePhoneNumberStep = () => {
      setActiveStep('phoneNumber')
    }

    const changeToRegisterStep = () => {
      setActiveStep('register')
    }

    const newProps = {
      handleRegisterFormSubmit,
      registerForm,
      validationSchema,
      activeStep,
      changePhoneNumberStep,
      createUser,
      openTermsOfServiceModal,
      openPrivacyModal,
      changeToRegisterStep,
    }
    if (
      !isSendOtpLoading &&
      !isCreateUserLoading &&
      !isLoginLoading &&
      !isProfileLoading
    ) {
      return <Component {...newProps} />
    }
    return (
      <div className='min-h-[500px] flex flex-col justify-center'>
        <Loading text='Loading' />
      </div>
    )
  }

  return WithRegisterPage
}

export default withRegisterPage
