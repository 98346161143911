import cn from 'classnames'
import Tooltip from '@components/Tooltip/Tooltip'
import React from 'react'
import { SelectButtonProps } from './interface'

export function SelectButton({
  className,
  options,
  value,
  label,
  onChange,
  activeClassName = 'bg-secondary',
  unActiveClassName = '',
  optionClassName = 'border-1 border-gray-600 rounded-[10px]',
  tooltip,
  disabled,
}: SelectButtonProps) {
  return (
    <div
      className={`overflow-x-auto no-scrollbar ${
        disabled && 'cursor-not-allowed'
      }`}
    >
      {label && (
        <div className='flex flex-row space-x-2'>
          <div className='text-sm mb-[16px] font-medium text-white'>
            {label}
          </div>
          {tooltip && <Tooltip title={label} text={tooltip} />}
        </div>
      )}
      <div className={cn('flex space-x-2 text-xs ', className)}>
        {options.map(option => (
          <div
            className={cn(
              'rounded-[4px] flex space-x-1.5 px-[12px] py-[12px] items-center justify-center whitespace-pre cursor-pointer h-[40px] min-w-80px flex-shrink-0',
              optionClassName,
              value === option.value
                ? option.activeClassName ?? activeClassName
                : unActiveClassName,
              { 'cursor-not-allowed': disabled }
            )}
            key={option.value}
            onClick={() => !disabled && onChange && onChange(option.value)}
            data-test-id={`select-btn-${option.value}`}
          >
            {option.prefixIcon}
            <div>{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  )
}
