import React, { useState } from 'react'
import { DateTime } from 'luxon'
import { toast } from 'react-toastify'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { authenticationSelector } from '@features/authentication/AuthenticaitonSlice/AuthenticationSlice'
import { TransferForm } from '@models/Wallet/TransferForm'
import { useWalletBalanceQuery } from '@client/exchangeClient/collections/WalletBalanceClientAction'
import { useSendOTPVerifyMutation } from '@client/mainClient/collections/SendOTPVerifyClientAction'
import { useUsdtTransferMutation } from '@client/exchangeClient/collections/UsdtTransferClientAction'
import { WalletRoutesPath } from '@config/Router'
import { EXCHANGE_ORIGIN_API_URL } from '@config/config'
import Loading from '@components/utils/Loading'
import { TransferPageProps, TransferStep } from './interface'

export const withTransferPage = (Component: React.FC<TransferPageProps>) => {
  function Hoc() {
    const { profile } = useSelector(authenticationSelector)
    const navigate = useNavigate()
    const [isPaidLoading, setIsPaidLoading] = useState(false)
    const [paidTime, setPaidTime] = useState('')
    const [transferStep, setTransferStep] = useState<TransferStep>('form')
    const [transferForm, setTransferForm] = useState<TransferForm>({
      amount: 0,
      phoneNumber: '',
      countryCode: 0,
    })
    const { data, isFetching: isBalanceFetching } = useWalletBalanceQuery(
      undefined,
      { refetchOnMountOrArgChange: true }
    )
    const [sendOtpMutate, { isLoading: isSendOtpLoading }] =
      useSendOTPVerifyMutation()
    const [tranferMutate, { isLoading: isTranferLoading }] =
      useUsdtTransferMutation()

    const handletransferFormSubmit = (form: TransferForm) => {
      if (profile) {
        sendOtpMutate({
          phoneNumber: profile.phoneNumber,
          phoneNumberCountryCode: profile.phoneNumberCountryCode,
        })
          .unwrap()
          .then(() => {
            setTransferForm(form)
            setTransferStep('otp')
          })
          .catch(() => {})
      }
    }

    const handleVerifiedOtp = async (resp: { verifiedToken: string }) => {
      try {
        setIsPaidLoading(true)
        const res = await tranferMutate({
          toUserPhoneNumber: transferForm.phoneNumber.slice(
            transferForm.countryCode.toString().length
          ),
          toUserPhoneNumberCountryCode: transferForm.countryCode,
          amount: transferForm.amount,
          verifyToken: resp.verifiedToken,
        }).unwrap()
        toast.success(`Transaction was created!.`)
        const eventSource = new EventSource(
          `${EXCHANGE_ORIGIN_API_URL}/subscribe/transaction/${res.transactionHash}`
        )
        setIsPaidLoading(false)
        setPaidTime(DateTime.now().toFormat('dd-MM-yyyy HH:mm:ss'))
        setTransferStep('success')
      } catch (_) {}
    }

    const goToTransactionPage = () => {
      navigate(WalletRoutesPath.transaction.absolutePath)
    }

    if (!isSendOtpLoading && !isPaidLoading && !isTranferLoading) {
      const newProps = {
        transferStep,
        transferForm,
        balance: data?.usdt || 0,
        isBalanceFetching,
        handletransferFormSubmit,
        handleVerifiedOtp,
        profile,
        goToTransactionPage,
        paidTime,
      }
      return <Component {...newProps} />
    }
    return <Loading text='Loading' />
  }
  return Hoc
}
