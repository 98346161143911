import { SortEnum } from './SortEnum'

export enum MyNetworkSortEnum {
  NAME_ASC = 'NAME_ASC',
  NAME_DESC = 'NAME_DESC',
  ID_ASC = 'ID_ASC',
  ID_DESC = 'ID_DESC',
  TOTAL_PROFIT_ASC = 'TOTAL_PROFIT_ASC',
  TOTAL_PROFIT_DESC = 'TOTAL_PROFIT_DESC',
}

export const myNetworkSortEnumToJson = (
  type?: MyNetworkSortEnum
): undefined | Record<string, SortEnum> => {
  switch (type) {
    case MyNetworkSortEnum.NAME_ASC:
      return { name: SortEnum.ASCENDING }
    case MyNetworkSortEnum.NAME_DESC:
      return { name: SortEnum.DESCENDING }
    case MyNetworkSortEnum.ID_ASC:
      return { id: SortEnum.ASCENDING }
    case MyNetworkSortEnum.ID_DESC:
      return { id: SortEnum.DESCENDING }
    case MyNetworkSortEnum.TOTAL_PROFIT_ASC:
      return { totalProfit: SortEnum.ASCENDING }
    case MyNetworkSortEnum.TOTAL_PROFIT_DESC:
      return { totalProfit: SortEnum.DESCENDING }
    default:
      return undefined
  }
}

export const myNetworkSortEnumToDisplayText = (type: MyNetworkSortEnum) => {
  switch (type) {
    case MyNetworkSortEnum.NAME_ASC:
      return 'Name : A-Z'
    case MyNetworkSortEnum.NAME_DESC:
      return 'Name : Z-A'
    case MyNetworkSortEnum.ID_ASC:
      return 'User ID : Low to High'
    case MyNetworkSortEnum.ID_DESC:
      return 'User ID : High to Low'
    case MyNetworkSortEnum.TOTAL_PROFIT_ASC:
      return 'Total profit : Low to High'
    case MyNetworkSortEnum.TOTAL_PROFIT_DESC:
      return 'Total profit : High to Low'
    default:
      return ''
  }
}
