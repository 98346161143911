import { useResponsive } from '@hooks/useResponsive'
import { Swiper, SwiperSlide } from 'swiper/react'
import { Autoplay, Pagination } from 'swiper'
import { DepositTutorialMessage } from '@constant/tutorial/DepositTutorialMessage'

const depositTutorialList = [
  {
    message: DepositTutorialMessage.step1,
    imagePath: '/depositTutorial/step1.png',
  },
  {
    message: DepositTutorialMessage.step2,
    imagePath: '/depositTutorial/step2.png',
  },
  {
    message: DepositTutorialMessage.step3,
    imagePath: '/depositTutorial/step3.png',
  },
  {
    message: DepositTutorialMessage.step4,
    imagePath: '/depositTutorial/step4.png',
  },
  {
    message: DepositTutorialMessage.step5,
    imagePath: '/depositTutorial/step5.png',
  },
  {
    message: DepositTutorialMessage.step6,
    imagePath: '/depositTutorial/step6.png',
  },
  {
    message: DepositTutorialMessage.step7,
    imagePath: '/depositTutorial/step7.png',
  },
  {
    message: DepositTutorialMessage.step8,
    imagePath: '/depositTutorial/step8.png',
  },
  {
    message: DepositTutorialMessage.step9,
    imagePath: '/depositTutorial/step9.png',
  },
  {
    message: DepositTutorialMessage.step10,
    imagePath: '/depositTutorial/step10.png',
  },
]

const DepositTutorialPage = props => {
  const { isMobile } = useResponsive()
  return (
    <div className='max-w-[850px] mx-auto pt-[14px] desktop:mt-2 mb-9 bg-gray-secondary rounded-[4px]'>
      <Swiper
        modules={[Pagination, Autoplay]}
        spaceBetween={50}
        pagination={{ clickable: true }}
        autoplay={false}
        slidesPerView={isMobile ? 1 : 2}
        slidesPerGroup={isMobile ? 1 : 2}
      >
        {depositTutorialList.map(tutorial => (
          <SwiperSlide key={tutorial.imagePath} className=''>
            <div className='flex flex-col items-center justify-center gap-y-3  w-full h-full pb-8'>
              <img
                src={tutorial.imagePath}
                alt={tutorial.imagePath}
                className='object-cover max-h-[550px]'
              />
              <p className='text-sm text-center'>{tutorial.message}</p>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  )
}
export default DepositTutorialPage
