import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { Users } from '@models/User/Users'
import { ProfitType } from '@models/Profit/ProfitType'

export const userProfitRankApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    userProfitRank: builder.query<
      Users,
      { profitType: ProfitType; page?: number; limit?: number }
    >({
      query: ({ profitType, page, limit }) => ({
        variables: { profitType, page: page ?? 1, limit: limit ?? 20 },
        document: gql`
          query GetUserProfitRank(
            $profitType: ProfitType!
            $page: Int
            $limit: Int
          ) {
            getUserProfitRank(
              profitType: $profitType
              page: $page
              limit: $limit
            ) {
              pagination {
                totalPage
                totalCount
                page
                limit
              }
              users {
                id
                name
                monthlyProfit
                totalProfit
                dailyProfit
                userTierId
                profileImgPath
                strategyDescription
              }
            }
          }
        `,
      }),
      transformResponse(res: { getUserProfitRank: Users }) {
        const { getUserProfitRank } = res
        return getUserProfitRank
      },
      providesTags: ['ProfitRank'],
    }),
  }),
})

export const { useUserProfitRankQuery, useLazyUserProfitRankQuery } =
  userProfitRankApi
