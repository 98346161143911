import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks'
import { useState, useEffect, useCallback, useMemo } from 'react'

const calculateMaxPages = (total: number, size: number) => {
  return Math.ceil(total / size)
}

export const isValidNotEmptyArray = (array: any[]): boolean => {
  return !!(array && array?.length && array?.length > 0)
}

const useInfiniteScroll = (
  useGetDataListQuery: UseQuery<any>,
  { limit = 10, ...queryParameters },
  itemsKey: string
) => {
  const [localPage, setLocalPage] = useState(1)
  const [combinedData, setCombinedData] = useState<any[]>([])

  const queryResponse = useGetDataListQuery({
    page: localPage,
    limit,
    ...queryParameters,
  })

  const { [itemsKey]: fetchData = [], pagination: remotePagination } =
    (queryResponse?.data as any) || {}

  const {
    page: remotePage = 1,
    totalCount: remoteTotal = 0,
    limit: remoteSize = 10,
  } = remotePagination || {
    page: 1,
    totalCount: 0,
    limit: 10,
  }

  useEffect(() => {
    if (isValidNotEmptyArray(fetchData)) {
      if (localPage === 1) setCombinedData(fetchData)
      else if (localPage === remotePage) {
        setCombinedData(previousData => [...previousData, ...fetchData])
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchData])

  const maxPages = useMemo<number>(() => {
    return calculateMaxPages(remoteTotal, remoteSize)
  }, [remoteTotal, remoteSize])

  const refresh = useCallback(() => {
    setLocalPage(1)
  }, [])

  const readMore = () => {
    if (localPage < maxPages && localPage === remotePage) {
      setLocalPage(page => page + 1)
    }
  }

  return {
    combinedData,
    localPage,
    readMore,
    refresh,
    isLoading: queryResponse?.isLoading,
    isFetching: queryResponse?.isFetching,
  }
}

export default useInfiniteScroll
