import React, { useState } from 'react'
import { CreateEasyTradeOwnerSelectPageProps } from '@features/bot/pages/CreateEasyTradeOwnerSelectPage/interface'
import { useEasyTradeOwnersQuery } from '@client/botClient/collections/EasyTradeOwnersClientAction'
import { useNavigate } from 'react-router-dom'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'

const withCreateEasyTradeOwnerSelectPage = (
  Component: React.FC<CreateEasyTradeOwnerSelectPageProps>
) => {
  const Hoc = () => {
    const navigate = useNavigate()
    const { isFuture, botMarketType } = useBotMarketType()
    const botRoute = isFuture ? ContractRoutesPath : SpotRoutesPath
    const { data, isLoading } = useEasyTradeOwnersQuery()
    const [selectedOwner, setSelectedOwner] = useState<number>()
    const handleSelectedOwner = id => setSelectedOwner(id)
    const handleOnNext = () => {
      navigate(
        botRoute.createEasyTradeConfig.absolutePath({
          ownerId: selectedOwner,
        })
      )
    }

    const newProps: CreateEasyTradeOwnerSelectPageProps = {
      easyTradeOwners: data,
      selectedOwner,
      isLoading,
      handleSelectedOwner,
      handleOnNext,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withCreateEasyTradeOwnerSelectPage
