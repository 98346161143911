import React from 'react'
import cn from 'classnames'
import { DateTime } from 'luxon'
import { TradeCopyByPerson } from '@models/TradeCopy/TradeCopyByPerson'
import NoData from '@components/NoData/NoData'
import { UserCircleIcon } from '@components/Icons/Icons'
import { useDrawer } from '@hooks/useDrawer'
import { useGetTradeCopyByPersonBotDetailsQuery } from '@client/botClient/collections/GetTradeCopyByPersonBotDetails'
import { TradeCopyByBot } from '@models/TradeCopy/TradeCopyByBot'
import SelectBotDrawer from './SelectBotDrawer'

interface SelectCopyByPersonalProps {
  selectedPersonalId?: number
  handleSelectedCopyByPersonalChange: (personId: number) => void
  copyByPersonalBot?: TradeCopyByPerson[]
  handleSelectedCopyByBotChange: (bot?: TradeCopyByBot) => void
  selectedBot?: TradeCopyByBot
}

function SelectCopyByPersonal({
  selectedPersonalId,
  handleSelectedCopyByPersonalChange,
  copyByPersonalBot,
  selectedBot,
  handleSelectedCopyByBotChange,
}: SelectCopyByPersonalProps) {
  const selectBotDrawer = useDrawer()

  const { data: copyByPersonBotDetails } =
    useGetTradeCopyByPersonBotDetailsQuery(selectedPersonalId!, {
      skip: !selectedPersonalId,
    })

  return (
    <>
      <span className='text-sm font-medium'>Select Personal</span>
      <div className='grid grid-col-1 gap-y-[8px] pt-[9px] max-h-[50vh] overflow-y-auto'>
        {copyByPersonalBot?.length ? (
          copyByPersonalBot.map(person => (
            <div
              key={person.id}
              className={cn(
                'border border-gray-300 rounded-[6px] px-[20px] py-[16px] cursor-pointer',
                { '!border-primary': person.id === selectedPersonalId }
              )}
              onClick={() => {
                handleSelectedCopyByPersonalChange(person.id)
                selectBotDrawer.handleOpenDrawer()
              }}
            >
              <div className='flex gap-x-[10px]'>
                {person.user.profileImgPath ? (
                  <img
                    src={person.user.profileImgPath}
                    alt='Profile'
                    className='w-[40px] h-[40px] rounded-full mr-[5px]'
                  />
                ) : (
                  <UserCircleIcon className='w-[45px] h-[45px]' />
                )}
                <div className='flex flex-col gap-y-[2px]'>
                  <div className='text-sm font-bold leading-[22px]'>
                    {person.user.name}
                  </div>
                  <div className='text-xs font-light'>{`Buy date: ${DateTime.fromMillis(
                    person.buyDate!
                  ).toFormat('dd/MM/yyyy')}`}</div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <NoData />
        )}
      </div>
      <SelectBotDrawer
        drawer={selectBotDrawer}
        bots={copyByPersonBotDetails}
        selectedBot={selectedBot}
        handleSelectedCopyByBotChange={handleSelectedCopyByBotChange}
      />
    </>
  )
}

export default SelectCopyByPersonal
