export enum TransactionTypeEnum {
  ACTIVATE_ACCOUNT = 'ACTIVATE_ACCOUNT',
  RE_NEW_ACTIVATE_ACCOUNT = 'RE_NEW_ACTIVATE_ACCOUNT',
  WITHDRAW = 'WITHDRAW',
  DEPOSIT = 'DEPOSIT',
  TRANSFER = 'TRANSFER',
  TRANSFER_FEE = 'TRANSFER_FEE',
  REFERRAL = 'REFERRAL',
  TRADE_COPY_BOT = 'TRADE_COPY_BOT',
  TRADE_COPY_BOT_INCOME = 'TRADE_COPY_BOT_INCOME',
  TRADE_COPY_PERSON = 'TRADE_COPY_PERSON',
  TRADE_COPY_PERSON_INCOME = 'TRADE_COPY_PERSON_INCOME',
  TOP_UP_KHOT_COIN = 'TOP_UP_KHOT_COIN',
  SYSTEM_FEE = 'SYSTEM_FEE',
}
