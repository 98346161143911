import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { KhotcoinPackage } from '@models/Khotcoin/KhotcoinPackage'

export const khotcoinPackagesApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    khotcoinPackages: builder.query<KhotcoinPackage[], void>({
      query: () => ({
        operationName: 'GetKhotcoinsPackages',
        document: gql`
          query GetKhotcoinsPackages {
            getKhotcoinsPackages {
              id
              name
              usdtAmount
              khotcoinAmount
            }
          }
        `,
      }),
      transformResponse(res: { getKhotcoinsPackages: KhotcoinPackage[] }) {
        const { getKhotcoinsPackages } = res
        return getKhotcoinsPackages
      },
      providesTags: ['KhotcoinPackages'],
    }),
  }),
})

export const { useKhotcoinPackagesQuery } = khotcoinPackagesApi
