import React from 'react'
import { ExchangeIcon } from '@components/Icons/Icons'

function SymbolImage({
  path,
  className = 'w-[40px] h-[40px]',
  avatarClassName = 'text-[#24A8B1]',
  imageClassName = 'rounded-full',
  children,
}: {
  path?: string
  className?: string
  avatarClassName?: string
  imageClassName?: string
  children?: React.ReactNode
}) {
  return (
    <div className={`rounded-full ${className}`}>
      {path ? (
        <img
          src={path}
          alt='Profile'
          className={`w-full h-full ${imageClassName}`}
        />
      ) : (
        <ExchangeIcon className={`w-full h-full ${avatarClassName}`} />
      )}
      {children}
    </div>
  )
}

export default SymbolImage
