import React from 'react'

const BotTabData = ({
  title,
  value,
  isShowColorValue,
}: {
  title: string
  value: string | number
  isShowColorValue?: boolean
}) => {
  return (
    <>
      <div className='flex-1 flex flex-col space-y-1 '>
        <span className='text-xs text-gray-400'>{title}</span>
        <span
          className={`text-xs ${
            isShowColorValue
              ? value > 0
                ? 'text-green-100'
                : value < 0
                ? 'text-red'
                : ''
              : ''
          }`}
        >
          {value}
        </span>
      </div>
    </>
  )
}

export default BotTabData
