import React from 'react'
import NoData from '@components/NoData/NoData'
import Loading from '@components/utils/Loading'
import TablePagination from '@components/Table/component/TablePagination/TablePagination'
import { FilteredIcon, FilterIcon } from '@components/Icons/Icons'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import OneOffAllButton from '@features/bot/components/BotPagination/components/OneOffAllButton/OneOffAllButton'
import StopCoveringAllButton from '@features/bot/components/BotPagination/components/StopCoveringAllButton/StopCoveringAllButton'
import { BotPaginationProps } from './interface'
import BotCard from '../BotCard/BotCard'
import TurnOnOffAllButton from './components/TurnOnOffAllButton/TurnOnOffAllButton'

function BotPagination({
  bots,
  isLoading,
  page,
  pageChange,
  pageCount,
  botCount,
  totalBotCount,
  botCountLongShort,
  handleOpenSortModal,
  isSorted,
  refetch,
  status,
  isOneOffAll,
  isStopCoveringAll,
}: BotPaginationProps) {
  return (
    <div>
      {isLoading ? (
        <Loading text='Loading' />
      ) : (
        <div className='py-[24px] px-[15px] flex flex-col gap-y-[16px]'>
          <div className='flex justify-between mr-4 items-center '>
            <div className='flex space-x-1 text-xs text-gray-400'>
              <span className='mr-1.5 '>
                Bot count: {botCount} of {totalBotCount}{' '}
              </span>
              <span className='text-green-100 mr-1.5'>
                Long: {botCountLongShort?.botLong ?? 0}
              </span>
              <span className='text-red'>
                Short: {botCountLongShort?.botShort ?? 0}
              </span>
            </div>
            {isSorted ? (
              <FilteredIcon
                onClick={handleOpenSortModal}
                height='24'
                width='24'
                className='cursor-pointer'
              />
            ) : (
              <FilterIcon
                onClick={handleOpenSortModal}
                height='24'
                width='24'
                className='cursor-pointer'
              />
            )}
          </div>
          {!!bots.length ? (
            <>
              {/* Bot actions */}
              <div className='flex flex-1 gap-3 flex-wrap'>
                {status === BotStatusEnum.RUNNING && (
                  <>
                    <OneOffAllButton isOneOffAll={isOneOffAll} />
                    <StopCoveringAllButton
                      isStopCoveringAll={isStopCoveringAll}
                    />
                    <TurnOnOffAllButton status={BotStatusEnum.RUNNING} />
                  </>
                )}
                {status === BotStatusEnum.STOP && (
                  <>
                    <TurnOnOffAllButton status={BotStatusEnum.STOP} />
                  </>
                )}
              </div>

              {bots.map(bot => (
                <BotCard
                  key={bot.id}
                  botId={bot.id}
                  botSymbolPath={bot.baseCurrencyData.imgPath}
                  isLoadingBotList={isLoading}
                  refetch={refetch}
                />
              ))}
              <TablePagination
                paginationProps={{
                  pageCount,
                  handleOnPageChange: pageChange,
                }}
                tableState={{
                  pageIndex: page - 1,
                }}
              />
            </>
          ) : (
            <NoData />
          )}
        </div>
      )}
    </div>
  )
}

export default BotPagination
