import { useDownlineUsersQuery } from '@client/mainClient/collections/DownlineUsersClientAction'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useUplineUserQuery } from '@client/mainClient/collections/UplineUserClientAction'
import SortModal from '@components/Modal/components/SortModal'
import { useModal } from '@components/Modal/context/ModalContext'
import { myNetworkSortOptions } from '@constant/myNetworkSortOptions'
import { useDebounce } from '@hooks/useDebounce'
import { usePagination } from '@hooks/usePagination'
import { useResponsive } from '@hooks/useResponsive'
import {
  MyNetworkSortEnum,
  myNetworkSortEnumToJson,
} from '@interfaces/MyNetworkSortEnum'
import React, { useState } from 'react'
import useCollapse from 'react-collapsed'
import { DateRangeTypeEnum } from '@interfaces/DateRangeTypeEnum'
import { ReferralPageProps } from './interface'

const withReferralPage = (Component: React.FC<ReferralPageProps>) => {
  function WithReferralPage() {
    const pageLimit = 10
    const { isMobile } = useResponsive()
    const { debounce } = useDebounce()
    const { page, pageChange, perpageChange } = usePagination()
    const [isExpanded, setExpanded] = useState(true)
    const [dateRangeType, setDateRangeType] = useState<DateRangeTypeEnum>(
      DateRangeTypeEnum.DAILY
    )

    const { getCollapseProps } = useCollapse({ isExpanded })

    const [searchText, setSearchText] = useState<string | undefined>()
    const [sortNetwork, setSortNetwork] = useState<MyNetworkSortEnum>()

    const handleSortChange = (type?: MyNetworkSortEnum) => {
      setSortNetwork(type)
    }
    const handleSearchTextChange = (value?: string) => {
      debounce(() => {
        setSearchText(value)
      }, 400)
    }
    const handleToggleExpanded = () => {
      setExpanded(prev => !prev)
    }
    const handleOnDateRangeTypeChanged = value => {
      setDateRangeType(value)
    }

    const { openModal } = useModal({
      component: () => (
        <SortModal
          onClear={() => handleSortChange()}
          onSelectData={handleSortChange}
          sortData={myNetworkSortOptions}
          initValue={sortNetwork}
        />
      ),
    })

    const { data: downlines, isLoading: isLoadingDownlines } =
      useDownlineUsersQuery(
        {
          page,
          limit: pageLimit,
          search: searchText,
          sort: myNetworkSortEnumToJson(sortNetwork),
          calculatedTeamProfit: dateRangeType,
          calculatedTotalProfitDateRangeType: dateRangeType,
          calculatedTotalReferralDateRangeType: dateRangeType,
        },
        { refetchOnMountOrArgChange: true }
      )
    const { data: upline, isLoading: isLoadingUpline } = useUplineUserQuery()
    const { data: profile, isLoading: isLoadingProfile } = useProfileQuery({
      calculatedTeamProfit: dateRangeType,
      calculatedTotalProfitDateRangeType: dateRangeType,
      calculatedTotalReferralDateRangeType: dateRangeType,
    })

    const newProps = {
      sortNetwork,
      isMobile,
      openSortModal: openModal,
      downlines,
      upline,
      profile,
      isLoadingDownlines,
      isLoadingUpline,
      isLoadingProfile,
      isExpanded,
      page,
      pageCount: downlines?.pagination.totalPage ?? 0,
      pageLimit,
      dateRangeType,
      handleSearchTextChange,
      handleToggleExpanded,
      getCollapseProps,
      pageChange,
      handleOnDateRangeTypeChanged,
    }

    return <Component {...newProps} />
  }

  return WithReferralPage
}

export default withReferralPage
