import React from 'react'
import { Bot } from '@models/Bot/Bot'
import BotTabData from '@features/bot/pages/BotPage/components/BotTabData'
import { DateTime } from 'luxon'
import { toLocaleStringActualDecimal } from '@libs/currencyFormat'
import { toPercentage } from '@libs/utils'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'

const StrategyDetailTab = ({ bot }: { bot: Bot }) => {
  const { isSpot, isFuture } = useBotMarketType()
  return (
    <div className='grid grid-cols-2 gap-x-2 gap-y-3 lg:px-12 py-5'>
      {/* Fist order limit */}
      <BotTabData
        title={isSpot ? 'Initial amount (USDT)' : 'First order limit (USDT)'}
        value={
          toLocaleStringActualDecimal(bot?.botStrategy?.firstOrderRawQuote) ??
          '-'
        }
      />
      {/* Leverage */}
      {isFuture && (
        <BotTabData
          title='Leverage'
          value={bot?.botStrategy?.leverage?.toLocaleString(undefined) ?? '-'}
        />
      )}

      {/* Cycle mode */}
      <BotTabData
        title='Cycle mode'
        value={
          bot.botStrategy?.isCycle
            ? bot.botStrategy?.isInfinityCycle
              ? 'Infinity'
              : `Cycle`
            : 'Single'
        }
      />
      {/* Number of cycle */}
      <BotTabData
        title='No. of cycle'
        value={
          bot.botStrategy?.isInfinityCycle
            ? `${bot?.cycleNo ?? '-'} / Infinity`
            : `${bot?.cycleNo ?? '-'} / ${
                bot?.botStrategy?.cycleQuantity ?? '-'
              }`
        }
      />

      {/* Double position */}
      <BotTabData
        title='Double position'
        value={bot?.botStrategy?.isFirstOrderDouble ? 'ON' : 'OFF'}
      />
      {/* Stop loss */}
      <BotTabData
        title='Stop loss'
        value={
          bot?.botStrategy?.isStopLoss
            ? bot?.botStrategy?.stopLossRatio
              ? `${toLocaleStringActualDecimal(
                  toPercentage(bot?.botStrategy?.stopLossRatio)
                )}%`
              : `${
                  bot?.botStrategy?.stopLossLimit
                    ? `${toLocaleStringActualDecimal(
                        bot?.botStrategy?.stopLossLimit
                      )} USDT`
                    : '-'
                }`
            : 'Off'
        }
      />

      {/* Order quantity */}
      <BotTabData
        title='Order quantity'
        value={bot?.botStrategy?.coveringQuantity ?? '-'}
      />
      {/* TP ratio */}
      <BotTabData
        title='TP ratio (%)'
        value={
          bot?.botStrategy?.isSplitTp
            ? bot?.botStrategy?.tpRatioSplit
                ?.map(
                  ratio =>
                    toLocaleStringActualDecimal(toPercentage(ratio)) ?? '-'
                )
                .join(', ')
            : toLocaleStringActualDecimal(
                toPercentage(bot?.botStrategy?.tpRatio)
              ) ?? '-'
        }
      />

      {/* TP retracement */}
      <BotTabData
        title='TPR ratio (%)'
        value={
          bot?.botStrategy?.isSplitTp
            ? bot?.botStrategy?.tpRetracementSplit
                ?.map(
                  ratio =>
                    toLocaleStringActualDecimal(toPercentage(ratio)) ?? '-'
                )
                .join(', ')
            : toLocaleStringActualDecimal(
                toPercentage(bot?.botStrategy?.tpRetracement)
              ) ?? '-'
        }
      />
      {/* Covering spread */}
      <BotTabData
        title='Covering spread'
        value={
          bot?.botStrategy?.isCovering
            ? bot?.botStrategy?.coverSpreads
                ?.map(
                  spread =>
                    toLocaleStringActualDecimal(toPercentage(spread)) ?? '-'
                )
                .join(', ')
            : '-'
        }
      />

      {/* Covering multiple */}
      <BotTabData
        title='Covering multiple'
        value={
          bot?.botStrategy?.isCovering
            ? bot?.botStrategy?.coverMultiples?.join(', ')
            : '-'
        }
      />

      {/* Pullback spread */}
      <BotTabData
        title='Pullback spread (%)'
        value={
          bot?.botStrategy?.isCovering
            ? bot?.botStrategy?.pullbackSpreads
                ?.map(
                  spread =>
                    toLocaleStringActualDecimal(toPercentage(spread)) ?? '-'
                )
                .join(', ')
            : '-'
        }
      />

      {/* Bot id */}
      <BotTabData title='Bot id' value={bot?.id ?? '-'} />

      {/* Create bot time */}
      <BotTabData
        title='Create bot time'
        value={
          bot?.createdAt
            ? DateTime.fromMillis(bot?.createdAt).toFormat(
                'dd-MM-yyyy HH:mm:ss'
              )
            : '-'
        }
      />

      {/* Active bot time */}
      <BotTabData
        title='Active bot time'
        value={
          bot?.startedAt
            ? DateTime.fromMillis(bot?.startedAt).toFormat(
                'dd-MM-yyyy HH:mm:ss'
              )
            : '-'
        }
      />

      {/* End at */}
      <BotTabData
        title='Finished/error time'
        value={
          bot?.endAt
            ? DateTime.fromMillis(bot?.endAt).toFormat('dd-MM-yyyy HH:mm:ss')
            : '-'
        }
      />
    </div>
  )
}

export default StrategyDetailTab
