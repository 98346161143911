import React from 'react'
import ReactPaginate from 'react-paginate'
import { NextIcon, PrevIcon } from '@components/Icons/Icons'
import { TablePaginationPropsType } from '@components/Table/component/TablePagination/interface'

import { withTablePagination } from './withTablePagination'

function TablePagination({
  paginationProps: { pageCount, gotoPage, handleOnPageChange },
  tableState: { pageIndex },
}: TablePaginationPropsType) {
  return (
    <div className='flex justify-end text-xs font-medium mt-[14px]'>
      <ReactPaginate
        previousLabel={
          <div
            className={`mx-1 ${pageIndex > 0 ? 'text-white' : 'text-gray-300'}`}
          >
            <PrevIcon />
          </div>
        }
        nextLabel={
          <div
            className={`mx-1 ${
              pageIndex + 1 < pageCount ? 'text-white' : 'text-gray-300'
            }`}
          >
            <NextIcon />
          </div>
        }
        disableInitialCallback
        pageCount={pageCount}
        initialPage={0}
        forcePage={pageIndex}
        marginPagesDisplayed={1}
        pageRangeDisplayed={3}
        onPageChange={({ selected }) => {
          gotoPage && gotoPage(selected)
          handleOnPageChange(selected + 1)
        }}
        containerClassName='flex gap-x-[5px] justify-end items-center text-center w-full mx-2 text-gray-300 '
        pageClassName='w-[22px] h-[22px] flex items-center justify-center'
        activeClassName='text-white'
        previousClassName='list-none'
        nextClassName='list-none'
        breakClassName='list-none'
      />
    </div>
  )
}

export default withTablePagination(TablePagination)
