import { gql } from 'graphql-request'
import { mainApi } from '@client/client'
import { ProfitFilterParams } from '@models/Profit/ProfitFilterParams'

export const profitApi = mainApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    profit: builder.query<{ profitUsd: number }, ProfitFilterParams>({
      query: ({ startAt, endAt, botMarketType }) => ({
        document: gql`
          query Profit(
            $startAt: Timestamp
            $endAt: Timestamp
            $botMarketType: BotMarketType
          ) {
            profit(
              start_at: $startAt
              end_at: $endAt
              botMarketType: $botMarketType
            ) {
              profitUsd
            }
          }
        `,
        variables: { startAt, endAt, botMarketType },
      }),
      transformResponse(res: { profit: { profitUsd: number } }) {
        const { profit } = res
        return profit
      },
      providesTags: (result, error, arg) => [{ type: 'Profit', ...arg }],
    }),
  }),
})

export const { useProfitQuery } = profitApi
