import React, { useState } from 'react'
import { BudgetOptionType } from '@interfaces/BudgetOptionType'
import { calculateBudgetByFormula } from '@constant/budgetCalculatorFormula'
import { BudgetCalculatorPageProps, BudgetInputType } from './interface'

const initialValue: BudgetInputType = { budget: '' }

const withBudgetCalculatorPage = (
  Component: React.FC<BudgetCalculatorPageProps>
) => {
  const Hoc = () => {
    const [budgetOptions, setBudgetOptions] = useState<BudgetOptionType[]>([])

    const handleOnSubmit = (v: BudgetInputType) => {
      setBudgetOptions(calculateBudgetByFormula(Number(v.budget)))
    }
    const handleOnClear = (
      setFieldValue: (
        field: string,
        value: any,
        shouldValidate?: boolean | undefined
      ) => void
    ) => {
      setFieldValue('budget', '')
      setBudgetOptions([])
    }
    const newProps = {
      initialValue,
      handleOnSubmit,
      handleOnClear,
      budgetOptions,
    }
    return <Component {...newProps} />
  }

  return Hoc
}
export default withBudgetCalculatorPage
