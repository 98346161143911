import { ProhibitIcon } from '@components/Icons/Icons'
import cn from 'classnames'
import withStopCoveringAllButton from '@features/bot/components/BotPagination/components/StopCoveringAllButton/withStopCoveringAllButton'
import { StopCoveringAllButtonProps } from '@features/bot/components/BotPagination/components/StopCoveringAllButton/interface'

const StopCoveringAllButton = ({
  handleOnStopCovering,
  isStopCoveringAll,
  isDisable,
}: StopCoveringAllButtonProps) => {
  return (
    <div
      onClick={handleOnStopCovering}
      className={cn(
        'flex flex-col items-center justify-center gap-0.5 h-[60px] w-[60px]',
        'border-1 rounded-[10px]',
        isDisable
          ? 'cursor-not-allowed bg-modalBg border-none'
          : 'cursor-pointer',
        isStopCoveringAll && !isDisable ? 'border-primary' : 'border-gray-300'
      )}
    >
      <ProhibitIcon
        height='22'
        width='22'
        className={cn(
          isDisable && 'text-gray-300',
          isStopCoveringAll && !isDisable && 'text-primary',
          !isStopCoveringAll && !isDisable && 'text-white'
        )}
      />
      <div
        className={cn(
          `text-center text-[10px] `,
          isDisable && 'text-gray-300',
          isStopCoveringAll && 'text-primary'
        )}
      >
        <span>Stop cover</span>
      </div>
    </div>
  )
}

const WrappedComponent = withStopCoveringAllButton(StopCoveringAllButton)
export default WrappedComponent
