import { botApi } from '@client/client'
import { gql } from 'graphql-request'
import { EasyTradeTemplateOption } from '@models/Bot/EasyTradeTemplateOption'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const easyTradeTemplateOptionsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    easyTradeTemplateOptions: builder.query<
      EasyTradeTemplateOption[],
      { botMarketType: BotMarketType; ownerId: number }
    >({
      query: ({ botMarketType, ownerId }) => ({
        operationName: 'EasyTradeTemplateOptions',
        document: gql`
          query EasyTradeTemplateOptions(
            $botMarketType: BotMarketType!
            $ownerId: Float!
          ) {
            easyTradeTemplates(
              botMarketType: $botMarketType
              ownerId: $ownerId
            ) {
              id
              name
              description
              logoImgPath
            }
          }
        `,
        variables: { botMarketType, ownerId },
      }),
      transformResponse(res: {
        easyTradeTemplates: EasyTradeTemplateOption[]
      }) {
        const { easyTradeTemplates } = res
        return easyTradeTemplates
      },
      providesTags: ['EasyTradeTemplateOptions'],
    }),
  }),
})

export const { useEasyTradeTemplateOptionsQuery } = easyTradeTemplateOptionsApi
