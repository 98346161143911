import React from 'react'
import { EmptyWalletIcon } from '@components/Icons/Icons'
import WalletBalance from '@components/WalletBalance'
import { SelectButton } from '@components/SelectButton/SelectButton'
import walletCoinSwap from '@assets/images/walletCoinSwap.png'
import khotcoin from '@assets/images/khotcoin.png'
import { KhotcoinContainerProps } from '@features/wallet/components/KhotcoinContainer/interface'
import withKhotcoinContainer from './withKhotcoinContainer'

function KhotcoinContainer({
  children,
  type,
  walletBalance,
  refetchWalletBalance,
  isWalletBalanceFetching,
  handleNavagatePage,
  handleSwitchWallet,
}: KhotcoinContainerProps) {
  return (
    <div className='max-w-[700px] mx-auto px-[17px] pt-[14px]'>
      <div className='flex items-center  mb-[24px]'>
        <EmptyWalletIcon />
        <span className='ml-[5px] text-sm font-bold'>Wallet</span>
        <div className='flex-1' />
        <div onClick={handleSwitchWallet}>
          <img
            src={walletCoinSwap}
            alt='wallet-coin-swap'
            height={40}
            width={40}
            className='cursor-pointer hover:border-1  hover:border-gray-300 holiday-overlay hover:rounded-[7px]'
          />
        </div>
      </div>
      <WalletBalance
        balance={walletBalance}
        refetchBalance={refetchWalletBalance}
        isFetching={isWalletBalanceFetching}
        title='Khotcoin balance'
        walletLogoPath={khotcoin}
        className='mb-[16px]'
      />
      <SelectButton
        options={[
          { label: 'Top up', value: 'top-up' },
          { label: 'Transaction', value: 'transaction' },
        ]}
        value={type}
        onChange={value => handleNavagatePage(value)}
      />
      <div>{children}</div>
    </div>
  )
}

export default withKhotcoinContainer(KhotcoinContainer)
