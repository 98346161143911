import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { plainToClass } from 'class-transformer'
import { ProfitHistory } from '@models/TradeCopy/ProfitHistory'

export const getTpHistoryApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getTpHistory: builder.query<
      ProfitHistory,
      {
        userId: number
        tradeCopyByPersonId: number
        page: number
        limit: number
      }
    >({
      query: ({ userId, tradeCopyByPersonId, page, limit }) => ({
        document: gql`
          query GetTpHistory(
            $userId: Float!
            $tradeCopyByPersonId: Float!
            $page: Int
            $limit: Int
          ) {
            getTpHistory(
              userId: $userId
              tradeCopyByPersonId: $tradeCopyByPersonId
              page: $page
              limit: $limit
            ) {
              bots {
                id
                endAt
                profitPercentage
                botStrategy {
                  side
                }
                baseCurrencyData {
                  imgPath
                  symbol
                }
                tradeActions {
                  quoteCurrency
                }
              }
              pagination {
                page
                limit
                totalCount
              }
            }
          }
        `,
        variables: {
          userId,
          tradeCopyByPersonId,
          page,
          limit,
        },
      }),
      transformResponse(res: { getTpHistory: ProfitHistory }) {
        return plainToClass(ProfitHistory, res.getTpHistory)
      },
      providesTags: (result, error, arg) => [{ type: 'TpHistory', ...arg }],
    }),
  }),
})

export const { useGetTpHistoryQuery } = getTpHistoryApi
