import React from 'react'
import {
  TurnOnOffAllButtonAcceptProps,
  TurnOnOffAllButtonProps,
} from '@features/bot/components/BotPagination/components/TurnOnOffAllButton/interface'
import { useModal } from '@components/Modal/context/ModalContext'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { useTurnOnOffAllBotMutation } from '@client/botClient/collections/TurnOnOffAllBotClientAction'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { toast } from 'react-toastify'
import { botApi } from '@client/client'
import { useAppDispatch } from '../../../../../../store'

const withTurnOnOffAllButton = (
  Component: React.FC<TurnOnOffAllButtonProps>
) => {
  const Hoc = ({ status }: TurnOnOffAllButtonAcceptProps) => {
    const isActive = status === BotStatusEnum.RUNNING
    const { botMarketType } = useBotMarketType()
    const dispatch = useAppDispatch()
    const [turnOnOffAllBot] = useTurnOnOffAllBotMutation()
    const { openModal } = useModal({
      component: (onConfirm: VoidFunction) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to {isActive ? 'pause' : 'resume'} all bots
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })
    const handleOnClickButton = () => {
      openModal(() => {
        turnOnOffAllBot({
          botMarketType,
          isTurnOn: !isActive,
        }).then(() => {
          toast.success(`${isActive ? 'Pause' : 'Resume'} all bots successful`)
          dispatch(botApi.util.invalidateTags([{ type: 'Bots' }]))
        })
      })
    }
    const newProps: TurnOnOffAllButtonProps = {
      isActive,
      handleOnClickButton,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withTurnOnOffAllButton
