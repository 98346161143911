import { gql } from 'graphql-request'
import { botApi } from '@client/client'
import { Bots } from '@models/Bot/Bots'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { BotSortInput } from '@models/Bot/BotSortInput'
import { BotMarketType } from '@interfaces/BotMarketEnum'

export const botsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    botIds: builder.query<
      Bots,
      {
        page: number
        botMarketType: BotMarketType
        limit: number
        status?: BotStatusEnum
        createAtStart?: number
        createAtEnd?: number
        sort?: BotSortInput
      }
    >({
      query: ({
        page,
        limit,
        status,
        createAtStart,
        createAtEnd,
        sort,
        botMarketType,
      }) => ({
        document: gql`
          query Bots(
            $page: Int
            $limit: Int
            $status: BotStatus
            $createAtStart: Timestamp
            $createAtEnd: Timestamp
            $sort: BotSortInput
            $botMarketType: BotMarketType!
          ) {
            bots(
              page: $page
              limit: $limit
              status: $status
              create_at_start: $createAtStart
              create_at_end: $createAtEnd
              sort: $sort
              bot_market_type: $botMarketType
            ) {
              bots {
                id
                baseCurrencyData {
                  imgPath
                }
              }
              pagination {
                totalPage
                totalCount
                limit
                page
              }
            }
          }
        `,
        variables: {
          page,
          limit,
          status,
          createAtStart,
          createAtEnd,
          sort,
          botMarketType,
        },
      }),
      transformResponse(res: { bots: Bots }) {
        const { bots } = res
        return bots
      },
      providesTags: (result, error, arg) => [{ type: 'Bots', ...arg }],
    }),
  }),
})

export const { useBotIdsQuery } = botsApi
