import { TradeSideEnum } from '@interfaces/TradeSideEnum'

export const sideOptions = [
  {
    label: 'Long/Buy',
    value: TradeSideEnum.LONG,
    activeClassName: 'border-1 border-green text-green ',
  },
  {
    label: 'Short/Sell',
    value: TradeSideEnum.SHORT,
    activeClassName: 'border-1 border-red text-red ',
  },
  {
    label: 'Hedge Mode',
    value: TradeSideEnum.HEDGE_MODE,
    activeClassName: `hedge-mode-button`,
  },
]
