import { botApi } from '@client/client'

import { BotProfitGroupByCoin } from '@models/Profit/BotProfitGroupByCoin'
import { BotProfitGroupByCoinInput } from '@models/Profit/BotProfitGroupByCoinInput'
import { classToPlain, plainToClass } from 'class-transformer'
import { gql } from 'graphql-request'

export const botProfitGroupByCoinApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    botProfitGroupByCoin: builder.query<
      BotProfitGroupByCoin[],
      { input: BotProfitGroupByCoinInput[] }
    >({
      query: input => ({
        document: gql`
          query BotProfitGroupByCoin($input: [BotProfitGroupByCoinInput!]!) {
            botProfitGroupByCoin(input: $input) {
              coins {
                symbol
                profit
                imgPath
              }
              end_date
              start_date
            }
          }
        `,
        variables: classToPlain(input),
      }),

      transformResponse(res: { botProfitGroupByCoin: BotProfitGroupByCoin[] }) {
        const { botProfitGroupByCoin } = res
        return plainToClass(BotProfitGroupByCoin, <any[]>botProfitGroupByCoin)
      },
      providesTags: ['BotProfitByCoin'],
    }),
  }),
})

export const { useBotProfitGroupByCoinQuery } = botProfitGroupByCoinApi
