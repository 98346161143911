import React from 'react'
import { Form, Formik } from 'formik'
import withBotSettingForm from '@features/bot/pages/CreateBotPage/components/BotSettingForm/withBotSettingForm'
import SelectField from '@components/form/SelectField/SelectField'
import { ExchangeIcon } from '@components/Icons/Icons'
import ToggleSwitchField from '@components/form/ToggleSwitchField/ToggleSwitchField'
import InputField from '@components/form/InputField/InputField'
import PrimaryButton from '@components/buttons/PrimaryButton'
import { useDrawer } from '@hooks/useDrawer'
import ButtonWithArrow from '@components/buttons/ButtonWithArrow'
import CheckboxField from '@components/form/CheckboxField/CheckboxField'
import { formEnterPrevent } from '@libs/formEnterPrevent'
import RadioInputField from '@components/form/RadioInputField/RadioInputField'
import { tpSplitOptions } from '@constant/form/createBotForm/tpSplitOptions'
import TpSplitDrawer from '@features/bot/pages/CreateBotPage/components/TpSplitDrawer/TpSplitDrawer'

import {
  advanceTpRatioTooltip,
  advanceTpRetracementTooltip,
  coveringMultiplesTooltip,
  coveringSpreadsTooltip,
  fistOrderLimitTooltip,
  limitPriceTooltip,
  orderQuantityTooltip,
  pullBackCoveringTooltip,
  stopLossLimitTooltip,
  stopLossRatioTooltip,
  stopLossTooltip,
  tPRatioTooltip,
  tPRetracementTooltip,
} from '@constant/tooltips/botTooltipsMessage'
import Tooltip from '@components/Tooltip/Tooltip'
import ToggleSwitchSimpleField from '@components/form/ToggleSwitchSimpleField/ToggleSwitchSimpleField'
import CoverMultiplesDrawer from '../CoverMultiplesDrawer/CoverMultiplesDrawer'
import CoverSpreadsDrawer from '../CoverSpreadsDrawer/CoverSpreadsDrawer'
import { BotSettingFormProps } from './interface'
import SecondStep from '../SecondStep/SecondStep'
import SelectCopyBySymbol from '../SelectCopyBySymbol'
import SelectCopyByPersonal from '../SelectCopyByPersonal'
import PullbackSpreadsDrawer from '../PullbackSpreadsDrawer/PullbackSpreadsDrawer'

const BotSettingForm = ({
  onMainFormSubmit,
  symbolOptions,
  validationSchema,
  maxLeverage,
  onSymbolChange,
  onCoverSpreadsFormSubmit,
  onCoverMultiplesFormSubmit,
  coverSpreadFormValue,
  tpRatioSplitFormValue,
  tpRetracementSplitFormValue,
  coverMultiplesFormValue,
  onCoveringQuantityChange,
  mode,
  onLeverageChange,
  handleSelectStrategy,
  selectedStrategy,
  minInitialMargin,
  mainFormInitValue,
  onTpRatioSplitFormSubmit,
  onTpRetracementSplitFormSubmit,
  tradeCopyData,
  isLoadingLeverageMargin,
  copyBySymbolBots,
  selectedBot,
  handleSelectedCopyByBotChange,
  copyByPersonalBot,
  selectedPersonalId,
  handleSelectedCopyByPersonalChange,
  pullbackSpreadsFormValue,
  onPullbackSpreadsFormSubmit,
  bot,
  handleNavigateToFAQ,
  handleOnIsFirstOrderDoubleChanged,
  isLoadingSubmitForm,
  handleToggleLimitPrice,
  isLimitPrice,
}: BotSettingFormProps) => {
  const coverSpreadsDrawer = useDrawer()
  const coverMultiplesDrawer = useDrawer()
  const tpRatioSplitDrawer = useDrawer()
  const tpRetracementSplitDrawer = useDrawer()
  const pullbackSpreadsDrawer = useDrawer()

  return (
    <div>
      <Formik
        initialValues={mainFormInitValue}
        enableReinitialize
        onSubmit={onMainFormSubmit}
        validationSchema={validationSchema}
      >
        {({ values, setFieldValue }) => (
          <>
            <Form
              onKeyDown={formEnterPrevent}
              className='grid grid-cols-1 gap-y-[30px] text-sm font-medium'
            >
              <>
                {['bot', 'copy-by'].includes(mode) ? (
                  <SelectField
                    name='symbol'
                    options={symbolOptions}
                    label='Select symbol'
                    placeholder='Select symbol'
                    leftIcon={
                      <div className='ml-[10px]'>
                        <ExchangeIcon width='16' height='16' />
                      </div>
                    }
                    onChange={onSymbolChange}
                    isSearchable
                  />
                ) : mode === 'copy-by-symbol' ? (
                  <SelectCopyBySymbol
                    selectedBot={selectedBot}
                    copyBySymbolBots={copyBySymbolBots}
                    handleSelectedCopyByBotChange={
                      handleSelectedCopyByBotChange
                    }
                  />
                ) : mode === 'copy-by-personal' ? (
                  <SelectCopyByPersonal
                    copyByPersonalBot={copyByPersonalBot}
                    selectedPersonalId={selectedPersonalId}
                    handleSelectedCopyByPersonalChange={
                      handleSelectedCopyByPersonalChange
                    }
                    selectedBot={selectedBot}
                    handleSelectedCopyByBotChange={
                      handleSelectedCopyByBotChange
                    }
                  />
                ) : (
                  <InputField
                    name='name'
                    type='text'
                    label='Strategy name'
                    placeholder='input your strategy name'
                    disabled={
                      mode === 'bot-update-all-strategy' ||
                      mode === 'bot-update-current-strategy' ||
                      mode === 'bot-update-main-strategy'
                    }
                  />
                )}
              </>

              {/*  Second step */}
              <SecondStep
                onLeverageChange={leverage =>
                  onLeverageChange(leverage, values.isFirstOrderDouble)
                }
                maxLeverage={maxLeverage}
                mode={mode}
                handleSelectStrategy={handleSelectStrategy}
                selectedStrategy={selectedStrategy}
                tradeCopyData={tradeCopyData}
              />

              {/*  Divider */}
              <div className='w-full h-[1px] bg-gray-500' />

              {/* Strategy config  */}
              <>
                <div className='flex items-center'>
                  <div className='flex-1'>Whether to open double positions</div>
                  <ToggleSwitchField
                    disabled={mode === 'bot-update-current-strategy'}
                    name='isFirstOrderDouble'
                    switchClassName='h-[24px] w-[44px]'
                    dotClassName='h-[20px] w-[20px] dot--transform-100'
                    onChange={e => {
                      handleOnIsFirstOrderDoubleChanged(e.currentTarget.checked)
                    }}
                  />
                </div>
                <div className='flex items-center'>
                  <div className='flex-1'>
                    Whether to open covered positions
                  </div>
                  <ToggleSwitchField
                    disabled={mode === 'bot-update-current-strategy'}
                    name='isCovering'
                    switchClassName='h-[24px] w-[44px]'
                    dotClassName='h-[20px] w-[20px] dot--transform-100'
                    onChange={e => {
                      if (!e.currentTarget.checked) {
                        setFieldValue('coveringQuantity', 1)
                        onCoveringQuantityChange(1)
                      } else {
                        setFieldValue('coveringQuantity', 6)
                        onCoveringQuantityChange(6)
                      }
                    }}
                  />
                </div>
                {[
                  'bot',
                  'bot-update-all-strategy',
                  'bot-update-main-strategy',
                  'bot-update-current-strategy',
                  'copy-by',
                  'copy-by-symbol',
                  'copy-by-personal',
                ].includes(mode) && (
                  <>
                    {/*  Divider */}
                    <div className='w-full h-[1px] bg-gray-500' />
                    {/* Limit price */}
                    <div className='flex flex-col gap-y-4'>
                      <div className='flex justify-between items-center'>
                        <div className='flex flex-row space-x-2 '>
                          <div className='flex-1'>Limit price</div>
                          <Tooltip
                            title='Limit price'
                            text={limitPriceTooltip}
                          />
                        </div>
                        <ToggleSwitchSimpleField
                          name='isLimitPrice'
                          value={isLimitPrice}
                          isShowValueInText
                          switchClassName='h-[24px] w-[44px]'
                          dotClassName='h-[20px] w-[20px] dot--transform-100'
                          disabled={
                            ![
                              'bot',
                              'copy-by',
                              'copy-by-symbol',
                              'copy-by-personal',
                            ].includes(mode)
                          }
                          onChange={e => {
                            handleToggleLimitPrice(e.target.checked)
                            if (!e.target.checked) {
                              setFieldValue('limitPrice', undefined)
                            }
                          }}
                        />
                      </div>

                      {isLimitPrice && (
                        <InputField
                          disabled={
                            ![
                              'bot',
                              'copy-by',
                              'copy-by-symbol',
                              'copy-by-personal',
                            ].includes(mode)
                          }
                          name='limitPrice'
                          type='number'
                          placeholder='Limit price'
                          rightIcon={
                            <span className='text-sm font-medium'>USDT</span>
                          }
                        />
                      )}
                    </div>
                    {/*  Divider */}
                    <div className='w-full h-[1px] bg-gray-500' />
                  </>
                )}
                <InputField
                  disabled={mode === 'bot-update-current-strategy'}
                  name='firstOrderRawQuote'
                  type='number'
                  label='First order Limit'
                  rightIcon={<span className='text-sm font-medium'>USDT</span>}
                  tooltip={fistOrderLimitTooltip}
                />
                <div className='flex justify-between'>
                  <div className='text-gray-59'>
                    initial margin should greater than
                  </div>
                  <div className='text-gray-59'>
                    {minInitialMargin || '-'} USDT
                  </div>
                </div>
                {values.isCovering && (
                  <InputField
                    name='coveringQuantity'
                    type='number'
                    label='Order quantity'
                    rightIcon={
                      <span className='text-sm font-medium'>Order(s)</span>
                    }
                    onChange={value => onCoveringQuantityChange(Number(value))}
                    tooltip={orderQuantityTooltip}
                  />
                )}
                {values.isCovering && (
                  <RadioInputField
                    name='isSplitTp'
                    options={tpSplitOptions}
                    direction='horizontal'
                  />
                )}
                {values.isSplitTp === 'split' && values.isCovering ? (
                  <ButtonWithArrow
                    onClick={tpRetracementSplitDrawer.handleOpenDrawer}
                    type='button'
                    label='TP Retracement'
                    tooltip={advanceTpRetracementTooltip}
                  >
                    {tpRetracementSplitFormValue.tpSplitField.join(', ')} %
                  </ButtonWithArrow>
                ) : (
                  <InputField
                    name='tpRetracement'
                    type='number'
                    label='TP Retracement'
                    rightIcon={<span className='text-sm font-medium'>%</span>}
                    tooltip={tPRetracementTooltip}
                  />
                )}

                {values.isSplitTp === 'split' && values.isCovering ? (
                  <ButtonWithArrow
                    onClick={tpRatioSplitDrawer.handleOpenDrawer}
                    type='button'
                    label='TP Ratio'
                    tooltip={advanceTpRatioTooltip}
                  >
                    {tpRatioSplitFormValue.tpSplitField.join(', ')} %
                  </ButtonWithArrow>
                ) : (
                  <InputField
                    name='tpRatio'
                    type='number'
                    label='TP Ratio'
                    rightIcon={<span className='text-sm font-medium'>%</span>}
                    tooltip={tPRatioTooltip}
                  />
                )}

                {/*  Divider */}
                <div className='w-full h-[1px] bg-gray-500' />

                {/*  Stop loss */}
                <div className='flex justify-between items-center'>
                  <div className='flex flex-row space-x-2 '>
                    <div className='flex-1'>Stop loss</div>
                    <Tooltip title='Stop loss' text={stopLossTooltip} />
                  </div>
                  <ToggleSwitchField
                    name='isStopLoss'
                    switchClassName='h-[24px] w-[44px]'
                    dotClassName='h-[20px] w-[20px] dot--transform-100'
                    onChange={e => {
                      if (!e.target.checked) {
                        setFieldValue('stopLossRatio', undefined)
                        setFieldValue('stopLossLimit', undefined)
                      }
                    }}
                  />
                </div>
              </>
              {values.isStopLoss && (
                <>
                  <InputField
                    name='stopLossRatio'
                    type='number'
                    label='Stop loss ratio'
                    disabled={!!values.stopLossLimit}
                    rightIcon={<span className='text-sm font-medium'>%</span>}
                    tooltip={stopLossRatioTooltip}
                  />
                  <InputField
                    name='stopLossLimit'
                    type='number'
                    label='Stop loss limit'
                    disabled={!!values.stopLossRatio}
                    rightIcon={
                      <span className='text-sm font-medium'>Unit</span>
                    }
                    tooltip={stopLossLimitTooltip}
                  />
                </>
              )}

              {/*  Cover fields */}
              {values.isCovering && (
                <>
                  {/*  Divider */}
                  <div className='w-full h-[1px] bg-gray-500' />

                  <ButtonWithArrow
                    onClick={() => coverSpreadsDrawer.handleOpenDrawer()}
                    type='button'
                    label='Covering spreads'
                    tooltip={coveringSpreadsTooltip}
                  >
                    {coverSpreadFormValue.coverSpreads
                      .flatMap(value => value.value)
                      .join(', ')}{' '}
                    %
                  </ButtonWithArrow>
                  <ButtonWithArrow
                    onClick={() => pullbackSpreadsDrawer.handleOpenDrawer()}
                    type='button'
                    label='Pullback spreads'
                    tooltip={pullBackCoveringTooltip}
                  >
                    {pullbackSpreadsFormValue.pullbackSpreads
                      .map(value => (value as any).value)
                      .join(', ')}{' '}
                    %
                  </ButtonWithArrow>
                  <ButtonWithArrow
                    onClick={() => coverMultiplesDrawer.handleOpenDrawer()}
                    type='button'
                    label='Cover multiples'
                    tooltip={coveringMultiplesTooltip}
                  >
                    {coverMultiplesFormValue.coverMultiples.join(', ')}
                  </ButtonWithArrow>
                </>
              )}
              {selectedStrategy && mode === 'bot' ? (
                <CheckboxField name='isSaveStrategy' label='Save changes' />
              ) : null}

              {mode === 'bot' && (
                <CheckboxField
                  name='isCopyable'
                  label={
                    <div>
                      Show your strategy in Trade copy.{' '}
                      <span
                        className='text-primary cursor-pointer'
                        onClick={e => {
                          e.stopPropagation()
                          handleNavigateToFAQ()
                        }}
                      >
                        More detail
                      </span>
                    </div>
                  }
                />
              )}

              {/* Submit button */}
              <div className='flex gap-x-4 mt-[30px]'>
                <PrimaryButton
                  type='submit'
                  className='w-full p-[12px] rounded-[18px]'
                  disabled={isLoadingLeverageMargin || isLoadingSubmitForm}
                >
                  {isLoadingSubmitForm ? 'Loading...' : 'Confirm'}
                </PrimaryButton>
              </div>
            </Form>
            <CoverMultiplesDrawer
              bot={bot}
              drawer={coverMultiplesDrawer}
              initialValues={coverMultiplesFormValue}
              onSubmit={onCoverMultiplesFormSubmit}
              isFirstOrderDouble={values.isFirstOrderDouble}
            />
          </>
        )}
      </Formik>
      <TpSplitDrawer
        drawer={tpRetracementSplitDrawer}
        initialValues={tpRetracementSplitFormValue}
        onSubmit={onTpRetracementSplitFormSubmit}
        desc='Take profit retracement'
        title='Advance take profit retracement'
      />
      <TpSplitDrawer
        bot={bot}
        drawer={tpRatioSplitDrawer}
        initialValues={tpRatioSplitFormValue}
        onSubmit={onTpRatioSplitFormSubmit}
        desc='Take profit ratio'
        title='Advance take profit ratio'
      />
      <CoverSpreadsDrawer
        bot={bot}
        drawer={coverSpreadsDrawer}
        initialValues={coverSpreadFormValue}
        onSubmit={onCoverSpreadsFormSubmit}
      />

      <PullbackSpreadsDrawer
        bot={bot}
        drawer={pullbackSpreadsDrawer}
        initialValues={pullbackSpreadsFormValue}
        onSubmit={onPullbackSpreadsFormSubmit}
      />
    </div>
  )
}

export default withBotSettingForm(BotSettingForm)
