import { useAllTierQuery } from '@client/mainClient/collections/AllTierClientAction'
import { useProfileQuery } from '@client/mainClient/collections/ProfileClientAction'
import { useModal } from '@components/Modal/context/ModalContext'
import { useResponsive } from '@hooks/useResponsive'
import React from 'react'
import Loading from '@components/utils/Loading'
import {
  TierAcceptProps,
  TierProps,
} from '@features/other/pages/ReferralPage/components/Tier/interface'
import TierSteps from './components/TierSteps'

function withTier(Component: React.FC<TierProps>) {
  function Hoc({ dateRangeType }: TierAcceptProps) {
    const { isMobile } = useResponsive()
    const { data: profile, isLoading: isLoadingProfile } = useProfileQuery({
      calculatedTeamProfit: dateRangeType,
      calculatedTotalProfitDateRangeType: dateRangeType,
      calculatedTotalReferralDateRangeType: dateRangeType,
    })
    const { data: allTier, isLoading: isLoadingAllTier } = useAllTierQuery(
      undefined,
      {
        refetchOnMountOrArgChange: true,
      }
    )

    const { openModal } = useModal({
      component: () => (
        <TierSteps
          allTier={allTier ?? []}
          currentTierId={profile!.userTier.id}
        />
      ),
    })

    const handleInfoClick = () => {
      openModal()
    }

    if (isLoadingProfile || isLoadingAllTier) {
      return <Loading text='Loading...' />
    }
    const newProps = {
      referralCode: profile?.referralCode || '',
      allTier: allTier ?? [],
      profile: profile!,
      isMobile,
      handleInfoClick,
    }

    return <Component {...newProps} />
  }
  return Hoc
}

export default withTier
