import { gql } from 'graphql-request'
import { plainToClass } from 'class-transformer'
import { botApi } from '@client/client'
import { TradeCopyByPerson } from '@models/TradeCopy/TradeCopyByPerson'

export const getMyTradeCopyByPersonsApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    getMyTradeCopyByPersons: builder.query<TradeCopyByPerson[], void>({
      query: () => ({
        document: gql`
          query GetMyTradeCopyByPersons {
            getMyTradeCopyByPersons {
              id
              user {
                name
                profileImgPath
              }
              buyDate
            }
          }
        `,
      }),
      transformResponse(res: { getMyTradeCopyByPersons: TradeCopyByPerson[] }) {
        return plainToClass(
          TradeCopyByPerson,
          <any[]>res.getMyTradeCopyByPersons
        )
      },
    }),
  }),
})

export const { useGetMyTradeCopyByPersonsQuery } = getMyTradeCopyByPersonsApi
