import React from 'react'
import { useModal } from '@components/Modal/context/ModalContext'
import {
  ManualCoveringButtonAcceptProps,
  ManualCoveringButtonProps,
} from '@features/bot/pages/BotPage/components/AddCoverButton/interface'
import { useManualCoveringMutation } from '@client/botClient/collections/ManualCoveringClientAction'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import { toast } from 'react-toastify'

const WithManualCoveringButton = (
  Component: React.FC<ManualCoveringButtonProps>
) => {
  const Hoc = ({
    bot,
    refetch,
    isDisable,
  }: ManualCoveringButtonAcceptProps) => {
    const [manualCoveringMutate, { isLoading: isManualCoveringLoading }] =
      useManualCoveringMutation()
    const { openModal: openManualCoveringModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Manual Covering</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to manual cover this round?
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleManualCovering = async () => {
      if (isDisable) return
      if (bot!.coverRound < bot!.botStrategy.coveringQuantity) {
        await openManualCoveringModal({
          symbol: bot!.symbol,
          onConfirm: async () => {
            manualCoveringMutate({
              botId: bot!.id,
            })
              .then(() => {
                toast.success(`Manual covering successful!`)
                refetch()
              })
              .catch(() => {})
          },
        })
      } else {
        toast.error(
          'Covering is already max. Please edit covering amount on your bot strategy to increase covering amount'
        )
      }
    }

    const newProps: ManualCoveringButtonProps = {
      handleManualCovering,
      isDisable,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default WithManualCoveringButton
