import { gql } from 'graphql-request'
import { botApi } from '@client/client'

export const deleteBotStrategyApi = botApi.injectEndpoints({
  overrideExisting: false,
  endpoints: builder => ({
    deleteBotStrategy: builder.mutation<boolean, { botStrategyId: number }>({
      query: ({ botStrategyId }) => ({
        document: gql`
          mutation DeleteBotStrategy($botStrategyId: Float!) {
            deleteBotStrategy(botStrategyId: $botStrategyId)
          }
        `,
        variables: {
          botStrategyId,
        },
      }),
    }),
  }),
})

export const { useDeleteBotStrategyMutation } = deleteBotStrategyApi
