import { RankingChartProps } from '@features/other/pages/RankingChartPage/interface'
import ProfileHeader from '@components/ProfileHeader/ProfileHeader'
import ReactECharts from 'echarts-for-react'
import RankingChartCard from '@features/other/pages/RankingChartPage/components/RankingChartCard'
import withRankingChartPage from './withRankingChartPage'

const RankingChartPage = ({
  rank,
  userName,
  profileImgPath,
  strategyDescription,
  timeSeries,
  echartOption,
  mode,
}: RankingChartProps) => {
  return (
    <div className='max-w-[700px] mx-auto desktop:mt-2 mb-9'>
      <ProfileHeader
        name={userName}
        description={strategyDescription}
        profileImgPath={profileImgPath}
        rank={rank}
        className='mb-[32px]'
      />
      <div className='px-[16px]'>
        {/* Chart */}
        <div className='flex flex-col space-y-[16px] mb-[32px]'>
          <p className='font-bold leading-none'>
            {mode === 'daily'
              ? 'Daily profit (Daily)'
              : 'Total profit (Monthly)'}
          </p>
          <div className='bg-white rounded-[10px] mobile:h-[160px] h-[240px] w-full]'>
            <ReactECharts
              option={echartOption}
              style={{
                height: '100%',
                width: '100%',
                backgroundColor: '#262829',
                borderRadius: '10px',
                border: 'none',
              }}
            />
          </div>
        </div>
        {/* Card */}
        <div className='flex flex-col space-y-[10px]'>
          {timeSeries.map((value, index) => (
            <RankingChartCard key={index} timeSeries={value} mode={mode} />
          ))}
        </div>
      </div>
    </div>
  )
}

const WrappedComponent = withRankingChartPage(RankingChartPage)
export default WrappedComponent
