import React, { useMemo, useState } from 'react'
import { CreateEasyBotPageProps } from '@features/bot/pages/CreateEasyBotPage/interface'
import { CreateBotFromEasyTradeForm } from '@models/Bot/CreateBotFromEasyTradeForm'
import { useEasyTradeTemplateOptionsQuery } from '@client/botClient/collections/EasyTradeTemplateOptionsClientAction'
import { useLazyEasyTradeTemplateDefaultQuery } from '@client/botClient/collections/EasyTradeTemplateDefaultClientAction'
import { EasyTradeTemplateDefault } from '@models/Bot/EasyTradeTemplateDefault'
import { initialEasyTradeValues } from '@constant/form/easyTradeForm/initialEasyTradeValues'
import { classToPlain, plainToClass } from 'class-transformer'
import { easyTradeSchema } from '@constant/form/easyTradeForm/validationSchemaEasyTrade'
import { useCreateBotFromEasyTradeMutation } from '@client/botClient/collections/CreateBotFromEasyTradeClientAction'
import { useModal } from '@components/Modal/context/ModalContext'
import { ConfirmModalProps } from '@components/Modal/components/ConfirmModal/interface'
import ConfirmModal from '@components/Modal/components/ConfirmModal'
import { toast } from 'react-toastify'
import { useNavigate, useParams } from 'react-router-dom'
import { BotStatusEnum } from '@interfaces/BotStatusEnum'
import { useBotMarketType } from '@features/bot/components/BotTypeContext/BotTypeContext'
import { ContractRoutesPath, SpotRoutesPath } from '@config/Router'

const withCreateEasyBotPage = (Component: React.FC<CreateEasyBotPageProps>) => {
  const Hoc = () => {
    const { ownerId } = useParams()
    const { isFuture, botMarketType } = useBotMarketType()
    const botRoute = isFuture ? ContractRoutesPath : SpotRoutesPath
    const navigate = useNavigate()
    const [selectedTemplate, setSelectedTemplate] =
      useState<EasyTradeTemplateDefault>()
    const [formInitialValue, setFormInitialValue] =
      useState<CreateBotFromEasyTradeForm>(initialEasyTradeValues)

    const {
      data: easyTradeTemplateOptions,
      isLoading: isLoadingTemplateOptions,
    } = useEasyTradeTemplateOptionsQuery({
      botMarketType,
      ownerId: parseInt(ownerId!, 10),
    })
    const [
      queryEasyTradeTemplateDefault,
      { isLoading: isLoadingTemplateDetail },
    ] = useLazyEasyTradeTemplateDefaultQuery()
    const [createBotMutate, { isLoading: isSubmitting }] =
      useCreateBotFromEasyTradeMutation()

    const validationSchema = useMemo(() => {
      return easyTradeSchema({
        minimumPerSymbol: selectedTemplate?.minimumPerSymbol ?? 0,
      })
    }, [selectedTemplate])

    const { openModal } = useModal({
      component: ({ onConfirm }: ConfirmModalProps) => (
        <ConfirmModal
          message={
            <div className='flex flex-col items-center'>
              <div className='text-lg font-medium'>Confirm</div>
              <p className='mt-[7px] text-sm'>
                Are you sure to create bot from easy trade
              </p>
            </div>
          }
          onConfirm={onConfirm}
        />
      ),
    })

    const handleOnSelectTemplate = async (templateId: number) => {
      queryEasyTradeTemplateDefault({
        id: templateId,
      })
        .unwrap()
        .then(templateDetail => {
          setSelectedTemplate(templateDetail)
          setFormInitialValue(
            plainToClass(
              CreateBotFromEasyTradeForm,
              {
                easyTradeId: templateId,
                symbols: templateDetail.symbols,
                totalInvestment:
                  templateDetail.symbols.length *
                  templateDetail.minimumPerSymbol,
              },
              { excludeExtraneousValues: true }
            )
          )
        })
    }

    const handleOnFormSubmit = (form: CreateBotFromEasyTradeForm) => {
      openModal({
        onConfirm: () => {
          const plainForm: Record<string, any> = classToPlain(form, {
            excludeExtraneousValues: true,
          })
          createBotMutate(plainForm as CreateBotFromEasyTradeForm)
            .unwrap()
            .then(() => {
              toast.success('Create bot success')
              navigate({
                pathname: botRoute.dashboard.absolutePath,
                search: `?tab=${BotStatusEnum.RUNNING}`,
              })
            })
            .catch(() => {})
        },
      })
    }

    const newProps: CreateEasyBotPageProps = {
      easyTradeTemplateOptions,
      isLoadingTemplateOptions,
      selectedTemplate,
      isLoadingTemplateDetail,
      formInitialValue,
      validationSchema,
      isSubmitting,
      handleOnFormSubmit,
      handleOnSelectTemplate,
    }
    return <Component {...newProps} />
  }
  return Hoc
}
export default withCreateEasyBotPage
